import { CircularProgress } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { PatientContext, usePatientContextQuery } from '@nuna/api'
import { buildRedirectParams, useAuthDataContext } from '@nuna/auth'

import { useIntakeConditions } from '../scenes/intake/hooks/useIntakeConditions'
import { CenterContent } from '../shared/components/Layout'
import { AbilityContext } from '../util/Can'
import { buildAbility } from '../util/ability'

export function ClientInitializer() {
  const { loading: loadingAuthContext, login } = useAuthDataContext()
  const { hasAppointment } = useIntakeConditions()
  const { loading: loadingPatientContext, data } = usePatientContextQuery()
  const location = useLocation()
  const loading = loadingAuthContext || loadingPatientContext

  if (loading) {
    return (
      <CenterContent style={{ marginTop: '6rem' }}>
        <CircularProgress />
      </CenterContent>
    )
  } else if (data?.patientContext && login?.id) {
    const ability = buildAbility(data?.patientContext as PatientContext)

    const patient = data?.patientContext?.patient
    const company = data?.patientContext?.company

    // Handle Mixpanel Tracking
    mixpanel.identify(patient.id)
    mixpanel.register({
      id: patient.id,
      patientId: patient.id,
      companyId: company?.id,
      companyName: company?.name,
    })
    mixpanel.people.set({
      id: patient.id,
      patientId: patient.id,
      companyId: company?.id,
      companyName: company?.name,
    })

    if (!patient.startIntakePermitted && location?.pathname !== '/parent-guardian') {
      return <Navigate to={{ pathname: '/parent-guardian' }} state={{ from: location.pathname }} />
    }

    // patients that already have appointments (via Zocdoc, etc.) but haven't finished intake must complete intake before being let into the app (so we can get their payment details)
    if (!patient.intakeCompleted && hasAppointment && !(location?.pathname ?? '').startsWith('/intake')) {
      return <Navigate to={{ pathname: '/intake' }} state={{ from: location.pathname }} />
    }

    return (
      <AbilityContext.Provider value={ability}>
        <Outlet />
      </AbilityContext.Provider>
    )
  } else {
    if (location.pathname === '/') {
      return <Navigate to={{ pathname: '/signup', search: buildRedirectParams() }} />
    }

    return <Navigate to={{ pathname: '/login', search: buildRedirectParams() }} />
  }
}

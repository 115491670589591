import { CurrentAvailability } from '@nuna/api'
import { AccordionProps, Radio } from '@nuna/tunic'

import { useSearchProviderContext } from '../SearchProviderContext'
import { searchProviderUtils } from '../search-provider.utils'
import { AccordionTypeProps, FilterAccordion, FilterAccordionLabel, FilterSection } from './FilterAccordion'
import { GeneralAvailabilityControl } from './GeneralAvailabilityControl'

const currentAvailabilityOptions = [
  { label: 'Today or tomorrow', value: CurrentAvailability.Tomorrow },
  { label: 'Next 7 days', value: CurrentAvailability.NextWeek },
  { label: 'Next 30 days', value: CurrentAvailability.Month },
]

export function AvailabilityAccordion(props: AccordionTypeProps) {
  const { appliedFilters, toggleCurrentAvailability } = useSearchProviderContext()

  const sections: AccordionProps['sections'] = []

  sections.push({
    label: props => (
      <FilterAccordionLabel {...props} filtersApplied={appliedFilters.currentAvailability.length}>
        Current Openings
      </FilterAccordionLabel>
    ),
    content: (
      <FilterSection>
        <div role="group" style={{ position: 'relative' }}>
          {currentAvailabilityOptions.map(option => (
            <Radio
              name="currentAvailability"
              key={option.value}
              onChange={() => toggleCurrentAvailability(option.value)}
              checked={appliedFilters.currentAvailability.includes(option.value)}
            >
              {option.label}
            </Radio>
          ))}
        </div>
      </FilterSection>
    ),
  })

  sections.push({
    label: props => (
      <FilterAccordionLabel
        {...props}
        filtersApplied={searchProviderUtils.generalAvailabilityFiltersCount(appliedFilters.generalAvailability)}
      >
        Office Hours
      </FilterAccordionLabel>
    ),
    content: <GeneralAvailabilityControl />,
    defaultOpen: false,
  })

  return <FilterAccordion {...props} sections={sections} />
}

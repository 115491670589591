import * as Yup from 'yup'

import { EmployerAssociationForm, employerCoverageSchema } from '@nuna/coverage'

import { SignupFormStepProps } from '../SignupForm'

export interface NameEmailValues {
  dob: string
  parentGuardianEmail: string
}

export const employerAssociationSchema = Yup.object().shape({
  employerCoverage: employerCoverageSchema,
})

export function EmployerAssociationStep({ formikProps }: SignupFormStepProps) {
  const { handleChange, errors, touched, values, handleBlur } = formikProps

  const onBlurOnChange = {
    onBlur: handleBlur,
    onChange: handleChange,
  }

  const commonProps = {
    ...onBlurOnChange,
    name: 'employerCoverage.employerAssociation',
  }

  const employerAssociationEmployeeProps = {
    ...onBlurOnChange,
    fullWidth: true,
    name: 'employerCoverage.employerAssociationEmployee',
  }

  return (
    <div>
      <EmployerAssociationForm
        {...{
          employerValues: values.employerCoverage,
          touched,
          errors,
          commonProps,
          employerAssociationEmployeeProps,
          isCheckout: false,
        }}
      />
    </div>
  )
}

import { useIntakeProgressQuery, usePatientContextQuery } from '@nuna/api'

export function useIntakeConditions() {
  const { data: patientContextData } = usePatientContextQuery()
  const { data: intakeProgressData, refetch: refetchIntakeProgressQueryForConditions } = useIntakeProgressQuery({
    variables: { id: patientContextData?.patientContext.patient.id ?? '' },
    skip: !patientContextData,
  })

  const hasMedMgmt = patientContextData?.patientContext.company?.contract?.hasMedMgmt
  const hasCompany = patientContextData?.patientContext.company !== null

  const shouldShowMedManagement = hasCompany && hasMedMgmt

  const shouldShowTherapistPreferences = (patientContextData?.patientContext.patient.providers ?? []).length === 0
  const hasAppointment =
    (patientContextData?.patientContext.patient.appointments ?? []).filter(appointment => !appointment.canceled)
      .length > 0
  const hasProvider = (patientContextData?.patientContext.patient.providers ?? []).length > 0
  const hasStateMismatch = (intakeProgressData?.intakeProgress.patientProviderStateMismatch ?? []).length > 0

  return {
    shouldShowEmployerAssociation: hasCompany,
    shouldShowMedManagement,
    shouldShowTherapistPreferences,
    hasAppointment,
    hasStateMismatch,
    hasProvider,
    refetchIntakeProgressQueryForConditions,
  }
}

import { styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

import { PatientPaymentStatus, usePatientContextQuery } from '@nuna/api'
import { IconRelationship, IconSwitch } from '@nuna/tunic'
import { FillButtonLink, GhostButton, Grid, borderGrey, greySet } from '@nuna/tunic'

import { getSelectProviderRoute } from '../../../util/routes'
import { CenterContent } from '../Layout'
import { ProviderCard } from '../NewProviderCard'
import therapistIllustrationSrc from './img/more-therapists.png'

const Container = styled('div')`
  margin-bottom: 1.26rem;
`

export function ProviderList() {
  const { error, data } = usePatientContextQuery({ fetchPolicy: 'cache-and-network' })

  if (!data || error) {
    return null
  }

  const {
    patientContext: { patient },
  } = data

  const providers = patient.providers
  const patientPaymentStatusIsOverdue = patient.paymentStatus === PatientPaymentStatus.Overdue
  const title = providers.length > 1 ? 'Care Team' : 'Your Therapist'

  return (
    <Container>
      <h2 className="h4">
        <IconRelationship size={28} className="mr-1" />
        {title}
      </h2>

      {providers.length ? (
        <Grid container spacing={3}>
          {providers.map(provider => (
            <Grid
              key={provider.id}
              size={{
                xs: 12,
                sm: 6,
                md: 4,
              }}
            >
              <ProviderCard
                key={provider.id}
                provider={provider}
                scheduleAppointmentDisabled={patientPaymentStatusIsOverdue}
              />
            </Grid>
          ))}
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 4,
            }}
          >
            <BrowseMoreCard to="/switch-provider">
              <div>
                <img src={therapistIllustrationSrc} alt="" className="mb-3" style={{ width: '180px' }} />
                <p className="text-secondary mb-3" style={{ maxWidth: '202px' }}>
                  Finding the best fit impacts your therapy experience in a big way.
                </p>

                <GhostButton
                  disabled={patientPaymentStatusIsOverdue}
                  variant={patientPaymentStatusIsOverdue ? 'secondary' : 'primary'}
                >
                  <IconSwitch size={24} className="mr-1" />
                  <span>Change Therapist</span>
                </GhostButton>
              </div>
            </BrowseMoreCard>
          </Grid>
        </Grid>
      ) : (
        <CenterContent style={{ border: '2px solid #eee', borderRadius: '.735rem', padding: '1.26rem' }}>
          <FillButtonLink to={getSelectProviderRoute()}>Choose Your Therapist</FillButtonLink>
        </CenterContent>
      )}
    </Container>
  )
}

const BrowseMoreCard = styled(NavLink)`
  align-items: center;
  background-color: ${greySet[5].hex};
  border: 1px solid ${borderGrey};
  border-radius: var(--border-radius);
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 1.5rem;
  text-align: center;

  &,
  &:hover {
    text-decoration: none;
  }
`

import { PaymentPreference, usePatientCoverageQuery } from '@nuna/api'
import { usePatientId } from '@nuna/auth'
import { coverageService } from '@nuna/core'

/** There is not a whole lot of magic here, just a hook to make it more convenient to access these values from various parts of intake w/o having to query and pass them into services.
 * If more state or complex querying logic is needed you could move it into IntakeContext, but since the query is using Apollo cache, the cache is working as its own sort of context, so I've kept them separate for now.
 */

export function useIntakeCoverageStatus(options?: Parameters<typeof usePatientCoverageQuery>[0]) {
  const patientId = usePatientId()
  const { data, refetch } = usePatientCoverageQuery({
    variables: { patientId },
    ...options,
  })

  const coverages = coverageService.getMostRecentCoverageByType(data?.patientCoverage ?? [])

  return {
    coverages,
    primaryCoverage: coverageService.getPrimaryCoverage(data?.patientCoverage ?? []),
    hasValidCoverage: coverageService.hasValidCoverage(coverages),
    hasValidEAP: coverageService.isCoverageValid(coverages[PaymentPreference.Employer]?.details),
    hasValidInsurance: coverageService.isCoverageValid(coverages[PaymentPreference.Insurance]?.details),
    needsAdditionalZeroCapCoverage: coverageService.needsAdditionalZeroCapCoverage(coverages),
    hasUploadedInsuranceCard: coverageService.hasUploadedInsuranceCard(coverages[PaymentPreference.Insurance]?.details),
    employerName: coverages[PaymentPreference.Employer]?.details?.employerName,
    refetch,
  }
}

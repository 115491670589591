import * as Yup from 'yup'
import { FormHelperText } from '@mui/material'
import { FieldArray, Formik } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import { IntakeProgressQuery, PatientMedManagementInput, useSavePatientMedicationPreferencesMutation } from '@nuna/api'
import { usePatientId } from '@nuna/auth'
import { carePreferencesService, formService } from '@nuna/core'
import { ChipGroup, Grid, PlaceholderButton, Radio } from '@nuna/tunic'

import { Collapser } from '../../../../shared/components/Collapser'
import { TextField } from '../../../../shared/components/TextField'
import { Tip } from '../../../../shared/components/Tip'
import { Container } from '../../InsuranceIntake'
import { useInsuranceIntakeContext } from '../../InsuranceIntakeContext'
import { IntakeNavigation } from '../../IntakeNavigation'
import { useIntakeNavigation } from '../../hooks/useIntakeNavigation'
import { YesNo } from '../../intakeConstants'
import { MedicationFormRow } from './MedicationFormRow'

const { parseMedications, medicationsToJSON } = carePreferencesService
const { makeSelection, oopsRequired, getFieldProps } = formService

interface Medication {
  name: string
  dose: string
  frequency: string
}

export interface MedicationFormValues {
  wantsMedMgmt: YesNo | null
  medications: Medication[]
  allergies: YesNo | null
  allergiesExplanation: string
}

const medicationSchema = Yup.object<MedicationFormValues>({
  wantsMedMgmt: Yup.mixed<YesNo>().nullable().required(makeSelection),
  medications: Yup.array<Medication>(
    Yup.object({
      name: Yup.string().required(oopsRequired('medication name')),
      dose: Yup.string().required(oopsRequired('dosage')),
      frequency: Yup.string().required(oopsRequired('frequency')),
    }),
  ),
  allergies: Yup.mixed<YesNo>().when('wantsMedMgmt', {
    is: wantsMedMgmt => wantsMedMgmt === YesNo.Yes,
    then: Yup.string().nullable().required(makeSelection),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  allergiesExplanation: Yup.string().when('allergies', {
    is: allergies => allergies === YesNo.Yes,
    then: Yup.string().required(oopsRequired('allergies')),
    otherwise: Yup.string().notRequired(),
  }),
})

export function Medication() {
  const patientId = usePatientId() ?? ''
  const { intakeProgressData, setIsMutationLoading } = useInsuranceIntakeContext()
  const { nextPath } = useIntakeNavigation()
  const navigate = useNavigate()

  const [savePatientMedicationPreferences] = useSavePatientMedicationPreferencesMutation()

  const handleSubmit = async (values: MedicationFormValues) => {
    setIsMutationLoading(true)
    await savePatientMedicationPreferences({
      variables: {
        id: patientId,
        preferences: getMedicationInput(values),
      },
    })

    setIsMutationLoading(false)

    navigate(`../${nextPath}`)
  }

  if (!intakeProgressData) {
    return null
  }

  return (
    <Formik
      initialValues={buildInitialValues(intakeProgressData)}
      validationSchema={medicationSchema}
      onSubmit={handleSubmit}
    >
      {formikProps => {
        const { values, handleSubmit, errors, handleBlur, touched } = formikProps

        const wantsMedMgmtProps = getFieldProps('wantsMedMgmt', formikProps)
        const allergiesProps = getFieldProps('allergies', formikProps)
        const medicationsProps = getFieldProps('medications', formikProps)

        return (
          <form onSubmit={handleSubmit}>
            <Container>
              <h1 id="medication-heading" className="h5 mb-4">
                Are you interested in talking to one of our clinicians about medication options?
              </h1>

              <div role="group" aria-labelledby="medication-heading">
                {[YesNo.Yes, YesNo.No].map(value => (
                  <Radio
                    key={value}
                    name={wantsMedMgmtProps.name}
                    value={value}
                    onChange={wantsMedMgmtProps.onChange}
                    checked={values[wantsMedMgmtProps.name] === value}
                    inline
                    dataTestId={`${wantsMedMgmtProps.name}-${value}`}
                  >
                    {value}
                  </Radio>
                ))}

                {wantsMedMgmtProps.helperText && (
                  <FormHelperText error={wantsMedMgmtProps.error}>{wantsMedMgmtProps.helperText}</FormHelperText>
                )}
              </div>

              <Tip
                intro="Affects your care team:"
                description="We'll automatically add a medication prescriber to your care team."
              />

              <AnimatePresence>
                {values.wantsMedMgmt === YesNo.Yes && (
                  <motion.div
                    key="medication-followup"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <h2 className="h5 mt-6">What medications (if any) are you currently taking?</h2>

                    <FieldArray name={medicationsProps.name}>
                      {arrayHelpers => (
                        <>
                          <Grid container spacing={4}>
                            {values.medications.map((_, index) => (
                              <MedicationFormRow
                                key={index}
                                name={medicationsProps.name}
                                rowValues={values?.[medicationsProps.name]?.[index]}
                                rowErrors={errors?.[medicationsProps.name]?.[index]}
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                rowTouched={(touched?.[medicationsProps.name] as any)?.[index]}
                                arrayHelpers={arrayHelpers}
                                index={index}
                                getFieldProps={getFieldProps}
                                formikHandleBlur={handleBlur}
                              />
                            ))}
                          </Grid>

                          <PlaceholderButton
                            type="button"
                            className="mt-4"
                            onClick={() => arrayHelpers.push({ name: '', dose: '', frequency: '' })}
                          >
                            Add Another
                          </PlaceholderButton>
                        </>
                      )}
                    </FieldArray>

                    <h2 id="allergies-heading" className="h5 mt-6 mb-4">
                      Do you have any food or medication allergies?
                    </h2>

                    <ChipGroup
                      error={allergiesProps.error}
                      helperText={allergiesProps.helperText}
                      aria-labelledby="allergies-heading"
                    >
                      {[YesNo.Yes, YesNo.No].map(value => (
                        <Radio
                          key={value}
                          name={allergiesProps.name}
                          value={value}
                          onChange={allergiesProps.onChange}
                          checked={values[allergiesProps.name] === value}
                          inline
                        >
                          {value}
                        </Radio>
                      ))}

                      <Collapser isOpen={values[allergiesProps.name] === YesNo.Yes}>
                        <div className="mt-4">
                          <TextField
                            {...getFieldProps('allergiesExplanation', formikProps)}
                            placeholder="Please explain"
                          />
                        </div>
                      </Collapser>
                    </ChipGroup>
                  </motion.div>
                )}
              </AnimatePresence>

              <IntakeNavigation className="mt-5" />
            </Container>
          </form>
        )
      }}
    </Formik>
  )
}

function buildInitialValues(data: IntakeProgressQuery): MedicationFormValues {
  const medicationPreferences = parseMedicationPreferences(data.intakeProgress.medicationPreferences)

  return {
    wantsMedMgmt: medicationPreferences.wantsMedMgmt,
    medications: medicationPreferences.medications,
    allergies: medicationPreferences.allergies,
    allergiesExplanation: medicationPreferences.allergiesExplanation,
  }
}

function getMedicationInput(values: MedicationFormValues): PatientMedManagementInput {
  return {
    wantsMedMgmt: values.wantsMedMgmt,
    medications: medicationsToJSON(values.medications),
    allergies: values.allergiesExplanation,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseMedicationPreferences(medicationPreferences: any): MedicationFormValues {
  let wantsMedMgmt: MedicationFormValues['wantsMedMgmt'] = null
  let medications: MedicationFormValues['medications'] = []
  let allergiesExplanation: MedicationFormValues['allergiesExplanation'] = ''
  let allergies: MedicationFormValues['allergies'] = null

  if (medicationPreferences) {
    const parsedMedications = parseMedications(medicationPreferences.medications)

    if (typeof parsedMedications === 'string') {
      medications = []
    } else {
      medications = parsedMedications
    }

    wantsMedMgmt = medicationPreferences.wantsMedMgmt ? YesNo.Yes : YesNo.No
    allergiesExplanation = medicationPreferences.allergies
    allergies = allergiesExplanation ? YesNo.Yes : YesNo.No
  }

  return {
    wantsMedMgmt,
    medications,
    allergiesExplanation,
    allergies,
  }
}

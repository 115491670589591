import { styled } from '@mui/material'
import { motion } from 'framer-motion'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { AppointmentTagType } from '@nuna/api'
import { AppointmentLocationIcon } from '@nuna/appointment'
import { HoverState, NavigationLink, VideoLink, useAppointmentDrawerSearchParams } from '@nuna/common'
import { appointmentService } from '@nuna/core'
import {
  Avatar,
  BelowTablet,
  FillButton,
  IconButton,
  IconCancelAppointment,
  IconCheckCircle,
  IconError,
  IconRescheduleAppointment,
  OutlineButton,
  StatusLabel,
  TabletPortraitMin,
  body2,
  borderGrey,
  csx,
  greySet,
} from '@nuna/tunic'

import { simpleFade } from '../../styles/animations'
import { AppointmentCardProps } from './AppointmentCard'

type AppointmentCardDetailsProps = Omit<AppointmentCardProps, 'hideActions'> & {
  hideActions: boolean
  onCancelClick: React.MouseEventHandler
  onJoinSessionClick: (startDatetime: string) => void
}

export function AppointmentCardDetails({
  appointment,
  isRefreshingAppointments,
  onJoinSessionClick,
  onCancelClick,
  layoutId,
  compact = false,
  error = false,
  canceled = false,
  hideActions = false,
  disableAnimations = false,
  outOfNetwork = false,
  inNetwork = false,
}: AppointmentCardDetailsProps) {
  const { openRescheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()
  const [isWithin15Minutes, setIsWithin15Minutes] = useState(false)
  const [isWithin24Hours, setIsWithin24Hours] = useState(false)
  const { provider, startDatetime } = appointment
  const [appointmentStartsIn, setAppointmentStartsIn] = useState(calculateTimeLabel(startDatetime))
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  const hasAddress = 'address' in appointment && !!appointment.address
  const address = hasAddress ? appointment.address : null

  // real-time updates to UI
  const updateConditions = useCallback(() => {
    const now = moment()
    const startMoment = moment(startDatetime)
    setIsWithin15Minutes(now.isAfter(startMoment.subtract(15, 'minutes')))
    setIsWithin24Hours(now.isAfter(startMoment.subtract(24, 'hours')))
    setAppointmentStartsIn(calculateTimeLabel(startDatetime))
  }, [startDatetime])
  useEffect(() => {
    updateConditions()
    const interval = setInterval(updateConditions, 5000) // Update every 5 seconds

    return () => clearInterval(interval)
  }, [startDatetime, updateConditions])

  if (!provider) {
    return <div>Error</div>
  }

  const animationProps = disableAnimations ? { transition: { duration: 0 } } : {}

  return (
    <>
      <Details layout layoutId={`${layoutId}-details`} {...animationProps}>
        {isMobile ? (
          <div className="top-align" style={{ gap: '1rem' }}>
            <Date className={csx(['body text-medium', { large: !compact }])} canceled={canceled || error}>
              {appointmentService.appointmentTimeSpan(appointment)}
            </Date>
            <div className="caption v-align text-medium text-secondary ml-auto" style={{ paddingTop: '6px' }}>
              <AppointmentLocationIcon iconSize={18} appointment={{ address }} includeAddressLabel={!isMobile} />
            </div>
          </div>
        ) : (
          <>
            <Date className={csx(['body text-medium', { large: !compact }])} canceled={canceled || error}>
              {appointmentService.appointmentTimeSpan(appointment)}
            </Date>
            <div className="mb-1 caption v-align text-medium text-secondary">
              <AppointmentLocationIcon iconSize={18} appointment={{ address }} includeAddressLabel={!isMobile} />
            </div>
          </>
        )}
        <NameTagContainer>
          <ProviderName canceled={canceled}>
            <MiniAvatar
              size="mini"
              url={provider.avatarUrl}
              style={{ display: 'inline-block', verticalAlign: 'middle', opacity: canceled ? 0.5 : 1 }}
            />{' '}
            {provider.firstName} {provider.lastName}{' '}
            {outOfNetwork && (
              <StatusLabel className="ml-1 v-align" intent="urgent">
                <IconError className="mr-xs" style={{ marginBottom: 1 }} size={12} /> Out of network
              </StatusLabel>
            )}
            {inNetwork && (
              <StatusLabel className="ml-1 v-align" intent="information">
                <IconCheckCircle className="mr-xs" style={{ marginBottom: 1 }} size={12} /> In network
              </StatusLabel>
            )}
          </ProviderName>

          {!hideActions &&
            'tags' in appointment && // let Typescript know we are using DetailedAppointmentFragment
            appointment.tags
              ?.filter(tag => tag.type === AppointmentTagType.Cap)
              .map(tag => (
                <StatusLabelStyled
                  className={isRefreshingAppointments ? 'loading' : ''}
                  key={tag.displayLabel}
                  intent={getTagColor(tag.tagInstance)}
                >
                  {tag.displayLabel}
                </StatusLabelStyled>
              ))}
        </NameTagContainer>
      </Details>

      {!hideActions && 'positionInCap' in appointment && (
        <Actions layout layoutId={`${appointment.id}-actions`} {...animationProps}>
          {!isRefreshingAppointments && <CapPosition {...simpleFade}>{appointment.positionInCap}</CapPosition>}
          <StartsIn>{appointmentStartsIn}</StartsIn>
          <HoverState>
            {([isHovered, setIsHovered]) => (
              <IconButton
                tooltip="Reschedule session"
                onMouseOver={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{ marginLeft: '0.25rem' }}
                variant="secondary"
                onClick={() => {
                  openRescheduleAppointmentDrawer(appointment.id)
                }}
                data-testid="reschedule-appointment-icon"
              >
                <IconRescheduleAppointment isHovered={isHovered} />
              </IconButton>
            )}
          </HoverState>

          <HoverState>
            {([isHovered, setIsHovered]) => (
              <IconButton
                tooltip="Cancel session"
                onMouseOver={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{ marginLeft: '0.25rem' }}
                onClick={onCancelClick}
                variant="secondary"
                data-testid="cancel-appointment-icon"
              >
                <IconCancelAppointment isHovered={isHovered} />
              </IconButton>
            )}
          </HoverState>
          {isWithin15Minutes && !hasAddress && (
            <VideoLink
              appointment={appointment}
              onClick={() => onJoinSessionClick(appointment.startDatetime)}
              className="ml-1"
            >
              {isMobile ? 'Join' : 'Join Session'}
            </VideoLink>
          )}
          {hasAddress ? (
            <NavigationLink
              address={address}
              containerProps={{ className: 'ml-1 v-align' }}
              labelOverride={isMobile ? 'Map' : 'Get Directions'}
              loadingMessages={[]}
              buttonElement={isWithin24Hours ? FillButton : OutlineButton}
              buttonProps={{ className: 'v-align' }}
            />
          ) : null}
        </Actions>
      )}
    </>
  )
}

const MiniAvatar = styled(Avatar)`
  display: inline-block;
  margin: 0 var(--margin-1) 0 0.125rem;
  vertical-align: middle;
`

const ProviderName = styled('div')<{ canceled: boolean }>`
  align-items: center;
  color: ${props => (props.canceled ? greySet[50].hex : body2)};
  display: inline-flex;

  @media (${BelowTablet}) {
    display: flex;
  }
`

const Details = styled(motion.div)`
  flex: 1;

  @media (${BelowTablet}) {
    width: 100%;
  }
`

const Actions = styled(motion.div)`
  flex: 1;
  align-items: center;
  display: flex;
  margin-left: auto;

  @media (${BelowTablet}) {
    width: 100%;
  }
`

const CapPosition = styled(motion.span)`
  color: ${greySet[50].hex};
  margin-right: auto;
  white-space: nowrap;

  @media (${TabletPortraitMin}) {
    position: absolute;
    right: 100%;
    height: 16px;
    top: 0;
    bottom: 0;
    margin: auto 1rem auto 0;
  }
`
CapPosition.defaultProps = { className: 'caption text-bold' }

const Date = styled('h3')<{ canceled?: boolean }>`
  display: inline-block;
  text-decoration: ${({ canceled = false }) => (canceled ? 'line-through' : 'none')};

  &.large {
    margin-bottom: 0.125rem;
  }
`

const StartsIn = styled('span')`
  color: ${body2};
  border-right: 1px solid ${borderGrey};
  line-height: 1;
  margin: 0 0.25rem 0 auto;
  padding-right: 1rem;

  @media (${BelowTablet}) {
    flex: 1;
    border-right: none;
    padding-right: 0px;
  }
`

const NameTagContainer = styled('div')`
  @media (${TabletPortraitMin}) {
    display: flex;
    align-items: center;
  }
`

const StatusLabelStyled = styled(StatusLabel)`
  margin-left: 0.5rem;
  vertical-align: text-bottom;

  &:first-of-type {
    position: relative;
    margin-left: 1rem;
    &::before {
      border-left: 1px solid ${borderGrey};
      content: '';
      display: inline-block;
      position: absolute;
      left: -0.5rem;
      height: 16px;
      width: 1px;
    }
  }

  @media (${BelowTablet}) {
    &:first-of-type {
      margin-left: 0;
      margin-top: 1rem;

      &::before {
        display: none;
      }
    }
  }
`

const getTagColor = (tagInstance: string) => {
  if (tagInstance === 'ONE_FREE_SESSION_LEFT') {
    return 'caution'
  } else if (tagInstance === 'FINAL_FREE_SESSION') {
    return 'urgent'
  } else if (tagInstance === 'CASH_PAY_APPOINTMENT') {
    return 'default'
  }

  return 'information'
}

const calculateTimeLabel = (startDatetime: string) => {
  const hasStarted = moment().isAfter(startDatetime)
  const startsIn = moment(startDatetime).fromNow()
  return hasStarted ? `Started ${startsIn}` : `Starts ${startsIn}`
}

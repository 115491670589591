import { HTMLAttributes } from 'react'

import { AvailabilityAccordion } from '../filter-controls/AvailabilityAccordion'
import { AvailabilityFilterCategory } from '../filter-controls/FilterCategories'
import { FilterMenu } from './FilterMenu'

export function AvailabilityFilterMenu(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <FilterMenu {...props} menu={<AvailabilityAccordion />}>
      <AvailabilityFilterCategory />
    </FilterMenu>
  )
}

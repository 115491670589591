import { AssessmentStatus, AssessmentType, usePatientContextQuery } from '@nuna/api'
import { CssrsFormValues, Severity, assessmentService, useAssessmentsContext } from '@nuna/assessments'
import { useAuthDataContext } from '@nuna/auth'

const { determineSeverity } = assessmentService

export const useIntakeAssessmentResults = () => {
  const { assessmentBundle } = useAssessmentsContext()
  const { login } = useAuthDataContext()
  const patientId = login?.patientId || ''
  const { data: patientContextData } = usePatientContextQuery({ skip: !patientId })
  const { assessments } = assessmentBundle || {}
  const gad7 = assessments?.find(a => a.type === AssessmentType.Gad_7 && a.status === AssessmentStatus.Completed)
  const phq9 = assessments?.find(a => a.type === AssessmentType.Phq_9 && a.status === AssessmentStatus.Completed)
  const cssrs = assessments?.find(a => a.type === AssessmentType.CSsrs && a.status === AssessmentStatus.Completed)
  const tookCssrs = !!cssrs
  const cssrsValues = cssrs?.values as CssrsFormValues | undefined
  // suicidalityRiskForIntake
  // if cssrs wasn't taken, return 0
  // by default return 'Low'
  // if Question 3 or 7 is true, return 'Moderate'
  // if Question 4, 5, 6, or 8 is true, return 'High'
  const suicidalityRiskForIntake = cssrsValues
    ? cssrsValues['cssrs:4']?.value ||
      cssrsValues['cssrs:5']?.value ||
      cssrsValues['cssrs:6']?.value ||
      cssrsValues['cssrs:8']?.value
      ? Severity.Severe
      : cssrsValues['cssrs:3']?.value || cssrsValues?.['cssrs:7']?.value
      ? Severity.Moderate
      : Severity.Mild
    : 0

  const hasModerateToSevereRiskForIntake = suicidalityRiskForIntake >= Severity.Moderate

  const gad7Severity = determineSeverity(gad7?.score, AssessmentType.Gad_7)
  const phq9Severity = determineSeverity(phq9?.score, AssessmentType.Phq_9)
  const maxSeverity = Math.max(gad7Severity, phq9Severity)

  const providers = patientContextData?.patientContext.patient.providers
  const shownProvider = providers?.[0]

  return { hasModerateToSevereRiskForIntake, maxSeverity, shownProvider, tookCssrs }
}

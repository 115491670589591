import { styled } from '@mui/material'

import { ClientReferral } from '@nuna/client-referral'

import { AppLayout } from '../../layouts/AppLayout'
import { AppContainer } from '../../shared/components/Container'
import { FAQ } from '../../shared/components/FAQ'
import { Subheader } from '../../shared/components/Subheader'
import { inviteFAQs } from './invite-faqs'

export function InviteLovedOnes() {
  return (
    <AppLayout paddingTop={0}>
      <Container>
        <Subheader />
        <ClientReferral />
        <h2 className="h3 mt-6 mb-4">FAQs</h2>
        <FAQ faqs={inviteFAQs} />
      </Container>
    </AppLayout>
  )
}

const Container = styled(AppContainer)`
  padding-bottom: var(--margin-7);
`

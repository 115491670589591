import { styled } from '@mui/material'
import { useFormikContext } from 'formik'
import moment from 'moment-timezone'
import { MouseEventHandler } from 'react'

import { AuthorizedInternationalRegionFragment, PublicCompanyFragment } from '@nuna/api'
import { Card, GhostButton, TextButtonExternalLink, TextButtonLink, makeTypographyComponent } from '@nuna/tunic'

import { InternationalAppLayout } from '../../../layouts/AppLayout'
import { AppContainer } from '../../../shared/components/Container'
import { getSignupRoute } from '../../../util/routes'
import coveredIllustrationSrc from '../img/international-covered.png'
import uncoveredIllustrationSrc from '../img/international-uncovered.png'
import { InternationalCoverageDetails } from './InternationalCoverageDetails'
import { FakeCompany } from './InternationalSignup'
import { InternationalSignupFormValues } from './InternationalSignupForm'
import { getInternationalPhoneNumbers } from './phoneUtils'

interface InternationalCoverageProps {
  publicCompany: PublicCompanyFragment | FakeCompany
  resetQuery: MouseEventHandler<HTMLButtonElement>
}

type InternationalCoverageStatus = 'none' | 'not-in-area' | 'future' | 'covered'

export function InternationalCoverage({ publicCompany, resetQuery }: InternationalCoverageProps) {
  const { values } = useFormikContext<InternationalSignupFormValues>()

  let supportedInternationalAreas: AuthorizedInternationalRegionFragment[]
  let matchingArea: AuthorizedInternationalRegionFragment | undefined
  if ('offersTava' in publicCompany) {
    supportedInternationalAreas = []
    matchingArea = undefined
  } else {
    supportedInternationalAreas = publicCompany.contract.supportedInternationalAreas
    matchingArea = supportedInternationalAreas.find(area => area.type === 'COUNTRY' && area.value === values.country)
  }

  let coverageStatus: InternationalCoverageStatus
  if (supportedInternationalAreas.length === 0) {
    coverageStatus = 'none'
  } else if (!matchingArea) {
    coverageStatus = 'not-in-area'
  } else if (moment(matchingArea.firstAvailableDate).isAfter(moment())) {
    coverageStatus = 'future'
  } else {
    coverageStatus = 'covered'
  }

  const { tollFree, direct } = getInternationalPhoneNumbers(matchingArea)

  const primaryNumber = tollFree || direct

  return (
    <InternationalAppLayout showTOS={coverageStatus === 'covered'}>
      <AppContainer>
        <Card
          style={{ paddingBottom: '3rem', paddingTop: '2rem' }}
          depth={coverageStatus === 'none' || coverageStatus === 'not-in-area' ? -1 : undefined}
          className="text-center"
        >
          <Illustration
            className="mb-2"
            src={
              coverageStatus === 'covered' || coverageStatus === 'future'
                ? coveredIllustrationSrc
                : uncoveredIllustrationSrc
            }
            alt=""
          />

          {(() => {
            if (coverageStatus === 'none') {
              return (
                <>
                  <Heading>Sorry, {publicCompany.name} doesn't sponsor global care.</Heading>
                  <ChangeButtons resetQuery={resetQuery} />
                </>
              )
            } else if (coverageStatus === 'not-in-area') {
              return (
                <>
                  <Heading>Sorry, {publicCompany.name} doesn't sponsor care in your country yet.</Heading>
                  <ChangeButtons resetQuery={resetQuery} />
                </>
              )
            } else if (coverageStatus === 'future') {
              return (
                <>
                  <Heading>
                    {publicCompany.name} has you covered beginning{' '}
                    {moment(matchingArea?.firstAvailableDate).format('MMM D, YYYY')}.
                  </Heading>
                  <p>Come back then to get started</p>
                </>
              )
            } else {
              return (
                <>
                  <Heading>
                    {publicCompany.name} has you covered for {matchingArea?.sessionCap} sessions* of therapy.
                  </Heading>
                  <p>To get started, call us at</p>
                  <TextButtonExternalLink style={{ fontSize: '1.625rem' }} href={`tel:${primaryNumber}`}>
                    {primaryNumber}
                  </TextButtonExternalLink>
                </>
              )
            }
          })()}
        </Card>

        {coverageStatus !== 'covered' && (
          <div className="text-secondary mt-5">
            <h2 className="h4 mt-5">Now what?</h2>
            <p className="large">
              {coverageStatus === 'none' && (
                <>
                  Ask your HR leader to{' '}
                  <TextButtonExternalLink href="https://www.tavahealth.com/demo">request a demo</TextButtonExternalLink>{' '}
                  with us today
                </>
              )}

              {coverageStatus === 'not-in-area' &&
                'Ask your HR leader to add coverage in your country. Once they do, it may take up to 60 days.'}

              {coverageStatus === 'future' &&
                `Your HR leader has already requested that the Tava benefits be extended to your country. We're setting that up in the coming days. Come back ${moment(
                  matchingArea?.firstAvailableDate,
                ).format('MMM D, YYYY')} to get started.`}
            </p>

            <p className="mt-4 mb-7">
              Live in the USA?{' '}
              <TextButtonLink variant="secondary" to={getSignupRoute()}>
                Go to US sign up
              </TextButtonLink>
            </p>
          </div>
        )}

        {coverageStatus === 'covered' && matchingArea && (
          <InternationalCoverageDetails internationalArea={matchingArea} />
        )}
      </AppContainer>
    </InternationalAppLayout>
  )
}

interface ChangeButtonsProps {
  resetQuery: InternationalCoverageProps['resetQuery']
}

function ChangeButtons({ resetQuery }: ChangeButtonsProps) {
  const { setFieldValue } = useFormikContext<InternationalSignupFormValues>()

  return (
    <>
      <GhostButton
        className="mr-2"
        variant="secondary"
        onClick={e => {
          resetQuery(e)
          setFieldValue('employer', null)
        }}
      >
        Change employer
      </GhostButton>
      <GhostButton
        variant="secondary"
        onClick={e => {
          resetQuery(e)
          setFieldValue('country', '')
        }}
      >
        Change country
      </GhostButton>
    </>
  )
}

const Illustration = styled('img')`
  max-width: 288px;
  width: 100%;
`

const Heading = styled(makeTypographyComponent('h4 mx-auto', 'h1'))`
  max-width: 480px;
`

import { useParams } from 'react-router-dom'

import { AssessmentBundleQuery, AssessmentType, useAssessmentBundleQuery } from '@nuna/api'

import { AssessmentFromBundleQuery } from '../types/types'
import { AssessmentHeader } from './AssessmentHeader'
import { Cssrs } from './assessment-forms/Cssrs'
import { Gad7 } from './assessment-forms/Gad7'
import { Phq9 } from './assessment-forms/Phq9'

interface AssessmentProps {
  assessmentBundle?: AssessmentBundleQuery['assessmentBundle'] | null
  assessmentId?: string
}

export function Assessment({
  assessmentBundle: passedAssessmentBundle,
  assessmentId: passedAssessmentId,
}: AssessmentProps) {
  const { assessmentId = passedAssessmentId, bundleId = passedAssessmentBundle?.id ?? '' } = useParams<{
    bundleId: string
    assessmentId: string
  }>()
  const { data: bundleData } = useAssessmentBundleQuery({
    fetchPolicy: 'cache-and-network',
    variables: { bundleId },
    skip: !bundleId || !!passedAssessmentBundle,
  })
  if (!bundleData && !passedAssessmentBundle) return null
  const { assessmentBundle } = bundleData || {}
  const calculatedAssessmentBundle: AssessmentBundleQuery['assessmentBundle'] | undefined =
    passedAssessmentBundle ?? assessmentBundle
  const assessment = calculatedAssessmentBundle?.assessments.find(a => a.id === assessmentId)
  if (!assessment) return null

  const { type } = assessment

  return (
    <main className="my-2 px-3">
      <AssessmentHeader type={type} />
      {renderAssessment(assessment)}
    </main>
  )
}

const renderAssessment = (assessment: AssessmentFromBundleQuery) => {
  const { type } = assessment
  const AssessmentComponent = assessmentFormByType[type]
  return AssessmentComponent ? <AssessmentComponent assessment={assessment} /> : null
}

const assessmentFormByType: {
  [key in AssessmentType]: (props: {
    assessment: AssessmentFromBundleQuery
    onSubmit?: (assessmentBundle?: AssessmentBundleQuery['assessmentBundle']) => void
  }) => JSX.Element | null
} = {
  CORE_10: () => null,
  GAD_7: Gad7,
  PHQ_9: Phq9,
  C_SSRS: Cssrs,
}

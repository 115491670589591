import { styled } from '@mui/material'
import { motion } from 'framer-motion'
import { noop } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useMatch, useNavigate } from 'react-router-dom'

import { QuestionAnswerPair, usePatientContextQuery } from '@nuna/api'
import {
  BelowTablet,
  Dialog,
  DialogContent,
  DialogProps,
  IconMinus,
  IconPlus,
  borderGrey,
  primary,
  tealSet,
} from '@nuna/tunic'

import { CloseButton } from '../../shared/components/CloseButton'
import { SessionLimitsModalCapHeader } from './SessionLimitsModalCapHeader'

const sessionLimitsModalDefaultContext: [boolean, (isTrue: boolean) => void] = [false, noop]
export const SessionLimitsModalContext = React.createContext(sessionLimitsModalDefaultContext)

export function SessionLimitsModalContextProvider({ children }: { children: React.ReactNode }) {
  const limitFaqMatch = useMatch('/limitfaq')
  const navigate = useNavigate()

  const stateAndSetter = useState(() => {
    // check the url to see if the modal should be shown on render
    return limitFaqMatch !== null
  })

  const [isOpen] = stateAndSetter

  // removes /limitfaq from url when the modal is closed
  useEffect(() => {
    if (!isOpen && limitFaqMatch) {
      navigate('/', { replace: true })
    }
  }, [isOpen, navigate, limitFaqMatch])

  // passing as a tuple to maintain referential equality and avoid unnecesary re-renders
  // https://medium.com/@xgbuils/using-hooks-the-state-and-the-setter-belongs-to-the-same-object-reference-326588cce03c
  return <SessionLimitsModalContext.Provider value={stateAndSetter}>{children}</SessionLimitsModalContext.Provider>
}

export function SessionLimitsModal(props: Omit<DialogProps, 'isOpen' | 'onClose'>) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { data } = usePatientContextQuery()
  const [isOpen, setIsOpen] = useContext(SessionLimitsModalContext)
  if (!data?.patientContext) return null

  const {
    patientContext: {
      patient: { paymentMethods },
      capInfo: { attendedCapStatus, capResetDate },
      capFaqMessages,
    },
  } = data

  return (
    <Dialog {...props} transitionDuration={500} isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <DialogContent paddingSize={isMobile ? 'sm' : 'lg'}>
        <SessionLimitsModalCapHeader
          attendedCapStatus={attendedCapStatus}
          hasPaymentMethod={(paymentMethods?.length ?? 0) > 0}
          capResetDate={capResetDate}
        />

        <Heading className="h4">{capFaqMessages?.title}</Heading>
        <Subheading className="text-medium mb-5">{capFaqMessages?.description}</Subheading>

        <h4 className="body text-medium text-secondary">Common questions</h4>

        {capFaqMessages?.commonQuestions.map(questionAnswerPair => (
          <AccordionItem key={questionAnswerPair.question} questionAnswer={questionAnswerPair} />
        ))}
      </DialogContent>
      <CloseButtonStyled onClick={() => setIsOpen(false)} />
    </Dialog>
  )
}

const CloseButtonStyled = styled(CloseButton)`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
`

const Heading = styled('h2')`
  margin-bottom: 0.5em;
  max-width: 19.5em;

  @media (${BelowTablet}) {
    font-size: 1.5rem;
  }
`

const Subheading = styled('p')``

function AccordionItem({ questionAnswer, ...props }: { questionAnswer: QuestionAnswerPair }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <QuestionAnswerContainer {...props}>
      <Button onClick={() => setIsOpen(!isOpen)}>
        <Question>{questionAnswer.question}</Question>

        {isOpen ? <IconMinus size={16} color={primary} /> : <IconPlus size={16} color={primary} />}
      </Button>

      <motion.div
        animate={{ height: isOpen ? 'auto' : 0 }}
        initial={false}
        transition={{ duration: 0.3, ease: 'easeOut' }}
        style={{ overflow: 'hidden' }}
      >
        <Answer>{questionAnswer.answer}</Answer>
      </motion.div>
    </QuestionAnswerContainer>
  )
}

const QuestionAnswerContainer = styled('div')`
  border-bottom: 1px solid ${borderGrey};
  border-top: 1px solid ${borderGrey};

  & + & {
    border-top: 0;
  }

  svg {
    transition: transform 0.3s;
    flex: 0 0 auto;
  }
`

const Button = styled('button')`
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  svg:last-child {
    margin-left: auto;
  }

  &.focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px ${tealSet[15].hex};
  }
`
Button.defaultProps = { className: 'h6 mb-0' }

const Question = styled('div')`
  text-align: left;
  margin-right: auto;
`

const Answer = styled('div')`
  padding-bottom: 1rem;

  p:last-child {
    margin-bottom: 0;
  }

  @media (${BelowTablet}) {
    && {
      font-size: 1.125rem;
    }
  }
`
Answer.defaultProps = { className: 'paragraphs' }

import { styled } from '@mui/material'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import type { PartnerBrandingFragment } from '@nuna/api'
import { SsoLogin, type SsoOrganization, getFromPath, useAuthDataContext, useSignupSearchParams } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { Logo, SignupLayout as SignupLayoutComponents, TextButtonLink, salmonSet, tealSet } from '@nuna/tunic'

import { SsoFinishSignup } from './SsoFinishSignup'
import { SsoWelcome } from './SsoWelcome'
import { getCustomizationsForSsoOrg } from './ssoOrganizationCustomizations'

const { BrandedContainer, BrandedHalf, CenteredContent, Half, RightAlignedHeader, Top } = SignupLayoutComponents

interface SsoLayoutProps {
  customBranding?: PartnerBrandingFragment | null
  organization: SsoOrganization
  postFlow?: boolean
}

export function SsoLayout({ customBranding, organization, postFlow = false }: SsoLayoutProps) {
  const { loading, loggedIn, login } = useAuthDataContext()
  const location = useLocation()

  useEffect(() => {
    if (loggedIn && !postFlow) {
      // Hard navigation to force reloading JS bundle
      window.location.href = getFromPath(location) ?? '/'
    }
  }, [loggedIn, location, postFlow])

  const { firstName, ssoMissingFields, ssoToken, ssoCoveredSessions, ssoSupportPhoneNumber } = useSignupSearchParams()
  const formRef = useRef<HTMLDivElement>(null)

  // Since the SSO signup flow requires a hard redirect, ensure the user jumps back to the
  // form instead of being reset to the top of the page on mobile.
  const scrollAfterSsoRef = useCallback(
    (node: HTMLDivElement) => {
      if (node && ssoToken) node.scrollIntoView()
    },
    [ssoToken],
  )

  const missingFields = useMemo(() => ssoMissingFields?.split(',') ?? [], [ssoMissingFields])
  const showFinish = postFlow && !loggedIn && !!ssoToken
  const { WelcomeContent, FinishSetupContent } = getCustomizationsForSsoOrg(organization.slug)
  const primaryColor = customBranding?.primaryColor ?? tealSet[70].hex
  const secondaryColor = customBranding?.secondaryColor ?? tealSet[70].hex
  const buttonColor = customBranding?.buttonColor ?? salmonSet[50].hex
  const buttonHoverColor = customBranding?.buttonHoverColor ?? salmonSet[50].hover

  return (
    <BrandedContainer $buttonColor={buttonColor} $hoverColor={buttonHoverColor}>
      <Top $reverse={showFinish}>
        <BrandedHalf $gradientStart={primaryColor} $gradientEnd={secondaryColor}>
          <CenteredContent>
            {showFinish ? (
              <FinishSetupContent numSessions={ssoCoveredSessions} supportPhoneNumber={ssoSupportPhoneNumber} />
            ) : (
              <WelcomeContent supportPhoneNumber={ssoSupportPhoneNumber} />
            )}
          </CenteredContent>

          <LogoFooter>
            {showFinish ? (
              <div className="flex-column v-align gap-1 full-width mb-1">
                <Logo color="white" scheme="dark" height={23} />
                <div className="flex-row gap-1">
                  <SubtleLink href="https://www.tavahealth.com/terms-and-conditions">Terms and Conditions</SubtleLink>
                  <SubtleLink href="https://www.tavahealth.com/privacy-policy">Privacy Policy</SubtleLink>
                </div>
              </div>
            ) : (
              <Logo color="white" scheme="dark" height={23} />
            )}
          </LogoFooter>
        </BrandedHalf>

        <Half ref={formRef}>
          {!showFinish && (
            <RightAlignedHeader className="v-align" ref={scrollAfterSsoRef}>
              <span>
                No {organization.name} account?{' '}
                <TextButtonLink to={routeService.login()} className="text-secondary">
                  Sign in with Tava
                </TextButtonLink>
              </span>
            </RightAlignedHeader>
          )}

          <CenteredContent maxWidth={520}>
            <div className="flex-column v-align gap-7">
              {(() => {
                // TODO: Better loading state?
                if (loading) return null

                if (postFlow && loggedIn && login) return <SsoWelcome login={login} />

                if (showFinish)
                  return <SsoFinishSignup firstName={firstName} missingFields={missingFields} ssoToken={ssoToken} />

                return <SsoLogin organization={organization} />
              })()}
              {customBranding?.logoUrl && <img src={customBranding.logoUrl} alt="" style={{ width: '345px' }} />}
            </div>
          </CenteredContent>
        </Half>
      </Top>

      {/* TODO: Removed for now, need to double-check if we want to replace it with anything else */}
      {/* <ClientFooter /> */}
    </BrandedContainer>
  )
}

const LogoFooter = styled('div')`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  min-height: 42px;
  padding: var(--margin-2);
  opacity: 0.6;
`

const SubtleLink = styled('a')`
  color: #fff;
  font-size: 13px;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }

  &:focus-visible {
    outline: none;
    text-decoration: underline;
  }

  &:not(:last-child)::after {
    content: '|';
    margin-left: var(--margin-1);
  }
`

import { styled } from '@mui/material'
import { useMediaQuery } from 'react-responsive'
import { NavLink } from 'react-router-dom'

import { BasicProviderFragment } from '@nuna/api'
import { supportService } from '@nuna/telemetry'
import {
  Avatar,
  BelowTablet,
  CenteredHeader,
  GhostButton,
  GhostButtonLink,
  IconButtonLink,
  IconChevronThick,
  IconFilter,
  OutlineButtonLink,
  shadowDepth,
  tealSet,
  white,
} from '@nuna/tunic'

import { useSearchProviderContext } from '../../shared/components/SearchProvider/SearchProviderContext'
import { handleNevermindClick } from './switch-provider.utils'

interface Props {
  oldProvider?: BasicProviderFragment
}

export function SwitchProviderHeader() {
  const { oldProvider } = useSearchProviderContext()
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  if (isMobile) {
    return <MobileHeader oldProvider={oldProvider} />
  }

  return <DesktopHeader oldProvider={oldProvider} />
}

function DesktopHeader({ oldProvider }: Props) {
  return (
    <HeaderBar className="v-align">
      <HomeLink to="/" className="text-white v-align text-medium centered-header-left">
        <IconChevronThick direction="left" size={16} className="mr-1" />
        Home
      </HomeLink>
      <span className="serif h5 mb-0 centered-header-center">Change Therapists</span>
      <span className="v-align centered-header-right">
        <GhostButton type="button" scheme="dark" className="mr-2" onClick={supportService.openChat}>
          Get Advice
        </GhostButton>
        {oldProvider && (
          <OutlineButtonLink
            to="/"
            variant="dark"
            className="v-align"
            onClick={() => handleNevermindClick(oldProvider.firstName)}
          >
            <Avatar size="mini" url={oldProvider.avatarUrl} className="mr-1" /> Stick with {oldProvider.firstName}
          </OutlineButtonLink>
        )}
      </span>
    </HeaderBar>
  )
}

function MobileHeader({ oldProvider }: Props) {
  const { setFilterDialogOpen } = useSearchProviderContext()
  return (
    <HeaderBar>
      <h5 className="text-center">Change Therapists</h5>
      <div className="space-between v-align pt-xs">
        <span className="v-align">
          <IconButtonLink variant="dark" to="/" tooltip="Back" small>
            <IconChevronThick direction="left" size={20} />
          </IconButtonLink>
          <GhostButton scheme="dark" className="ml-1" onClick={() => setFilterDialogOpen(true)}>
            <IconFilter />
            Filters
          </GhostButton>
        </span>
        {oldProvider && (
          <GhostButtonLink
            to="/"
            scheme="dark"
            className="v-align"
            onClick={() => handleNevermindClick(oldProvider.firstName)}
          >
            <Avatar size="mini" customSize={18} url={oldProvider.avatarUrl} className="mr-1" /> Stick with{' '}
            {oldProvider.firstName}
          </GhostButtonLink>
        )}
      </div>
    </HeaderBar>
  )
}

const HomeLink = styled(NavLink)`
  :hover {
    color: ${tealSet[5].hex};
  }
`

const HeaderBar = styled(CenteredHeader)`
  background-color: ${tealSet[90].hex};
  box-shadow: 0px 1px 0px ${shadowDepth(4, 'warm')};
  padding: var(--spacing-2);
  position: relative;
  color: ${white.hex};
  position: sticky;
  top: 0;
  z-index: 2;

  @media (${BelowTablet}) {
    padding-left: 10px;
  }
`

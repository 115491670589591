import { styled } from '@mui/material'
import { motion } from 'framer-motion'
import truncate from 'html-truncate'
import { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { useRankTracking } from '@nuna/appointment'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { appointmentService } from '@nuna/core'
import {
  BelowTablet,
  Box,
  FillButton,
  GhostButton,
  Grid,
  IconFilter,
  TextButton,
  csx,
  flexRightJustify,
  sansSerifFontStack,
  tealSet,
} from '@nuna/tunic'

import { AppContainer, AppLayoutTopPadding } from '../../shared/components/Container'
import { ProviderProfileDrawerContext } from '../../shared/components/ProviderProfileDrawer'
import { ProviderProfileDrawerTabs } from '../../shared/components/ProviderProfileDrawer/ProviderProfileDrawerContext'
import { ProviderSelectCard } from '../../shared/components/ProviderSelectCard'
import { FilteredSummary } from '../../shared/components/SearchProvider/FilteredSummary'
import { SearchProvider } from '../../shared/components/SearchProvider/SearchProvider'
import {
  type FilteredProvider,
  useSearchProviderContext,
} from '../../shared/components/SearchProvider/SearchProviderContext'
import { ChooseATime } from './ChooseATime'
import bgScribbles from './img/bg_scribbles_curves.png'

const TRUNCATED_BIO_LENGTH = 200

interface Props {
  topRecommendation: FilteredProvider
  otherProviders: FilteredProvider[]
}

export function ProviderSuggestionsMultipleResults({ topRecommendation, otherProviders }: Props) {
  const { openScheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()

  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const [showingTruncatedBio, setShowingTruncatedBio] = useState(true)
  const aboutBlurb = topRecommendation?.bio ?? ''
  const shouldTruncateBio = aboutBlurb.length > TRUNCATED_BIO_LENGTH
  const truncatedBlurb = shouldTruncateBio ? truncate(aboutBlurb, TRUNCATED_BIO_LENGTH, {}) : aboutBlurb

  const { setProviderRank } = useRankTracking()
  const { setFilterDialogOpen, setOnProviderCardClick } = useSearchProviderContext()
  const {
    providerId: [, setProvider],
    selectedTab: [, setSelectedTabId],
  } = useContext(ProviderProfileDrawerContext)

  const openScheduleDrawer = (startDatetime?: string) => {
    setProviderRank({
      providerId: topRecommendation.id,
      providerRank: '1',
      totalSuggestions: `${otherProviders.length}`,
      score: `${topRecommendation.score}`,
    })

    if (startDatetime) {
      openScheduleAppointmentDrawer(topRecommendation.id, {
        timeSlot: appointmentService.timeSlotToTimeStamp(startDatetime),
      })
    } else {
      openScheduleAppointmentDrawer(topRecommendation.id)
    }
  }

  const openTopRecommendationProfile = () => {
    setProviderRank({
      providerId: topRecommendation.id,
      providerRank: '1',
      totalSuggestions: `${otherProviders.length}`,
      score: `${topRecommendation.score}`,
    })
    setSelectedTabId(ProviderProfileDrawerTabs.About)
    setProvider(topRecommendation.id ?? null)
  }

  const handleScheduleClick = () => {
    openScheduleDrawer()
  }

  const handleSlotClicked = (startDatetime: string) => {
    openScheduleDrawer(startDatetime)
  }

  useEffect(() => {
    setOnProviderCardClick(() => (providerId: string) => {
      setProviderRank({
        providerId,
        providerRank: `${otherProviders.findIndex(provider => provider.id === providerId) + 1}`,
        totalSuggestions: `${otherProviders.length}`,
        score: `${otherProviders.find(provider => provider.id === providerId)?.score}`,
      })
      setSelectedTabId(ProviderProfileDrawerTabs.About)
      setProvider(providerId)
    })
  }, [setOnProviderCardClick, setProvider, setProviderRank, setSelectedTabId, otherProviders])

  return (
    <main>
      {topRecommendation && (
        <TopSection>
          <AppContainer>
            <header className="text-center">
              <h3 className={csx([{ h4: isMobile, 'mt-2 h3': !isMobile }, 'mb-2'])}>
                We recommend {topRecommendation.firstName}
              </h3>
              <p className={csx([{ 'mb-2': isMobile, 'mb-5': !isMobile }, 'large text-secondary'])}>
                This may be the best match for you, based on what you've told us.
              </p>
            </header>

            <Grid container spacing={5}>
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                  md: 5,
                  lg: 4,
                }}
              >
                <TopRecommendationCardContainer>
                  <ProviderSelectCard
                    provider={topRecommendation}
                    providerSuggestionRank={0}
                    renderScheduleButton={<FillButton onClick={handleScheduleClick}>Schedule Appointment</FillButton>}
                  />
                </TopRecommendationCardContainer>
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                  md: 7,
                  lg: 8,
                }}
              >
                <Grid container direction={isMobile ? 'column' : 'column-reverse'}>
                  <Grid>
                    <ChooseATime
                      provider={topRecommendation}
                      className={isMobile ? 'mt-3' : 'mt-5'}
                      onViewAllTimesClick={handleScheduleClick}
                      onSlotClick={handleSlotClicked}
                    />
                  </Grid>
                  <Grid>
                    <h5 className={isMobile ? 'mt-5' : 'mt-2'}>About {topRecommendation.firstName}</h5>
                    <About
                      className="paragraphs"
                      dangerouslySetInnerHTML={{ __html: showingTruncatedBio ? truncatedBlurb : aboutBlurb }}
                    />
                    {shouldTruncateBio && (
                      <div className="mb-2">
                        <TextButton onClick={() => setShowingTruncatedBio(!showingTruncatedBio)}>
                          {showingTruncatedBio ? 'more' : 'less'}
                        </TextButton>
                      </div>
                    )}

                    <Box sx={flexRightJustify()}>
                      <GhostButton onClick={() => openTopRecommendationProfile()}>View profile</GhostButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AppContainer>
        </TopSection>
      )}

      <BottomSection>
        <AppContainer className="text-center">
          {topRecommendation && <h3 className="mt-5 mb-2">Other Available Therapists</h3>}
          {isMobile && (
            <div className="space-between center-align gap-4 mt-2">
              <GhostButton className="ml-1 mb-2" onClick={() => setFilterDialogOpen(true)}>
                <IconFilter />
                Filters
              </GhostButton>
              <FilteredSummary />
            </div>
          )}
          <SearchProvider />
        </AppContainer>
      </BottomSection>
    </main>
  )
}

const TopSection = styled('section')`
  background: ${tealSet[5].hex} url(${bgScribbles});
  padding: ${AppLayoutTopPadding} 0 4rem 0;
`

const TopRecommendationCardContainer = styled('div')`
  max-height: 600px;
`

const BottomSection = styled('section')`
  min-height: 800px;
`

const About = styled(motion.div)`
  font-size: 1.2rem;
  font-family: ${sansSerifFontStack};
  p + p {
    margin-top: 1em;
  }
`

import { styled } from '@mui/material'
import { range, startCase } from 'lodash'
import { HTMLAttributes, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { Badge, BelowTablet, Checkbox, Chip, DropdownButton, IconCategory, Menu } from '@nuna/tunic'

import { useMentalHealthLibraryContext } from '../MentalHealthLibraryContext'
import { usePostCategories } from '../hooks/usePostCategories'

export function CategoryFilter(props: HTMLAttributes<HTMLDivElement>) {
  const { selectedCategories, toggleCategory } = useMentalHealthLibraryContext()
  const { categories } = usePostCategories()

  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  if (isMobile) {
    return <MobileCategoryFilter {...props} />
  }

  return (
    <div {...props} role="group">
      {categories.size === 0
        ? renderSkeletons()
        : Array.from(categories).map(category => (
            <Chip
              key={category}
              onClick={() => toggleCategory(category)}
              checked={selectedCategories.has(category)}
              small
            >
              {startCase(category)}
            </Chip>
          ))}
    </div>
  )
}

function renderSkeletons() {
  return range(5).map(i => (
    <Chip key={i} small className="loading">
      Loading
    </Chip>
  ))
}

function MobileCategoryFilter(props: HTMLAttributes<HTMLDivElement>) {
  const { selectedCategories, toggleCategory } = useMentalHealthLibraryContext()
  const { categories } = usePostCategories()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <div {...props}>
      <DropdownButton className="text-secondary" onClick={e => setAnchorEl(e.currentTarget)} isActive={!!anchorEl}>
        {selectedCategories.size > 0 ? (
          <Badge className="mr-1">{selectedCategories.size}</Badge>
        ) : (
          <IconCategory style={{ marginLeft: 2 }} className="mr-1" size={16} />
        )}
        Topics
      </DropdownButton>

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <FilterSection>
          {Array.from(categories).map(category => (
            <Checkbox
              checked={selectedCategories.has(category)}
              key={category}
              onClick={() => toggleCategory(category)}
              className="checkbox"
            >
              {startCase(category)}
            </Checkbox>
          ))}
        </FilterSection>
      </Menu>
    </div>
  )
}

export const FilterSection = styled('div')`
  padding: var(--spacing-1) 0;

  .checkbox {
    &:not(:last-child) {
      margin-bottom: var(--spacing-1-5);
    }
  }
`

import { styled } from '@mui/material'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { AssessmentType } from '@nuna/api'
import { Assessment, useAssessmentsContext } from '@nuna/assessments'
import { routeService } from '@nuna/core'
import { BelowTablet, Grid } from '@nuna/tunic'

import { IntakeLayout } from '../../layouts/IntakeLayout'
import { InsuranceIntakeContextProvider, useInsuranceIntakeContext } from './InsuranceIntakeContext'
import { InsuranceIntakeSkeleton } from './InsuranceIntakeSkeleton'
import { P4Confirmation } from './P4Confirmation'
import { IntakePaths, preferencesRoot } from './intakeConstants'
import { AssessmentIntakeResults } from './steps/AssessmentIntakeResults/AssessmentIntakeResults'
import { Challenges } from './steps/Challenges'
import { InsurancePersonalInformation } from './steps/InsurancePersonalInformation'
import { IntakeCoverage } from './steps/IntakeCoverage'
import { Medication } from './steps/Medication'
import { StateMismatch } from './steps/StateMismatch'
import { TherapistPreferences } from './steps/TherapistPreferences'

export function Intake() {
  return (
    <InsuranceIntakeContextProvider>
      <IntakeLayout>
        <IntakeWithContext />
      </IntakeLayout>
    </InsuranceIntakeContextProvider>
  )
}

function IntakeWithContext() {
  const location = useLocation()
  const { intakeProgressData, patientContextData } = useInsuranceIntakeContext()
  const { assessmentBundle } = useAssessmentsContext()

  if (!assessmentBundle) {
    return (
      <GridContainer container justifyContent="center">
        <Grid
          size={{
            xs: 12,
            md: 10,
            lg: 7,
            xl: 6,
          }}
        >
          <InsuranceIntakeSkeleton />
        </Grid>
      </GridContainer>
    )
  }

  const firstRequiredValue = intakeProgressData?.intakeProgress.mobilePhone
  const firstPath = IntakePaths.personalInformation

  if (
    location.pathname !== `${routeService.intake()}/${IntakePaths.p4Confirmation}` &&
    location.pathname !== routeService.intake() &&
    !location.pathname.includes(firstPath) &&
    !firstRequiredValue
  ) {
    return <Navigate to={firstPath} />
  }

  if (patientContextData?.patientContext.patient.intakeCompleted) {
    return <Navigate to="/" />
  }

  return (
    <GridContainer container justifyContent="center">
      <Grid
        size={{
          xs: 12,
          md: 10,
          lg: 7,
          xl: 6,
        }}
      >
        <Routes>
          <Route index element={<Navigate to={IntakePaths.personalInformation} />} />
          <Route path={IntakePaths.p4Confirmation} element={<P4Confirmation />} />
          <Route path={IntakePaths.personalInformation} element={<InsurancePersonalInformation />} />
          <Route path={IntakePaths.stateMismatch} element={<StateMismatch />} />
          <Route path={`${IntakePaths.coverage}/*`} element={<IntakeCoverage />} />
          <Route
            path={IntakePaths.assessment1Gad7}
            element={
              <Assessment
                assessmentBundle={assessmentBundle}
                assessmentId={assessmentBundle?.assessments.find(a => a.type === AssessmentType.Gad_7)?.id}
              />
            }
          />
          <Route
            path={IntakePaths.assessment2Phq9}
            element={
              <Assessment
                assessmentBundle={assessmentBundle}
                assessmentId={assessmentBundle?.assessments.find(a => a.type === AssessmentType.Phq_9)?.id}
              />
            }
          />
          <Route
            path={IntakePaths.assessment3Cssrs}
            element={
              <Assessment
                assessmentBundle={assessmentBundle}
                assessmentId={assessmentBundle?.assessments.find(a => a.type === AssessmentType.CSsrs)?.id}
              />
            }
          />
          <Route path={IntakePaths.assessmentSummary} element={<AssessmentIntakeResults />} />
          <Route path={IntakePaths.challenges} element={<Challenges />} />
          <Route path={IntakePaths.medication} element={<Medication />} />
          <Route path={IntakePaths.therapistPreferences} element={<TherapistPreferences />} />
          {/* Add catch-all preferences/* here to allow redirect to a proper screen */}
          <Route path={`${preferencesRoot}/*`} element={<Navigate replace to={`../${IntakePaths.challenges}`} />} />
        </Routes>
      </Grid>
    </GridContainer>
  )
}

const GridContainer = styled(Grid)`
  margin-top: 10rem;

  @media (${BelowTablet}) {
    margin-top: 4rem;

    .h3 {
      font-size: 2rem;
    }

    .h4 {
      font-size: 1.5rem;
    }

    .h5 {
      font-size: 1.25rem;
    }
  }
`

export const Container = styled('main')`
  @media (${BelowTablet}) {
    margin-top: 2rem;
  }
`

import { styled } from '@mui/material'
import { useMediaQuery } from 'react-responsive'

import { supportService } from '@nuna/telemetry'
import { BelowTablet, Grid, OutlineButton, TextButton } from '@nuna/tunic'

import { AppContainer } from '../../Container'
import { useSearchProviderContext } from '../SearchProviderContext'
import office from '../img/office.png'

export function UndesiredResultsPrompt({ title = "Not finding what you're looking for?" }: { title?: string }) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  const { resultsLoading, clearFilters } = useSearchProviderContext()

  return !resultsLoading ? (
    <Container>
      <Grid container spacing={isMobile ? 2 : 10} direction="row-reverse" alignItems="center">
        <OfficeImageContainer
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <img src={office} alt="" />
        </OfficeImageContainer>
        <Grid
          className="mt-1-5 text-left"
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <h4>{title}</h4>
          <p className="large mb-4">
            We are always adding more providers to our network. In the meantime, don't hesitate to{' '}
            <TextButton onClick={supportService.openChat}>reach out to us</TextButton> for help and suggestions. You can
            also expand your search by adjusting your filters.
          </p>
          <p>
            <OutlineButton onClick={() => clearFilters()}>Reset your filters</OutlineButton>
          </p>
        </Grid>
      </Grid>
    </Container>
  ) : null
}

const Container = styled(AppContainer)`
  padding-top: var(--margin-5);
  padding-bottom: var(--margin-6);

  @media (${BelowTablet}) {
    .h3 {
      font-size: 2rem;
    }
  }
`

const OfficeImageContainer = styled(Grid)`
  img {
    width: 75%;
    max-width: 350px;
  }

  text-align: center;

  @media (${BelowTablet}) {
    display: flex;
    justify-content: center;
    padding-bottom: 0;

    img {
      max-width: 270px;
    }
  }
`

import { matchPath, resolvePath, useLocation } from 'react-router-dom'

import { PaymentPreference } from '@nuna/api'

import { IntakePaths } from '../intakeConstants'
import { useIntakeConditions } from './useIntakeConditions'
import { useIntakeCoverageStatus } from './useIntakeCoverageStatus'

export function useIntakeNavigation() {
  const location = useLocation()

  const { shouldShowMedManagement, shouldShowTherapistPreferences, hasStateMismatch } = useIntakeConditions()

  const { coverages, needsAdditionalZeroCapCoverage } = useIntakeCoverageStatus()

  const EAPCoverage = coverages[PaymentPreference.Employer]

  let stepPaths: string[] = [
    IntakePaths.personalInformation,
    IntakePaths.sponsor,
    IntakePaths.insurance,
    IntakePaths.cash,
    IntakePaths.assessment1Gad7,
    IntakePaths.assessment2Phq9,
    IntakePaths.assessment3Cssrs,
    IntakePaths.assessmentSummary,
  ]

  // if zero cap, and post cap preference is cash, then remove insurance stage
  // TODO: we need a better way to configure this stuff in the future, this is
  // going to become flag hell really fast
  if (needsAdditionalZeroCapCoverage && EAPCoverage?.details.alternateCoverageSuggestion === PaymentPreference.Cash) {
    stepPaths = stepPaths.filter(step => step !== IntakePaths.insurance)
  }

  // TODO: we want to replace this check and the ones above with a config engine
  // when we overhaul intake @christianallred
  const skipStepsForNullcap = [IntakePaths.insurance, IntakePaths.cash]
  if (EAPCoverage?.details.sessionsCap === null) {
    stepPaths = stepPaths.filter(step => !skipStepsForNullcap.includes(step))
  }

  if (hasStateMismatch) {
    stepPaths.splice(1, 0, IntakePaths.stateMismatch)
  }

  if (shouldShowTherapistPreferences) {
    stepPaths = [
      ...stepPaths,
      ...[
        IntakePaths.challenges,
        ...(shouldShowMedManagement ? [IntakePaths.medication] : []),
        IntakePaths.therapistPreferences,
      ],
    ]
  }

  const currentStepPathIndex = stepPaths.findIndex(path => {
    const resolvedPath = resolvePath(path, '/intake')
    return !!matchPath(resolvedPath.pathname, location.pathname)
  })

  const nextPath = currentStepPathIndex + 1 < stepPaths.length ? stepPaths[currentStepPathIndex + 1] : undefined

  return {
    stepPaths,
    currentStepPathIndex,
    previousPath: stepPaths[Math.max(currentStepPathIndex - 1, 0)],
    nextPath,
    currentPath: stepPaths[currentStepPathIndex],
  }
}

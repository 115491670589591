import { styled } from '@mui/material'
import { compact, head } from 'lodash'
import { HTMLAttributes, useState } from 'react'

import { coverageService, errorService } from '@nuna/core'
import { supportService } from '@nuna/telemetry'
import {
  BelowTablet,
  Card,
  CollapsingListItem,
  FileUpload,
  Grid,
  OutlineButton,
  Phone,
  TextButton,
  TextButtonExternalLink,
  csx,
  fontSize,
  makeTypographyComponent,
  toast,
} from '@nuna/tunic'

import { useInsuranceIntakeContext } from '../../../InsuranceIntakeContext'
import { useIntakeCoverageStatus } from '../../../hooks/useIntakeCoverageStatus'
import { InsuranceCardDisplay } from './InsuranceCardDisplay'
import { useUploadInsuranceCardImages } from './useUploadInsuranceCardImages'

interface Props extends HTMLAttributes<HTMLDivElement> {
  onEditClick: () => void
}

export function InvalidInsuranceTroubleshooting({ onEditClick, className, ...props }: Props) {
  const { patientContextData, intakeProgressData, refetchIntakeProgress } = useInsuranceIntakeContext()
  const { needsAdditionalZeroCapCoverage, employerName, refetch } = useIntakeCoverageStatus()

  const insurancePolicy = intakeProgressData?.intakeProgress.insurancePolicy
  const patientId = patientContextData?.patientContext.patient.id ?? ''
  const policyId = insurancePolicy?.id ?? ''
  const { uploadImages, loading: uploadImagesLoading } = useUploadInsuranceCardImages(patientId, policyId)

  const [imageFiles, setImageFiles] = useState<[File | undefined, File | undefined]>([undefined, undefined])
  const frontImage = imageFiles[0]
  const backImage = imageFiles[1]

  const helpUrl = insurancePolicy?.insurancePayer?.helpUrl || supportService.articles.insurance

  const cardUploaded = coverageService.hasUploadedInsuranceCard(insurancePolicy)

  const handleUploadImages = async () => {
    const genericErrorMsg = `There was a problem uploading your insurance card images. Please contact ${supportService.supportEmails.clientSupport}.`
    if (!frontImage || !backImage) {
      return
    }

    if (!patientId || !policyId) {
      toast.urgent(genericErrorMsg)
      console.error('Missing patientId or policyId')
      return
    }

    try {
      const response = await uploadImages(frontImage, backImage)
      if (response?.cardFrontUrl && response?.cardBackUrl) {
        refetch()
        refetchIntakeProgress()
      }
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, genericErrorMsg))
    }
  }

  return (
    <StyledInvalidInsuranceTroubleshooting {...props} className={csx([className, 'text-secondary'])}>
      <ResponsiveCollapsingListItem label="Upload photos of my insurance card">
        {cardUploaded && (
          <Card depth={-4} className="p-3">
            <h4>Thanks. We will attempt to verify your insurance and get back to you in a few days.</h4>
            <div>Click next below to proceed.</div>
          </Card>
        )}
        {!cardUploaded && (
          <>
            {' '}
            <div className="responsive-large mb-2">
              Our team can often resolve issues verifying insurance once they have your insurance card in hand. Upload a
              picture of the front and back of your card.
            </div>
            <Grid container spacing={4}>
              <Grid size={{ xs: 12, md: 'auto' }}>
                <ImageUploadLabel>Front of card</ImageUploadLabel>
                <CreditCardContainer className={csx({ 'has-image': !!frontImage })}>
                  <FileUpload
                    fit="cover"
                    size="md"
                    accept="image/*"
                    onDrop={files => setImageFiles([files[0], backImage])}
                    savedFiles={insurancePolicy?.cardFrontUrl ? [{ url: insurancePolicy?.cardFrontUrl }] : undefined}
                    getFileDisplay={fileDisplayProps => (
                      <InsuranceCardDisplay
                        {...fileDisplayProps}
                        file={head(fileDisplayProps.files)}
                        savedFile={head(fileDisplayProps.savedFiles)}
                        alt="insurance card front"
                      />
                    )}
                  />
                </CreditCardContainer>
              </Grid>
              <Grid size={{ xs: 12, md: 'auto' }}>
                <ImageUploadLabel>Back of card</ImageUploadLabel>
                <CreditCardContainer className={csx({ 'has-image': !!backImage })}>
                  <FileUpload
                    fit="cover"
                    size="md"
                    accept="image/*"
                    onDrop={files => setImageFiles([frontImage, files[0]])}
                    savedFiles={insurancePolicy?.cardBackUrl ? [{ url: insurancePolicy?.cardBackUrl }] : undefined}
                    getFileDisplay={fileDisplayProps => (
                      <InsuranceCardDisplay
                        {...fileDisplayProps}
                        file={head(fileDisplayProps.files)}
                        savedFile={head(fileDisplayProps.savedFiles)}
                        alt="insurance card back"
                      />
                    )}
                  />
                </CreditCardContainer>
              </Grid>
            </Grid>
            <OutlineButton
              type="button"
              className="troubleshoot-button mt-3"
              isLoading={uploadImagesLoading}
              disabled={compact(imageFiles).length < 2}
              onClick={handleUploadImages}
            >
              Submit Insurance Card
            </OutlineButton>
          </>
        )}
      </ResponsiveCollapsingListItem>
      <ResponsiveCollapsingListItem label="Ensure you're entering the Member ID (not GroupID)">
        <div className="responsive-large mb-1">
          Your insurance card may have more than one number to refer to. Entering the wrong number is a common issue
          that causes insurance validation to fail. The one we need is called Member ID.
        </div>
        <div className="locate-then-edit">
          <div className="v-align">
            <TextButtonExternalLink className="locate-link" href={helpUrl ?? ''}>
              Locate your Member ID
            </TextButtonExternalLink>
            <span className="mx-2 then">then</span>
          </div>
          <OutlineButton className="troubleshoot-button" type="button" onClick={onEditClick}>
            Edit Your Insurance
          </OutlineButton>
        </div>
      </ResponsiveCollapsingListItem>
      <ResponsiveCollapsingListItem className="pb-0" label="Pay out of pocket instead">
        <div className="responsive-large mb-1">
          {needsAdditionalZeroCapCoverage ? (
            <>
              {employerName} normally uses insurance to cover sessions. If you would like to pay with a card instead,{' '}
              <TextButton onClick={supportService.openChat}>reach out to our support team</TextButton>
            </>
          ) : (
            `Since we accept credit card as a payment method, you are welcome to enter a credit card on the next step, then try to resolve your insurance later.`
          )}
        </div>
      </ResponsiveCollapsingListItem>
    </StyledInvalidInsuranceTroubleshooting>
  )
}

const StyledInvalidInsuranceTroubleshooting = styled('div')`
  .locate-then-edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  @media (${BelowTablet}) {
    .troubleshoot-button {
      width: 100%;
    }

    .locate-then-edit {
      margin-top: var(--spacing-3);
      .troubleshoot-button {
        margin-top: var(--spacing-2);
      }
    }

    h4 {
      font-size: ${fontSize.h6};
    }
  }
`

export const ResponsiveCollapsingListItem = styled(CollapsingListItem)`
  @media (${Phone}) {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
`

const CreditCardContainer = styled('div')`
  width: 377px;
  max-width: 100%;

  &:not(.has-image) {
    height: 212px;
  }

  @media (${BelowTablet}) {
    .content .upload-instructions {
      width: auto;
    }
  }
`

const ImageUploadLabel = makeTypographyComponent('caption text-bold uppercase mb-xs', 'div')

import { styled } from '@mui/material'
import { HTMLAttributes, MouseEvent, ReactNode, useState } from 'react'

import { DropdownButton, Menu } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  menu: ReactNode
}

const MAX_HEIGHT_BUFFER = 24

export function FilterMenu({ menu, children, ...props }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [maxHeight, setMaxHeight] = useState<undefined | number>()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleEntered = (node: HTMLElement) => {
    setMaxHeight(window.innerHeight - node.offsetTop - MAX_HEIGHT_BUFFER)
  }

  return (
    <div {...props}>
      <DropdownButton type="button" onClick={handleClick} isActive={!!anchorEl}>
        {children}
      </DropdownButton>
      <StyledMenu
        className="mt-2"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{ style: { overflow: 'auto', maxHeight } }}
        TransitionProps={{ onEntering: handleEntered }}
      >
        {menu}
      </StyledMenu>
    </div>
  )
}
const StyledMenu = styled(Menu)`
  .MuiPopover-paper {
    background-color: #fff;
    min-width: 300px;
  }
`

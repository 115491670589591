import { IconProps } from './IconProps'

export function IconGlobe({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3686 6.94843C23.0632 6.14038 23.6197 5.93298 24.0001 5.93298C24.3806 5.93298 24.9371 6.14038 25.6316 6.94843C26.322 7.75159 27.0117 9.00155 27.6207 10.6766C28.7757 13.8535 29.5379 18.274 29.6086 23.2417H18.3916C18.4624 18.274 19.2246 13.8535 20.3796 10.6766C20.9885 9.00155 21.6782 7.75159 22.3686 6.94843ZM15.3913 23.2417C15.4619 18.0062 16.2613 13.2239 17.5601 9.6515C17.9596 8.55264 18.4163 7.54061 18.9309 6.65381C11.6525 8.77728 6.27426 15.3574 5.94873 23.2417H15.3913ZM6.07081 26.2417H15.4312C15.6188 30.8941 16.3851 35.1164 17.5601 38.3485C17.9596 39.4474 18.4163 40.4594 18.9309 41.3462C12.114 39.3574 6.96401 33.4592 6.07081 26.2417ZM18.4337 26.2417H29.5665C29.3804 30.6037 28.6594 34.4665 27.6207 37.3234C27.0117 38.9985 26.322 40.2484 25.6316 41.0516C24.9371 41.8596 24.3806 42.067 24.0001 42.067C23.6197 42.067 23.0632 41.8596 22.3686 41.0516C21.6782 40.2484 20.9885 38.9985 20.3796 37.3234C19.3409 34.4665 18.6199 30.6037 18.4337 26.2417ZM32.5691 26.2417C32.3814 30.8941 31.6152 35.1164 30.4401 38.3485C30.0406 39.4474 29.5839 40.4594 29.0693 41.3462C35.8862 39.3574 41.0362 33.4592 41.9294 26.2417H32.5691ZM42.0515 23.2417C41.726 15.3574 36.3478 8.77728 29.0693 6.65381C29.5839 7.54061 30.0406 8.55264 30.4401 9.6515C31.7389 13.2239 32.5383 18.0062 32.6089 23.2417H42.0515ZM45.0671 24C45.0671 35.635 35.6351 45.067 24.0001 45.067C12.3651 45.067 2.93311 35.635 2.93311 24C2.93311 12.365 12.3651 2.93298 24.0001 2.93298C35.6351 2.93298 45.0671 12.365 45.0671 24Z"
        fill={color}
      />
    </svg>
  )
}

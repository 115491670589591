import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function ScrollToTop() {
  const location = useLocation()

  useEffect(() => {
    return () => location && window.scrollTo(0, 0)
  }, [location])

  return null
}

import { styled } from '@mui/material'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Route, Routes } from 'react-router-dom'

import { usePartnerBrandingQuery, usePatientContextQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { BelowTablet, IconButtonLink, IconClose, Logo, ProgressHeader, borderGrey } from '@nuna/tunic'

import { useIntakeNavigation } from '../intake/hooks/useIntakeNavigation'
import { useIntakeAssessmentResults } from './hooks/useIntakeAssessmentResults'
import { useIntakeConditions } from './hooks/useIntakeConditions'
import { IntakePaths, assessmentRoot, preferencesRoot } from './intakeConstants'

export function IntakeProgressHeader(props: React.HTMLAttributes<HTMLDivElement>) {
  const { hasAppointment } = useIntakeConditions()
  const { stepPaths, currentStepPathIndex } = useIntakeNavigation()
  const { hasModerateToSevereRiskForIntake } = useIntakeAssessmentResults()
  const { data } = usePatientContextQuery()
  const { login } = useAuthDataContext()
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  const company = data?.patientContext?.company
  const patient = data?.patientContext?.patient

  // Look up custom branding based on SSO organization or associated sponsor company
  // Prefer SSO org, so if the user is logged in with SSO but associated with a differently-branded
  // sponsor company then use the SSO org.
  const { data: customBranding } = usePartnerBrandingQuery({
    variables: {
      companyId: !login?.organizationSlug ? company?.id : undefined,
      organizationSlug: login?.organizationSlug ?? undefined,
    },
    skip: !login?.organizationSlug && !company?.id,
  })
  const { logoUrl, primaryColor, secondaryColor } = customBranding?.partnerBranding ?? {}
  const orgLogoImg = logoUrl ? (
    <div className="h-align full-width">
      <img src={logoUrl} alt="" style={{ width: '280px' }} />
    </div>
  ) : undefined
  const customColors = primaryColor ? { start: primaryColor ?? undefined, end: secondaryColor ?? undefined } : undefined

  if (!patient || hasModerateToSevereRiskForIntake) return null

  return (
    <ProgressHeader
      current={currentStepPathIndex}
      max={stepPaths.length}
      leftSlot={
        <span className="v-align shrink-0">
          <Logo renderText={false} height={28} />

          <StepHeading className="h6 mb-0">
            <Routes>
              <Route path={`${routeService.intake()}`} element="Intake" />
              <Route path={`${IntakePaths.p4Confirmation}`} element="You're booked" />
              <Route path={`${IntakePaths.personalInformation}/*`} element="Personal Information" />
              <Route path={`${IntakePaths.stateMismatch}/*`} element="Personal Information" />
              <Route path={`${IntakePaths.coverage}/*`} element="Coverage" />
              <Route path={`${assessmentRoot}/*`} element={isMobile ? 'Assessment' : 'Mental Health Assessment'} />
              <Route path={`${preferencesRoot}/*`} element="Therapist Preferences" />
            </Routes>
          </StepHeading>
        </span>
      }
      rightSlot={
        !hasAppointment && ( // if a patient has an appointment already scheduled (Zocdoc, etc.) they can't leave intake until it's done
          <CloseButton
            data-testid="intake-exit-button"
            to="/"
            className="ml-auto shrink-0"
            tooltip="Close"
            variant="secondary"
          >
            <IconClose />
          </CloseButton>
        )
      }
      centerSlot={!isMobile ? orgLogoImg : undefined}
      customColors={customColors}
      {...props}
    ></ProgressHeader>
  )
}

const CloseButton = styled(IconButtonLink)`
  @media (${BelowTablet}) {
    svg {
      width: 28px;
    }
  }
`

const StepHeading = styled('h1')`
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid ${borderGrey};
`

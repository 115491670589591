import { AvailabilityAccordion } from '../filter-controls/AvailabilityAccordion'
import { AvailabilityFilterCategory } from '../filter-controls/FilterCategories'
import { CategoryHeader } from './CategoryHeader'

export function AvailabilitySection() {
  return (
    <>
      <CategoryHeader pl={19}>
        <AvailabilityFilterCategory iconSize={24} iconContainerWidth={38} />
      </CategoryHeader>
      <AvailabilityAccordion className="pl-2" style={{ paddingTop: 11 }} mobile />
    </>
  )
}

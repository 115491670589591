import * as Yup from 'yup'
import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { EmailVerificationField, useSignupPrevalidation } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { BelowTablet, Grid } from '@nuna/tunic'

import { Illustration } from '../../intake/shared/Illustration'
import { SignupFormStepProps } from '../SignupForm'
import verifyIllustrationSrc from '../img/verify-illustration.png'

export interface VerifyEmailValues {
  verifyEmail: string
}

export const verifyEmailInitialValues = {
  verifyEmail: '',
}

export const verifyEmailSchema = Yup.object().shape({
  verifyEmail: Yup.string().email("Oops, that email isn't formatted correctly").required('Please retype your email'),
})

export function VerifyEmailStep({ formikProps }: SignupFormStepProps) {
  const navigate = useNavigate()
  const { handleChange, errors, touched, values, handleBlur, setFieldValue } = formikProps
  const [prevalidatePatientSignup, { undismissedErrors: emailErrors, dismissError }, { loading }] =
    useSignupPrevalidation()

  const dismissPrevalidationErrors = () => {
    emailErrors.forEach(dismissError)
  }

  return (
    <div className="mb-2">
      <div className="mb-5">
        <Grid container spacing={4} direction="row-reverse" alignItems="center">
          <Grid size={{ xs: 12, sm: 4 }}>
            <IllustrationStyled src={verifyIllustrationSrc} alt="" />
          </Grid>
          <Grid size={{ xs: 12, sm: 8 }}>
            <h2 className="h3">Let's verify we got that right.</h2>
            <p className="text-secondary">Please retype your email address.</p>
          </Grid>
        </Grid>
      </div>

      <EmailVerificationField
        error={!!errors.verifyEmail}
        existingEmail={values.email}
        helperText={errors.verifyEmail ?? ''}
        isLoading={loading}
        name="verifyEmail"
        onBlur={handleBlur}
        onChange={e => {
          dismissPrevalidationErrors()
          handleChange(e)
        }}
        onLoginClick={() => navigate(routeService.login())}
        onUpdateEmailClick={async () => {
          const { valid } = await prevalidatePatientSignup(values.verifyEmail)

          if (valid) {
            setFieldValue('email', values.verifyEmail)
          }
        }}
        showUpdateError={emailErrors.length > 0}
        touched={!!touched.verifyEmail}
        typedEmail={values.verifyEmail}
      />
    </div>
  )
}

const IllustrationStyled = styled(Illustration)`
  @media (${BelowTablet}) {
    max-width: 192px;
  }
`

import { styled } from '@mui/material'
import { useState } from 'react'

import { PatientPaymentMethodFragment } from '@nuna/api'
import { CreditCardCard, CreditCardSelect, useClientPaymentMethods } from '@nuna/payment-methods'
import {
  Collapse,
  Drawer,
  DrawerProps,
  FillButton,
  IconButton,
  IconClose,
  IconCreditCard,
  IconLoading,
  IconTrash,
  eggshell,
  interactiveText,
  interactiveTextHover,
  makeTypographyComponent,
} from '@nuna/tunic'

interface Props extends DrawerProps {
  patientId: string
  clientPaymentMethods: ReturnType<typeof useClientPaymentMethods>
}

export function CardManagementDrawer({
  patientId,
  onClose,
  clientPaymentMethods: { paymentMethods, defaultForFees, defaultForSessions, savePaymentMethod, deletePaymentMethod },
  ...props
}: Props) {
  const [isOtherCardsOpen, setIsOtherCardsOpen] = useState(false)

  const otherPaymentMethods = paymentMethods.filter(
    paymentMethod => !paymentMethod.defaultForSessions && !paymentMethod.defaultForFees,
  )

  return (
    <StyledDrawer size="min(400px, 100vw)" onClose={onClose} {...props}>
      <div className="pt-2 px-3 pb-3">
        <div className="v-align mt-0 mb-3">
          <h2 className="h5 v-align mb-0">
            <IconCreditCard className="mr-1" /> Card Management
          </h2>

          <IconButton variant="secondary" tooltip="Close" className="ml-auto" onClick={onClose}>
            <IconClose size={20} />
          </IconButton>
        </div>

        <section>
          <Heading>Session Costs</Heading>
          <p className="caption italic text-secondary">
            May include insurance co-pays or session costs after employer benefits have been used.
          </p>
          <CreditCardSelect
            cards={paymentMethods}
            value={defaultForSessions}
            onChange={card => {
              savePaymentMethod({
                ...card,
                defaultForSessions: true,
                isUserAcknowledged: true,
                patientId,
              })
            }}
          />
        </section>

        <section>
          <Heading className="mt-4">Cancelation and other fees</Heading>
          <p className="caption italic text-secondary">
            This includes late cancelation, late rescheduling, and no-show fees. Note that HSA cards cannot be used for
            these fees.
          </p>
          <CreditCardSelect
            cards={paymentMethods}
            value={defaultForFees}
            showHSAWarning
            onChange={card => {
              savePaymentMethod({
                ...card,
                defaultForFees: true,
                isUserAcknowledged: true,
                patientId,
              })
            }}
          />
        </section>

        {otherPaymentMethods.length > 0 && (
          <section>
            <Heading className="mt-4 v-align">
              {otherPaymentMethods.length} other cards not in use{' '}
              <ShowHideButton className="ml-auto" onClick={() => setIsOtherCardsOpen(!isOtherCardsOpen)}>
                {isOtherCardsOpen ? 'Hide' : 'Show'}
              </ShowHideButton>
            </Heading>

            <Collapse isOpen={isOtherCardsOpen}>
              {otherPaymentMethods.map(paymentMethod => (
                <OtherCard
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                  deletePaymentMethod={deletePaymentMethod}
                />
              ))}
            </Collapse>
          </section>
        )}

        <FillButton className="mt-4" onClick={onClose}>
          Done
        </FillButton>
      </div>
    </StyledDrawer>
  )
}

interface OtherCardProps {
  paymentMethod: PatientPaymentMethodFragment
  deletePaymentMethod: ReturnType<typeof useClientPaymentMethods>['deletePaymentMethod']
}

function OtherCard({ paymentMethod, deletePaymentMethod }: OtherCardProps) {
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const handleDelete = async () => {
    setIsDeleteLoading(true)
    await deletePaymentMethod(paymentMethod.id)
    setIsDeleteLoading(false)
  }

  return (
    <CreditCardCard
      depth={-1}
      className="mb-1"
      number={paymentMethod.accountLastFour}
      name={paymentMethod.description}
      rightSlot={
        <IconButton small variant="secondary" tooltip="Delete" onClick={handleDelete}>
          {isDeleteLoading ? <IconLoading className="rotate" size={16} /> : <IconTrash size={20} />}
        </IconButton>
      }
    />
  )
}

const Heading = makeTypographyComponent('text-bold body', 'h3')
const ShowHideButton = styled('button')`
  color: ${interactiveText};

  &:hover {
    color: ${interactiveTextHover};
  }
`

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${eggshell};
  }
`

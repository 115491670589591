import { IconProps } from './IconProps'

export function IconDuplicate({ size = 18, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7353 14.8438V7.71875H12.0882V5.9375H16.5V16.625H5.91176V13.0625H1.5V2.375H12.0882V13.0625H7.67647V14.8438H14.7353ZM10.3235 4.15625H3.26471V11.2812H10.3235V4.15625Z"
        fill={color}
      />
    </svg>
  )
}

import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import { compact } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  IntakeProgressQuery,
  PatientIntakeSpecialtiesInput,
  SpecialtyInput,
  useSavePatientSpecialtiesMutation,
  useSpecialtiesQuery,
} from '@nuna/api'
import { usePatientId } from '@nuna/auth'
import { formService, specialtiesService } from '@nuna/core'
import { Chip, ChipGroup, ChipLoader, GhostButton, Typography, borderGrey } from '@nuna/tunic'

import { Tip } from '../../../shared/components/Tip'
import { getProviderSuggestionRoute } from '../../../util/routes'
import { Container } from '../InsuranceIntake'
import { useInsuranceIntakeContext } from '../InsuranceIntakeContext'
import { IntakeNavigation } from '../IntakeNavigation'
import { useIntakeNavigation } from '../hooks/useIntakeNavigation'
import { useSkipIntake } from '../hooks/useSkipIntake'
import matchIllustration from '../img/match-illustration-love.png'
import { NONE_OF_ABOVE } from '../intakeConstants'
import { StepHeader } from '../shared/StepHeader'

const { getFieldProps } = formService

export interface ChallengesFormValues {
  challenges: SpecialtyInput[]
  areasOfImpact: SpecialtyInput[]
  // otherAreaExplanation: string
}

export const challengesSchema = Yup.object<ChallengesFormValues>({
  challenges: Yup.array<SpecialtyInput>(Yup.object()).min(1, 'Please select at least 1 issue or challenge'),
  areasOfImpact: Yup.array<SpecialtyInput>(Yup.object()).min(1, 'Please select at least 1 area'),
  // otherAreaExplanation: Yup.string().when("areasOfImpact", {
  //   is: areas => areas.includes("other"),
  //   then: Yup.string().required("Please describe how these issues are affecting your life"),
  //   otherwise: Yup.string().notRequired(),
  // }),
})

export function Challenges() {
  const patientId = usePatientId() ?? ''
  const navigate = useNavigate()
  const { nextPath } = useIntakeNavigation()
  const { skipIntake } = useSkipIntake()

  const { intakeProgressData, setIsMutationLoading } = useInsuranceIntakeContext()

  const { data: specialtiesData, loading: areSpecialtiesLoading } = useSpecialtiesQuery()
  const [savePatientSpecialties] = useSavePatientSpecialtiesMutation()

  const challenges = specialtiesService.getChallenges(specialtiesData?.specialties ?? [])
  const areas = specialtiesService.getAreasOfImpact(specialtiesData?.specialties ?? [])

  const handleSubmit = async (values: ChallengesFormValues) => {
    setIsMutationLoading(true)
    await savePatientSpecialties({
      variables: {
        id: patientId,
        specialties: getSpecialtiesInput(values),
      },
    })
    setIsMutationLoading(false)

    navigate(`../${nextPath}`)
  }

  if (!intakeProgressData) {
    return null
  }

  return (
    <Formik
      initialValues={buildInitialValues(intakeProgressData)}
      validationSchema={challengesSchema}
      onSubmit={handleSubmit}
    >
      {formikProps => {
        const { values, handleSubmit, setFieldValue } = formikProps

        const challengesProps = getFieldProps('challenges', formikProps)
        const areasProps = getFieldProps('areasOfImpact', formikProps)

        const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
          const { name, value } = e.currentTarget

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((values as any)[name].find((specialty: SpecialtyInput) => specialty.id === NONE_OF_ABOVE)) {
            setFieldValue(name, [{ id: value }])
            return
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const existingValueIndex = (values as any)[name].findIndex(
            (specialty: SpecialtyInput) => specialty.id === value,
          )

          if (existingValueIndex > -1) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const copy = [...(values as any)[name]]

            copy.splice(existingValueIndex, 1)
            setFieldValue(name, copy)
          } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setFieldValue(name, [...(values as any)[name], { id: value }])
          }
        }

        return (
          <form onSubmit={handleSubmit}>
            <Container>
              <StepHeader
                className="mb-0"
                imageSrc={matchIllustration}
                smallHeading="Therapist Preferences"
                largeHeading="Finding a great therapist match makes all the difference."
              >
                <Typography sx={{ textWrap: 'pretty' }}>
                  Tell us what matters most to you. We’ll recommend a therapist that fits your needs.
                </Typography>
              </StepHeader>
              <h1 id="challenges-heading" className="h5">
                Which concerns or challenges are you dealing with right now?
              </h1>

              <Tip className="mb-3" intro="Affects therapist results:" description="Select all that apply" />

              <GroupSection
                error={challengesProps.error}
                helperText={challengesProps.helperText}
                aria-labelledby="challenges-heading"
              >
                {areSpecialtiesLoading && <ChipLoader quantity={20} />}

                {!areSpecialtiesLoading &&
                  challenges.map(({ id, name }) => (
                    <Chip
                      key={id}
                      name={challengesProps.name}
                      value={id}
                      checked={!!values.challenges.find(challenge => challenge.id === id)}
                      type="checkbox"
                      onChange={e => handleChange(e)}
                      dataTestId={name}
                      className="fs-exclude"
                    >
                      {name}
                    </Chip>
                  ))}
              </GroupSection>

              <Chip
                name={challengesProps.name}
                value={NONE_OF_ABOVE}
                type="checkbox"
                onChange={() => setFieldValue(challengesProps.name, [{ id: NONE_OF_ABOVE }])}
                checked={!!values.challenges.find(challenge => challenge.id === NONE_OF_ABOVE)}
              >
                None of these, just looking for some help
              </Chip>

              <h2 className="h5 mt-6" id="areas-heading">
                What areas of your life are most significantly impacted by these challenges?
              </h2>
              <p className="mb-5">Select any areas you think may be valuable to address with your therapist</p>

              <GroupSection error={areasProps.error} helperText={areasProps.helperText} aria-labelledby="areas-heading">
                {areSpecialtiesLoading && <ChipLoader quantity={10} />}
                {!areSpecialtiesLoading &&
                  areas.map(({ id, name }) => (
                    <Chip
                      key={id}
                      name={areasProps.name}
                      value={id}
                      checked={!!values.areasOfImpact.find(area => area.id === id)}
                      type="checkbox"
                      onChange={e => handleChange(e)}
                      dataTestId={name}
                      className="fs-exclude"
                    >
                      {name}
                    </Chip>
                  ))}
              </GroupSection>

              <Chip
                name={areasProps.name}
                value={NONE_OF_ABOVE}
                type="checkbox"
                onChange={() => setFieldValue('areasOfImpact', [{ id: NONE_OF_ABOVE }])}
                checked={!!values.areasOfImpact.find(challenge => challenge.id === NONE_OF_ABOVE)}
              >
                Another area
              </Chip>

              <IntakeNavigation
                className="mt-7"
                skipButton={() => (
                  <GhostButton sx={{ ml: 4 }} onClick={() => skipIntake(getProviderSuggestionRoute())}>
                    Skip to view all providers
                  </GhostButton>
                )}
              />
            </Container>
          </form>
        )
      }}
    </Formik>
  )
}

export function buildInitialValues(data: IntakeProgressQuery): ChallengesFormValues {
  return {
    challenges: specialtiesService.getChallenges(compact(data.intakeProgress.specialties ?? [])),
    areasOfImpact: specialtiesService.getAreasOfImpact(compact(data.intakeProgress.specialties ?? [])),
  }
}

function getSpecialtiesInput(values: ChallengesFormValues): PatientIntakeSpecialtiesInput {
  return {
    areasOfImpact: values.areasOfImpact.find(area => area.id === NONE_OF_ABOVE)
      ? []
      : values.areasOfImpact.map(area => ({ id: area.id })),
    challenges: values.challenges.find(challenge => challenge.id === NONE_OF_ABOVE)
      ? []
      : values.challenges.map(challenge => ({ id: challenge.id })),
  }
}

const GroupSection = styled(ChipGroup)`
  border-bottom: 1px solid ${borderGrey};
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
`

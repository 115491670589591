import { useApolloClient } from '@apollo/client'
import gql from 'graphql-tag'
import { useLocation } from 'react-router-dom'

import { LoginScreen } from '@nuna/auth'
import { routeService } from '@nuna/core'

const PATIENT_CONTEXT_QUERY = gql`
  {
    patientContext {
      patient {
        intakeCompleted
      }
    }
  }
`

interface RouteState {
  from: Location
}

export function LoginLanding() {
  const location = useLocation()
  const fromLocation = location.state ? (location.state as RouteState).from : null
  const fromInviteLink = fromLocation?.pathname === '/invite' ? fromLocation.pathname : null

  const client = useApolloClient()

  const getRedirectRoute = (intakeCompleted: boolean) => {
    if (fromInviteLink) {
      return fromInviteLink
    }

    if (!intakeCompleted) {
      return routeService.intake()
    }

    return null
  }

  const getCustomRedirectPath = () => {
    return client
      .query({
        query: PATIENT_CONTEXT_QUERY,
        fetchPolicy: 'network-only',
      })
      .then(patientContextQuery =>
        getRedirectRoute(!!patientContextQuery?.data?.patientContext?.patient?.intakeCompleted),
      )
  }

  return <LoginScreen audience="client" getRedirectPath={getCustomRedirectPath} showSignupLink={true} />
}

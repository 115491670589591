import { cloneDeep, intersectionBy, sum } from 'lodash'

import { GeneralAvailabilityInput, PreferredProviderGender } from '@nuna/api'

import { SearchProviderFilterState } from './SearchProviderFilterState'

export enum SpecialtyFilterCategory {
  SessionTypes = 'sessionTypes',
  Concerns = 'concerns',
  Ethnicity = 'ethnicity',
  Faith = 'faith',
  Sexuality = 'sexuality',
  Modalities = 'modalities',
  Languages = 'languages',
}

export type ProviderFilterCategory = 'availability' | 'coverage' | 'specialties' | 'demographics' | 'favorites'
export type ProviderFilterSpecialtyOption = { id: string; name: string; disabled?: boolean | null }

export const READABLE_SPECIALTY_CATEGORIES: Record<SpecialtyFilterCategory, string> = {
  [SpecialtyFilterCategory.SessionTypes]: 'Session Types',
  [SpecialtyFilterCategory.Concerns]: 'Concerns',
  [SpecialtyFilterCategory.Ethnicity]: 'Ethnicity',
  [SpecialtyFilterCategory.Faith]: 'Faith',
  [SpecialtyFilterCategory.Sexuality]: 'Sexuality',
  [SpecialtyFilterCategory.Modalities]: 'Methods',
  [SpecialtyFilterCategory.Languages]: 'Language',
}

function genderFilterApplied(gender?: PreferredProviderGender) {
  return gender ? 1 : 0
}

function ageFiltersApplied(initial: [number, number] | undefined, current: [number, number]) {
  if (!initial) {
    return 0
  }
  if (initial[0] === current[0] && initial[1] === current[1]) {
    return 0
  }

  return 1
}

function aggregateSpecialtyFilters(categories: SpecialtyFilterCategory[], filterState: SearchProviderFilterState) {
  return categories.reduce((prev, category) => {
    return prev + filterState[category].length
  }, 0)
}

export function generalAvailabilityFiltersCount({ days, hours }: GeneralAvailabilityInput) {
  return (days ?? []).length + (hours ?? []).length
}

function filterCount(filterState: SearchProviderFilterState, category?: ProviderFilterCategory) {
  if (!filterState.availableValues) return 0

  const count: number[] = []

  if (!category || category === 'availability') {
    count.push(
      filterState.currentAvailability.length + generalAvailabilityFiltersCount(filterState.generalAvailability),
    )
  }

  if (!category || category === 'coverage') {
    count.push(filterState.coverage.length)
  }

  if (!category || category === 'specialties') {
    count.push(
      aggregateSpecialtyFilters(
        [
          SpecialtyFilterCategory.SessionTypes,
          SpecialtyFilterCategory.Concerns,
          SpecialtyFilterCategory.Languages,
          SpecialtyFilterCategory.Modalities,
        ],
        filterState,
      ),
    )
  }

  if (!category || category === 'demographics') {
    count.push(
      searchProviderUtils.genderFilterApplied(filterState.gender) +
        ageFiltersApplied(filterState.availableValues.ageRange, filterState.ageRange) +
        aggregateSpecialtyFilters(
          [SpecialtyFilterCategory.Ethnicity, SpecialtyFilterCategory.Faith, SpecialtyFilterCategory.Sexuality],
          filterState,
        ),
    )
  }

  if (!category && filterState.search) {
    count.push(1)
  }

  if ((!category || category === 'favorites') && filterState.favoritesOnly) {
    count.push(1)
  }

  if (count.length === 0) {
    return 0
  }

  return sum(count)
}

function readableFilters(filterState: SearchProviderFilterState) {
  return {
    specialities: buildSpecialtyNames(filterState),
    coverages: [...filterState.coverage],
    generalAvailability: filterState.generalAvailability,
    gender: filterState.gender,
    searchTerm: filterState.search,
    currentAvailability: [...filterState.currentAvailability],
  }
}

function buildSpecialtyNames(state: SearchProviderFilterState): string[] {
  const specialtyNames: string[] = []
  Object.values(SpecialtyFilterCategory).forEach(category => {
    if (state[category]) {
      specialtyNames.push(...state[category].map(option => option.name))
    }
  })
  return specialtyNames
}

function intersectSpecialities(
  specialities1: Record<SpecialtyFilterCategory, ProviderFilterSpecialtyOption[]>,
  specialities2: Record<SpecialtyFilterCategory, ProviderFilterSpecialtyOption[]>,
) {
  return Object.entries(specialities1).reduce((acc, [category, specialities]) => {
    acc[category as SpecialtyFilterCategory] = intersectionBy(
      specialities,
      specialities2[category as SpecialtyFilterCategory],
      'name',
    )
    return acc
  }, {} as Record<SpecialtyFilterCategory, ProviderFilterSpecialtyOption[]>)
}

function applyUpdatedSpecialties(
  originalSpecialities: Record<SpecialtyFilterCategory, ProviderFilterSpecialtyOption[]>,
  updatedSpecialities: Record<SpecialtyFilterCategory, ProviderFilterSpecialtyOption[]>,
) {
  const newSpecialities = cloneDeep(originalSpecialities)
  Object.entries(newSpecialities).forEach(([category, specialties]) => {
    specialties.forEach(s => {
      s.disabled = !updatedSpecialities[category as SpecialtyFilterCategory].some(s2 => s2.name === s.name)
    })
  })
  return newSpecialities
}

export const searchProviderUtils = {
  genderFilterApplied,
  ageFiltersApplied,
  aggregateSpecialtyFilters,
  generalAvailabilityFiltersCount,
  filterCount,
  readableFilters,
  intersectSpecialities,
  applyUpdatedSpecialties,
}

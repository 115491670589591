import { styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

import { Logo, OutlineButtonLink, eggshell } from '@nuna/tunic'

import { SplitContent } from '../shared/components/Layout'

const Header = styled('div')`
  background-color: ${eggshell};
  padding: 1rem;
`

interface ScreenTakeoverLayoutProps {
  children?: React.ReactNode
}

export function ScreenTakeoverLayout({ children }: ScreenTakeoverLayoutProps) {
  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: eggshell,
      }}
    >
      <header>
        <Header>
          <SplitContent>
            <NavLink to="/signup">
              <Logo height={36} />
            </NavLink>
            <div>
              <OutlineButtonLink to="/signup">Sign Up</OutlineButtonLink>
            </div>
          </SplitContent>
        </Header>
      </header>
      <div>{children}</div>
    </div>
  )
}

import { MouseEventHandler } from 'react'

import { Card, CardBody, GhostButton, Grid } from '@nuna/tunic'

import creditCardImgSrc from '../img/credit-card.png'

interface Props {
  onAddClick: MouseEventHandler<HTMLButtonElement>
}

export function EmptyCardPrompt({ onAddClick }: Props) {
  return (
    <Card depth={-1}>
      <CardBody>
        <Grid container spacing={1} alignItems="center">
          <Grid
            size={{
              xs: 12,
              sm: 4,
            }}
          >
            <img src={creditCardImgSrc} alt="Credit Card" />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 8,
            }}
          >
            <div className="ml-2">
              <p>You need a credit card on file to cover potential late cancelation fees</p>
              <GhostButton variant="secondary" onClick={onAddClick}>
                Add a card now
              </GhostButton>
            </div>
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  )
}

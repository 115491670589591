import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode } from 'react'

import { Accordion, AccordionLabelProps, AccordionProps, Badge, csx } from '@nuna/tunic'

export interface AccordionTypeProps extends HTMLAttributes<HTMLDivElement> {
  mobile?: boolean
}

type FilterAccordionProps = AccordionProps & AccordionTypeProps

export function FilterAccordion({ style, className, mobile, ...props }: FilterAccordionProps) {
  return (
    <div style={style} className={className}>
      <Accordion
        {...props}
        buttonProps={{ className: csx({ 'pl-1': !mobile }), style: { paddingLeft: mobile ? 12 : undefined } }}
        collapseProps={{ className: 'pl-1' }}
        className="pl-xs pr-1"
      />
    </div>
  )
}

interface FilterAccordionLabelProps extends AccordionLabelProps {
  filtersApplied: number
  children: ReactNode
}
export function FilterAccordionLabel({ isOpen, filtersApplied, children }: FilterAccordionLabelProps) {
  return (
    <div className="v-align flex-remaining-space">
      {children} {!isOpen && filtersApplied > 0 && <Badge className="ml-auto">{filtersApplied}</Badge>}
    </div>
  )
}

export const FilterSection = styled('div')`
  padding: var(--spacing-1) 0;

  .checkbox,
  .radio {
    &:not(:last-child) {
      margin-bottom: var(--spacing-1-5);
    }
  }

  .radio {
    // the focus shadow gets clipped without a bit extra left margin
    margin-left: 4px;
  }
`

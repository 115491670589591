import { styled } from '@mui/material'
import { NavLinkProps } from 'react-router-dom'

import { InternalAppointment } from '@nuna/api'
import { routeService } from '@nuna/core'
import { FillButtonLink, FillButtonLinkProps } from '@nuna/tunic'

export interface VideoLinkProps extends Omit<FillButtonLinkProps, 'to'>, Omit<NavLinkProps, 'to'> {
  appointment: Pick<InternalAppointment, 'id'>
}

export function VideoLink({ appointment, children, ...props }: VideoLinkProps) {
  return (
    <VideoLinkButton className="full-width mt-2" to={routeService.videoSession(appointment.id)} {...props}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore react-router's children clashes with styled-components */}
      {children}
    </VideoLinkButton>
  )
}

const VideoLinkButton = styled(FillButtonLink)`
  display: flex;
  flex-wrap: wrap;
  height: auto;
  padding: 0.75rem 1rem;
`

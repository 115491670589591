import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { routeService } from '@nuna/core'
import { IconButtonLink, IconMail } from '@nuna/tunic'

import { useConversationUnreadContext } from '../context/ConversationUnreadContext'
import { UnreadBadge } from './UnreadBadge'

const { conversationsBaseRoute } = routeService

export function ConversationIconLink(props: HTMLAttributes<HTMLDivElement>) {
  const { hasAnyUnread } = useConversationUnreadContext()

  return (
    <Container {...props}>
      {hasAnyUnread() && <UnreadBadgeStyled />}
      <IconButtonLink small className="mr-1" variant="secondary" tooltip="Go to messages" to={conversationsBaseRoute}>
        <IconMail size={20} />
      </IconButtonLink>
    </Container>
  )
}

const Container = styled('div')`
  align-items: center;
  position: relative;
  display: flex;
`

const UnreadBadgeStyled = styled(UnreadBadge)`
  position: absolute;
  right: calc(100% - 0.25rem);
  width: 10px;
  height: 10px;
`

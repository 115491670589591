import { CoverageFiltersSelect } from '../filter-controls/CoverageFiltersSelect'
import { CoverageFilterCategory } from '../filter-controls/FilterCategories'
import { CategoryHeader } from './CategoryHeader'

export function CoverageSection() {
  return (
    <>
      <CategoryHeader pl={21}>
        <CoverageFilterCategory iconSize={18} iconContainerWidth={35} />
      </CategoryHeader>
      <div style={{ padding: '11px 28px 0 28px' }}>
        <CoverageFiltersSelect />
      </div>
    </>
  )
}

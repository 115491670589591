import { Navigate } from 'react-router-dom'

import { useCurrentAppointmentsQuery } from '@nuna/api'
import { routeService } from '@nuna/core'

export function PatientVideoRedirect() {
  const { loading, data } = useCurrentAppointmentsQuery({ fetchPolicy: 'no-cache', variables: { timeBeforeStart: 15 } })

  if (loading) {
    return null
  }

  const currentAppointments = data?.currentAppointments || []
  const [currentAppointment] = currentAppointments

  if (!currentAppointment) {
    return <Navigate to={routeService.noCurrentSessionRoute()} />
  }

  return <Navigate to={routeService.videoSession(currentAppointment.id)} />
}

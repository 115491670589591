import { AssessmentStatus, AssessmentType, usePatientContextQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'

import { useAssessmentsContext } from '../context/AssessmentsContext'
import { determineSeverity } from '../util/assessments'

export const useAssessmentResults = () => {
  const { assessmentBundle } = useAssessmentsContext()
  const { login } = useAuthDataContext()
  const { patientId } = login || {}
  const { data: patientContextData } = usePatientContextQuery({ skip: !patientId })
  const { assessments } = assessmentBundle || {}
  const gad7 = assessments?.find(a => a.type === AssessmentType.Gad_7 && a.status === AssessmentStatus.Completed)
  const phq9 = assessments?.find(a => a.type === AssessmentType.Phq_9 && a.status === AssessmentStatus.Completed)
  const cssrs = assessments?.find(a => a.type === AssessmentType.CSsrs && a.status === AssessmentStatus.Completed)
  const cssrsScore = cssrs?.score ?? 0
  const hasSuicidalityRisk = cssrsScore > 0

  const gad7Severity = determineSeverity(gad7?.score, AssessmentType.Gad_7)
  const phq9Severity = determineSeverity(phq9?.score, AssessmentType.Phq_9)
  const maxSeverity = Math.max(gad7Severity, phq9Severity)

  const providers = patientContextData?.patientContext.patient.providers
  const shownProvider =
    providers?.find(provider => assessments?.find(assessment => assessment.requesterId === provider.loginId)) ||
    providers?.[0] // show the assigned provider, otherwise the first one

  return { hasSuicidalityRisk, maxSeverity, shownProvider }
}

import { noop } from 'lodash'

import { PreferredProviderGender } from '@nuna/api'
import { Box, Radio, RadioGroup, RangeField } from '@nuna/tunic'

import { useSearchProviderContext } from '../SearchProviderContext'
import { SpecialtyFilterCategory, searchProviderUtils } from '../search-provider.utils'
import { AccordionTypeProps, FilterAccordion, FilterAccordionLabel, FilterSection } from './FilterAccordion'
import { SpecialtiesFilterSection } from './SpecialtiesFilterSection'

const GENDER_OPTIONS = [
  { key: PreferredProviderGender.Female, label: 'Female' },
  { key: PreferredProviderGender.Male, label: 'Male' },
  { key: PreferredProviderGender.Transgender, label: 'Transgender' },
]

export function DemographicsAccordion(props: AccordionTypeProps) {
  const { appliedFilters, specialtyFilterOptions, setGender, setAgeRange } = useSearchProviderContext()

  const sections = []
  if (specialtyFilterOptions.faith?.length !== 0) {
    sections.push(SpecialtiesFilterSection(SpecialtyFilterCategory.Faith, 'radio'))
  }
  if (specialtyFilterOptions.sexuality?.length !== 0) {
    sections.push(SpecialtiesFilterSection(SpecialtyFilterCategory.Sexuality, 'radio'))
  }
  if (specialtyFilterOptions.ethnicity?.length !== 0) {
    sections.push(SpecialtiesFilterSection(SpecialtyFilterCategory.Ethnicity, 'radio'))
  }

  return (
    <FilterAccordion
      {...props}
      sections={[
        {
          label: props => (
            <FilterAccordionLabel
              filtersApplied={searchProviderUtils.genderFilterApplied(appliedFilters.gender)}
              {...props}
            >
              Gender
            </FilterAccordionLabel>
          ),
          content: (
            <FilterSection>
              <RadioGroup>
                {GENDER_OPTIONS.map(option => (
                  <Radio
                    key={option.key}
                    value={PreferredProviderGender.Female}
                    checked={appliedFilters.gender === option.key}
                    onClick={() => setGender(option.key)}
                    onChange={noop}
                  >
                    {option.label}
                  </Radio>
                ))}
              </RadioGroup>
            </FilterSection>
          ),
        },
        {
          label: props => (
            <FilterAccordionLabel
              {...props}
              filtersApplied={searchProviderUtils.ageFiltersApplied(
                appliedFilters?.availableValues?.ageRange,
                appliedFilters.ageRange,
              )}
            >
              Age
            </FilterAccordionLabel>
          ),
          content: (
            <FilterSection>
              <Box sx={{ display: 'flex', alignItems: 'end', height: '70px', pl: 2, pr: 3 }}>
                <RangeField
                  min={appliedFilters?.availableValues?.ageRange?.[0] ?? 0}
                  max={appliedFilters?.availableValues?.ageRange?.[1] ?? 100}
                  value={[...appliedFilters.ageRange]}
                  onChange={(_e: Event, newValue: number[]) => setAgeRange(newValue as [number, number])}
                />
              </Box>
            </FilterSection>
          ),
        },
        ...sections,
      ]}
    />
  )
}

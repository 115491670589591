import { usePostListQuery } from '../generated/sanctuary-graphql'
import { getPostCategory } from '../util/getPostCategory'

export function usePostCategories() {
  const { data, error } = usePostListQuery()

  const categories = new Set(data?.getPosts.flatMap(post => post.category.map(getPostCategory)))

  return { categories, error }
}

import { styled } from '@mui/material'
import { ReactNode, useEffect } from 'react'
import { Outlet, Route, Routes, useMatch, useNavigate, useParams } from 'react-router-dom'

import { ConversationStatus } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { routeService } from '@nuna/core'
import {
  ConversationContextProvider,
  ConversationDetail,
  ConversationMenu,
  useConversationContext,
  useSaveConversationMutationWithCacheUpdate,
} from '@nuna/messaging'
import { IconMail, toast } from '@nuna/tunic'

import { AppLayout } from '../../layouts/AppLayout'

const { conversationsDetailFullPath } = routeService

export function ClientMessages() {
  return (
    <AppLayoutStyled disableFooter>
      <Routes>
        <Route
          path="*"
          element={
            <ConversationContextProvider>
              <ClientConversationWithContext />
            </ConversationContextProvider>
          }
        >
          <Route
            path=":conversationId"
            element={
              <DetailContainer>
                <ConversationDetail audience="client" />
              </DetailContainer>
            }
          />
        </Route>
      </Routes>
    </AppLayoutStyled>
  )
}

function ClientConversationWithContext() {
  const navigate = useNavigate()
  const { conversations } = useConversationContext()
  const isOnConversationDetail = !!useMatch(conversationsDetailFullPath)
  const [saveConversationMutation] = useSaveConversationMutationWithCacheUpdate()

  // client should always auto select if there is only one conversation, regardless of desktop or mobile
  useEffect(() => {
    if (conversations.length === 1 && !isOnConversationDetail) {
      const conversation = conversations[0]

      if (conversation.status === ConversationStatus.NotCreated) {
        saveConversationMutation({
          variables: { recipients: (conversation.participants ?? []).map(p => p.loginId) },
        })
          .then(response => {
            if (response.data?.saveConversation) {
              navigate(response.data.saveConversation.id, { replace: true })
            } else {
              toast.urgent('Error initializing conversation')
            }
          })
          .catch(e => {
            console.error(e)
            toast.urgent('Error initializing conversation')
          })
      } else {
        navigate(conversation.id, { replace: true })
      }
    }
  }, [conversations, isOnConversationDetail, navigate, saveConversationMutation])

  return (
    <ScrollContainer>
      {conversations.length > 1 && <ConversationMenu audience="client" className="mr-4" style={{ marginTop: 53 }} />}
      <Outlet />
    </ScrollContainer>
  )
}

function DetailContainer({ children }: { children: ReactNode }) {
  const { login } = useAuthDataContext()
  const { conversations } = useConversationContext()
  const { conversationId } = useParams()

  const conversation = conversations.find(c => c.id === conversationId)
  const provider = conversation?.participants?.find(participant => participant.loginId !== login?.id)

  const classNames = ['v-align', 'mb-2']

  classNames.push(provider ? 'h6' : 'h4')

  return (
    <StyledDetailContainer>
      <h1 className={classNames.join(' ')}>
        <IconMail className="mr-1" size={provider ? 20 : 28} />
        Messages {provider && `with ${provider.displayData.firstName} ${provider.displayData.lastName}`}
      </h1>
      {children}
    </StyledDetailContainer>
  )
}

const ScrollContainer = styled('div')`
  justify-content: center;
  display: flex;
  padding-left: var(--margin-2);
  padding-right: var(--margin-2);
  height: 100%;
`

const StyledDetailContainer = styled('div')`
  width: 100%;
  max-width: 675px;
  height: 100%;
  display: flex;
  flex-direction: column;

  > div {
    overflow: auto;
  }
`

const AppLayoutStyled = styled(AppLayout)`
  height: 100vh;
  overflow: hidden;

  > div {
    flex: 1 1 100%;
    overflow: auto;
  }
`

import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'

import { PatientContext, PatientContextDocument, PatientContextQuery } from '@nuna/api'

import { client } from './apollo'

export const getPatientContext = async () => {
  const res = await client.query<PatientContextQuery>({
    query: PatientContextDocument,
  })

  if (res.data.patientContext) {
    return res.data.patientContext
  } else {
    throw new Error('No Patient Context Found')
  }
}

type Actions = 'manage' | 'create' | 'view' | 'update' | 'delete' | 'schedule'
type Subjects = 'billing' | 'addCardPrompt' | 'appointment'

export type AppAbility = Ability<[Actions, Subjects]>
export const AppAbility = Ability as AbilityClass<AppAbility>

const defineRules = (patientContext: PatientContext) => {
  const { can, rules, cannot } = new AbilityBuilder<AppAbility>()

  const {
    patient: {
      appointmentConfiguration: { appointmentFeeApplies },
      paymentMethods,
      stripeCustomerId,
    },
  } = patientContext

  if (appointmentFeeApplies && stripeCustomerId) {
    can('manage', 'billing')
  }

  if (appointmentFeeApplies && stripeCustomerId && !paymentMethods?.length) {
    can('view', 'addCardPrompt')
  }

  can('schedule', 'appointment')
  if (appointmentFeeApplies && stripeCustomerId && !paymentMethods?.length) {
    cannot('schedule', 'appointment')
  }

  return rules
}

export function buildAbility(patientContext: PatientContext) {
  const rules = defineRules(patientContext)
  return new AppAbility(rules)
}

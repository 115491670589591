import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'

import { usePatientContextQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { IconChevronThick, IconUpload, MenuDivider, MenuItem, NavMenu } from '@nuna/tunic'

export function PatientNavMenu() {
  const { data: patientContextData } = usePatientContextQuery()

  const patient = patientContextData?.patientContext.patient

  const location = useLocation()
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const { onLogout } = useAuthDataContext()

  const pathname = location.pathname
  return (
    <NavMenu firstName={patient?.firstName} avatarUrl={patient?.avatarUrl}>
      {pathname !== '/' && (
        <>
          <MenuItem leadingIcon={IconChevronThick} to="/">
            Back to Home
          </MenuItem>
          <MenuDivider />
        </>
      )}

      {!patient?.avatarUrl && pathname !== '/profile' && (
        <>
          <MenuItem style={isMobile ? { height: 'auto' } : undefined} to="/profile" trailingIcon={IconUpload}>
            <div>
              {isMobile && (
                <span className="block mb-1 text-teal body text-medium">Connect with your therapist...</span>
              )}
              <span className="text-teal">Add your photo</span>
            </div>
          </MenuItem>
          <MenuDivider />
        </>
      )}

      <MenuItem to="/profile">Personal Info</MenuItem>
      <MenuItem to="/assessments">Assessments</MenuItem>
      <MenuItem to={routeService.clientBilling()}>Billing Info</MenuItem>

      <MenuItem to="/family-members">Send an Invite</MenuItem>

      <MenuDivider />
      <MenuItem button onClick={() => onLogout && onLogout()}>
        Sign Out
      </MenuItem>
    </NavMenu>
  )
}

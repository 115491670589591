import { HTMLAttributes } from 'react'

import { interactiveFill } from '@nuna/tunic'

export function VideoAskPlayButton(props: HTMLAttributes<HTMLButtonElement>) {
  return (
    <button {...props}>
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M34.5 17.9999C34.5 8.88724 27.1127 1.49994 18 1.49994C8.8873 1.49994 1.5 8.88724 1.5 17.9999C1.5 27.1126 8.8873 34.4999 18 34.4999C27.1127 34.4999 34.5 27.1126 34.5 17.9999Z"
          fill={interactiveFill}
          stroke="white"
          strokeWidth="3"
        />
        <path
          d="M24.3756 17.6657C25.0098 18.0563 25.0098 18.9781 24.3756 19.3687L15.6447 24.7455C14.9784 25.1558 14.1203 24.6765 14.1203 23.894L14.1203 13.1403C14.1203 12.3579 14.9784 11.8786 15.6447 12.2889L24.3756 17.6657Z"
          fill="white"
        />
      </svg>
    </button>
  )
}

import { styled } from '@mui/material'
import React from 'react'

import { BelowTablet, IconClose } from '@nuna/tunic'
import { IconButton, body2 } from '@nuna/tunic'

interface CloseButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  showEscText?: boolean
  iconSize?: number
}

export function CloseButton({ showEscText = true, iconSize = 28, ...props }: CloseButtonProps) {
  return (
    <IconButton tooltip="Close" type="button" variant="secondary" {...props}>
      <IconClose size={iconSize} />
      {showEscText && (
        <EscText>
          <span className="text-bold">ESC</span> to close
        </EscText>
      )}
    </IconButton>
  )
}

const EscText = styled('span')`
  bottom: 0;
  color: ${body2};
  height: 16px;
  margin: auto 1rem auto 0;
  pointer-events: none;
  position: absolute;
  right: 100%;
  top: 0;
  white-space: nowrap;

  @media (${BelowTablet}) {
    display: none;
  }
`

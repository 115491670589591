import { noop, omit, pick, sortBy } from 'lodash'
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'

import {
  BasicProviderFragment,
  CurrentAvailability,
  DayOfWeek,
  FilteredProviderFragment,
  OfficeHours,
  PatientContextQuery,
  PatientDetailsQuery,
  PaymentMethodInput,
  PreferredProviderGender,
  ProviderFilterSpecialitiesFragment,
  ProviderFiltersInput,
  type SuggestedProvidersForPatientQuery,
  useSuggestedProviderFiltersForPatientLazyQuery,
  useSuggestedProvidersForPatientLazyQuery,
} from '@nuna/api'

import { SearchProviderFilterState, intialState, useSearchProviderFilterReducer } from './SearchProviderFilterState'
import {
  ProviderFilterCategory,
  ProviderFilterSpecialtyOption,
  SpecialtyFilterCategory,
  searchProviderUtils,
} from './search-provider.utils'

export type FilteredProvider = SuggestedProvidersForPatientQuery['suggestedProvidersForPatient']['providers'][number]

export type PatientContext = PatientContextQuery['patientContext']['patient'] | PatientDetailsQuery['patient']

export type PaymentMethodFragment = {
  __typename?: 'PaymentMethod'
  id: string
  name: string
  type: string
  effectiveDate: string
  endDate: string
}

type ProviderCardClickFn = (providerId: string) => void

interface SearchProviderContext {
  patient?: PatientContext
  oldProvider?: BasicProviderFragment
  appliedFilters: SearchProviderFilterState
  defaultCoverage?: SearchProviderFilterState['coverage']
  specialtyFilterOptions: ProviderFilterSpecialitiesFragment
  paymentMethodOptions: PaymentMethodInput[]
  filterDialogOpen: boolean
  resultsLoading: boolean
  results: FilteredProviderFragment[]
  totalFilterCount: number
  unfilteredProviderCount?: number
  visibleProviderCount?: number
  setOnProviderCardClick: (fn: ProviderCardClickFn) => void
  onProviderCardClick: ProviderCardClickFn
  setFilterDialogOpen: (open: boolean) => void
  toggleCurrentAvailability: (currentAvailability: CurrentAvailability) => void
  toggleGeneralAvailabilityDays: (payload: DayOfWeek) => void
  toggleGeneralAvailabilityHours: (payload: OfficeHours) => void
  togglePaymentMethod: (paymentMethod: PaymentMethodInput) => void
  toggleSpecialtyMulti: (specialty: ProviderFilterSpecialtyOption, category: SpecialtyFilterCategory) => void
  toggleSpecialtySingle: (specialty: ProviderFilterSpecialtyOption, category: SpecialtyFilterCategory) => void
  setGender: (gender: PreferredProviderGender) => void
  removeProviderFromResult: (id: string) => void
  setAgeRange: (range: [number, number]) => void
  setExcludedProviderIds: (excludedIds: string[]) => void
  setSearchText: (searchText: string) => void
  setFavoritesOnly: (favoritesOnly: boolean) => void
  setFilterState: (state: SearchProviderFilterState) => void
  clearFilters: (preserveCoverage?: boolean) => void
  filterCount: (category?: ProviderFilterCategory) => number
}

const SearchProviderContext = createContext<SearchProviderContext>(buildDefault())

interface SearchProviderContextProps {
  patient: PatientContext
  children: ReactNode
  onProviderCardClick?: (providerId: string) => void
  oldProvider?: BasicProviderFragment
}

export function SearchProviderContextProvider({
  patient,
  children,
  onProviderCardClick: onProviderCardClickProp,
  oldProvider,
}: SearchProviderContextProps) {
  const [filterDialogOpen, setFilterDialogOpen] = useState(false)
  const {
    appliedFilters,
    toggleCurrentAvailability,
    toggleGeneralAvailabilityDays,
    toggleGeneralAvailabilityHours,
    togglePaymentMethod,
    toggleSpecialtyMulti,
    toggleSpecialtySingle,
    setGender,
    setAgeRange,
    setSearchText,
    setFavoritesOnly,
    setFilterState,
    setInitialFilterState,
    clearFilters,
    filterCount,
  } = useSearchProviderFilterReducer()

  const [onProviderCardClick, setOnProviderCardClick] = useState<ProviderCardClickFn>(
    () => onProviderCardClickProp ?? noop,
  )

  const [specialtyFilterOptions, setSpecialtyFilterOptions] = useState<
    Omit<ProviderFilterSpecialitiesFragment, '__typename'>
  >(() => buildDefault().specialtyFilterOptions)
  const [defaultCoverage, setDefaultCoverage] = useState<PaymentMethodInput[]>()
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<PaymentMethodInput[]>([])
  const [excludedProviderIds, setExcludedProviderIds] = useState<string[]>([])

  const [queryFilterOptions, { data: providerFilterData }] = useSuggestedProviderFiltersForPatientLazyQuery({
    fetchPolicy: 'network-only',
    context: { debounceKey: '1', debounceTimeout: 500 },
  })

  const [queryProviders, { data: providerResultsData, loading: queryProvidersLoading, updateQuery }] =
    useSuggestedProvidersForPatientLazyQuery({
      fetchPolicy: 'network-only',
      context: { debounceKey: '2', debounceTimeout: 1000 },
    })

  const removeProviderFromResult = (id: string) => {
    // Modify result by removing provider and decrementing counts
    updateQuery(prev => {
      const items = prev.suggestedProvidersForPatient.providers.filter(v => v.id !== id)
      return { ...prev, providerSearch: { items } } as SuggestedProvidersForPatientQuery
    })
  }

  const resultsLoading = !providerFilterData || queryProvidersLoading

  const totalFilterCount = useMemo(() => filterCount(), [filterCount])

  useEffect(() => {
    queryFilterOptions({ variables: { patientId: patient.id } })
  }, [patient.id, queryFilterOptions])

  useEffect(() => {
    if (!appliedFilters.availableValues && providerFilterData?.suggestedProviderFiltersForPatient.availableFilters) {
      const { availableFilters, defaultFilters } = providerFilterData.suggestedProviderFiltersForPatient

      const availableFilterState = {
        ageRange: availableFilters.ageRange as [number, number],
        coverage: availableFilters.paymentMethods.map(
          method => pick(method, ['id', 'name', 'type']) as PaymentMethodInput,
        ),
        specialities: omit(availableFilters.specialities, '__typename'),
        currentAvailability: [CurrentAvailability.Month],
      }

      const defaultCoverage = defaultFilters.paymentMethods.map(
        method => pick(method, ['id', 'name', 'type']) as PaymentMethodInput,
      )

      const defaultSpecialities = searchProviderUtils.intersectSpecialities(
        omit(defaultFilters.specialities, '__typename'),
        availableFilterState.specialities,
      )

      setInitialFilterState({
        available: availableFilterState,
        default: {
          ...pick(defaultFilters, ['gender', 'generalAvailability']),
          ageRange: defaultFilters.ageRange as [number, number],
          coverage: defaultCoverage,
          gender: defaultFilters.gender ?? undefined,
          generalAvailability: {
            days: defaultFilters.generalAvailability?.days ?? undefined,
            hours: defaultFilters.generalAvailability?.hours ?? undefined,
          },
          ...defaultSpecialities,
        },
      })

      setDefaultCoverage(defaultCoverage)
      setAgeRange(defaultFilters.ageRange as [number, number])
      setPaymentMethodOptions(
        availableFilters.paymentMethods.map(method => pick(method, ['id', 'name', 'type']) as PaymentMethodInput),
      )
      setSpecialtyFilterOptions(availableFilterState.specialities)
    }
  }, [
    appliedFilters,
    providerFilterData,
    setInitialFilterState,
    setAgeRange,
    setPaymentMethodOptions,
    setSpecialtyFilterOptions,
  ])

  useEffect(() => {
    if (appliedFilters) {
      queryProviders({
        variables: {
          patientId: patient.id,
          filters: buildProviderFiltersFilter(appliedFilters, true),
        },
      })
    }
  }, [appliedFilters, patient.id, queryProviders])

  const updatedSpecialities = useMemo(
    () =>
      providerResultsData?.suggestedProvidersForPatient?.updatedSpecialities
        ? omit(providerResultsData?.suggestedProvidersForPatient?.updatedSpecialities, '__typename')
        : undefined,
    [providerResultsData],
  )
  useEffect(() => {
    if (updatedSpecialities) {
      setSpecialtyFilterOptions(prevOptions =>
        searchProviderUtils.applyUpdatedSpecialties(prevOptions, updatedSpecialities),
      )
    }
  }, [setSpecialtyFilterOptions, updatedSpecialities])

  const results = useMemo(
    () =>
      (providerResultsData?.suggestedProvidersForPatient.providers ?? []).filter(
        p => !excludedProviderIds.includes(p.id),
      ),
    [excludedProviderIds, providerResultsData],
  )

  const excludedProvidersOffset =
    providerResultsData?.suggestedProvidersForPatient.providers.filter(p => excludedProviderIds?.includes(p.id))
      .length ?? 0
  const totalCount = providerFilterData?.suggestedProviderFiltersForPatient.availableFilters.totalCount
  const visibleCount = providerResultsData?.suggestedProvidersForPatient.providers.length

  const value: SearchProviderContext = {
    resultsLoading,
    patient,
    oldProvider,
    results,
    appliedFilters,
    defaultCoverage,
    specialtyFilterOptions,
    paymentMethodOptions,
    filterDialogOpen,
    totalFilterCount,
    unfilteredProviderCount: totalCount !== undefined ? totalCount - (excludedProviderIds?.length ?? 0) : undefined,
    visibleProviderCount: visibleCount !== undefined ? visibleCount - excludedProvidersOffset : undefined,
    setOnProviderCardClick,
    onProviderCardClick,
    setFilterDialogOpen,
    toggleCurrentAvailability,
    toggleGeneralAvailabilityDays,
    toggleGeneralAvailabilityHours,
    togglePaymentMethod,
    toggleSpecialtyMulti,
    toggleSpecialtySingle,
    removeProviderFromResult,
    setGender,
    setAgeRange,
    setExcludedProviderIds,
    setSearchText,
    setFavoritesOnly,
    setFilterState,
    clearFilters,
    filterCount,
  }

  return <SearchProviderContext.Provider value={value}>{children}</SearchProviderContext.Provider>
}

function buildDefault(): SearchProviderContext {
  return {
    resultsLoading: true,
    results: [],
    filterDialogOpen: false,
    paymentMethodOptions: [],
    specialtyFilterOptions: {
      [SpecialtyFilterCategory.Concerns]: [],
      [SpecialtyFilterCategory.Ethnicity]: [],
      [SpecialtyFilterCategory.Faith]: [],
      [SpecialtyFilterCategory.SessionTypes]: [],
      [SpecialtyFilterCategory.Sexuality]: [],
      [SpecialtyFilterCategory.Modalities]: [],
      [SpecialtyFilterCategory.Languages]: [],
    },
    totalFilterCount: 0,
    appliedFilters: intialState(),
    setOnProviderCardClick: noop,
    onProviderCardClick: noop,
    setFilterDialogOpen: noop,
    toggleCurrentAvailability: noop,
    toggleGeneralAvailabilityDays: noop,
    toggleGeneralAvailabilityHours: noop,
    togglePaymentMethod: noop,
    toggleSpecialtyMulti: noop,
    toggleSpecialtySingle: noop,
    removeProviderFromResult: noop,
    setGender: noop,
    setAgeRange: noop,
    setExcludedProviderIds: noop,
    setSearchText: noop,
    setFavoritesOnly: noop,
    clearFilters: noop,
    setFilterState: noop,
    filterCount: () => 0,
  }
}

export function useSearchProviderContext() {
  return useContext(SearchProviderContext)
}

function buildProviderFiltersFilter(state: SearchProviderFilterState, includeAgeRange: boolean): ProviderFiltersInput {
  const filters: ProviderFiltersInput = {
    specialities: buildSpecialtiesFilter(state),
    coverages: [...state.coverage],
    generalAvailability: state.generalAvailability,
    preferredGender: state.gender,
    term: state.search,
    currentAvailability: [...state.currentAvailability],
    favoritesOnly: state.favoritesOnly,
  }

  if (includeAgeRange) {
    filters.ageRange = sortBy([...state.ageRange])
  }

  return filters
}

function buildSpecialtiesFilter(state: SearchProviderFilterState): string[] {
  const specialtyIds: string[] = []
  Object.values(SpecialtyFilterCategory).forEach(category => {
    if (state[category]) {
      specialtyIds.push(...state[category].map(option => option.id))
    }
  })
  return specialtyIds
}

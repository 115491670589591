import { IconProps } from './IconProps'

export function IconFavorite({ size = 24, ...props }: IconProps) {
  return (
    <svg width={size} viewBox="0 0 49 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.7311 12.2432C23.4815 10.8209 25.5185 10.8209 26.2689 12.2432L29.6956 18.738C29.985 19.2865 30.5124 19.6698 31.1235 19.7755L38.3593 21.0275C39.9439 21.3016 40.5734 23.2389 39.4526 24.3921L34.3346 29.6581C33.9023 30.1028 33.7009 30.7229 33.7891 31.3367L34.8344 38.6053C35.0633 40.197 33.4154 41.3943 31.9723 40.6847L25.3825 37.4445C24.826 37.1709 24.174 37.1709 23.6175 37.4445L17.0277 40.6847C15.5846 41.3943 13.9367 40.197 14.1656 38.6053L15.2109 31.3367C15.2991 30.7229 15.0977 30.1028 14.6654 29.6581L9.54742 24.3921C8.42663 23.2389 9.05609 21.3016 10.6407 21.0275L17.8765 19.7755C18.4876 19.6698 19.015 19.2865 19.3044 18.738L22.7311 12.2432Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5768 10.42C22.1816 7.16831 26.8184 7.16831 28.4232 10.42L31.7387 17.1378L39.1522 18.2151C42.7407 18.7365 44.1735 23.1464 41.5769 25.6775L36.2124 30.9065L37.4788 38.2901C38.0918 41.8641 34.3405 44.5895 31.1309 42.9022L24.5 39.4161L17.8692 42.9022C14.6595 44.5895 10.9083 41.8641 11.5213 38.2901L12.7876 30.9065L7.42315 25.6775C4.82651 23.1464 6.25937 18.7365 9.84783 18.2151L17.2614 17.1378L20.5768 10.42ZM24.5 12.3562L21.1846 19.074C20.5473 20.3653 19.3155 21.2603 17.8905 21.4673L10.4769 22.5446L15.8414 27.7737C16.8726 28.7788 17.3431 30.2269 17.0997 31.6461L15.8333 39.0297L22.4642 35.5436C23.7387 34.8736 25.2614 34.8736 26.5359 35.5436L33.1668 39.0297L31.9004 31.6461C31.657 30.2269 32.1275 28.7788 33.1586 27.7737L38.5231 22.5446L31.1096 21.4673C29.6846 21.2603 28.4527 20.3653 27.8155 19.074L24.5 12.3562Z"
        fill="#017D8D"
      />
      <path
        d="M24.5 17.6365L27.2781 23.2654L33.4899 24.1681L28.995 28.5496L30.0561 34.7364L24.5 31.8154L18.944 34.7364L20.0051 28.5496L15.5101 24.1681L21.722 23.2654L24.5 17.6365Z"
        fill="#6AD0C4"
      />
      <path
        d="M48.1286 34.6898C48.2813 34.2097 47.4958 33.5483 46.5935 32.8744C45.6912 32.2005 42.8499 29.9916 42.8499 29.9916C42.0824 29.7475 40.0304 31.8711 39.9115 32.2449C39.7927 32.6188 40.3223 35.663 41.0899 35.9071C41.0899 35.9071 44.8786 35.4351 45.8011 35.3465C46.7236 35.2578 47.9759 35.1699 48.1286 34.6898Z"
        fill="#6AD0C4"
      />
      <path
        d="M38.4976 7.4258C38.0857 7.13579 37.2186 7.68596 36.3045 8.34377C35.3904 9.00157 32.4292 11.0468 32.4292 11.0468C31.9655 11.7054 33.3735 14.3011 33.6943 14.5269C34.0151 14.7527 37.0777 15.1633 37.5413 14.5047C37.5413 14.5047 38.2308 10.7495 38.4238 9.84301C38.6167 8.93654 38.9096 7.71582 38.4976 7.4258Z"
        fill="#6AD0C4"
      />
      <path
        d="M10.3322 7.16205C9.91282 7.44122 10.1121 8.44855 10.3948 9.5387C10.6774 10.6289 11.513 14.1294 11.513 14.1294C11.9593 14.7998 14.8885 14.426 15.2151 14.2086C15.5417 13.9913 17.0322 11.2846 16.5859 10.6141C16.5859 10.6141 13.3343 8.61299 12.559 8.10521C11.7837 7.59743 10.7516 6.88287 10.3322 7.16205Z"
        fill="#6AD0C4"
      />
      <path
        d="M1.06176 34.4741C1.19475 34.96 2.21362 35.0879 3.33732 35.1627C4.46102 35.2376 8.04664 35.5461 8.04664 35.5461C8.82349 35.3335 9.39063 32.4355 9.28707 32.0571C9.18352 31.6787 7.08352 29.4121 6.30667 29.6247C6.30667 29.6247 3.38385 32.0812 2.65786 32.6573C1.93188 33.2333 0.928778 33.9881 1.06176 34.4741Z"
        fill="#6AD0C4"
      />
      <path
        d="M24.5042 51.7659C25.008 51.7753 25.4179 50.8338 25.8065 49.7768C26.1951 48.7198 27.502 45.3666 27.502 45.3666C27.517 44.5613 24.8965 43.2002 24.5043 43.1928C24.112 43.1855 21.3453 44.5613 21.3302 45.3666C21.3302 45.3666 22.8631 48.8634 23.2111 49.7223C23.5591 50.5813 24.0005 51.7565 24.5042 51.7659Z"
        fill="#6AD0C4"
      />
    </svg>
  )
}

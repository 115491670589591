import * as Yup from 'yup'
import { Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import { omit } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useNavigate } from 'react-router-dom'

import { usePatientSignupSsoMutation } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { isMinor } from '@nuna/client'
import { errorService, formService, routeService, signupService } from '@nuna/core'
import { supportService } from '@nuna/telemetry'
import { Checkbox, CollapsePresence, DOBTextField, FillButton, TextField, toast } from '@nuna/tunic'

import { Tip } from '../../shared/components/Tip'

const { composeHelperTextWithError } = formService
const { agreesToTermsSchema, dobSchema, emailSchema, parentGuardianEmailSchema } = signupService

type SsoSignupValues = {
  agreesToTerms: boolean
  dob?: string
  email?: string
  parentGuardianEmail?: string
}

type SsoFinishSignupProps = {
  firstName?: string
  missingFields?: string[]
  ssoToken: string
}

export function SsoFinishSignup({ firstName, missingFields = [], ssoToken }: SsoFinishSignupProps) {
  const { onLogin } = useAuthDataContext()
  const navigate = useNavigate()
  const [patientSignupSsoMutation, { loading }] = usePatientSignupSsoMutation()

  const handleSubmit = async (values: SsoSignupValues) => {
    try {
      const input = { ...omit(values, 'agreesToTerms'), ssoToken }
      const loginData = (await patientSignupSsoMutation({ variables: { input } }))?.data?.patientSignupSso

      if (loginData) {
        onLogin({ login: loginData.login })
        mixpanel.track('signup', { userId: loginData.login.id })

        navigate(routeService.intake())
      }
    } catch (e) {
      const transformedError = errorService.transformGraphQlError(
        e,
        `Error completing signup. Please try again or contact ${supportService.supportEmails.clientSupport}`,
      )
      toast.urgent(transformedError)
    }
  }

  return (
    <Formik
      initialValues={{ dob: '', email: '', parentGuardianEmail: '', agreesToTerms: false }}
      onSubmit={handleSubmit}
      validationSchema={buildValidationSchema(missingFields)}
      enableReinitialize
    >
      {({ errors, touched, values, handleBlur, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="flex-column gap-1">
          <h1 className="h3">Welcome{firstName ? `, ${firstName}` : '!'}</h1>
          <p className="paragraphs">
            You're a few steps away from finding a personal therapist for yourself! <br />
            Let's collect some information before we get you connected.
          </p>

          <div className="flex-column gap-3">
            {missingFields.includes('email') && (
              <TextField
                label="Personal email"
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                {...composeHelperTextWithError('', errors.dob, touched.dob)}
              />
            )}

            {missingFields.includes('dob') && (
              <DOBTextField
                id="dob"
                label="Date of birth"
                value={values.dob}
                placeholder="MM/DD/YYYY"
                name="dob"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                {...composeHelperTextWithError('', errors.dob, touched.dob)}
                slotProps={{ htmlInput: { 'data-testid': 'signup-dob' } }}
              />
            )}

            <AnimatePresence>
              {(isMinor(values.dob) || missingFields.includes('parentGuardianEmail')) && (
                <CollapsePresence>
                  <Tip
                    className="mb-1"
                    description="We'll need your parent or guardian's consent to get you set up with a therapist. We'll email them everything they need to know."
                  />
                  <TextField
                    label="Parent or guardian's email"
                    type="email"
                    name="parentGuardianEmail"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.parentGuardianEmail}
                    inputProps={{
                      'data-testid': 'signup-parent-guardian-email',
                    }}
                    {...composeHelperTextWithError('', errors.parentGuardianEmail, touched.parentGuardianEmail)}
                  />
                </CollapsePresence>
              )}
            </AnimatePresence>
          </div>

          <Checkbox
            className={missingFields.length > 0 ? 'mt-4' : 'mt-2'}
            error={!!errors.agreesToTerms && !!touched.agreesToTerms}
            checked={values.agreesToTerms}
            name="agreesToTerms"
            onChange={handleChange}
            labelProps={{ 'data-testid': 'signup-tos' }}
          >
            <span>
              I agree to Tava's{' '}
              <a
                href="https://www.tavahealth.com/consent-to-care"
                target="_blank"
                rel="noreferrer"
                className="sso-external-link"
              >
                Consent to Care
              </a>{' '}
              &{' '}
              <a
                href="https://www.tavahealth.com/notice-of-privacy-practices"
                target="_blank"
                rel="noreferrer"
                className="sso-external-link"
              >
                Notice of Privacy Practices
              </a>
            </span>
          </Checkbox>

          <FillButton data-testid="signup-get-started" isLoading={loading} className="full-width mt-4" type="submit">
            Get Started
          </FillButton>
        </form>
      )}
    </Formik>
  )
}

function buildValidationSchema(missingFields: string[] = []) {
  const requireDob = missingFields.includes('dob')
  const requireEmail = missingFields.includes('email')
  const requireParentGuardianEmail = requireDob || missingFields.includes('parentGuardianEmail')

  return Yup.object({
    agreesToTerms: agreesToTermsSchema,
    dob: requireDob ? dobSchema : dobSchema.optional(),
    email: requireEmail ? emailSchema : emailSchema.optional(),
    parentGuardianEmail: requireParentGuardianEmail ? parentGuardianEmailSchema : parentGuardianEmailSchema.optional(),
  })
}

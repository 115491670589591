import mixpanel from 'mixpanel-browser'
import { useEffect, useMemo } from 'react'

import { usePatientContextQuery, useSuggestedProvidersForPatientQuery } from '@nuna/api'
import { toast } from '@nuna/tunic'

import { AppLayout } from '../../layouts/AppLayout'
import {
  SearchProviderContextProvider,
  useSearchProviderContext,
} from '../../shared/components/SearchProvider/SearchProviderContext'
import { ProviderSuggestionsMultipleResults } from './ProviderSuggestionsMultipleResults'
import { SuggestionLoader } from './SuggestionLoader'

export function ProviderSuggestionResults() {
  const { data: patientData } = usePatientContextQuery()

  if (!patientData) return <SuggestionLoader />

  return (
    <SearchProviderContextProvider patient={patientData.patientContext.patient}>
      <WithContext />
    </SearchProviderContextProvider>
  )
}

function WithContext() {
  const { defaultCoverage, patient, results, unfilteredProviderCount, setExcludedProviderIds } =
    useSearchProviderContext()
  const { data, loading, error } = useSuggestedProvidersForPatientQuery({
    fetchPolicy: 'no-cache',
    variables: {
      patientId: patient?.id ?? '',
      filters: { coverages: defaultCoverage },
      includeAvailability: true,
      notifyNoProviderMatches: true,
      excludeReferrals: true,
    },
    skip: !patient || !defaultCoverage,
  })
  const providers = useMemo(() => data?.suggestedProvidersForPatient?.providers ?? [], [data])
  const topProvider = providers[0]
  if (error) {
    toast.urgent('Error loading suggested provider, please try again or contact support')
  }

  // Exclude the top provider, if present in the filtered results
  useEffect(() => {
    if (topProvider) setExcludedProviderIds([topProvider.id])
  }, [topProvider, setExcludedProviderIds])

  if (!patient || loading || unfilteredProviderCount === undefined || !data) return <SuggestionLoader />

  const mixPanelData = { patientId: patient.id, providerIds: results?.map(provider => provider.id) }

  mixpanel.track('[provider-suggestions]', mixPanelData)
  return (
    <AppLayout paddingTop={0}>
      <ProviderSuggestionsMultipleResults otherProviders={results} topRecommendation={topProvider} />
    </AppLayout>
  )
}

import { times } from 'lodash'
import React from 'react'
import ContentLoader from 'react-content-loader'

export function LargeRectangleListLoader({ numItems = 10 }) {
  return (
    <React.Fragment>
      {times(numItems, i => i).map(n => (
        <ContentLoader height={100} width={600} speed={4} primaryColor="#f4f4f4" secondaryColor="#ecebeb" key={n}>
          <rect x="0" y="0" rx="4" ry="4" width="600" height="80" />
        </ContentLoader>
      ))}
    </React.Fragment>
  )
}

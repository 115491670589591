import { styled } from '@mui/material'
import { HTMLMotionProps, motion } from 'framer-motion'

import { body2, greySet } from '@nuna/tunic'

export function CapResetDivider(props: HTMLMotionProps<'div'>) {
  return <Container {...props} className="body caption" />
}

const Container = styled(motion.div)`
  background-color: ${greySet[5].hex};
  border-radius: var(--border-radius);
  color: ${body2};
  line-height: 1;
  margin-bottom: 1rem;
  padding: 0.75rem;
  text-align: center;
`

import { noop } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import { usePatientContextQuery } from '@nuna/api'

export enum ProviderProfileDrawerTabs {
  About = 'about',
  Availability = 'availability',
}

const providerProfileDrawerDefaultContext: {
  providerSuggestionRank: [number | null, (rank: number | null) => void]
  providerId: [string | null, (id: string | null) => void]
  openWithDefaultProvider: () => void
  selectedTab: [ProviderProfileDrawerTabs, (tab: ProviderProfileDrawerTabs) => void]
  defaultOpenTimeSlot: string | null
  setDefaultOpenTimeSlot: (timeSlot: string | null) => void
} = {
  providerSuggestionRank: [null, noop],
  providerId: [null, noop],
  openWithDefaultProvider: noop,
  selectedTab: [ProviderProfileDrawerTabs.Availability, noop],
  defaultOpenTimeSlot: null,
  setDefaultOpenTimeSlot: noop,
}

export const ProviderProfileDrawerContext = React.createContext(providerProfileDrawerDefaultContext)

export function ProviderProfileDrawerContextProvider({ children }: { children: React.ReactNode }) {
  const scheduleAppointmentMatch = useMatch('/schedule-appointment/:providerId')
  const navigate = useNavigate()
  const { data } = usePatientContextQuery()

  const providerSuggestionRankStateAndSetter = useState<number | null>(null)

  const providerIdStateAndSetter = useState<string | null>(() => {
    return scheduleAppointmentMatch?.params?.providerId ?? null
  })
  const selectedTabStateAndSetter = useState<ProviderProfileDrawerTabs>(ProviderProfileDrawerTabs.Availability)

  const [providerId, setProviderId] = providerIdStateAndSetter
  const [defaultOpenTimeSlot, setDefaultOpenTimeSlot] = useState<string | null>(null)

  const openWithDefaultProvider = () => {
    const providers = data?.patientContext?.patient?.providers || []
    const therapistProviders = providers.filter(provider => provider.type === 'THERAPIST')
    const defaultProvider = therapistProviders.length
      ? therapistProviders[0]
      : providers.length
      ? providers[0]
      : undefined

    if (!defaultProvider) return
    setProviderId(defaultProvider.id)
  }

  useEffect(() => {
    if (!providerId && scheduleAppointmentMatch) {
      navigate('/', { replace: true })
    }
  }, [providerId, scheduleAppointmentMatch, navigate])

  return (
    <ProviderProfileDrawerContext.Provider
      value={{
        providerSuggestionRank: providerSuggestionRankStateAndSetter,
        providerId: providerIdStateAndSetter,
        openWithDefaultProvider,
        selectedTab: selectedTabStateAndSetter,
        defaultOpenTimeSlot,
        setDefaultOpenTimeSlot,
      }}
    >
      {children}
    </ProviderProfileDrawerContext.Provider>
  )
}

import { HTMLAttributes } from 'react'

import { InvalidSponsorReason } from '@nuna/core'
import { csx } from '@nuna/tunic'

import { ResponsiveCollapsingListItem } from './InvalidInsuranceNextSteps/InvalidInsuranceTroubleshooting'

interface Props extends HTMLAttributes<HTMLDivElement> {
  sponsorName: string
  reason: InvalidSponsorReason
}

export function InvalidSponsorNextSteps({ sponsorName, className, reason, ...props }: Props) {
  return (
    <div className={csx(['text-secondary', className])} {...props}>
      <ResponsiveCollapsingListItem
        label={`Ask your HR team at ${sponsorName} to ${
          reason === InvalidSponsorReason.notAvailableToDependents ? 'cover dependents' : 'sponsor Tava'
        }`}
      >
        <p className="responsive-large">
          Let your HR team know that Tava is a valuable benefit they should consider sponsoring for{' '}
          {reason === InvalidSponsorReason.notAvailableToDependents ? 'dependents of' : ''} their members. They can
          learn more and get in touch with our team at tavahealth.com
        </p>
      </ResponsiveCollapsingListItem>
      <ResponsiveCollapsingListItem className="pb-0" label={`Use insurance or pay out-of-pocket instead`}>
        <p className="responsive-large">
          Select "Skip adding sponsor" below to check if we accept your insurance. If not, you can always book with a
          credit card, then try to resolve your sponsor coverage or insurance later.
        </p>
      </ResponsiveCollapsingListItem>
      <ResponsiveCollapsingListItem
        className="pb-0"
        label={`If you received an invitation email, forward it to our team`}
      >
        <p className="responsive-large">
          Tava works with referral partners including Health Advocate. If you received an invitation email, forward it
          to our team at support@tavahealth.com and we’ll get you set up correctly.
        </p>
      </ResponsiveCollapsingListItem>
    </div>
  )
}

import { HTMLAttributes } from 'react'

import { DemographicsAccordion } from '../filter-controls/DemographicsAccordion'
import { DemographicsFilterCategory } from '../filter-controls/FilterCategories'
import { FilterMenu } from './FilterMenu'

export function DemographicsFilterMenu(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <FilterMenu {...props} menu={<DemographicsAccordion />}>
      <DemographicsFilterCategory />
    </FilterMenu>
  )
}

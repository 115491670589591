import { styled } from '@mui/material'

import { greySet } from '@nuna/tunic'

export const CategoryHeader = styled('div')<{ pl: number }>`
  align-items: center;
  background-color: ${greySet[5].hex};
  color: ${greySet[80].hex};
  display: flex;
  font-weight: 500;
  height: 35px;
  margin-top: 11px;
  padding-right: var(--spacing-3);
  padding-left: ${({ pl }) => `${pl}px;`};
`

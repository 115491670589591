import { styled } from '@mui/material'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { ProviderProfileFragment } from '@nuna/api'
import { BelowTablet, TabPanel, TabletPortraitMin, body2, interactiveFill, interactiveText } from '@nuna/tunic'

import { Drawer, DrawerProps } from './Drawer'
import { ProviderDrawerHeader, SwitchProviderData } from './ProviderProfileDrawer/ProviderDrawerHeader'
import { SearchProviderFilterState } from './SearchProvider/SearchProviderFilterState'

// TODO: There is a lot of screwy TS things going on in here related to looping through children, etc. Need to fix one day (but not today) - Dallin

interface DrawerTabProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string
  title: string
}

function DrawerTab({ title: _title, id, ...props }: DrawerTabProps) {
  // the title prop is used below but here we want to avoid creating a tooltip by not passing title to the div
  return <TabPanel value={id} id={id} {...props} />
}

export interface ProviderDrawerProps extends DrawerProps {
  provider: ProviderProfileFragment | undefined
  fullHeight?: boolean
  showCloseButton?: boolean
  switchingProvider?: SwitchProviderData
  filters?: SearchProviderFilterState
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}

export function ProviderDrawer({
  provider,
  children,
  isOpen,
  onClose,
  fullHeight = false,
  showCloseButton = false,
  switchingProvider,
  filters,
}: ProviderDrawerProps) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  return (
    <Drawer
      size={isMobile && !fullHeight ? '97%' : 'auto'}
      isOpen={isOpen}
      onClose={onClose}
      isCloseButtonShown={false}
    >
      <FlexWrapper fullHeight={fullHeight}>
        <ProviderDrawerHeader
          switchingProvider={switchingProvider}
          provider={provider}
          onClose={onClose}
          showCloseButton={showCloseButton}
          filters={filters}
        />

        <div style={{ overflow: 'auto', flexGrow: 1 }}>{children}</div>
      </FlexWrapper>
    </Drawer>
  )
}

ProviderDrawer.Tab = DrawerTab

const FlexWrapper = styled('div')<{ fullHeight: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;

  @media (${TabletPortraitMin}) {
    min-height: 55vh;
    ${props => props.fullHeight && 'height: 100vh;'};
    /* transition: min-height 10s; */
  }

  [role='tabpanel'][hidden] {
    display: block;
  }

  [role='tab'] {
    color: ${body2};
    font-weight: 500;
    font-size: 18px;
    position: relative;

    @media (${BelowTablet}) {
      padding-bottom: 0.5rem;
      margin-top: 0.5rem;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    &[aria-selected='true'],
    &:hover {
      color: ${interactiveText};
    }

    &[aria-selected='true']::after {
      bottom: -35px;
      content: '';
      position: absolute;
      left: 0;
      background-color: ${interactiveFill};
      height: 3px;
      width: 100%;

      @media (${BelowTablet}) {
        bottom: -2px;
      }
    }
  }
`

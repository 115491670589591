import { styled } from '@mui/material'
import { first } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { HTMLAttributes, ReactNode, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  BasicSpecialtyFragment,
  ProviderStatus,
  PublicProviderFragment,
  usePatientContextQuery,
  usePublicProviderQuery,
} from '@nuna/api'
import { useAuthDataContext, useSourceReferralSearchParams } from '@nuna/auth'
import { useEffectOnce, useTitle } from '@nuna/common'
import {
  errorService,
  numberService,
  routeService,
  sessionStorageService,
  specialtiesService,
  userService,
} from '@nuna/core'
import {
  BelowTablet,
  Card,
  Chip,
  ContextualAlert,
  Grid,
  IconCheckCircle,
  MediumMin,
  Skeleton,
  TabletPortraitMin,
  TextButtonLink,
  borderGrey,
  csx,
  greySet,
  listFormat,
  makeTypographyComponent,
  marginSizes,
  toast,
} from '@nuna/tunic'

import { PublicProviderLayout } from '../../layouts/PublicProviderLayout'
import { AppContainer } from '../../shared/components/Container'
import { getProviderSuggestionRoute, getSignupRoute } from '../../util/routes'
import { PublicProviderCard, PublicProviderCardLoader } from './PublicProviderCard'
import { PublicProviderCardSchedule } from './PublicProviderCardSchedule'
import { PublicProviderInsuranceSelect } from './PublicProviderInsuranceSelect'
import { PublicProviderVideoBio } from './PublicProviderVideoBio'
import headerBg from './img/header-bg.jpg'

const { humanReadablePronouns } = userService
const { setSignupSourceParams } = sessionStorageService

// Once we don't need the Siderail we could get rid of wrapping this seperately
export function PublicProviderProfile() {
  return (
    <PublicProviderLayout>
      <PublicProviderProfileWithContext />
    </PublicProviderLayout>
  )
}

const shouldShowScheduleButton = (provider: PublicProviderFragment, isProviderSourced: boolean) => {
  if (provider.p4Disabled) return false
  if (provider.credentialedInStates.length === 0) return false
  if (provider.status === ProviderStatus.AcceptingClients) return true
  if (provider.status === ProviderStatus.NotAcceptingClients && isProviderSourced) return true
  return false
}

function PublicProviderProfileWithContext() {
  const { login, loggedIn } = useAuthDataContext()
  const { providerSlug = '' } = useParams()
  const { source, sourceChannel, isProviderSourced } = useSourceReferralSearchParams()
  const { data, error } = usePublicProviderQuery({
    variables: { slug: providerSlug, patientId: login?.patientId, source, sourceChannel },
    skip: !providerSlug,
  })
  const [selectedInsurance, setSelectedInsurance] = useState<string | null>(null)
  const { data: patientContextData } = usePatientContextQuery({ skip: !loggedIn })
  const provider = data?.publicProvider
  const navigate = useNavigate()

  useEffectOnce(() => {
    if (provider) {
      if (provider.status === ProviderStatus.Terminated) {
        navigate(getSignupRoute())
      } else {
        localStorage.setItem('providerSourcedProviderId', provider.id)
        setSignupSourceParams({
          source,
          channel: sourceChannel,
          isProviderSourced: 'true',
          providerSourcedProviderId: provider.id,
        })
      }
    }
  }, !!provider && isProviderSourced)

  useTitle(
    provider
      ? `${provider.firstName} ${provider.lastName} ${
          provider.licenseTitle || 'Therapist'
        } on Tava Health - Schedule Therapy`
      : 'Schedule Therapy on Tava Health',
    true,
  )

  useEffect(() => {
    if (error) {
      toast.urgent(errorService.transformGraphQlError(error, "Unable to load provider's profile"))
    }
  }, [error])

  if (!provider)
    return (
      <>
        <PageHeader>
          <PageContainer>
            <Grid container spacing={8}>
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                  md: 4,
                }}
              >
                <ResponsivePublicProviderCardLoader />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                  md: 4,
                }}
              >
                <div className="pt-5">
                  <Skeleton height={300} style={{ maxWidth: 500 }} dimensionUnits="px" />
                </div>
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                  md: 4,
                }}
              >
                <div className="pt-5">
                  <Skeleton height={200} dimensionUnits="px" />
                </div>
              </Grid>
            </Grid>
          </PageContainer>
        </PageHeader>

        <Content>
          <PageContainer>
            <Grid container spacing={8} direction="row-reverse">
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                  md: 8,
                }}
              >
                <Skeleton height={15} />
                <Skeleton className="mt-3" height={15} />
              </Grid>

              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                  md: 4,
                }}
              >
                <Skeleton height={40} />
              </Grid>
            </Grid>
          </PageContainer>
        </Content>
      </>
    )

  const videoResponse = first(provider.videoAskResponses)
  const challenges = specialtiesService.getChallenges(provider.specialties)
  const populations = specialtiesService.getProviderPopulationSpecialties(provider.specialties)
  const methodologies = specialtiesService.getModalitySpecialties(provider.specialties)
  const languages = specialtiesService.getLanguagesSpokenSpecialties(provider.specialties)
  const ethnicities = specialtiesService.getEthnicitySpecialties(provider.specialties)
  const faiths = specialtiesService.getFaithSpecialties(provider.specialties)
  const sexualities = specialtiesService.getSexualitySpecialties(provider.specialties)
  const showScheduling = shouldShowScheduleButton(provider, isProviderSourced)
  const hasProvider = (patientContextData?.patientContext?.patient?.providers ?? []).length > 0

  return (
    <>
      <PageHeader>
        <PageContainer>
          <OverlappedCard>
            <GridWrapper container>
              <ProviderProfileGrid
                size={{
                  xs: 12,
                  sm: 6,
                  md: 3,
                }}
              >
                <PublicProviderCard provider={provider} />
              </ProviderProfileGrid>
              <ScheduleGrid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                }}
              >
                {showScheduling ? (
                  <PublicProviderCardSchedule selectedInsurance={selectedInsurance} />
                ) : (
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: '100%' }}
                  >
                    <CheckBackLater loggedIn={loggedIn} hasProvider={hasProvider} />
                  </Grid>
                )}
              </ScheduleGrid>
              <CoverageGrid
                size={{
                  xs: 12,
                  sm: 6,
                  md: 3,
                }}
              >
                <CoverageWrapper>
                  {!!provider.insuranceEnrollments?.length && (
                    <CoverageSection
                      className="mb-4"
                      specialties={provider.specialties}
                      individualsOnly
                      heading="Insurance"
                    >
                      <div style={{ maxWidth: 386 }}>
                        <PublicProviderInsuranceSelect
                          insuranceEnrollments={provider.insuranceEnrollments ?? []}
                          onChange={payer => {
                            mixpanel.track('selected public provider insurance filter', { payer })
                            setSelectedInsurance(payer)
                          }}
                          value={selectedInsurance}
                          className="mt-2"
                        />
                        {selectedInsurance && (
                          <ContextualAlert className="mt-2" intent="information" icon={<IconCheckCircle />}>
                            Good news! {provider.firstName} accepts your insurance carrier. We’ll verify your specific
                            plan later on.
                          </ContextualAlert>
                        )}
                      </div>
                    </CoverageSection>
                  )}

                  <CoverageSection specialties={provider.specialties} className="mb-4" heading="Cash Rate">
                    {numberService.centsToFormattedDollars(Number(provider.cashRate ?? ''))}/hr
                  </CoverageSection>

                  <CoverageSection specialties={provider.specialties} heading="Tava Networks">
                    Employer Benefit
                  </CoverageSection>
                </CoverageWrapper>
              </CoverageGrid>
            </GridWrapper>
          </OverlappedCard>
        </PageContainer>
      </PageHeader>

      <Content>
        <PageContainer>
          <Grid container spacing={8} direction="row-reverse">
            <Grid
              size={{
                xs: 12,
                sm: 6,
                md: 8,
              }}
            >
              <h2 className="h4">About Me</h2>
              {videoResponse && (
                <PublicProviderVideoBio providerId={provider.id} className="mr-2 mb-2" {...videoResponse} />
              )}
              <div
                className="paragraphs large text-secondary mt-4"
                dangerouslySetInnerHTML={{ __html: provider.aboutBlurb ?? '' }}
              />
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 6,
                md: 4,
              }}
            >
              <SpecialtyChipsSection heading="Challenges treated" specialties={challenges} />
              <SpecialtyChipsSection heading="Client focus" specialties={populations} />
              <SpecialtyChipsSection heading="Methods" specialties={methodologies} />

              {(languages.length > 0 ||
                ethnicities.length > 0 ||
                faiths.length > 0 ||
                sexualities.length > 0 ||
                provider.pronouns) && (
                <BorderTop>
                  <SpecialtyTextSection heading="Languages" specialties={languages} />
                  <SpecialtyTextSection heading="Ethnicity" specialties={ethnicities} />
                  <SpecialtyTextSection heading="Faith" specialties={faiths} />
                  {provider.pronouns && (
                    <SpecialtyTextSection heading="Pronouns" text={humanReadablePronouns[provider.pronouns]} />
                  )}
                  <SpecialtyTextSection heading="Sexuality" specialties={sexualities} />
                </BorderTop>
              )}

              {provider.insuranceEnrollments.length > 0 && (
                <BorderTop>
                  <SpecialtyTextSection heading="Insurance Carriers" text={provider.insuranceEnrollments.join('\n')} />
                </BorderTop>
              )}
            </Grid>
          </Grid>
        </PageContainer>
      </Content>
    </>
  )
}

interface CoverageSectionProps extends HTMLAttributes<HTMLDivElement> {
  heading: ReactNode
  children: ReactNode
  specialties: BasicSpecialtyFragment[]
  individualsOnly?: boolean
  className?: string
}

function CoverageSection({
  heading,
  children,
  specialties,
  individualsOnly = false,
  className = '',
}: CoverageSectionProps) {
  return (
    <div className={csx(['top-align', className])}>
      <div className="full-width">
        <h2 className="body caption text-secondary text-medium">
          <HeaderHeading>{heading}</HeaderHeading>
          <br />
          {getTherapyTypesString(specialties, individualsOnly)}
        </h2>
        <div className="large mb-1">{children}</div>
      </div>
    </div>
  )
}

function getTherapyTypesString(specialties: BasicSpecialtyFragment[], individualsOnly = false) {
  if (individualsOnly) return 'Individuals'

  const therapyTypes = specialtiesService.getTypesOfTherapy(specialties)

  return listFormat(
    therapyTypes
      .map(therapyType => {
        if (therapyType.name.includes('Individual')) {
          return 'Individuals'
        } else if (therapyType.name.includes('Family')) {
          return 'Families'
        } else if (therapyType.name.includes('Couples')) {
          return 'Couples'
        }

        return ''
      })
      .filter(name => name !== ''),
    { useOxfordComma: true },
  )
}

interface SpecialtyChipsSectionProps extends HTMLAttributes<HTMLDivElement> {
  heading: ReactNode
  specialties: BasicSpecialtyFragment[]
}

function SpecialtyChipsSection({ heading, specialties, className = '', ...props }: SpecialtyChipsSectionProps) {
  if (specialties.length === 0) return null

  return (
    <div className={csx(['mb-4', className])} {...props}>
      <SpecialtyChipsHeading>{heading}</SpecialtyChipsHeading>
      <div role="group">
        {specialties.map(specialty => (
          <Chip key={specialty.id} small type="display">
            {specialty.name}
          </Chip>
        ))}
      </div>
    </div>
  )
}

interface SpecialtyTextSectionProps extends Omit<SpecialtyChipsSectionProps, 'specialties'> {
  specialties?: BasicSpecialtyFragment[]
  text?: string
}

function SpecialtyTextSection({
  heading,
  specialties = [],
  text = '',
  className = '',
  ...props
}: SpecialtyTextSectionProps) {
  if (specialties.length === 0 && !text) return null

  return (
    <div
      style={{ lineHeight: 1.5, whiteSpace: 'pre-line' }}
      className={csx(['text-dark-grey mb-1', className])}
      {...props}
    >
      <SpecialtyTextHeading>{heading}:</SpecialtyTextHeading> {specialties.map(specialty => specialty.name).join(', ')}
      {text}
    </div>
  )
}

function CheckBackLater({ loggedIn, hasProvider }: { loggedIn: boolean; hasProvider: boolean }) {
  if (loggedIn && !hasProvider) {
    return (
      <p className="my-4 large">
        Check back later, or
        <br />
        <TextButtonLink to={getProviderSuggestionRoute()}>get a recommendation</TextButtonLink>
      </p>
    )
  } else if (loggedIn && hasProvider) {
    return (
      <p className="my-4 large">
        Check back later, or
        <br />
        <TextButtonLink to={routeService.switchProvider()}>choose another therapist</TextButtonLink>
      </p>
    )
  } else {
    return (
      <p className="my-4 large">
        Check back later, or <TextButtonLink to={getSignupRoute()}>sign up</TextButtonLink> for
        <br />a new therapist recommendation
      </p>
    )
  }
}

const ResponsivePublicProviderCardLoader = styled(PublicProviderCardLoader)`
  margin-bottom: -6rem;

  @media (${BelowTablet}) {
    margin-bottom: -4rem;
  }
`

const PageHeader = styled('header')`
  background: url(${headerBg}) repeat left center ${greySet[5].hex};
  background-size: auto 100%;
  padding-top: var(--margin-5);
`

const PageContainer = styled(AppContainer)`
  // needed to offset Grid negative margins
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: var(--margin-7);
`

const Content = styled('div')`
  background-color: #fff;
  margin-top: var(--margin-7);
  padding-bottom: var(--margin-7);

  @media (${BelowTablet}) {
    margin-top: var(--margin-5);
    padding-bottom: var(--margin-5);
  }
`

const HeaderHeading = makeTypographyComponent('text-bold body text-default mr-xs uppercase', 'span')
const SpecialtyChipsHeading = makeTypographyComponent('body large text-medium', 'h2')
const SpecialtyTextHeading = makeTypographyComponent('body text-medium inline', 'h2')

const BorderTop = styled('div')`
  border-top: 1px solid ${borderGrey};
  padding-top: var(--margin-3);
  padding-bottom: var(--margin-3);
`

const OverlappedCard = styled(Card)`
  margin-bottom: calc(calc(var(--spacing-7) * -1) - 60px);
  z-index: 2;
  position: relative;

  @media (${BelowTablet}) {
    margin-bottom: 0;
  }
`

const CoverageWrapper = styled('div')`
  @media (${BelowTablet}) {
    padding-top: 0;
  }
`

const ProviderProfileGrid = styled(Grid)`
  padding: ${marginSizes[3]};

  @media (${MediumMin}) {
    padding: ${marginSizes[4]};
  }
`

const ScheduleGrid = styled(Grid)`
  order initial;
  margin-top: ${marginSizes[2]};
  padding: ${marginSizes[3]};

  @media (${TabletPortraitMin}) {
    order: 2;
    margin-top: ${marginSizes[5]};
    border-top: 1px solid ${greySet[15].hex};
  }

  @media (${MediumMin}) {
    order: initial;
    padding: ${marginSizes[4]};
    border-top: none;
  }
`

const CoverageGrid = styled(Grid)`
  background-color: ${greySet[0].hex};
  padding: ${marginSizes[3]};

  @media (${MediumMin}) {
    padding: ${marginSizes[4]};
  }
`
const GridWrapper = styled(Grid)`
  align-items: stretch;
`

import { useCallback, useEffect, useRef, useState } from 'react'

export function useIntersect({
  root = null,
  rootMargin = '',
  threshold = 0,
}): [(node: HTMLElement) => void, IntersectionObserverEntry | undefined] {
  const [entry, setEntry] = useState<IntersectionObserverEntry>()
  const [node, setNode] = useState<HTMLElement>()

  const observer = useRef(
    new window.IntersectionObserver(([entry]) => setEntry(entry), { root, rootMargin, threshold }),
  )

  const setRef = useCallback((node: HTMLElement) => {
    setNode(node)
  }, [])

  useEffect(() => {
    const { current: currentObserver } = observer
    currentObserver.disconnect()

    if (node) {
      currentObserver.observe(node)
    }

    return () => currentObserver.disconnect()
  }, [node])

  return [setRef, entry]
}

import mixpanel from 'mixpanel-browser'
import { useState } from 'react'

import { PaymentPreference } from '@nuna/api'
import { usePatientId } from '@nuna/auth'
import { useFeatureFlags } from '@nuna/feature-flag'
import { PaymentMethodSelect, useClientPaymentMethods } from '@nuna/payment-methods'
import { ContextualAlert, GhostButton, GhostButtonLink, IconInfo, PersistentAlert, csx } from '@nuna/tunic'

import { useIntakeCoverageStatus } from '../../../hooks/useIntakeCoverageStatus'
import { useIntakeNavigation } from '../../../hooks/useIntakeNavigation'
import { CoverageResult } from '../../CoverageResults/CoverageResult'
import { CoverageNextButton } from './CoverageNextButton'
import { IntakeCoverageStatus } from './IntakeCoverageStatus'

export function IntakeCashForm() {
  const { autoCharge: autoChargeFeature } = useFeatureFlags()
  const patientId = usePatientId() ?? ''
  const { nextPath } = useIntakeNavigation()
  const { coverages, refetch, hasUploadedInsuranceCard, hasValidEAP, hasValidInsurance } = useIntakeCoverageStatus({
    fetchPolicy: 'cache-and-network',
  }) // fetchPolicy ordinarily would not be necessary but has to be here to handle a wacky edge case with e2e tests and stripe mock server
  const [isEditing, setIsEditing] = useState(false)

  // The logic of this is hard to reason about in a single line
  const isCardRequired = (() => {
    if (hasValidInsurance || hasUploadedInsuranceCard) return true // card is always required w/ insurance regardless of any other coverage
    if (hasValidEAP) return false // EAP w/o insurance is only scenario that doesn't require a card

    return true
  })()

  const {
    paymentMethods,
    defaultForSessions,
    savePaymentMethod,
    queryResult: { loading: paymentMethodsLoading },
  } = useClientPaymentMethods(patientId, { fetchPolicy: 'cache-and-network' })

  const coverage = coverages[PaymentPreference.Cash]

  if (paymentMethodsLoading && !defaultForSessions) return null

  if (coverage && !isEditing) {
    return (
      <>
        <CoverageResult successText="We've got your card on file" status="success">
          <IntakeCoverageStatus coverage={coverage} onEditClick={() => setIsEditing(true)} />
        </CoverageResult>

        <CoverageNextButton nextPath={nextPath} />
      </>
    )
  }

  const getInstructionsLegacy = () => {
    if (hasValidInsurance || hasUploadedInsuranceCard) {
      return 'Next, enter your card to cover coinsurance costs and incidental fees for canceling too late or missing an appointment.'
    }

    if (hasValidEAP) {
      return 'Next, enter your card to cover incidental fees for canceling too late or missing an appointment.'
    }

    return `Add your card for session costs (charged after each session). You’ll review the total amount before you schedule. 
    Pro tip: you can potentially get reimbursed for out-of-network care through your insurance by submitting a claim later.`
  }

  const getInstructionsNew = () => {
    if (hasValidInsurance || hasUploadedInsuranceCard) {
      return (
        <>
          We kindly ask that you keep a credit card on file with us to be used for{' '}
          <b>
            any remaining balance not covered by insurance or incidental fees for canceling or missing an appointment
          </b>
          . This ensures a seamless and hassle-free billing process for you, allowing us to focus on providing you with
          the best possible care.{' '}
          <GhostButtonLink
            to={
              'https://client-support.tavahealth.com/hc/en-us/articles/29344099298068-What-you-owe-after-insurance-pay-sessions'
            }
            variant="secondary"
            target="_blank"
          >
            Learn more
          </GhostButtonLink>
          .
          <br />
          <PersistentAlert icon={<IconInfo size={20} />} intent="information" className="mt-4">
            <small className="pr-1">
              Card will be <b>automatically</b> charged
            </small>
          </PersistentAlert>
        </>
      )
    }

    if (hasValidEAP) {
      return <>Next, enter your card to cover incidental fees for canceling too late or missing an appointment.</>
    }

    return (
      <>
        Add your card for session costs (charged after each session). You'll review the total amount before you
        schedule.
        <br />
        Pro tip: you can potentially get reimbursed for out-of-network care through your insurance by submitting a claim
        later.
      </>
    )
  }

  const Instructions = () => {
    return autoChargeFeature ? (
      <div className="paragraphs mb-4 text-secondary">{getInstructionsNew()}</div>
    ) : (
      <p className="paragraphs mb-4 text-secondary">{getInstructionsLegacy()}</p>
    )
  }

  const Title = () => {
    return autoChargeFeature ? (
      <h2 className="h4 mb-2">Incidental credit card setup</h2>
    ) : (
      <h2 className="h4 mb-2">Credit card setup</h2>
    )
  }

  return (
    <div>
      <Title />
      <Instructions />
      <div style={{ maxWidth: '500px' }}>
        <PaymentMethodSelect
          paymentMethods={paymentMethods}
          value={defaultForSessions}
          onChange={() => {
            mixpanel.track('Intake - added card')
            refetch()
          }}
          defaultFor="sessions"
          defaultToFormIfEmpty
          showHSAWarning
          loading={paymentMethodsLoading}
          patientId={patientId}
          savePaymentMethod={savePaymentMethod}
          hideCancelOnForm
        />
      </div>

      {isEditing && (
        <GhostButton variant="secondary" className="mt-3" onClick={() => setIsEditing(false)}>
          Cancel
        </GhostButton>
      )}

      {!isCardRequired && paymentMethods.length === 0 && (
        <GhostButtonLink className="mt-4" to={`../../${nextPath}`}>
          Add my card later
        </GhostButtonLink>
      )}

      <CoverageNextButton
        className={csx({ 'mb-3': isCardRequired })}
        overrideMargins={isCardRequired}
        nextPath={nextPath}
        disabled={!coverage}
      />

      {isCardRequired && !coverage && (
        <ContextualAlert className="mb-5" intent="urgent">
          You need to add a card to continue
        </ContextualAlert>
      )}
    </div>
  )
}

import { styled } from '@mui/material'
import { truncate } from 'lodash'
import { HTMLAttributes, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { StatusLabel, csx, fontSize, makeTypographyComponent, tealSet } from '@nuna/tunic'

import {
  FeaturedPostBreakPointMax,
  FeaturedPostBreakPointMin,
  useMentalHealthLibraryContext,
} from '../MentalHealthLibraryContext'
import { PostFragment } from '../generated/sanctuary-graphql'
import { ImageOptions } from '../util/getImageAtSize'
import { PostButton, ThumbnailButton } from './PostButton'

export interface PostProps extends HTMLAttributes<HTMLDivElement> {
  post: PostFragment
  large?: boolean
  hideDescription?: boolean
  hideTime?: boolean
  imageOptions?: ImageOptions
  truncateDescription?: boolean
}

export function Post({
  post,
  hideDescription = false,
  hideTime = false,
  large = false,
  truncateDescription = false,
  imageOptions = { width: 300 },
  className = '',
  ...props
}: PostProps) {
  const { language } = useMentalHealthLibraryContext()
  const [isHovered, setIsHovered] = useState(false)

  const isMobile = useMediaQuery({ query: `(${FeaturedPostBreakPointMax})` })

  const [postDetails] = post.postDetailsList // not entirely sure why this is a list, but all of our posts purchased from Sanctuary have only one item in the list
  const postMedia = post.media.find(p => p.language === language)

  if (!postMedia) return null

  return (
    <PostContainer className={csx([{ 'featured-large': large, hovered: isHovered }, className])} {...props}>
      <PostButton
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        large={large}
        className="mr-2"
        post={post}
        imageOptions={imageOptions}
      />
      <div className="post-text">
        <Title className="post-title text-dark">{post.universalTitle}</Title>
        {!hideDescription && (
          <Description>
            {truncateDescription && !isMobile && !large
              ? truncate(postDetails.description, {
                  length: 110,
                  omission: '...',
                  separator: /,? +/,
                })
              : postDetails.description}
          </Description>
        )}
        {!hideTime && (
          <StatusLabel intent={isHovered ? 'information' : 'default'}>{postMedia.approximateTime} min</StatusLabel>
        )}
      </div>
    </PostContainer>
  )
}

export function PostSkeleton(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <PostContainer {...props} aria-busy>
      <ThumbnailButton className="mr-2 loading" />

      <div className="post-text full-width">
        <Title className="loading">loading</Title>
        <Description className="loading">loading</Description>
        <StatusLabel className="loading">0 min</StatusLabel>
      </div>
    </PostContainer>
  )
}

const PostContainer = styled('article')`
  display: flex;
  align-items: flex-start;

  @media (${FeaturedPostBreakPointMin}) {
    &.featured-large {
      display: block;
      position: relative;
      grid-row: 1 / span 3;

      button {
        min-height: 300px;
        width: 100%;
      }

      .post-text {
        position: absolute; // absolute so the grid aligns to the image only and not the text
        margin-top: var(--margin-2);
      }

      .post-title {
        font-size: ${fontSize.large};
      }

      &.hovered {
        ${ThumbnailButton} {
          transform: scale(1.02);
        }
      }
    }
  }
`

const Title = styled(makeTypographyComponent('body text-medium', 'h3'))`
  .hovered & {
    color: ${tealSet[80].hex};
  }
`
const Description = makeTypographyComponent('caption', 'p')

import { styled } from '@mui/material'
import { useEffect, useState } from 'react'

import { useCancelPolicyStatusQuery, usePatientContextQuery } from '@nuna/api'
import { errorService, numberService } from '@nuna/core'
import { ProviderPolicyFAQs } from '@nuna/fee-policies'
import { Collapse, IconChevronThick, Skeleton, borderGrey, toast } from '@nuna/tunic'

import { ProviderBioProps } from './ProviderBio'

interface ProviderBioPoliciesProps {
  provider: ProviderBioProps['provider']
}

export function ProviderBioPolicies({ provider }: ProviderBioPoliciesProps) {
  const { data: patientContextData } = usePatientContextQuery()
  const { data: cancelPolicyData, error } = useCancelPolicyStatusQuery({
    variables: {
      providerId: provider?.id ?? '',
      clientId: patientContextData?.patientContext.patient.id ?? '',
    },
    skip: !patientContextData || !provider,
  })
  const [faqOpen, setFaqOpen] = useState(false)

  useEffect(() => {
    if (error) {
      toast.urgent(errorService.transformUserGraphqlError(error, 'Unable to load provider policies'))
    }
  }, [error])

  if (!cancelPolicyData) {
    return <Skeleton className="mt-2" height={4} />
  }

  const cancelPolicy = cancelPolicyData.cancelPolicyStatus.cancelPolicy

  if (!cancelPolicy || !provider) {
    return null
  }

  return (
    <>
      <h3 className="h6 mt-4 mb-3">Policies</h3>

      <PoliciesContainer hideBottomBorder={faqOpen}>
        <button
          className="text-medium text-secondary v-align full-width space-between py-2 mt-1"
          type="button"
          onClick={() => setFaqOpen(!faqOpen)}
        >
          <span>
            {numberService.centsToFormattedDollars(cancelPolicy.chargeAmount)} Late Cancel -{' '}
            {numberService.centsToFormattedDollars(cancelPolicy.noShowChargeAmount)} No Show
          </span>{' '}
          <IconChevronThick size={14} direction={faqOpen ? 'up' : 'down'} />
        </button>
        <Collapse isOpen={faqOpen}>
          <p className="paragraphs text-secondary mb-2">
            {provider.firstName} charges {numberService.centsToFormattedDollars(cancelPolicy.chargeAmount)} if you
            cancel or reschedule a session less than{' '}
            {numberService.minutesToFormattedHours(cancelPolicy.cancelPeriodMinutes)} beforehand. Or{' '}
            {numberService.centsToFormattedDollars(cancelPolicy.noShowChargeAmount)} if you simply don't show up for a
            session.
          </p>
          <ProviderPolicyFAQs
            provider={provider}
            coverageType={cancelPolicyData.cancelPolicyStatus.paymentPreference}
            sharedListItemProps={{ className: 'px-0 py-1', compact: true }}
          />
        </Collapse>
      </PoliciesContainer>
    </>
  )
}

const PoliciesContainer = styled('div')<{ hideBottomBorder: boolean }>`
  border-top: 1px solid ${borderGrey};
  padding-bottom: var(--spacing-1);
  ${props => !props.hideBottomBorder && `border-bottom: 1px solid ${borderGrey};`}
`

import { greySet } from '../styles/colorSets'
import { IconProps } from './IconProps'

export function IconFavoriteOutline({ size = 24, color = greySet[50].hex, ...props }: IconProps) {
  return (
    <svg width={size} viewBox="0 0 49 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5768 10.42C22.1816 7.16831 26.8184 7.16831 28.4232 10.42L31.7387 17.1378L39.1522 18.2151C42.7407 18.7365 44.1735 23.1464 41.5769 25.6775L36.2124 30.9065L37.4788 38.2901C38.0918 41.8641 34.3405 44.5895 31.1309 42.9021L24.5 39.4161L17.8692 42.9021C14.6595 44.5895 10.9083 41.8641 11.5213 38.2901L12.7876 30.9065L7.42315 25.6775C4.82651 23.1464 6.25937 18.7365 9.84783 18.2151L17.2614 17.1378L20.5768 10.42ZM24.5 12.3562L21.1846 19.074C20.5473 20.3653 19.3155 21.2603 17.8905 21.4673L10.4769 22.5446L15.8414 27.7737C16.8726 28.7788 17.3431 30.2269 17.0997 31.6461L15.8333 39.0297L22.4642 35.5436C23.7387 34.8736 25.2614 34.8736 26.5359 35.5436L33.1668 39.0297L31.9004 31.6461C31.657 30.2269 32.1275 28.7788 33.1586 27.7737L38.5231 22.5446L31.1096 21.4673C29.6846 21.2603 28.4527 20.3653 27.8155 19.074L24.5 12.3562Z"
        fill={color}
      />
    </svg>
  )
}

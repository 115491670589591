import { AcceptProviderFeesDrawerLauncher, LateCancelationPolicyModalLauncher } from '@nuna/fee-policies'

/**
 * The basic idea of this component is that we have a lot of modals and popouts
 * that you might need to use throughout the app, but today you have to put the
 * modal on every scene that you want to show it on and then write handler code
 * to show that modal when appropriate. This approach is to put all or some of
 * the modals that you might need in one place so that its easier to use modals
 * more reliably.
 *
 * Rules:
 * - Only put "launchers" in the farm
 * - Each launcher should have a known api for how to interact with it
 *  - Today we use a lot of useSearchParams for this. this is nice because it
 *    gives us a nice clean way to deep link too it :)
 *    - ! Launchers should open modals based on search params? lets rif on this
 * - The farm should not hold any interaction logic only the launcher.
 * - Farms are for one app, thats why this is the arrow farm
 *
 *
 */
export const ArrowModalFarm = () => {
  return (
    <>
      <LateCancelationPolicyModalLauncher />
      <AcceptProviderFeesDrawerLauncher />
    </>
  )
}

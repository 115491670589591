import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import mixpanel from 'mixpanel-browser'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { OrganizationRelationship, PaymentPreference } from '@nuna/api'
import { InvalidSponsorReason } from '@nuna/core'
import {
  EmployerCoverageForm,
  EmployerCoverageFormValues,
  buildEmployerCoverageInput,
  employerCoverageSchema,
  employerInitialValues,
  useSaveEmployerCoverage,
} from '@nuna/coverage'
import { FillButton, GhostButton, GhostButtonLink, Phone, TextButtonLink, toast } from '@nuna/tunic'

import { useInsuranceIntakeContext } from '../../../InsuranceIntakeContext'
import { IntakeNavigation } from '../../../IntakeNavigation'
import { useIntakeCoverageStatus } from '../../../hooks/useIntakeCoverageStatus'
import { useIntakeNavigation } from '../../../hooks/useIntakeNavigation'
import { CoverageResult, CoverageResultIllustration } from '../../CoverageResults/CoverageResult'
import { InvalidSponsorNextSteps } from '../../CoverageResults/InvalidSponsorNextSteps'
import scribbleSrc from '../../CoverageResults/img/scribble.png'
import { CoverageNextButton } from './CoverageNextButton'
import { IntakeCoverageStatus } from './IntakeCoverageStatus'

const schema = Yup.object().shape({
  employerValues: employerCoverageSchema,
})

export function IntakeSponsorForm() {
  const navigate = useNavigate()
  const { patientContextData, setIsMutationLoading } = useInsuranceIntakeContext()
  const { nextPath } = useIntakeNavigation()
  const { coverages, refetch, needsAdditionalZeroCapCoverage } = useIntakeCoverageStatus()
  const [isEditing, setIsEditing] = useState(false)
  const [invalidSponsor, setInvalidSponsor] = useState<{ name: string; reason: InvalidSponsorReason }>()
  const [savePatientEmployer] = useSaveEmployerCoverage({
    refetchQueries: ['PatientContext'],
  })

  const coverage = coverages[PaymentPreference.Employer]

  const handleSubmit = async (values: { employerValues: EmployerCoverageFormValues }) => {
    if (values.employerValues.employer?.isReferralIdRequired && values.employerValues.referralId) {
      // want to catch the good scenario so they don't trickle down to the other two cases here
      // no-op
    } else if (values.employerValues.employer?.isReferralIdRequired && !values.employerValues.referralId) {
      mixpanel.track('Intake - Sponsor requires referralId', { sponsor: values.employerValues.employer.name })
      setInvalidSponsor({
        name: values.employerValues.employer.name,
        reason: InvalidSponsorReason.requiresReferralId,
      })
      return
    } else if (values.employerValues.employer?.offersTava === false) {
      mixpanel.track('Intake - Sponsor Not Offering Tava', { sponsor: values.employerValues.employer.name })
      setInvalidSponsor({
        name: values.employerValues.employer.name,
        reason: InvalidSponsorReason.notOnTava,
      })
      return
    }

    setIsMutationLoading(true)

    const response = await savePatientEmployer({
      variables: {
        id: patientContextData?.patientContext.patient.id ?? '',
        employer: buildEmployerCoverageInput(values),
      },
    })

    if (!response.didSucceed) {
      if (
        values.employerValues?.employerAssociation &&
        values.employerValues?.employerAssociation !== OrganizationRelationship.Employee
      ) {
        setInvalidSponsor({
          name: values.employerValues.employer?.name ?? '',
          reason: InvalidSponsorReason.notAvailableToDependents,
        })
      } else {
        toast.urgent(response.error?.message)
      }
      setIsMutationLoading(false)
      return
    }

    refetch()
    setIsEditing(false)
    setIsMutationLoading(false)
  }

  if (invalidSponsor) {
    return (
      <>
        <CoverageResult
          illustration={<CoverageResultIllustration src={scribbleSrc} alt="" style={{ maxWidth: 130 }} />}
          status="invalid"
          errorText={`Looks like ${invalidSponsor.name} ${
            invalidSponsor.reason === InvalidSponsorReason.notAvailableToDependents
              ? "doesn't cover dependents."
              : "isn't sponsoring Tava yet."
          }`}
          bottomSection={
            <InvalidSponsorNextSteps
              reason={invalidSponsor.reason}
              className="mt-5"
              sponsorName={invalidSponsor.name}
            />
          }
        >
          <IntakeCoverageStatus
            coverage={{
              type: PaymentPreference.Employer,
              invalid: true,
              details: { invalidSponsorReason: invalidSponsor.reason, employerName: invalidSponsor.name },
            }}
          />

          <GhostButton className="mt-3" variant="secondary" onClick={() => setInvalidSponsor(undefined)}>
            Change sponsoring organization
          </GhostButton>
        </CoverageResult>

        <GhostButtonLink className="mt-4" to={`../../${nextPath}`}>
          Skip adding sponsor
        </GhostButtonLink>

        <CoverageNextButton nextPath={nextPath} />
      </>
    )
  }

  if (coverage && !isEditing) {
    return (
      <>
        <CoverageResult
          status={needsAdditionalZeroCapCoverage ? 'zeroCapEAP' : 'success'}
          successText={"We've verified your organization"}
          employerName={coverage.details.employerName}
          onAddCoverageClick={() => navigate(`../../${nextPath}`)}
          coverage={coverage}
        >
          <IntakeCoverageStatus
            coverage={coverage}
            validationOptions={{ considerZeroCapValid: true }}
            onEditClick={() => setIsEditing(true)}
          />
        </CoverageResult>

        <CoverageNextButton nextPath={nextPath} />
      </>
    )
  }

  return (
    <Formik
      initialValues={{ employerValues: { ...employerInitialValues, associationRequired: true } }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <h2 className="h4 mb-2">Sponsor setup</h2>
          <p className="mb-4 paragraphs text-secondary">
            Many employers, associations, school districts, and organizations sponsor free sessions for their members.
            Enter yours here to check if you’re covered.
          </p>

          <EmployerCoverageForm
            isCheckout={false}
            autocompleteProps={{
              includeUnknownInOptions: true,
              clearOnBlur: true,
            }}
            skipButtonSlot={
              !coverage && (
                <SkipButtonWrapper>
                  <TextButtonLink to={`../../${nextPath}`}>I don't have a sponsor</TextButtonLink>
                </SkipButtonWrapper>
              )
            }
          />

          {isEditing && (
            <GhostButton variant="secondary" className="mt-3" onClick={() => setIsEditing(false)}>
              Cancel
            </GhostButton>
          )}

          <IntakeNavigation
            className="mt-5"
            nextButton={props => (
              <FillButton data-testid="intake-next" type="submit" {...props}>
                Verify Sponsor
              </FillButton>
            )}
          />
        </form>
      )}
    </Formik>
  )
}

const SkipButtonWrapper = styled('div')`
  margin-top: 1.8125rem;

  @media (${Phone}) {
    margin-left: 2rem;
    margin-top: 1.5rem;
  }
`

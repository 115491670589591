import React from 'react'

import { carePreferencesService } from '@nuna/core'
import { Chip, ChipGroup } from '@nuna/tunic'

import { MiscAnswer } from './MiscAnswer'
import { ALL_OF_THE_ABOVE } from './TherapistPreferences'
import { preferencesSubheadingSize } from './shared'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function TherapistAge({ handleChangeWithMiscOption, therapistAgeProps, values, setFieldValue }: any) {
  return (
    <>
      <h2 id="therapist-age-heading" className={`${preferencesSubheadingSize} mt-6`}>
        Therapist Age <span className="text-normal">(relative to my own age)</span>
      </h2>
      <p className="mb-4">Select all that apply</p>

      <ChipGroup
        error={therapistAgeProps.error}
        helperText={therapistAgeProps.helperText}
        aria-labelledby="#therapist-age-heading"
      >
        {carePreferencesService.providerAgeValues
          .filter(value => value !== ALL_OF_THE_ABOVE)
          .map(value => (
            <Chip
              key={value}
              name={therapistAgeProps.name}
              value={value}
              type="checkbox"
              checked={values[therapistAgeProps.name].includes(value)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeWithMiscOption(therapistAgeProps.name, therapistAgeProps.onChange, e)
              }
              dataTestId={`${therapistAgeProps.name}-${value}`}
            >
              {carePreferencesService.getProviderAgeLabel(value)}
            </Chip>
          ))}
      </ChipGroup>

      <MiscAnswer>
        <Chip
          type="checkbox"
          checked={values[therapistAgeProps.name].includes(ALL_OF_THE_ABOVE)}
          value={ALL_OF_THE_ABOVE}
          name={therapistAgeProps.name}
          onChange={() => setFieldValue(therapistAgeProps.name, [ALL_OF_THE_ABOVE])}
          dataTestId={`${therapistAgeProps.name}-${ALL_OF_THE_ABOVE}`}
        >
          Any age works
        </Chip>
      </MiscAnswer>
    </>
  )
}

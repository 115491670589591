import { styled } from '@mui/material'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'

import { useAssessmentBundleQuery } from '@nuna/api'
import { Link } from '@nuna/common'
import { routeService } from '@nuna/core'
import {
  BelowTablet,
  Grid,
  IconButton,
  IconChevronLeft,
  IconClose,
  Logo,
  ProgressHeader,
  Skeleton,
  greySet,
  toast,
} from '@nuna/tunic'

import { AssessmentsProvider } from '../context/AssessmentsContext'
import { useAssessmentRouting } from '../hooks/useAssessmentRouting'
import { assessmentBundleTypeDisplayLookupDesktop, assessmentBundleTypeDisplayLookupMobile } from '../util/assessments'
import { Assessment } from './Assessment'
import { AssessmentResults } from './AssessmentResults'

export function AssessmentBundle({ resultsOnClick }: { resultsOnClick: (providerId: string) => void }) {
  const { bundleId = '' } = useParams<{ bundleId: string }>()
  const navigate = useNavigate()
  const { data: bundleData, networkStatus } = useAssessmentBundleQuery({
    fetchPolicy: 'cache-and-network',
    variables: { bundleId },
    skip: !bundleId,
  })
  const isCompleteNetworkStatus = networkStatus === 7 || networkStatus === 8
  const { assessmentBundle } = bundleData ?? {}
  const { navigateWithinAssessmentBundle, completedAssessments, totalAssessments, navigateBack } = useAssessmentRouting(
    {
      assessmentBundle,
    },
  )
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  // We have to wait until the component is rendered to use navigation
  useEffect(() => {
    if (!assessmentBundle && !isCompleteNetworkStatus) return
    if (isCompleteNetworkStatus && !assessmentBundle) {
      toast.urgent('Assessment bundle not found.')
      navigate(routeService.assessments)
      return
    }
    if (assessmentBundle) {
      navigateWithinAssessmentBundle(assessmentBundle, false)
    }
  }, [assessmentBundle, isCompleteNetworkStatus, navigate, navigateWithinAssessmentBundle])

  const assessmentBundleDisplay =
    assessmentBundle && assessmentBundle.type
      ? !isMobile
        ? assessmentBundleTypeDisplayLookupDesktop[assessmentBundle.type]
        : assessmentBundleTypeDisplayLookupMobile[assessmentBundle.type]
      : ''

  if (!assessmentBundle) return null

  return (
    <AssessmentsProvider value={{ assessmentBundle, navigateWithinAssessmentBundle }}>
      <div
        style={{
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: greySet[0].hex,
        }}
      >
        <ProgressHeader
          persona="client"
          max={totalAssessments}
          current={completedAssessments}
          leftSlot={
            <div className="v-align">
              <Link to="/">
                <Logo color="salmon" scheme="dark" height={32} renderText={false} />
              </Link>
              <IconButton onClick={navigateBack} tooltip="Back" className="ml-2">
                <IconChevronLeft color={greySet['70'].hex} />
              </IconButton>
              <h6 className="ml-2 mb-0">
                {assessmentBundleDisplay ? assessmentBundleDisplay : <Skeleton width={14} height={1} />}
              </h6>
            </div>
          }
          rightSlot={
            <IconButton onClick={() => navigate(routeService.assessments)} tooltip="Exit">
              <IconClose color={greySet['70'].hex} size={14} />
            </IconButton>
          }
          centerSlot={<div />}
        />
        <GridContainer container justifyContent="center">
          <Grid
            size={{
              xs: 12,
              md: 10,
              lg: 7,
              xl: 6,
            }}
          >
            <Routes>
              <Route path=":assessmentId" element={<Assessment />} />
              <Route path="results" element={<AssessmentResults defaultButtonOnClick={resultsOnClick} />} />
            </Routes>
          </Grid>
        </GridContainer>
      </div>
    </AssessmentsProvider>
  )
}

const GridContainer = styled(Grid)`
  margin-top: 10rem;

  @media (${BelowTablet}) {
    margin-top: 4rem;

    .h3 {
      font-size: 2rem;
    }

    .h4 {
      font-size: 1.5rem;
    }

    .h5 {
      font-size: 1.25rem;
    }
  }
`

import { useState } from 'react'

import { useClientPaymentMethods } from '@nuna/payment-methods'
import { GhostButton } from '@nuna/tunic'

import { CardManagementDrawer } from './components/CardManagementDrawer'
import { EmptyCardPrompt } from './components/EmptyCardPrompt'
import { PaymentMethodCard } from './components/PaymentMethodCard'

interface Props {
  patientId: string
}

export function FeePaymentMethodManagement({ patientId }: Props) {
  const [isCardManagementDrawerOpen, setIsCardManagementDrawerOpen] = useState(false)
  const clientPaymentMethods = useClientPaymentMethods(patientId)

  const { defaultForFees } = clientPaymentMethods

  const openCardManagementDrawer = () => {
    setIsCardManagementDrawerOpen(true)
  }

  return (
    <>
      {defaultForFees ? (
        <PaymentMethodCard paymentMethod={defaultForFees} />
      ) : (
        <EmptyCardPrompt onAddClick={openCardManagementDrawer} />
      )}

      <p className="mt-1 caption text-secondary italic">
        This credit card is only used to cover any late cancelation or no show fees you incur.
      </p>

      {defaultForFees && (
        <GhostButton className="mt-2" variant="secondary" onClick={openCardManagementDrawer}>
          Manage payment methods
        </GhostButton>
      )}

      <CardManagementDrawer
        patientId={patientId}
        clientPaymentMethods={clientPaymentMethods}
        isOpen={isCardManagementDrawerOpen}
        onClose={() => setIsCardManagementDrawerOpen(false)}
      />
    </>
  )
}

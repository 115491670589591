import { usePatientContextQuery } from '@nuna/api'
import { routeService } from '@nuna/core'
import { FillButtonLink } from '@nuna/tunic'

import { Banner } from '../../shared/components/Banner'
import { timeOfDay } from '../../util/timeOfDay'

export const IntakeMessage = () => {
  const { loading, error, data } = usePatientContextQuery()

  if (loading || error || !data) {
    return null
  }

  if (data && data.patientContext.patient.intakeCompleted) {
    return null
  }

  return (
    <Banner className="mb-4">
      <div style={{ paddingLeft: '.5rem' }}>
        <h2 className="h5 mb-0">
          Good {timeOfDay}, {data.patientContext.patient.firstName}! 👋
        </h2>
        <p className="m-0">
          Before you can choose a therapist and schedule an appointment, we need to ask you just a few more questions.
        </p>
      </div>

      <FillButtonLink to={routeService.intake()}>Finish Up</FillButtonLink>
    </Banner>
  )
}

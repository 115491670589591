import { DayOfWeek, OfficeHours } from '@nuna/api'
import { Checkbox, CircleToggleButton } from '@nuna/tunic'

import { useSearchProviderContext } from '../SearchProviderContext'
import { FilterSection } from './FilterAccordion'

const DAYS_OF_WEEK = [
  { key: DayOfWeek.Sun, label: 'S', tooltip: 'Sunday' },
  { key: DayOfWeek.Mon, label: 'M', tooltip: 'Monday' },
  { key: DayOfWeek.Tue, label: 'T', tooltip: 'Tuesday' },
  { key: DayOfWeek.Wed, label: 'W', tooltip: 'Wednesday' },
  { key: DayOfWeek.Thurs, label: 'Th', tooltip: 'Thursday' },
  { key: DayOfWeek.Fri, label: 'F', tooltip: 'Friday' },
  { key: DayOfWeek.Sat, label: 'Sa', tooltip: 'Saturday' },
]

const HOURS = [
  { key: OfficeHours.Morning, label: 'Mornings' },
  { key: OfficeHours.Afternoon, label: 'Afternoons' },
  { key: OfficeHours.Evening, label: 'Evenings' },
]

export function GeneralAvailabilityControl() {
  const { appliedFilters, toggleGeneralAvailabilityDays, toggleGeneralAvailabilityHours } = useSearchProviderContext()
  const days = appliedFilters.generalAvailability.days ?? []
  const hours = appliedFilters.generalAvailability.hours ?? []

  return (
    <FilterSection>
      <div className="v-align space-between pb-1-5" style={{ minWidth: 296 }}>
        {DAYS_OF_WEEK.map(d => (
          <CircleToggleButton
            key={d.key}
            size="md"
            tooltip={d.tooltip}
            isActive={days.includes(d.key)}
            onClick={() => toggleGeneralAvailabilityDays(d.key)}
          >
            {d.label}
          </CircleToggleButton>
        ))}
      </div>
      {HOURS.map(h => (
        <Checkbox
          className="checkbox"
          key={h.key}
          checked={hours.includes(h.key)}
          onChange={() => toggleGeneralAvailabilityHours(h.key)}
        >
          {h.label}
        </Checkbox>
      ))}
    </FilterSection>
  )
}

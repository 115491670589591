import { styled } from '@mui/material'
import { TextField as MUITextField, TextFieldProps as MUITextFieldProps } from '@mui/material'
import React, { useState } from 'react'

import { styledUtils } from '@nuna/tunic'
import { BelowTablet } from '@nuna/tunic'
import { error, interactiveFill } from '@nuna/tunic'

/** @deprecated use TextField from tunic */
export type TextFieldProps = MUITextFieldProps & { maxCharacters?: number }

/** @deprecated use TextField from tunic */
export function TextField(props: TextFieldProps) {
  const [isFocused, setIsFocused] = useState(false)
  const { error, value, maxCharacters } = props

  const handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(true)
    props.onFocus && props.onFocus(e)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(false)
    props.onBlur && props.onBlur(e)
  }

  let helperText = props.helperText
  if (maxCharacters) {
    helperText = (
      <HelperText>
        {error && <Error>{error}</Error>}
        <CharacterCount>
          {(value as string).length}/{maxCharacters}
        </CharacterCount>
      </HelperText>
    )
  }

  return (
    <TextFieldStyled
      $isFocused={isFocused}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...props}
      helperText={helperText}
    />
  )
}

const TextFieldStyled = styled(MUITextField, styledUtils.transientPropOptions)<{ $isFocused: boolean }>`
  ${({ $isFocused }) =>
    $isFocused &&
    `
  .MuiInput-underline {
    &::after {
      border-bottom-color: ${interactiveFill};
    }
  }
`}
`

const HelperText = styled('span')`
  display: flex;
  width: 100%;
  line-height: 1.2;
`

const Error = styled('span')`
  color: ${error};

  @media (${BelowTablet}) {
    text-align: left;
    width: 75%;
  }
`

const CharacterCount = styled('span')`
  margin-left: auto;
  text-align: right;
`

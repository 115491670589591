import { styled } from '@mui/material'
import { useEffect, useState } from 'react'

import { BelowTablet, Dialog, DialogContent, DialogProps, ProgressBar, fontSize, tealSet } from '@nuna/tunic'

import pendingSrc from '../../../../CoverageResults/img/pending.png'

interface Props extends Omit<DialogProps, 'isOpen'> {
  isLoading: boolean
  maxEstimatedLoadingTime?: number // In milliseconds. Defaults to 30 seconds
}

export function ECheckLoadingDialog({ isLoading, maxEstimatedLoadingTime = 30000, ...props }: Props) {
  const [bufferedIsOpen, setBufferedIsOpen] = useState(isLoading)
  const [progressPercentage, setProgressPercentage] = useState(1)

  useEffect(() => {
    if (isLoading) {
      setBufferedIsOpen(true)
      setProgressPercentage(1)
    } else {
      // if isLoading turns to false, the progress bar will animate to 100% and then close the dialog
      setProgressPercentage(100)
      const timeout = setTimeout(() => {
        setBufferedIsOpen(false)
      }, 1000)

      return () => clearTimeout(timeout)
    }
  }, [isLoading])

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setProgressPercentage(prev => {
          if (prev >= 90) {
            // the progress bar will stop animating once it reaches 90% and will wait for isLoading to turn to false to fill to 100%
            clearInterval(interval)
            return prev
          }

          return prev + 10 // bar fills up by 10% every time it animates
        })
      }, maxEstimatedLoadingTime / 10)

      return () => clearInterval(interval)
    }
  }, [isLoading, maxEstimatedLoadingTime])

  return (
    <Dialog isOpen={bufferedIsOpen} {...props}>
      <DialogContent className="text-center p-3">
        <Illustration src={pendingSrc} alt="" />

        <h2 className="h4 mt-3 mb-3">Verifying your insurance</h2>

        <Copy className="text-secondary">
          Please hang tight while we verify your insurance plan. This can take up to 30 seconds.
        </Copy>

        <ProgressBar
          bars={[{ key: 'eCheckDialogProgress', percent: progressPercentage, color: tealSet[70].hex }]}
          className="mt-3"
        />
      </DialogContent>
    </Dialog>
  )
}

const Illustration = styled('img')`
  max-width: 350px;
  width: 100%;
`

const Copy = styled('p')`
  line-height: 1.4;
  font-size: ${fontSize.large};
  max-width: 26em; // keeps the line break right after "insurance plan."

  @media (${BelowTablet}) {
    font-size: ${fontSize.body};
  }
`

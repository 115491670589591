import { styled } from '@mui/material'

import { BelowTablet, borderGrey, greySet } from '@nuna/tunic'

export function Banner(props: React.HTMLAttributes<HTMLElement>) {
  return <Container role="status" {...props} />
}

const Container = styled('section')`
  align-items: center;
  background-color: ${greySet[5].hex};
  border: 1px solid ${borderGrey};
  border-radius: var(--border-radius);
  display: flex;
  padding: 1rem;
  min-height: 98px;

  & + & {
    margin-top: 1rem;
  }

  a,
  button {
    margin-left: auto;
  }

  @media (${BelowTablet}) {
    flex-direction: column;
    align-items: flex-start;

    a,
    button {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
`

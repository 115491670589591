import React from 'react'

import { carePreferencesService } from '@nuna/core'
import { Chip, ChipGroup } from '@nuna/tunic'

import { MiscAnswer } from './MiscAnswer'
import { ALL_OF_THE_ABOVE } from './TherapistPreferences'
import { preferencesSubheadingSize } from './shared'

export function TherapistGender({
  handleChangeWithMiscOption,
  therapistGenderProps,
  values,
  setFieldValue,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  return (
    <>
      <h2 id="therapist-gender-heading" className={`${preferencesSubheadingSize} mt-6`}>
        Therapist Gender
      </h2>
      <p className="mb-4">Select all that apply</p>

      <ChipGroup
        error={therapistGenderProps.error}
        helperText={therapistGenderProps.helperText}
        aria-labelledby="#therapist-gender-heading"
      >
        {carePreferencesService.providerGenderValues
          .filter(value => value !== ALL_OF_THE_ABOVE)
          .map(value => (
            <Chip
              key={value}
              name={therapistGenderProps.name}
              value={value}
              type="checkbox"
              checked={values[therapistGenderProps.name].includes(value)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeWithMiscOption(therapistGenderProps.name, therapistGenderProps.onChange, e)
              }
              dataTestId={`${therapistGenderProps.name}-${value}`}
            >
              {carePreferencesService.getProviderGenderLabel(value)}
            </Chip>
          ))}
      </ChipGroup>

      <MiscAnswer>
        <Chip
          type="checkbox"
          checked={values[therapistGenderProps.name].includes(ALL_OF_THE_ABOVE)}
          value={ALL_OF_THE_ABOVE}
          name={therapistGenderProps.name}
          onChange={() => setFieldValue(therapistGenderProps.name, [ALL_OF_THE_ABOVE])}
          dataTestId={`${therapistGenderProps.name}-${ALL_OF_THE_ABOVE}`}
        >
          Any gender works
        </Chip>
      </MiscAnswer>
    </>
  )
}

import { styled } from '@mui/material'

import { AuthorizedInternationalRegionFragment } from '@nuna/api'
import { FAQItem } from '@nuna/common'
import { GhostButtonExternalLink, TextButtonExternalLink, makeTypographyComponent } from '@nuna/tunic'

import resourceIllustrationSrc from '../img/international-resources.png'
import { getInternationalPhoneNumbers } from './phoneUtils'

interface InternationalCoverageDetailsProps {
  internationalArea: AuthorizedInternationalRegionFragment
}

export function InternationalCoverageDetails({ internationalArea }: InternationalCoverageDetailsProps) {
  const { tollFree, direct } = getInternationalPhoneNumbers(internationalArea)

  return (
    <>
      <div className="mt-3">
        <p className="caption italic text-light text-center text-secondary mb-5">
          We'll connect you with our global therapy partner to find you a therapist.
          <br />
          *Your benefit includes {internationalArea.sessionCap} sessions per case per year and is available to you and
          dependents age 13&ndash;25
        </p>

        <FAQItem
          fullWidth
          question="View all ways to get started"
          answer={
            <div className="body">
              <Heading>Start via phone call</Heading>
              {tollFree && (
                <p>
                  Toll-free number (calling from inside your country)
                  <br />
                  <TextButtonExternalLink href={`tel:${tollFree}`}>{tollFree}</TextButtonExternalLink>
                </p>
              )}

              {direct && (
                <p>
                  Global direct dial number (calling from outside your country). You can request a callback to lower
                  your costs
                  <br />
                  <TextButtonExternalLink href={`tel:${direct}`}>{direct}</TextButtonExternalLink>
                </p>
              )}
              <Heading className="mt-4">Start via out partner's website</Heading>
              <p>
                Upon visiting our{' '}
                <TextButtonExternalLink href={internationalArea.resourceSite}>partner's website</TextButtonExternalLink>
                , click on the LIVE CONNECT button and request that a counselor connect with you.
              </p>
            </div>
          }
        />
      </div>

      <section className="text-center text-secondary my-7">
        <Illustration src={resourceIllustrationSrc} alt="" />
        <h3 className="h4 mt-3">Get help through self-guided resources</h3>
        <p className="large" style={{ maxWidth: 618, margin: 'auto' }}>
          Our global therapy partner offers a number of valuable articles, videos, and audio clips that can help with
          your challenges. Try mixing in some self-guided learning with your therapy.
        </p>
        <GhostButtonExternalLink className="mt-3" href={internationalArea.resourceSite}>
          Open self-help resources
        </GhostButtonExternalLink>
      </section>
    </>
  )
}

const Heading = makeTypographyComponent('text-bold body mb-2', 'h4')
const Illustration = styled('img')`
  width: 100%;
  max-width: 278px;
`

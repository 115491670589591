import { styled } from '@mui/material'

import { AssessmentType } from '@nuna/api'
import { Illustration } from '@nuna/common'
import { Grid, GridProps, borderGrey } from '@nuna/tunic'

import assessmentIllustrationSrc from '../img/assessment-illustration.png'
import suicidalIllustrationSrc from '../img/assessment-suicidal-illustration.png'

const assessmentHeaders = {
  C_SSRS: {
    heading: 'Help is available. You can speak to someone and get support by calling 988 at any time.',
    subheading: 'Suicide Risk Assessment',
    illustration: suicidalIllustrationSrc,
    note: null,
  },
  default: {
    heading: 'Let’s see how therapy might help with your personal situation.',
    subheading: 'Mental Health Assessment',
    illustration: assessmentIllustrationSrc,
    note: null,
  },
} as const

type AssessmentHeaderKeys = keyof typeof assessmentHeaders

interface AssessmentHeaderProps {
  type: AssessmentType
}

export const AssessmentHeader = ({ type }: AssessmentHeaderProps) => {
  const headerKey: AssessmentHeaderKeys = type in assessmentHeaders ? (type as AssessmentHeaderKeys) : 'default'
  const { heading, subheading, illustration, note } = assessmentHeaders[headerKey]

  return (
    <Header container direction="row-reverse" alignItems="center" className="mb-6">
      <Grid className="text-center" size={{ xs: 12, sm: 4 }}>
        <Illustration src={illustration} alt="" width="100%" />
      </Grid>
      <Grid size={{ xs: 12, sm: 8 }}>
        <p className="text-medium text-secondary mb-3">{subheading}</p>
        <h2 className="h3 mb-4">{heading}</h2>
        {note && <p className="text-secondary">{note}</p>}
      </Grid>
    </Header>
  )
}

const Header = styled((props: GridProps) => <Grid component="header" {...props} />)`
  border-bottom: 1px solid ${borderGrey};
  padding-bottom: 2rem;
`

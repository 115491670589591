import { styled } from '@mui/material'
import { groupBy } from 'lodash'
import moment from 'moment-timezone'
import React, { Fragment } from 'react'
import { useMediaQuery } from 'react-responsive'

import { GhostButton, Grid, StatusLabel, flexRightJustify } from '@nuna/tunic'
import { BelowTablet } from '@nuna/tunic'

import { ChipButton } from '../../shared/components/ChipButton'
import type { FilteredProvider } from '../../shared/components/SearchProvider/SearchProviderContext'

interface ChooseATimeProps extends React.HTMLAttributes<HTMLDivElement> {
  provider: FilteredProvider
  onViewAllTimesClick: React.MouseEventHandler<HTMLButtonElement>
  onSlotClick: (startDatetime: string) => void
}

export function ChooseATime({ provider, onViewAllTimesClick, onSlotClick, ...props }: ChooseATimeProps) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const [soonestOpening] = provider.availableTimes
  const nextAvailableSlots = provider.availableTimes.slice(0, isMobile ? 4 : 5)
  const groupedTimes = groupBy(nextAvailableSlots, slot => moment(slot.start).format('MM/DD/YYYY'))

  const buildChipButton = (start: string) => {
    const format = isMobile ? 'MMM DD [at] h:mma' : 'h:mma'
    const className = isMobile ? '' : 'mr-2'
    return (
      <ChipButton hoverText="Schedule" key={start} className={className} onClick={() => onSlotClick(start)}>
        {moment(start).format(format)}
      </ChipButton>
    )
  }

  return (
    <Wrapper {...props}>
      <Header container direction="row" alignItems="center">
        <Grid size={{ xs: 12, sm: 'auto' }}>
          <h5 className={!isMobile ? 'mb-0 mr-2' : ''}>Choose a Time to Meet</h5>
        </Grid>
        <Grid size={{ xs: 12, sm: 'grow' }}>
          <span className="soonest-opening">Soonest Opening:</span>
          <StatusLabel intent="information">
            {soonestOpening ? moment(soonestOpening?.start).fromNow() : 'Unavailable'}
          </StatusLabel>
        </Grid>
      </Header>
      <section className="mt-2">
        {isMobile ? (
          <Grid container spacing={1}>
            {nextAvailableSlots.map(slot => (
              <Grid
                key={slot.start}
                size={{
                  xs: 6,
                }}
              >
                {buildChipButton(slot.start)}
              </Grid>
            ))}
          </Grid>
        ) : (
          Object.keys(groupedTimes).map(day => {
            const dayLabel = (
              <span key={day} className="mr-2">
                {moment(day).format('ddd, MMM DD')}
              </span>
            )
            const buttons = groupedTimes[day].map(slot => buildChipButton(slot.start))
            return (
              <Fragment key={day}>
                {dayLabel}
                {buttons}
              </Fragment>
            )
          })
        )}
      </section>
      <ViewAllTimesContainer className="mt-2" sx={flexRightJustify()}>
        <GhostButton onClick={onViewAllTimesClick}>View all times</GhostButton>
      </ViewAllTimesContainer>
    </Wrapper>
  )
}

const Wrapper = styled('div')``

const Header = styled(Grid)`
  .soonest-opening {
    margin-right: 0.5rem;
  }
  @media (${BelowTablet}) {
    text-align: left;
  }
`

const ViewAllTimesContainer = styled('div')`
  @media (${BelowTablet}) {
    text-align: left;
  }
`

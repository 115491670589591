import { styled } from '@mui/material'
import { Route, Routes } from 'react-router-dom'

import { BelowTablet, IconBook, eggshell, errorIllustration, fontSize } from '@nuna/tunic'

import { AppLayout } from '../../layouts/AppLayout'
import { AppContainer } from '../../shared/components/Container'
import { MentalHealthLibraryContextProvider } from './MentalHealthLibraryContext'
import { CategoryFilter } from './components/CategoryFilter'
import { FeaturedPosts } from './components/FeaturedPosts'
import { PostList } from './components/PostList'
import { PostPlayer } from './components/PostPlayer'
import { usePostListQuery } from './generated/sanctuary-graphql'

export function MentalHealthLibrary() {
  return (
    <Routes>
      <Route index element={<MentalHealthLibraryContent />} />
      <Route path="/:postId" element={<MentalHealthLibraryContent />} />
    </Routes>
  )
}

function MentalHealthLibraryContent() {
  const { error } = usePostListQuery()

  return (
    <AppLayoutStyled verticalCenter={!!error} paddingTop={0} style={{ backgroundColor: '#fff' }}>
      {error ? (
        <AppContainer className="pt-2 pb-5 text-center">
          <img src={errorIllustration} alt="" />
          <p className="h5 mt-2 paragraphs mx-auto" style={{ maxWidth: '26em' }}>
            Oops, the mental health library is currently unavailable. Please try again later.
          </p>
        </AppContainer>
      ) : (
        <MentalHealthLibraryContextProvider>
          <div style={{ backgroundColor: eggshell }}>
            <AppContainer className="featured-section pt-5 pb-6">
              <Heading className="mb-4">
                <IconBook size={26} className="mr-1" style={{ transform: 'translateY(2px)' }} /> Mental Health Library
              </Heading>

              <h2 className="body large text-medium mb-2">Featured content</h2>
              <FeaturedPosts />
            </AppContainer>
          </div>

          <AppContainer className="post-section pb-7 pt-6">
            <h2 className="h4 desktop-only">Topics</h2>
            <CategoryFilter className="mb-4" />
            <PostList />
          </AppContainer>

          <PostPlayer />
        </MentalHealthLibraryContextProvider>
      )}
    </AppLayoutStyled>
  )
}

const AppLayoutStyled = styled(AppLayout)`
  .featured-section {
    padding-top: var(--spacing-5);
    padding-bottom: var(--spacing-6);

    @media (${BelowTablet}) {
      padding-bottom: var(--spacing-5);
    }
  }

  .post-section {
    padding-top: var(--spacing-6);
    padding-bottom: var(--spacing-7);

    @media (${BelowTablet}) {
      padding-top: var(--spacing-5);
      padding-bottom: var(--spacing-6);
    }
  }
`

const Heading = styled('h1')`
  font-size: ${fontSize.h3};

  @media (${BelowTablet}) {
    font-size: ${fontSize.h4};
  }
`

import { Autocomplete, AutocompleteProps, TextField as MUITextField } from '@mui/material'
import mixpanel from 'mixpanel-browser'

import { IconError } from '@nuna/tunic'

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<string, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput'
>

interface PublicProviderInsuranceSelectProps extends LimitedAutocompleteProps {
  insuranceEnrollments: string[]
  value: string | null
  onChange: (value: string | null) => void
}

export function PublicProviderInsuranceSelect({
  insuranceEnrollments,
  value,
  onChange,
  ...props
}: PublicProviderInsuranceSelectProps) {
  return (
    <Autocomplete
      value={value}
      options={insuranceEnrollments}
      onChange={(_event, value) => {
        onChange(value)
      }}
      renderInput={params => (
        <MUITextField
          {...params}
          inputProps={{ ...params.inputProps, 'data-testid': 'public-provider-insurance-autocomplete' }}
          placeholder="Search for your carrier..."
          onFocus={() => mixpanel.track('used public provider insurance filter')}
        />
      )}
      noOptionsText={
        <div className="v-align">
          <IconError className="mr-1" />
          No results found
        </div>
      }
      {...props}
    />
  )
}

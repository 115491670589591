import { styled } from '@mui/material'
import { isNil } from 'lodash'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useInviteQuery, usePublicCompanyQuery } from '@nuna/api'
import { useSignupSearchParams } from '@nuna/auth'
import { routeService } from '@nuna/core'
import {
  Box,
  GhostButtonExternalLink,
  SignupLayout as SignupLayoutComponents,
  Skeleton,
  UserChip,
  toast,
} from '@nuna/tunic'

import { SignupForm } from './SignupForm'
import { SignupLayout } from './SignupLayout'
import { companySpecificFAQs, faqs } from './faqItems'
import { useCompanySlug } from './hooks/useCompanySlug'
import { PreconfiguredSignup } from './preconfigured/PreconfiguredSignup'

const { Heading } = SignupLayoutComponents

interface Props {
  redirectToInvite?: boolean
}

export function Signup({ redirectToInvite = false }: Props) {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { scrubbedCompanySlug } = useCompanySlug()

  const fromUnauthenticatedInvite = (state as { from: Location })?.from?.pathname === '/invite'

  const { companyId, inviteId, patientSource } = useSignupSearchParams()

  const {
    data: inviteData,
    error: inviteError,
    loading: isLoadingInvite,
  } = useInviteQuery({
    variables: { id: inviteId ?? '' },
    skip: !inviteId,
  })

  const hasInvite = !isNil(inviteId) && isNil(inviteError)
  const inviteLoading = hasInvite && isNil(inviteData)
  const invite = inviteData?.invite
  const inviteExpired = inviteError?.message === 'Invite has expired'
  const inviteCompanyId = invite?.destinationCompanyId
  const { data: publicCompany, loading: isLoadingPublicCompany } = usePublicCompanyQuery({
    variables: { id: companyId || inviteCompanyId || '', companySlug: scrubbedCompanySlug },
    skip: !companyId && !inviteCompanyId && !scrubbedCompanySlug,
  })
  const companyData = publicCompany?.publicCompany
  const companySpecificFAQItems =
    companySpecificFAQs(companyData?.contract?.sessionNumberLimit?.toString() || '', companyData?.name || '') ?? []
  const { contract, name: companyName, logoUrl: companyLogoUrl, partnerBranding } = companyData || {}
  // remove any trailing numbers from the company name, e.g. "Company 1" -> "Company", "3 Amigos" -> "3 Amigos"
  const companyNameWithNoTrailingNumbers = companyName?.replace(/\s*\d+$/, '')
  const { benefitsGuideUrl, sessionNumberLimit, availableToDependents } = contract || {}

  useEffect(() => {
    if (invite && invite.hasBeenAccepted) {
      toast.info('Your Tava invitation has already been accepted.')
      // if the user navigates to a url that has an invite id in it but the invite has already been accepted,
      // redirect them to home.
      navigate(routeService.login())
    }
    if (inviteExpired) {
      toast.info('Looks like you already accepted your Tava invitation. Great! You can sign in now.')

      // if the user navigates to a url that has an invite id in it but the invite has already been accepted,
      // redirect them to home.
      navigate(routeService.login())
    }
  }, [invite, inviteExpired, navigate])

  if (['zocdoc', 'carenavigator', 'providerform'].includes(patientSource?.toLowerCase() ?? '')) {
    return <PreconfiguredSignup />
  }

  if (isLoadingInvite || isLoadingPublicCompany) return null

  return (
    <SignupLayout
      customBranding={partnerBranding}
      renderForm={
        <Container>
          {companyLogoUrl && <CompanyLogo src={companyLogoUrl} alt={`${companyNameWithNoTrailingNumbers} logo`} />}
          <SignupForm
            hasInvite={hasInvite}
            invite={invite}
            inviteLoading={inviteLoading}
            redirectToInvite={redirectToInvite || fromUnauthenticatedInvite}
          />
        </Container>
      }
      renderLeftContent={
        <>
          {companyData && (
            <Box mt={12}>
              <Heading className="h2 mb-5">Get started with your mental health benefit</Heading>
              <p className={`large ${benefitsGuideUrl ? 'mb-2' : 'mb-7'}`}>
                {`${companyNameWithNoTrailingNumbers} is providing you ${
                  availableToDependents ? `and your dependents (age 13+) ` : ' '
                }${
                  sessionNumberLimit && sessionNumberLimit > 0
                    ? `${sessionNumberLimit} free sessions a year with`
                    : `access to`
                } licensed mental health professionals through Tava’s secure and confidential online platform.`}
              </p>
              {benefitsGuideUrl && (
                <p className="mt-2 mb-7">
                  <GhostButtonExternalLink href={benefitsGuideUrl || ''} scheme="dark">
                    Download your benefits guide
                  </GhostButtonExternalLink>
                </p>
              )}
            </Box>
          )}
          {hasInvite && !companyData && (
            <>
              <Heading className="mb-1">You've been invited to join Tava from:</Heading>
              {inviteLoading && <Skeleton width={10} height={2} className="mb-3" />}
              <UserChip avatarUrl={invite?.inviterAvatarUrl} className="mb-3">
                {invite?.inviterFullName}
              </UserChip>
            </>
          )}
          {!hasInvite && !companyData && (
            <Heading>
              Life can be hard.
              <br />
              Tava can help.
            </Heading>
          )}

          {!companyData && (
            <p className="large mb-3">
              Have you ever wondered what it would be like to meet your best self? It all starts here. Find the right
              therapist for you and get the care you need—on your schedule.
            </p>
          )}
        </>
      }
      faqItems={!companyData ? faqs : companySpecificFAQItems}
    />
  )
}

const Container = styled('div')`
  padding: 4rem 0 2rem;
`

const CompanyLogo = styled('img')`
  width: 100%;
  height: auto;
  max-width: 200px;
`

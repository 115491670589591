import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import { isEqual } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  IntakeProgressQuery,
  MostImportantTherapistAttribute,
  TherapistPreferencesInput,
  useSavePatientTherapistPreferencesMutation,
} from '@nuna/api'
import { usePatientId } from '@nuna/auth'
import { carePreferencesService, formService } from '@nuna/core'
import { FillButtonWithChevron, borderGrey } from '@nuna/tunic'

import { getProviderSuggestionRoute } from '../../../../util/routes'
import { Container } from '../../InsuranceIntake'
import { useInsuranceIntakeContext } from '../../InsuranceIntakeContext'
import { IntakeNavigation } from '../../IntakeNavigation'
import { useIntakeNavigation } from '../../hooks/useIntakeNavigation'
import { useSkipIntake } from '../../hooks/useSkipIntake'
import { TherapistAge } from './TherapistAge'
import { TherapistAvailability } from './TherapistAvailability'
import { TherapistGender } from './TherapistGender'
import { TherapistMostImportant } from './TherapistMostImportant'

const { makeSelection, getFieldProps } = formService

export interface TherapistPreferencesFormValues {
  bestTimes: string[]
  therapistGender: string[]
  therapistAge: string[]
  importantAttribute: MostImportantTherapistAttribute | null
}

const therapistPreferencesSchema = Yup.object().shape<TherapistPreferencesFormValues>({
  bestTimes: Yup.array().of(Yup.string()).min(1, makeSelection),
  therapistGender: Yup.array().of(Yup.string()).min(1, makeSelection),
  therapistAge: Yup.array().of(Yup.string()).min(1, makeSelection),
  importantAttribute: Yup.mixed<MostImportantTherapistAttribute>().nullable().required(makeSelection),
})

export const ALL_OF_THE_ABOVE = 'allOfAbove'

export function TherapistPreferences() {
  const patientId = usePatientId() ?? ''
  const navigate = useNavigate()
  const { skipIntake } = useSkipIntake()

  const { intakeProgressData, setIsMutationLoading } = useInsuranceIntakeContext()
  const [savePatientTherapistPreferences] = useSavePatientTherapistPreferencesMutation()
  const { nextPath } = useIntakeNavigation()

  const handleSubmit = async (values: TherapistPreferencesFormValues) => {
    setIsMutationLoading(true)

    await savePatientTherapistPreferences({
      variables: {
        id: patientId,
        preferences: getTherapistPreferencesInput(values),
      },
    })

    setIsMutationLoading(false)
    navigate(`../${nextPath}`)
  }

  if (!intakeProgressData) return null

  return (
    <Formik
      initialValues={buildInitialValues(intakeProgressData)}
      validationSchema={therapistPreferencesSchema}
      onSubmit={handleSubmit}
    >
      {formikProps => {
        const { values, setFieldValue, handleSubmit } = formikProps

        const handleChangeWithMiscOption = (
          name: string,
          onChange: React.ChangeEventHandler<HTMLInputElement>,
          e: React.ChangeEvent<HTMLInputElement>,
        ) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((values as any)[name].includes(ALL_OF_THE_ABOVE)) {
            setFieldValue(name, [e.target.value])
          } else {
            onChange(e)
          }
        }

        const therapistGenderProps = getFieldProps('therapistGender', formikProps)
        const therapistAgeProps = getFieldProps('therapistAge', formikProps)
        const importantAttributeProps = getFieldProps('importantAttribute', formikProps)
        const bestTimesFieldProps = getFieldProps('bestTimes', formikProps)

        const handleBestTimesChange = (e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeWithMiscOption(bestTimesFieldProps.name, bestTimesFieldProps.onChange, e)

        return (
          <form onSubmit={handleSubmit}>
            <Container>
              <Header>
                <h1 className="h4">Tell us what you are looking for in your therapist.</h1>
                <p>
                  Finding the right match is very important. Select any attributes that you feel would positively impact
                  your therapy experience. We’ll do our best to match you with as many of the criteria as possible.
                </p>
              </Header>

              <section>
                <TherapistAvailability
                  handleBestTimesChange={handleBestTimesChange}
                  bestTimesFieldProps={bestTimesFieldProps}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </section>

              <section>
                <TherapistGender
                  handleChangeWithMiscOption={handleChangeWithMiscOption}
                  therapistGenderProps={therapistGenderProps}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </section>

              <section>
                <TherapistAge
                  handleChangeWithMiscOption={handleChangeWithMiscOption}
                  therapistAgeProps={therapistAgeProps}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </section>

              <section>
                <TherapistMostImportant importantAttributeProps={importantAttributeProps} values={values} />
              </section>

              <IntakeNavigation
                className="mt-5"
                nextButton={() => (
                  <FillButtonWithChevron
                    data-testid="intake-next"
                    onClick={() => skipIntake(getProviderSuggestionRoute())}
                  >
                    Complete Intake
                  </FillButtonWithChevron>
                )}
              />
            </Container>
          </form>
        )
      }}
    </Formik>
  )
}

export function buildInitialValues(data: IntakeProgressQuery): TherapistPreferencesFormValues {
  return {
    bestTimes: checkAllOfTheAbove(
      'therapistAvailability',
      data.intakeProgress.therapistPreferences?.therapistAvailability,
    ),
    therapistGender: checkAllOfTheAbove('therapistGender', data.intakeProgress.therapistPreferences?.therapistGender),
    therapistAge: checkAllOfTheAbove('therapistAge', data.intakeProgress.therapistPreferences?.therapistAge),
    importantAttribute: data.intakeProgress.therapistPreferences?.mostImportantAttribute ?? '',
  }
}

function checkAllOfTheAbove(field: 'therapistGender' | 'therapistAge' | 'therapistAvailability', value: string[] = []) {
  switch (field) {
    case 'therapistGender':
      if (
        isEqual(
          [...value].sort(),
          [...carePreferencesService.providerGenderValues.filter(key => key !== ALL_OF_THE_ABOVE)].sort(),
        )
      ) {
        return [ALL_OF_THE_ABOVE]
      }
      return value
    case 'therapistAge':
      if (
        isEqual(
          [...value].sort(),
          [...carePreferencesService.providerAgeValues.filter(key => key !== ALL_OF_THE_ABOVE)].sort(),
        )
      ) {
        return [ALL_OF_THE_ABOVE]
      }
      return value
    case 'therapistAvailability':
      if (
        isEqual(
          [...value].sort(),
          [...carePreferencesService.providerAvailabilityValues.filter(key => key !== ALL_OF_THE_ABOVE)].sort(),
        )
      ) {
        return [ALL_OF_THE_ABOVE]
      }
      return value
  }
}

export function getTherapistPreferencesInput(values: TherapistPreferencesFormValues): TherapistPreferencesInput {
  return {
    mostImportantAttribute: values.importantAttribute,
    therapistAvailability: getAllOfTheAboveValues('therapistAvailability', values.bestTimes),
    therapistGender: getAllOfTheAboveValues('therapistGender', values.therapistGender),
    therapistAge: getAllOfTheAboveValues('therapistAge', values.therapistAge),
  }
}

function getAllOfTheAboveValues(field: 'therapistGender' | 'therapistAge' | 'therapistAvailability', value: string[]) {
  if (value.includes(ALL_OF_THE_ABOVE)) {
    switch (field) {
      case 'therapistGender':
        return carePreferencesService.providerGenderValues.filter(key => key !== ALL_OF_THE_ABOVE)
      case 'therapistAge':
        return carePreferencesService.providerAgeValues.filter(key => key !== ALL_OF_THE_ABOVE)
      case 'therapistAvailability':
        return carePreferencesService.providerAvailabilityValues.filter(key => key !== ALL_OF_THE_ABOVE)
    }
  }

  return value
}

const Header = styled('div')`
  border-bottom: 1px solid ${borderGrey};
  padding-bottom: 3.75rem;
  margin-bottom: 3.75rem;
`

import * as Yup from 'yup'
import { AnimatePresence } from 'framer-motion'
import { useRef } from 'react'

import { Invite } from '@nuna/api'
import { PrevalidationError } from '@nuna/auth'
import { isMinor } from '@nuna/client'
import { formService, routeService, signupService } from '@nuna/core'
import {
  CollapsePresence,
  ContextualAlert,
  DOBTextField,
  GhostButton,
  Grid,
  OutlineButtonLink,
  Skeleton,
  TextField,
} from '@nuna/tunic'

import { Tip } from '../../../shared/components/Tip'
import { SignupFormStepProps } from '../SignupForm'

const { composeHelperTextWithError } = formService
const { firstNameSchema, lastNameSchema, dobSchema, parentGuardianEmailSchema, emailSchema } = signupService

export interface NameEmailValues {
  firstName: string
  lastName: string
  dob: string
  parentGuardianEmail: string
  email: string
}

export const nameEmailSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  dob: dobSchema,
  parentGuardianEmail: parentGuardianEmailSchema,
  email: emailSchema,
})

export function getNameEmailInitialValues(
  invite?: Pick<Invite, 'recipientEmail' | 'recipientFirstName' | 'recipientLastName'>,
) {
  return {
    firstName: invite?.recipientFirstName ?? '',
    lastName: invite?.recipientLastName ?? '',
    dob: '',
    parentGuardianEmail: '',
    email: invite?.recipientEmail ?? '',
  }
}

interface NameEmailStepProps extends SignupFormStepProps {
  prevalidationErrors: PrevalidationError[]
  dismissError: (error: PrevalidationError) => void
}

export function NameEmailStep({ prevalidationErrors, dismissError, formikProps }: NameEmailStepProps) {
  const { handleChange, errors, touched, values, handleBlur, setFieldValue } = formikProps
  const emailInputRef = useRef<HTMLInputElement>(null)

  return (
    <div>
      <Grid container spacing={4}>
        <Grid size={{ xs: 6 }}>
          <TextField
            label="First name"
            type="text"
            name="firstName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            inputProps={{
              'data-testid': 'signup-first-name',
            }}
            {...composeHelperTextWithError('', errors.firstName, touched.firstName)}
            className="mb-3"
          />
        </Grid>
        <Grid size={{ xs: 6 }}>
          <TextField
            label="Last name"
            type="text"
            name="lastName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            inputProps={{
              'data-testid': 'signup-last-name',
            }}
            {...composeHelperTextWithError('', errors.lastName, touched.lastName)}
            className="mb-3"
          />
        </Grid>
      </Grid>

      <DOBTextField
        id="dob"
        label="Date of birth"
        value={values.dob}
        placeholder="MM/DD/YYYY"
        name="dob"
        fullWidth
        onBlur={handleBlur}
        onChange={handleChange}
        {...composeHelperTextWithError('', errors.dob, touched.dob)}
        slotProps={{
          htmlInput: { 'data-testid': 'signup-dob' },
        }}
      />

      <AnimatePresence>
        {isMinor(values.dob) && (
          <CollapsePresence>
            <Tip
              className="mb-2 mt-1"
              description="We’ll need your parent or guardian’s consent to get you set up with a therapist. We’ll email them everything they need to know."
            />
            <TextField
              label="Parent or guardian's email"
              type="email"
              name="parentGuardianEmail"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.parentGuardianEmail}
              inputProps={{
                'data-testid': 'signup-parent-guardian-email',
              }}
              {...composeHelperTextWithError('', errors.parentGuardianEmail, touched.parentGuardianEmail)}
            />
          </CollapsePresence>
        )}
      </AnimatePresence>

      <TextField
        label="Personal email"
        type="email"
        name="email"
        onChange={e => {
          prevalidationErrors.forEach(dismissError)
          handleChange(e)
        }}
        onBlur={handleBlur}
        value={values.email}
        helperText={touched.email && errors.email}
        inputProps={{
          'data-testid': 'signup-email',
        }}
        $inputRef={emailInputRef}
        error={!!(errors.email && touched.email) || prevalidationErrors.length > 0}
        className="mt-3 mb-2"
      />

      <AnimatePresence>
        {prevalidationErrors.map(error => {
          if (error.field === 'email') {
            return (
              <CollapsePresence>
                <ContextualAlert intent="urgent">
                  Oops, we encountered an error. If you already have an account, please log in instead.
                  <GhostButton
                    variant="secondary"
                    className="no-wrap ml-3"
                    onClick={() => {
                      setFieldValue('email', '')
                      emailInputRef.current?.focus()
                      dismissError(error)
                    }}
                  >
                    Enter another
                  </GhostButton>
                  <OutlineButtonLink variant="secondary" className="ml-2" to={routeService.login()}>
                    Login
                  </OutlineButtonLink>
                </ContextualAlert>
              </CollapsePresence>
            )
          }

          return (
            <CollapsePresence>
              <ContextualAlert largeText intent="urgent">
                {error.error}
              </ContextualAlert>
            </CollapsePresence>
          )
        })}
      </AnimatePresence>
    </div>
  )
}

export function NameEmailStepLoadingSkeleton() {
  return (
    <div>
      <h2 className="h3">Get Started</h2>
      <Grid container spacing={4}>
        <Grid size={{ xs: 6 }}>
          <Skeleton fullWidth height={2} />
        </Grid>
        <Grid size={{ xs: 6 }}>
          <Skeleton fullWidth height={2} />
        </Grid>
        <Grid size={12}>
          <Skeleton fullWidth height={2} />
        </Grid>
        <Grid size={12}>
          <Skeleton fullWidth height={2} />
        </Grid>
        <Grid size={12}>
          <Skeleton className="mt-5" width={10} height={2} />
        </Grid>
      </Grid>
    </div>
  )
}

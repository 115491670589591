import { styled } from '@mui/material'

import { Drawer as BaseDrawer, DrawerProps as BaseDrawerProps } from '@nuna/tunic'
import { BelowTablet } from '@nuna/tunic'
import { borderGrey } from '@nuna/tunic'

import { CloseButton } from './CloseButton'

export interface DrawerProps extends BaseDrawerProps {
  isCloseButtonShown?: boolean
}

export function Drawer({ onClose, children, isCloseButtonShown = true, ...props }: DrawerProps) {
  return (
    <BaseDrawer onClose={onClose} anchor="bottom" {...props}>
      {children}
      {isCloseButtonShown && <CloseButton showEscText={false} onClick={onClose} />}
    </BaseDrawer>
  )
}

const Header = styled('div')`
  border-bottom: 1px solid ${borderGrey};
  padding: 1rem 0;

  @media (${BelowTablet}) {
    padding: 1rem;
  }
`
Drawer.Header = Header

export const WidthContainer = styled('div')`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 2rem;
  width: 100%;

  @media (${BelowTablet}) {
    padding: 0 1rem;
  }
`

export const DrawerContent = styled(WidthContainer)`
  overflow: auto;
  padding: 2rem;

  @media (${BelowTablet}) {
    padding: 1.5rem 2rem;
  }
`

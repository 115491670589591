import { styled } from '@mui/material'
import { HTMLMotionProps, motion } from 'framer-motion'
import React from 'react'

interface CollapserProps extends HTMLMotionProps<'div'> {
  isOpen: boolean
  children: React.ReactNode
  duration?: number
}

export function Collapser({ isOpen, children, duration = 0.3, ...props }: CollapserProps) {
  return (
    <Container initial={{ height: 0 }} animate={{ height: isOpen ? 'auto' : 0 }} transition={{ duration }} {...props}>
      {children}
    </Container>
  )
}

const Container = styled(motion.div)`
  overflow: hidden;
`

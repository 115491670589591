import { useFormikContext } from 'formik'
import { isNil } from 'lodash'
import { useCallback, useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom'

import { BasicProviderFragment } from '@nuna/api'
import { routeService } from '@nuna/core'

import { AppLayout } from '../../layouts/AppLayout'
import { AppContainer } from '../../shared/components/Container'
import { SearchProvider } from '../../shared/components/SearchProvider/SearchProvider'
import {
  type PatientContext,
  SearchProviderContextProvider,
  useSearchProviderContext,
} from '../../shared/components/SearchProvider/SearchProviderContext'
import { SwitchProviderHeader } from './SwitchProviderHeader'
import { SwitchProviderScheduleDrawer } from './SwitchProviderScheduleDrawer'

interface Props {
  patient: PatientContext
  oldProvider?: BasicProviderFragment | null
  setHasConfirmedNewProvider: (arg: boolean) => void
}

export function SwitchProviderSearch({ patient, setHasConfirmedNewProvider, oldProvider }: Props) {
  const { providerId } = useParams<{ providerId: string }>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values } = useFormikContext<any>()

  if (!values.oldProviderId && !providerId) {
    return <Navigate to={routeService.switchProvider()} />
  } else if (!values.changeReason) {
    return <Navigate to={routeService.switchProvider(values.oldProviderId || providerId)} />
  }

  if (isNil(oldProvider)) {
    return <Navigate to={routeService.switchProvider()} />
  }

  return (
    <SearchProviderContextProvider oldProvider={oldProvider} patient={patient}>
      <AppLayout disableFooter paddingTop={23} customHeader={<SwitchProviderHeader />}>
        <WithContext setHasConfirmedNewProvider={setHasConfirmedNewProvider} oldProvider={oldProvider} />
      </AppLayout>
    </SearchProviderContextProvider>
  )
}

/** There's a circular dependency with 2 different context providers. We need the ProviderProfileDrawerContext
 * which is initialized by AppLayout in order to open the provider drawer properly and the AppLayout needs a custom
 * header which needs the provider search context. I thought about refactoring AppLayout to make initiating
 * ProviderProfileDrawerContext optional so it can be initiated before AppLayout in this instance, but decided
 * against it for this one-off use case. Instead, the SearchProviderContext has a onProviderCardClick setter which
 * is invoked after all dependencies are initialized.
 */

interface WithContextProps extends Omit<Props, 'patient'> {
  oldProvider: BasicProviderFragment
}
function WithContext({ oldProvider }: WithContextProps) {
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values } = useFormikContext<any>()
  const { appliedFilters, totalFilterCount, setOnProviderCardClick } = useSearchProviderContext()

  const openProfile = useCallback(
    (newProviderId: string) => {
      navigate(routeService.switchProvider(oldProvider.id, newProviderId))
    },
    [navigate, oldProvider.id],
  )

  useEffect(() => setOnProviderCardClick(() => openProfile), [setOnProviderCardClick, openProfile])

  return (
    <>
      <AppContainer>
        <SearchProvider />
      </AppContainer>
      <Routes>
        <Route
          path=":newProviderId"
          element={
            <SwitchProviderScheduleDrawer
              isOpen
              filters={totalFilterCount > 0 ? appliedFilters : undefined}
              oldProvider={oldProvider}
              changeReason={values.changeReason}
              freeFormReason={values.freeFormReason}
            />
          }
        />
      </Routes>
    </>
  )
}

import { AppointmentDrawer } from '@nuna/appointment-drawer'
import { AssessmentBundle } from '@nuna/assessments'
import { useAppointmentDrawerSearchParams } from '@nuna/common'

export function AssessmentBundleLayout() {
  const { openScheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()
  return (
    <>
      <AppointmentDrawer audience="client" />
      <AssessmentBundle resultsOnClick={openScheduleAppointmentDrawer} />
    </>
  )
}

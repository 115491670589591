import { styled } from '@mui/material'
import { useContext, useState } from 'react'

import { ProviderProfileFragment } from '@nuna/api'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { routeService } from '@nuna/core'
import { Avatar, IconButtonLink, IconMail, OutlineButton, greySet, shadowWithHoverTransition } from '@nuna/tunic'

import { ProviderProfileDrawerContext } from './ProviderProfileDrawer'
import { ProviderProfileDrawerTabs } from './ProviderProfileDrawer/ProviderProfileDrawerContext'

interface ProviderForCard extends ProviderProfileFragment {
  type: string
  email: string
}

interface ProviderCardProps {
  scheduleAppointmentDisabled?: boolean
  provider: ProviderForCard
}

export function ProviderCard({ provider, scheduleAppointmentDisabled }: ProviderCardProps) {
  const [isHovered, setIsHovered] = useState(false)
  const {
    providerId: [, setProvider],
    selectedTab: [, setSelectedTabId],
  } = useContext(ProviderProfileDrawerContext)
  const { openScheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()

  return (
    <Card
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        setSelectedTabId(ProviderProfileDrawerTabs.About)
        setProvider(provider.id)
      }}
    >
      <Avatar isHoveredExternal={isHovered} style={{ margin: 'auto' }} url={provider.avatarUrl} size="md" />

      <h3 className="h5 mt-1 mb-1">
        {provider.firstName} {provider.lastName}
      </h3>

      <div className="text-secondary" style={{ fontSize: '1.125rem', textTransform: 'capitalize' }}>
        {provider.type?.toLowerCase()}
      </div>

      <OutlineButton
        disabled={scheduleAppointmentDisabled}
        variant={scheduleAppointmentDisabled ? 'secondary' : 'primary'}
        className="mt-4"
        onClick={e => {
          e.stopPropagation()

          openScheduleAppointmentDrawer(provider.id)
        }}
      >
        Schedule
      </OutlineButton>

      <MessagingPositionedIconButton
        tooltip={`Send a message to ${provider.firstName}`}
        to={routeService.conversationParticipantRoute(provider.loginId)}
      >
        <IconMail />
      </MessagingPositionedIconButton>
    </Card>
  )
}

const Card = styled('div')`
  background-color: #fff;
  border: 1px solid ${greySet[15].hex};
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem;
  position: relative;
  text-align: center;
  align-items: center;

  ${shadowWithHoverTransition(1)}
`

const MessagingPositionedIconButton = styled(IconButtonLink)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`
MessagingPositionedIconButton.defaultProps = { variant: 'secondary' }

import { styled } from '@mui/material'
import { isNil, take } from 'lodash'
import { FormEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import { useProviderLicensesQuery, useSavePatientIntakeMutation } from '@nuna/api'
import { addressService } from '@nuna/core'
import { Avatar, Grid, OutlineButtonWithChevron, StatusLabel, borderGrey, greySet } from '@nuna/tunic'

import { Container } from '../InsuranceIntake'
import { useInsuranceIntakeContext } from '../InsuranceIntakeContext'
import { IntakeNavigation } from '../IntakeNavigation'
import { useIntakeNavigation } from '../hooks/useIntakeNavigation'

const { USStates } = addressService

export function StateMismatch() {
  const navigate = useNavigate()
  const { intakeProgressData, patientContextData, setIsMutationLoading } = useInsuranceIntakeContext()
  const { nextPath } = useIntakeNavigation()

  const provider = patientContextData?.patientContext.patient.providers[0]
  const patient = patientContextData?.patientContext.patient

  const { data: licenseData } = useProviderLicensesQuery({
    variables: { providerId: provider?.id ?? '' },
    skip: isNil(provider?.id),
  })

  const [savePatientIntake] = useSavePatientIntakeMutation({
    refetchQueries: ['PatientContext'], // without this the med management logic gets messed up
  })

  const licenseStates = (licenseData?.provider.credentials ?? []).map(license => license.state)
  const licenseStatesToShow = take(licenseStates, 4)
  const extraLicenseCount = licenseStates.length - 4

  if (!patient || !provider) {
    return null
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setIsMutationLoading(true)
    await savePatientIntake({ variables: { id: patient.id, input: { enforcePatientProviderStateMismatch: true } } })
    setIsMutationLoading(false)
    navigate(`../${nextPath}`)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Grid container spacing={4}>
          <Grid
            size={{
              xs: 12,
              sm: 8,
            }}
          >
            <h2 className="h3">
              Looks like {provider.firstName} is not licensed to perform therapy in{' '}
              {intakeProgressData?.intakeProgress.state ? USStates[intakeProgressData.intakeProgress.state] : ''}.
            </h2>
            <p className="text-secondary large">
              Not to worry. We'll help you find a different therapist that can care for your needs. We just need a
              little more information from you to make a recommendation.
            </p>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 4,
            }}
          >
            <ProviderCard>
              <Avatar style={{ margin: 'auto' }} url={provider.avatarUrl} size="md" />
              <h3 className="h5 mt-1 mb-1">
                {provider.firstName} {provider.lastName}
              </h3>
              <div className="text-secondary" style={{ fontSize: '1.125rem', textTransform: 'capitalize' }}>
                {provider.type?.toLowerCase()}
              </div>
              <div className="text-secondary text-medium mt-3">State Licensure:</div>
              {licenseStatesToShow.length > 0 && (
                <div className="mt-1 v-align">
                  {licenseStatesToShow.map(state => (
                    <StatusLabel className="state-badge" key={state}>
                      {state}
                    </StatusLabel>
                  ))}
                  {extraLicenseCount > 0 && (
                    <span className="caption text-bold state-badge">+ {extraLicenseCount}</span>
                  )}
                </div>
              )}
            </ProviderCard>
          </Grid>
        </Grid>

        <IntakeNavigation
          className="mt-5"
          nextButton={buttonProps => (
            <OutlineButtonWithChevron {...buttonProps} type="submit">
              Next Step: Coverage
            </OutlineButtonWithChevron>
          )}
        />
      </Container>
    </form>
  )
}

const ProviderCard = styled('div')`
  align-items: center;
  background-color: ${greySet[5].hex};
  border: 1px solid ${borderGrey};
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 1.5rem;
  text-align: center;

  .state-badge:not(:last-child) {
    margin-right: var(--spacing-1);
  }
`

import { ChallengesFormValues } from '../intake/steps/Challenges'
import { InsurancePersonalInformationFormValues } from '../intake/steps/InsurancePersonalInformation'
import { MedicationFormValues } from '../intake/steps/Medication/Medication'
import { TherapistPreferencesFormValues } from '../intake/steps/TherapistPreferences/TherapistPreferences'

export enum YesNo {
  Yes = 'Yes',
  No = 'No',
}

export interface IntakeFormValues
  extends InsurancePersonalInformationFormValues,
    MedicationFormValues,
    ChallengesFormValues,
    TherapistPreferencesFormValues {}

export const assessmentRoot = 'assessment'

export const preferencesRoot = 'preferences'

export const IntakePaths = {
  p4Confirmation: 'confirmation',
  emailVerification: `email-verification`,
  assessment1Gad7: `${assessmentRoot}/gad-7`,
  assessment2Phq9: `${assessmentRoot}/phq-9`,
  assessment3Cssrs: `${assessmentRoot}/cssrs`,
  assessmentSummary: `${assessmentRoot}/results`,
  employerAssocation: `information`,
  personalInformation: 'information/personal',
  stateMismatch: 'information/provider-state-mismatch',
  emergencyContact: `information/emergency-contact`,
  medication: `preferences/medication`,
  challenges: `preferences/challenges`,
  therapistPreferences: `preferences/therapist`,
  coverage: `coverage`,
  sponsor: 'coverage/sponsor',
  insurance: 'coverage/insurance',
  cash: 'coverage/cash',
}

export const NONE_OF_ABOVE = 'noneOfAbove'

import { IconProps } from './IconProps'

export function IconMorning({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (13 / 22) * size

  return (
    <svg width={size} height={height} viewBox="0 0 22 13" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M6.19699 4.87195L4.54699 3.23112L3.25449 4.52362L4.89533 6.16445L6.19699 4.87195ZM3.66699 10.0603H0.916992V11.8936H3.66699V10.0603ZM11.917 0.939453H10.0837V3.64362H11.917V0.939453ZM18.7462 4.52362L17.4537 3.23112L15.8128 4.87195L17.1053 6.16445L18.7462 4.52362ZM18.3337 10.0603V11.8936H21.0837V10.0603H18.3337ZM11.0003 5.47695C7.96616 5.47695 5.50033 7.94279 5.50033 10.977C5.50033 11.6196 5.61093 12.2367 5.81412 12.8103C8.25033 12.8103 8.60878 12.8103 11.0003 12.8103C13.3919 12.8103 13.7503 12.8103 16.1865 12.8103C16.3897 12.2367 16.5003 11.6196 16.5003 10.977C16.5003 7.94279 14.0345 5.47695 11.0003 5.47695Z" />
    </svg>
  )
}

import { styled } from '@mui/material'
import { range, some } from 'lodash'

import { BelowTablet } from '@nuna/tunic'

import { useMentalHealthLibraryContext } from '../MentalHealthLibraryContext'
import { usePostListQuery } from '../generated/sanctuary-graphql'
import { getPostCategory } from '../util/getPostCategory'
import { Post, PostSkeleton } from './Post'

export function PostList() {
  const { selectedCategories } = useMentalHealthLibraryContext()
  const { data, error } = usePostListQuery()

  if (error) return <div>Error loading resources</div> // TODO

  const posts = data?.getPosts ?? []

  const filteredPosts = () => {
    if (selectedCategories.size === 0) return posts

    return posts.filter(post => some(post.category, category => selectedCategories.has(getPostCategory(category))))
  }

  return (
    <GridContainer>
      {filteredPosts().length === 0
        ? renderSkeletons()
        : filteredPosts().map(post => <Post key={post.id} post={post} />)}
    </GridContainer>
  )
}

function renderSkeletons() {
  return range(12).map(i => <PostSkeleton key={i} />)
}

const GridContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--margin-4);
  row-gap: var(--margin-3);

  @media (${BelowTablet}) {
    grid-template-columns: 1fr;
  }
`

import { HTMLAttributes } from 'react'

import { FileUploadDisplayProps, GhostButton, SavedFile, csx } from '@nuna/tunic'

type Props = FileUploadDisplayProps &
  HTMLAttributes<HTMLDivElement> & {
    alt: string
    file: File | undefined
    savedFile: SavedFile | undefined
  }

export function InsuranceCardDisplay({ file, savedFile, alt, replaceButtonProps, className, ...props }: Props) {
  const imgSrc = file ? URL.createObjectURL(file) : savedFile?.url

  if (!imgSrc) {
    // this component is guaranteed to either haeve a file or savedFile prop so this should never happen
    return null
  }

  return (
    <div {...props} className={csx([className, 'text-center'])}>
      <img src={imgSrc} alt={alt} />
      <GhostButton type="button" className="mt-1" {...replaceButtonProps}>
        Replace Image
      </GhostButton>
    </div>
  )
}

import { isNil } from 'lodash'
import { HTMLAttributes, useEffect, useState } from 'react'

import { addressService } from '@nuna/core'
import { Skeleton, csx } from '@nuna/tunic'

import { useSearchProviderContext } from './SearchProviderContext'

const { USStates } = addressService

export function FilteredSummary({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  const [showing, setShowing] = useState<number | undefined>()
  const [total, setTotal] = useState<number | undefined>()
  const { patient, resultsLoading, unfilteredProviderCount, visibleProviderCount } = useSearchProviderContext()
  const state = USStates[patient?.state ?? ''] ?? ''

  useEffect(() => {
    if (unfilteredProviderCount) setTotal(unfilteredProviderCount)
  }, [unfilteredProviderCount])

  useEffect(() => {
    if (!isNil(visibleProviderCount)) setShowing(visibleProviderCount)
  }, [visibleProviderCount])

  return (
    <div className={csx(['caption text-secondary text-medium text-left', className])} {...props}>
      {(() => {
        if (isNil(showing) || isNil(total) || resultsLoading) {
          return <Skeleton height={1.1} width={10} />
        }

        if (showing === total) {
          return <>showing all therapists in {state}</>
        }

        return (
          <>
            showing {showing} of {total} therapists in {state}
          </>
        )
      })()}
    </div>
  )
}

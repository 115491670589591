import { styled } from '@mui/material'
import { useEffect, useState } from 'react'

import { AppLayout } from '../../layouts/AppLayout'
import { AppContainer } from '../../shared/components/Container'
import illustration from '../../shared/img/provider-illustration.png'

const headingText = [
  'Checking schedules...',
  'Considering needs...',
  'Matching to therapist specialties...',
  'Finding the best match...',
]

export function SuggestionLoader() {
  const [headingTextIndex, setHeadingTextIndex] = useState(0)

  useEffect(() => {
    if (headingTextIndex < headingText.length - 1) {
      const timer = setTimeout(() => {
        setHeadingTextIndex(headingTextIndex + 1)
      }, 2000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [headingTextIndex])

  return (
    <AppLayout paddingTop={0} verticalCenter>
      <Container>
        <Illustration src={illustration} alt="" />
        <h1 className="mt-5 h4">{headingText[headingTextIndex]}</h1>
      </Container>
    </AppLayout>
  )
}

const Container = styled(AppContainer)`
  text-align: center;
  width: 100%;
  padding-bottom: var(--margin-7);
  padding-top: var(--margin-7);
`

const Illustration = styled('img')`
  max-width: 370px;
  width: 100%;
`

// pulled all these from this spreadsheet https://docs.google.com/spreadsheets/d/1sqA6w6nySZ2qFkQcJPSCCAWBt8uisfb5ygYfOpP1xc4/edit?ts=60539354#gid=0

const licenseTitles: { [acronym: string]: string } = {
  aprn: 'Advanced Practice Registered Nurse',
  cmft: 'Certified Marriage and Family Therapist',
  'csw-pip': 'Certified Social Worker in Private, Independent Practice',
  do: 'Doctor of Osteopathic Medicine',
  fnp: 'Family Nurse Practitioner',
  imft: 'Independent Marriage and Family Therapist',
  lcmhc: 'Licensed Clinical Mental Health Counselor',
  lcpc: 'Licensed Clinical Professional Counselor',
  lcsw: 'Licensed Clinical Social Worker',
  licsw: 'Licensed Independent Clinical Social Worker',
  'lcsw-c': 'Licensed Certified Social Worker-Clinical',
  limhp: 'Licensed Independent Mental Health Practitioner',
  lisw: 'Licensed Independent Social Worker',
  'lisw-cp': 'Licensed Independent Social Worker-Clinical Practice',
  lcmft: 'Licensed Clinical Marriage and Family Therapist',
  lmft: 'Licensed Marriage and Family Therapist',
  lmhc: 'Licensed Mental Health Counselor',
  'lmhp-cpc': 'Licensed Mental Health Practitioner - Certified Professional Counselor',
  'lmsw-c': 'Licensed Master’s Social Worker with Clinical Designation',
  lpc: 'Licensed Professional Counselor',
  lpcc: 'Licensed Professional Clinical Counselor',
  'lpc-mh': 'Licensed Professional Counselor - Mental Health',
  lpcmh: 'Licensed Professional Counselor of Mental Health',
  'lpc/mhsp': 'Licensed Professional Counselor with a Mental Health Services Provider designation',
  lscsw: 'Licensed Specialist Clinical Social Worker',
  md: 'Doctor of Medicine',
  mft: 'Marriage and Family Therapist',
  np: 'Nurse Practitioner',
  pa: 'Physician Assistant',
  phd: 'Doctor of Philosophy',
  psychnp: 'Psychiatric Nurse Practitioner',
  psyd: 'Doctor of Psychology',
}

export function getFullLicenseTitle(acronym: string | null) {
  if (!acronym) return null

  const title = licenseTitles[acronym.toLowerCase()]

  return title ? title : acronym
}

import { styled } from '@mui/material'
import { useMediaQuery } from 'react-responsive'

import { Dialog, DialogProps, IconButton, IconClose } from '@nuna/tunic'

import { FeaturedPostBreakPointMax, useMentalHealthLibraryContext } from '../MentalHealthLibraryContext'

export function PostPlayer(props: Omit<DialogProps, 'isOpen' | 'onClose'>) {
  const { activePost, closePost, language } = useMentalHealthLibraryContext()
  const isMobile = useMediaQuery({ query: `(${FeaturedPostBreakPointMax})` })

  return (
    <Dialog maxWidth="xl" isOpen={!!activePost} onClose={closePost} noChrome {...props}>
      <IconButton
        variant="dark"
        style={{ position: 'fixed', zIndex: 3, top: 32, right: 32 }}
        onClick={closePost}
        tooltip="Close"
      >
        <IconClose />
      </IconButton>
      {(() => {
        if (!activePost) return null

        const postMedia = activePost.media.find(p => p.language === language)

        if (!postMedia) return null

        if (postMedia.__typename === 'AudioMedia') {
          return <audio autoPlay controls src={postMedia.media.url} />
        }

        if (postMedia.__typename === 'VideoMedia') {
          const videoUrl =
            isMobile && postMedia.portraitVideo?.url ? postMedia.portraitVideo.url : postMedia.landscapeVideo.url

          return <Video autoPlay controls src={videoUrl} />
        }

        return null
      })()}
    </Dialog>
  )
}

const Video = styled('video')`
  max-height: calc(100vh - 64px);
`

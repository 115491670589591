import { styled } from '@mui/material'
import { ReactNode, useEffect } from 'react'

import { Phone } from '../../styles/breakpoints'
import { GhostButtonExternalLink } from '../Button'
import { Dialog, DialogContent, DialogProps } from '../Dialog/Dialog'
import illustrationSrc from './img/LaptopGuy.png'

export interface RedirectDialogProps extends DialogProps {
  delay: number | undefined
  redirectUrl: string
  children: ReactNode
  target?: string
}

export function RedirectDialog({
  delay,
  redirectUrl,
  children,
  isOpen,
  onClose,
  target = '_blank',
  ...props
}: RedirectDialogProps) {
  useEffect(() => {
    if (delay && isOpen) {
      const timer = setTimeout(() => {
        window.open(redirectUrl, target)
      }, delay)

      return () => clearTimeout(timer)
    }
  }, [delay, redirectUrl, isOpen, target])

  return (
    <Dialog role="alert" maxWidth="xs" onClose={onClose} isOpen={isOpen} {...props}>
      <DialogContent className="p-3">
        <FlexContainer className="v-align">
          <Illustration src={illustrationSrc} alt="" />

          <div>
            <h2 className="h4">Hang tight.</h2>
            {children}
            <div className="mt-3">
              <GhostButtonExternalLink onClick={() => onClose?.({}, 'backdropClick')} href={redirectUrl}>
                Redirect now
              </GhostButtonExternalLink>
            </div>
          </div>
        </FlexContainer>
      </DialogContent>
    </Dialog>
  )
}

const FlexContainer = styled('div')`
  gap: 1rem;

  @media (${Phone}) {
    flex-direction: column;
    text-align: center;
  }
`

const Illustration = styled('img')`
  width: 100%;
  max-width: 126px;
  margin: 1rem;
`

import { useSearchProviderContext } from '../SearchProviderContext'
import { SpecialtyFilterCategory } from '../search-provider.utils'
import { AccordionTypeProps, FilterAccordion } from './FilterAccordion'
import { SpecialtiesFilterSection } from './SpecialtiesFilterSection'

export function SpecialtiesAccordion(props: AccordionTypeProps) {
  const { specialtyFilterOptions } = useSearchProviderContext()

  const sections = []
  if (specialtyFilterOptions?.sessionTypes?.length !== 0) {
    sections.push(SpecialtiesFilterSection(SpecialtyFilterCategory.SessionTypes))
  }
  if (specialtyFilterOptions?.concerns?.length !== 0) {
    sections.push(SpecialtiesFilterSection(SpecialtyFilterCategory.Concerns))
  }
  if (specialtyFilterOptions?.languages?.length !== 0) {
    sections.push(SpecialtiesFilterSection(SpecialtyFilterCategory.Languages))
  }
  if (specialtyFilterOptions?.modalities?.length !== 0) {
    sections.push(SpecialtiesFilterSection(SpecialtyFilterCategory.Modalities))
  }

  return <FilterAccordion {...props} sections={sections} />
}

import { IconLightning } from '@nuna/tunic'

interface TipProps extends React.HTMLAttributes<HTMLDivElement> {
  description: React.ReactNode
  intro?: React.ReactNode
}

export const Tip = ({ intro, description, className = '', ...props }: TipProps) => (
  <div className={`v-align mt-4 text-secondary ${className}`} {...props}>
    <IconLightning style={{ alignSelf: 'flex-start' }} className="flex-static" />
    <p className="mb-0">
      {intro && <span className="text-medium">{intro} </span>}
      {description}
    </p>
  </div>
)

import { useNavigate } from 'react-router-dom'

import type { LoginData } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { FillButton } from '@nuna/tunic'

export function SsoWelcome({ login }: { login: LoginData }) {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(routeService.intake())
  }

  return (
    <div className="flex-column">
      <h1 className="h3">Welcome, {login.firstName}!</h1>
      <p className="pb-5 paragraphs">
        Thank you for putting in the work for a better you. 92% of Tava clients report improved mental health after just
        4 sessions. We're confident you will, too.
      </p>
      <FillButton onClick={handleClick}>Get Started</FillButton>
    </div>
  )
}

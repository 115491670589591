/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'

import { ProviderType, usePatientContextQuery } from '@nuna/api'
import { Grid } from '@nuna/tunic'

import { ProviderRadioCard } from './ProviderRadioCard'
import { ButtonContainer, Container, ContinueButton, FormHeader, FormHeading, Header } from './switch-provider.utils'

export function SwitchProviderList() {
  const { data, loading } = usePatientContextQuery()
  const [selectedProviderId, setSelectedProviderId] = useState('')

  const unsortedProviders = data?.patientContext?.patient?.providers.slice() ?? []

  const providers =
    unsortedProviders.sort((a, b) => {
      if (a.type === b.type) {
        return 0
      }

      if (a.type === ProviderType.Prescriber) {
        return 1
      }

      if (b.type === ProviderType.Prescriber) {
        return -1
      }

      return 0
    }) ?? []

  const therapists = providers.filter(provider => provider.type === ProviderType.Therapist)

  if (!loading && therapists.length === 1) {
    return <Navigate to={`/switch-provider/${therapists[0].id}`} />
  } else if (!loading && therapists.length === 0) {
    return <Navigate to={`/select-provider`} />
  }

  return (
    <>
      <Header>
        <h1 className="h3">Who would you like to change?</h1>
      </Header>
      <Container>
        <FormHeader>
          <FormHeading>Select a provider to remove</FormHeading>

          <ButtonContainer isShownMobile={!!selectedProviderId}>
            <ContinueButton
              className={selectedProviderId ? '' : ' disabled'}
              to={`/switch-provider/${selectedProviderId}`}
              onClick={(
                e: React.MouseEvent<HTMLAnchorElement, MouseEvent> & React.MouseEvent<HTMLButtonElement, MouseEvent>,
              ) => {
                if (!selectedProviderId) {
                  e.preventDefault()
                }
              }}
            >
              Continue
            </ContinueButton>
          </ButtonContainer>
        </FormHeader>

        <div style={{ maxWidth: '1000px', margin: 'auto' }}>
          <Grid container justifyContent="center" spacing={3}>
            {providers.map(provider => (
              <Grid
                key={provider.id}
                size={{
                  xs: 12,
                  sm: 4,
                  md: 3,
                  lg: 'auto',
                }}
              >
                <ProviderRadioCard
                  disabled={provider.type === ProviderType.Prescriber}
                  // @ts-ignore
                  provider={provider}
                  checked={selectedProviderId === provider.id}
                  onChange={e => {
                    setSelectedProviderId(e.target.value)
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </div>

        <p className="mt-3 text-secondary text-center">
          It's normal to see a few therapists before finding the right fit. In fact, we encourage it.
        </p>
      </Container>
    </>
  )
}

import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'
import { useMediaQuery } from 'react-responsive'

import { IconAudio, IconPlay, greySet, tealSet } from '@nuna/tunic'

import {
  FeaturedPostBreakPointMax,
  FeaturedPostBreakPointMin,
  useMentalHealthLibraryContext,
} from '../MentalHealthLibraryContext'
import { PostFragment, PostMediaType } from '../generated/sanctuary-graphql'
import { ImageOptions, getImageAtSize } from '../util/getImageAtSize'

export interface PostButtonProps extends HTMLAttributes<HTMLButtonElement> {
  post: PostFragment
  imageOptions: ImageOptions
  large?: boolean
}

export function PostButton({ post, imageOptions, large = false, ...props }: PostButtonProps) {
  const { openPost, language } = useMentalHealthLibraryContext()

  const isMobile = useMediaQuery({ query: `(${FeaturedPostBreakPointMax})` })

  const postMedia = post.media.find(p => p.language === language) ?? post.media[0]

  return (
    <ThumbnailButton {...props} onClick={() => openPost(post)} url={getImageAtSize(postMedia.photo.url, imageOptions)}>
      <IconContainer large={large} className="vh-align absolute-center">
        {post.mediaType === PostMediaType.Audio ? (
          <IconAudio color="#fff" size={large && !isMobile ? 37 : 23} />
        ) : (
          <IconPlay style={{ transform: 'translateX(2px)' }} size={large && !isMobile ? 29 : 18} />
        )}
      </IconContainer>
    </ThumbnailButton>
  )
}

const IconContainer = styled('div')<{ large: boolean }>`
  height: 42px;
  width: 42px;
  background-color: ${tealSet[70].transparency(0.75)};
  border-radius: 100%;

  .hovered & {
    background-color: ${tealSet[90].transparency(0.75)};
  }

  @media (${FeaturedPostBreakPointMin}) {
    ${props =>
      props.large &&
      `
        height: 68px;
        width: 68px;
      `};
  }
`

export const ThumbnailButton = styled('button')<{ url?: string }>`
  align-items: center;
  justify-content: center;
  background-color: ${greySet[15].hex};
  ${props => (props.url ? `background-image: url(${props.url});` : '')}
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius-sm);
  display: flex;
  flex-shrink: 0;
  height: 100%;
  width: 154px;
  min-height: 96px;
  position: relative;
  transition: transform 0.3s;

  .hovered & {
    transform: scale(1.05);
  }
`

import * as Yup from 'yup'
import { TextField } from '@mui/material'
import { formatPhoneNumber } from 'react-phone-number-input'

import { Patient, useUpdatePatientProfileMutation } from '@nuna/api'
import { ProfileDisplayValue, ProfileSection } from '@nuna/common'
import { formService } from '@nuna/core'
import { PhoneTextField, phoneUSAValidationRegex } from '@nuna/tunic'

const { oopsRequired } = formService

interface ProfileContactInfoProps {
  isLoading: boolean
  patient?: Pick<Patient, 'mobilePhone' | 'email'>
  heading: string
  description: string
}

export function ProfileContactInfo({ isLoading, patient, description, heading }: ProfileContactInfoProps) {
  const [updatePatientProfile, { loading: updateLoading }] = useUpdatePatientProfileMutation()

  const initialValues: Pick<Patient, 'email' | 'mobilePhone'> = {
    email: patient?.email ?? '',
    mobilePhone: patient?.mobilePhone ?? '',
  }

  return (
    <ProfileSection
      heading={heading}
      isLoading={isLoading}
      updateLoading={updateLoading}
      handleSubmit={values => updatePatientProfile({ variables: values })}
      renderDisplayValue={
        <ProfileDisplayValue>
          {patient?.email}
          <br />
          {formatPhoneNumber(patient?.mobilePhone ?? '')}
        </ProfileDisplayValue>
      }
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email is required').email('Must be a valid email format'),
        mobilePhone: Yup.string()
          .required(oopsRequired('phone number'))
          .matches(phoneUSAValidationRegex, 'Please enter a valid US phone number'),
      })}
      initialValues={initialValues}
      renderForm={({ values, handleChange, handleBlur, touched, errors, setFieldValue }) => (
        <>
          <TextField
            name="email"
            value={values.email}
            label="Email"
            error={(errors.email && touched.email) as boolean}
            helperText={touched.email ? errors.email : null}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
          <PhoneTextField
            name="mobilePhone"
            value={values.mobilePhone}
            label="Mobile Phone"
            error={(errors.mobilePhone && touched.mobilePhone) as boolean}
            helperText={touched.mobilePhone ? errors.mobilePhone : null}
            onChange={value => setFieldValue('mobilePhone', value)}
            onBlur={handleBlur}
            fullWidth
          />
        </>
      )}
      description={description}
    />
  )
}

import { styled } from '@mui/material'
import moment from 'moment'
import { HTMLAttributes, MouseEvent, ReactNode, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import {
  FilteredProviderFragment,
  PaymentPreference,
  useAddFavoriteProviderMutation,
  useRemoveFavoriteProviderMutation,
} from '@nuna/api'
import {
  BelowTablet,
  Card,
  IconClock,
  IconFavorite,
  IconFavoriteOutline,
  IconTherapist,
  Tooltip,
  csx,
  fontSize,
  greySet,
  listFormat,
  shadowDepth,
} from '@nuna/tunic'

import { VideoAskAvatar } from '../../VideoAskAvatar'
import { useSearchProviderContext } from '../SearchProviderContext'

interface Props extends Pick<HTMLAttributes<HTMLButtonElement>, 'onClick'> {
  provider: FilteredProviderFragment
}
export function ProviderResultCard({ provider, ...props }: Props) {
  const [addFavoriteProvider] = useAddFavoriteProviderMutation()
  const [removeFavoriteProvider] = useRemoveFavoriteProviderMutation()
  const { appliedFilters, removeProviderFromResult } = useSearchProviderContext()

  const [isHovered, setIsHovered] = useState(false)

  const [isFavorite, setIsFavorite] = useState(provider.isFavorite)

  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  const therapyType = therapyTypeString(provider.sessionTypes)

  const nextAvailableDate = moment(provider.nextAvailable)

  const takesInsurance = (provider.coverages ?? []).includes(PaymentPreference.Insurance)

  const removeFavorite = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    removeFavoriteProvider({
      variables: {
        providerId: provider.id,
      },
    }).then(res => {
      setIsFavorite(res.data?.removeFavoriteProvider ? false : isFavorite)

      // If we are showing favorites only and a provider was removed from favorites, remove
      // them from the result list
      if (appliedFilters.favoritesOnly) {
        removeProviderFromResult(provider.id)
      }
    })
  }

  const addFavorite = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    addFavoriteProvider({
      variables: {
        providerId: provider.id,
      },
    }).then(res => {
      setIsFavorite(res.data?.addFavoriteProvider ? true : isFavorite)
    })
  }

  return (
    <StyledButton {...props}>
      <StyledCard
        className="text-default"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className="mr-1 avatar-container">
          <VideoAskAvatar
            size={isMobile ? 'sm' : 'md'}
            showGif={isHovered}
            profileUrl={provider.avatarUrl || ''}
            responses={provider.videoAskResponses || []}
            providerId={provider.id ?? ''}
            onClick={e => e.stopPropagation()}
          />
        </span>
        <span className="text-left flex-remaining-space">
          <div className="flex flex-row">
            <h4 className="mb-1 name flex-remaining-space">
              {provider.firstName} {provider.lastName}
            </h4>
          </div>

          {therapyType && <div className="mb-2">{therapyType}</div>}
          {takesInsurance && (
            <InfoLine icon={<IconTherapist size={22} />} className="mb-1">
              Accepts your insurance
            </InfoLine>
          )}
          {nextAvailableDate.isValid() && (
            <InfoLine icon={<IconClock size={22} />}>Next opening: {formatNextAvailable(nextAvailableDate)}</InfoLine>
          )}
        </span>

        <Tooltip content={isFavorite ? 'Remove favorite' : 'Favorite'}>
          <StyledFavoriteIcon>
            {(isMobile || isHovered) && !isFavorite && (
              <IconFavoriteOutline role="button" size={36} onClick={addFavorite} />
            )}
            {isFavorite && <IconFavorite role="button" size={36} onClick={removeFavorite} />}
          </StyledFavoriteIcon>
        </Tooltip>
      </StyledCard>
    </StyledButton>
  )
}

function formatNextAvailable(date: moment.Moment) {
  return date.calendar(null, {
    sameDay: '[Today at] h:mma',
    nextDay: '[Tomorrow at] h:mma',
    nextWeek: 'MMM Do [at] h:mma',
    sameElse: 'MMM Do [at] h:mma',
  })
}

const StyledButton = styled('button')`
  width: 100%;
`

const StyledCard = styled(Card)`
  align-items: center;
  padding: 10px 15px 7px 15px;
  display: flex;
  width: 100%;
  position: relative;
  transition: transform 0.3s;

  @media (${BelowTablet}) {
    padding: 24px 5px 10px 5px;
    align-items: flex-start;
    .avatar-container {
      margin-left: 0;
    }
    .name {
      font-size: ${fontSize.h5};
      margin-bottom: 5px;
    }
  }

  &:hover {
    box-shadow: ${shadowDepth(1.5)};
    transform: translateY(-6px);
  }

  &.focus-visible {
    outline: none;

    &::after {
      border-radius: var(--border-radius);
      box-shadow: 0 0 0 6px ${greySet.tint[40]};
      content: '';
      display: block;
      height: calc(100% + 2px);
      left: -1px;
      position: absolute;
      top: -1px;
      width: calc(100% + 2px);
    }
  }
`

const StyledInfoLine = styled('div')`
  .icon-container {
    color: ${greySet[70].hex};
    height: 18.2px;
    display: flex;
    align-items: center;
    position: relative;
    > svg {
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .content {
    line-height: 18.2px;
    margin-left: 20px;
  }

  @media (${BelowTablet}) {
    .icon-container {
      /* margin-right: 5px; */
    }
    &.mb-1 {
      margin-bottom: 10px;
    }
  }
`

// Needed to center icons and add dedicated dimensions since the selected icon is
// slightly bigger than the non-selected one. Without it being centered it makes the icon jump
const StyledFavoriteIcon = styled('span')`
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 40px;
  margin-top: 10px;
  margin-left: 1em;
`

interface InfoLineProps extends HTMLAttributes<HTMLDivElement> {
  icon: ReactNode
}
function InfoLine({ icon, className, children, ...props }: InfoLineProps) {
  return (
    <StyledInfoLine {...props} className={csx([className, 'top-align text-medium caption'])}>
      <span className="icon-container h-align mr-1">{icon}</span>
      <span className="content">{children}</span>
    </StyledInfoLine>
  )
}

function therapyTypeString(types?: string[] | null) {
  if (!types) {
    return null
  }

  const cleansed = types.filter(t => t !== 'Coaching').map(t => t.replace(' Therapy', ''))

  return listFormat(cleansed, { useOxfordComma: true, useAmpersand: true }) + ' Therapy'
}

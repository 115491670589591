import { IconProps } from './IconProps'

export function IconGoalPath({ size = 16, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.875 10.4454L6.23442 5.48239V0H7.00281H7.08815H10.6659L9.44344 1.39966L10.6659 2.68504H7.08815V5.48239L9.22992 7.69957L9.22896 7.7005L12.0156 10.591L12.5232 10.0333L11.9833 9.47875L10.5173 7.97282L11.6016 6.81707L15.2083 10.4454C13.6261 12.6498 11.0441 14.3333 8.12748 14.3333C5.10289 14.3333 2.4381 12.7895 0.875 10.4454Z"
        fill={color}
      />
    </svg>
  )
}

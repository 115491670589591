import { styled } from '@mui/material'
import { isNil } from 'lodash'
import React, { CSSProperties } from 'react'

import { usePatientContextQuery } from '@nuna/api'
import { AppointmentDrawer } from '@nuna/appointment-drawer'
import { eggshell } from '@nuna/tunic'

import { PatientNoCurrentSessionModal } from '../../scenes/home/PatientNoCurrentSessionModal'
import { SessionLimitsModal, SessionLimitsModalContextProvider } from '../../scenes/home/SessionLimitsModal'
import { InternationalFooter } from '../../scenes/signup/international/InternationalFooter'
import { ClientFooter } from '../../shared/components/ClientFooter'
import { AppLayoutTopPadding } from '../../shared/components/Container'
import {
  ProviderProfileDrawer,
  ProviderProfileDrawerContextProvider,
} from '../../shared/components/ProviderProfileDrawer'
import { Navbar } from './Navbar'

interface AppLayoutInterface {
  children: React.ReactNode
  customHeader?: React.ReactNode
  disableFooter?: boolean
  verticalCenter?: boolean
  style?: CSSProperties
  className?: string
  paddingTop?: number | string
}

export function AppLayout({
  children,
  customHeader,
  disableFooter = false,
  verticalCenter = false,
  style = {},
  className = '',
  paddingTop,
}: AppLayoutInterface) {
  const { data } = usePatientContextQuery()

  if (!data) {
    return null
  }

  return (
    <SessionLimitsModalContextProvider>
      <ProviderProfileDrawerContextProvider>
        <EggshellBackground className={className} style={style}>
          {customHeader ? customHeader : <Navbar />}

          <Content verticalCenter={verticalCenter} paddingTop={paddingTop}>
            {children}
          </Content>

          {!disableFooter && <ClientFooter />}
        </EggshellBackground>

        <SessionLimitsModal />
        <PatientNoCurrentSessionModal />
        <ProviderProfileDrawer />
        <AppointmentDrawer audience="client" />
      </ProviderProfileDrawerContextProvider>
    </SessionLimitsModalContextProvider>
  )
}

export function InternationalAppLayout({
  children,
  showTOS = false,
  paddingTop,
  verticalCenter = false,
}: AppLayoutInterface & { showTOS?: boolean }) {
  return (
    <EggshellBackground>
      <Navbar disableMenu />

      <Content verticalCenter={verticalCenter} paddingTop={paddingTop}>
        {children}
      </Content>

      <InternationalFooter showTOS={showTOS} />
    </EggshellBackground>
  )
}

const EggshellBackground = styled('div')`
  background-color: ${eggshell};
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Content = styled('div')<Pick<AppLayoutInterface, 'paddingTop' | 'verticalCenter'>>`
  height: 100%;
  flex-grow: 1;
  padding-top: ${({ paddingTop }) => {
    if (isNil(paddingTop)) {
      return AppLayoutTopPadding
    }
    return typeof paddingTop === 'string' ? paddingTop : `${paddingTop}px`
  }};
  margin: auto;
  width: 100%;

  ${({ verticalCenter = false }) =>
    verticalCenter &&
    `
  display: flex;
  align-items: center;
  justify-content: center;
  `}
`

import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { IconSearch, TextField, csx } from '@nuna/tunic'

import { useSearchProviderContext } from '../SearchProviderContext'

export function FilterSearchField({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  const { appliedFilters, setSearchText } = useSearchProviderContext()
  return (
    <Container {...props} className={csx([className, 'v-align'])}>
      <TextField
        className="search-field full-width"
        renderIcon={color => <IconSearch color={color} />}
        value={appliedFilters.search}
        onChange={e => setSearchText(e.currentTarget.value)}
        placeholder="Name search"
      />
    </Container>
  )
}

const Container = styled('div')`
  .search-field {
    .input-container {
      padding-top: 0;
      input {
        padding-bottom: 4px;
      }
    }
    .icon-container {
      padding-top: 3px;
    }
  }
`

import { useSearchParams } from 'react-router-dom'

import { usePatientContextQuery } from '@nuna/api'
import { MODAL_LATE_CANCELATION_POLICY } from '@nuna/core'

import { LateCancelationPolicyModal } from './LateCancelationPolicyModal'

export const LateCancelationPolicyModalLauncher = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const providerId = searchParams.get(MODAL_LATE_CANCELATION_POLICY)

  const { data: patientData } = usePatientContextQuery()

  const { patient } = patientData?.patientContext || {}
  const coverageType = patient?.paymentPreference

  const provider = patient?.providers?.find(({ id }) => id === providerId)

  const handleCloseModal = () => {
    setSearchParams(oldParams => {
      oldParams.delete(MODAL_LATE_CANCELATION_POLICY)
      return oldParams
    })
  }

  return (
    <LateCancelationPolicyModal
      coverageType={coverageType}
      provider={provider}
      isOpen={!!provider && !!patient}
      onClose={handleCloseModal}
    />
  )
}

import { useNavigate } from 'react-router-dom'

import { AssessmentBundleQuery, AssessmentBundleType, AssessmentStatus, AssessmentType } from '@nuna/api'

interface UseAssessmentRoutingProps {
  assessmentBundle: AssessmentBundleQuery['assessmentBundle'] | undefined
  devDebugMode?: boolean // Allows testing without automatic navigation, displays the route in the console instead.
}

export function useAssessmentRouting({ assessmentBundle, devDebugMode = false }: UseAssessmentRoutingProps) {
  const navigate = useNavigate()

  const totalAssessments = assessmentBundle?.assessments.length || 0
  const completedAssessments =
    assessmentBundle?.assessments.filter(a => a.status === AssessmentStatus.Completed).length || 0

  const navigateBack = () => {
    const assessments = assessmentBundle?.assessments ?? []
    const currentAssessment = assessments.find(a => window.location.pathname.includes(a.id))
    const previousId = currentAssessment ? assessments[assessments.indexOf(currentAssessment) - 1]?.id : null
    const hasBackInParams = window.location.search.includes('back')
    const isResultsPage = assessments.every(a => a.status === AssessmentStatus.Completed) && !hasBackInParams
    if (previousId && assessmentBundle) {
      navigate(`/assessments/${assessmentBundle.id}/${previousId}?back`)
    } else if (isResultsPage) {
      const lastAssessmentId = assessments[assessments.length - 1].id
      navigate(`/assessments/${assessmentBundle?.id}/${lastAssessmentId}?back`)
    } else {
      navigate('/assessments')
    }
  }

  const navigateWithinAssessmentBundle = (
    assessmentBundle?: AssessmentBundleQuery['assessmentBundle'] | null,
    ignoreBack = true,
  ) => {
    if (!assessmentBundle) return
    if (window.location.search.includes('back') && !ignoreBack) return

    let targetRoute = '/assessments'

    if (assessmentBundle.type === AssessmentBundleType.MentalHealthAssessment) {
      const gad7 = assessmentBundle.assessments.find(a => a.type === AssessmentType.Gad_7)
      const phq9 = assessmentBundle.assessments.find(a => a.type === AssessmentType.Phq_9)
      const cssRs = assessmentBundle.assessments.find(a => a.type === AssessmentType.CSsrs)

      if (!isCompletedIfItExists(gad7)) {
        targetRoute = `/assessments/${assessmentBundle.id}/${gad7?.id}`
      } else if (!isCompletedIfItExists(phq9)) {
        targetRoute = `/assessments/${assessmentBundle.id}/${phq9?.id}`
      } else if (!isCompletedIfItExists(cssRs)) {
        targetRoute = `/assessments/${assessmentBundle.id}/${cssRs?.id}`
      } else {
        targetRoute = `/assessments/${assessmentBundle.id}/results`
      }
    }

    // Don't create a navigation loop!
    if (targetRoute !== window.location.pathname) {
      if (devDebugMode) {
        // eslint-disable-next-line no-console
        console.debug('devDebugMode enabled. Would have navigated to:', targetRoute)
      } else {
        navigate(targetRoute, { replace: true })
      }
    }
  }

  return { navigateWithinAssessmentBundle, totalAssessments, completedAssessments, navigateBack }
}

const isCompletedIfItExists = (assessment: { status: AssessmentStatus } | null | undefined) =>
  !assessment || assessment.status === AssessmentStatus.Completed

/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import { noop } from 'lodash'
import { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import {
  SwitchProviderChangeReason,
  useBasicProviderQuery,
  usePatientContextQuery,
  useProviderSinceDateQuery,
} from '@nuna/api'
import { supportService } from '@nuna/telemetry'
import {
  Avatar,
  BelowTablet,
  FillButtonLink,
  GhostButton,
  GhostButtonLink,
  TabletPortraitMin,
  borderGrey,
  shadowDepth,
} from '@nuna/tunic'

import { AppLayout } from '../../layouts/AppLayout'
import { AppContainer, AppLayoutTopPadding } from '../../shared/components/Container'
import { useIntersect } from '../../shared/hooks/useIntersect'
import providerIllustration from '../../shared/img/provider-illustration.png'
import { SwitchProviderList } from './SwitchProviderList'
import { SwitchProviderReason } from './SwitchProviderReason'
import { SwitchProviderSearch } from './SwitchProviderSearch'
import { handleNevermindClick } from './switch-provider.utils'

export function handleGetAdviceClick() {
  supportService.openChat()
}

const SwitchProviderSchema = Yup.object().shape({
  oldProviderId: Yup.string().required(),
  changeReason: Yup.string().required(),
  freeFormReason: Yup.string().when('changeReason', {
    is: SwitchProviderChangeReason.SomethingElse,
    then: Yup.string().required('Oops, please enter your own reason'),
  }),
})

export function SwitchProvider() {
  const { providerId = '' } = useParams()

  const { data: patientContextData, loading: patientContextLoading } = usePatientContextQuery()

  const { data } = useBasicProviderQuery({ skip: !providerId, variables: { id: providerId } })
  const [hasConfirmedNewProvider, setHasConfirmedNewProvider] = useState(false)

  const patient = patientContextData?.patientContext.patient
  const signUpDate = patient?.createdAt
  const state = patient?.state

  const { data: providerCountData } = useProviderSinceDateQuery({
    skip: !signUpDate || !state,
    variables: { date: signUpDate, state: state ?? null },
  })

  const [mobileCancelButtonRef, entry] = useIntersect({ rootMargin: '0px', threshold: 1 })
  const [isMobileCancelButtonSticky, setIsMobileCancelButtonSticky] = useState(false)

  useEffect(() => {
    if (entry && entry.intersectionRatio < 1) {
      setIsMobileCancelButtonSticky(true)
    } else {
      setIsMobileCancelButtonSticky(false)
    }
  }, [entry])

  const newProviderCount = providerCountData?.providersSinceDate.length ?? 0
  const provider = data?.provider

  const currentProviders = patientContextData?.patientContext.patient.providers ?? null

  if (
    currentProviders &&
    providerId &&
    !hasConfirmedNewProvider &&
    !currentProviders.find(provider => provider.id === providerId)
  ) {
    // if providerId in url is not in the user's providers, redirect
    return <Navigate to="/switch-provider" />
  }

  if (patientContextLoading || !patient) {
    return (
      <AppLayout disableFooter>
        <AppContainer>
          <LoadingSkeleton />
        </AppContainer>
      </AppLayout>
    )
  }

  return (
    <Formik
      validationSchema={SwitchProviderSchema}
      initialValues={{
        oldProviderId: '',
        newProviderId: '',
        changeReason: '',
        start: null,
        end: null,
        freeFormReason: '',
      }}
      onSubmit={noop} // submission is handled from the drawer
    >
      <Routes>
        <Route
          path="*"
          element={
            <AppLayout disableFooter>
              <AppContainer>
                <Main>
                  {provider && (
                    // @ts-ignore
                    <MobileCancelButton isSticky={isMobileCancelButtonSticky} ref={mobileCancelButtonRef}>
                      <Routes>
                        <Route
                          path=":providerId/select"
                          element={
                            <>
                              <GhostButton onClick={handleGetAdviceClick}>Get Advice</GhostButton>
                              <Divider />
                            </>
                          }
                        />
                      </Routes>
                      <GhostButtonLink to="/" onClick={() => handleNevermindClick(provider?.firstName)}>
                        <Avatar url={provider.avatarUrl} size="mini" className="mr-1" /> Stick with{' '}
                        {provider?.firstName}
                      </GhostButtonLink>
                    </MobileCancelButton>
                  )}
                  <Routes>
                    <Route
                      index
                      element={
                        <>
                          <SwitchProviderList />
                          <IllustrationSection newProviderCount={newProviderCount} />
                        </>
                      }
                    />
                    <Route path=":providerId" element={<SwitchProviderReason oldProvider={provider} />} />
                  </Routes>
                </Main>
              </AppContainer>
            </AppLayout>
          }
        />

        <Route
          path=":providerId/select/*"
          element={
            <SwitchProviderSearch
              patient={patient}
              oldProvider={provider}
              setHasConfirmedNewProvider={setHasConfirmedNewProvider}
            />
          }
        />
      </Routes>
    </Formik>
  )
}

function IllustrationSection({ newProviderCount }: { newProviderCount: number }) {
  return (
    <section className="mt-7 text-center">
      <h2 className="h6">We're continually adding providers to meet your needs</h2>
      {newProviderCount > 0 && (
        <p className="italic">
          There are <em className="text-bold">{newProviderCount} new</em> since you signed up.
        </p>
      )}
      <Illustration className="mt-4" src={providerIllustration} alt="" />
    </section>
  )
}

const Illustration = styled('img')`
  max-width: 370px;
  width: 100%;
`

export const Main = styled('main')``

export const Container = styled('section')`
  background-color: #fff;
  border-radius: var(--border-radius);
  border: 1px solid ${borderGrey};
  padding: 1.5rem;
  overflow: hidden;
`

export const Header = styled('header')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  h1 {
    @media (${BelowTablet}) {
      font-size: 2rem;
    }
  }
`
Header.defaultProps = { className: 'mb-4' }

export const ContinueButton = styled(FillButtonLink)`
  margin-left: auto;

  @media (${BelowTablet}) {
    width: 100%;
    height: 62px;
    font-size: 1.25rem;
    box-shadow: ${shadowDepth(8)};
  }
`

export const BackButton = styled(GhostButton)`
  @media (${BelowTablet}) {
    height: 62px;
    font-size: 1.25rem;
    width: 62px;
    box-shadow: ${shadowDepth(8)};
  }
`

export const ButtonContainer = styled('div')<{ isShownMobile: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @media (${BelowTablet}) {
    bottom: 21px; // random non-rem number to align with intercom
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    left: 1.26rem;
    top: auto;
    transform: translateY(${props => (props.isShownMobile ? 0 : 'calc(100% + 21px + .5rem)')});
    transition: transform 0.3s;
    width: calc(100% - (2 * 1.26rem) - 68px);
    z-index: 10;
  }
`

export const FormHeading = styled('h2')`
  @media (${TabletPortraitMin}) {
    line-height: 44px; // keeps it aligned with buttons
  }
`
FormHeading.defaultProps = { className: 'h6 mb-2' }

const MobileCancelButton = styled('div')<{ isSticky: boolean }>`
  display: none;

  @media (${BelowTablet}) {
    align-items: center;
    border-bottom: 1px solid ${borderGrey};
    display: flex;
    font-size: 1.125rem;
    font-weight: 600;
    justify-content: center;
    margin: -${AppLayoutTopPadding} -1.26rem 2rem;
    padding: 0.75rem 1rem;
    position: sticky;
    top: -1px;
    transition: background-color 0.3s, box-shadow 0.3s;
    z-index: 10;

    ${props =>
      props.isSticky &&
      `
      background-color: rgba(255, 255, 255, 0.98);
      box-shadow: ${shadowDepth(1.5)};
    `}
  }
`

export const Divider = styled('span')`
  display: inline-block;
  height: 1.5rem;
  width: 0;
  border-right: 1px solid ${borderGrey};
  margin-left: 1rem;
  margin-right: 1rem;
`

export const FormHeader = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  position: relative;

  @media (${BelowTablet}) {
    margin-bottom: 0;
  }
`

function LoadingSkeleton() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="loading mb-4" style={{ width: '100%', height: '70px' }}></div>
      <div className="loading mb-7" style={{ width: '100%', height: '270px' }}></div>
      <div className="loading" style={{ width: '370px', height: '280px' }}></div>
    </div>
  )
}

import * as Yup from 'yup'
import { Formik } from 'formik'
import { useState } from 'react'

import { PaymentPreference, useSavePatientIntakeMutation } from '@nuna/api'
import { coverageService } from '@nuna/core'
import {
  InsuranceCoverageForm,
  InsuranceCoverageFormValues,
  buildInsuranceCoverageInput,
  insuranceCoverageSchema,
  insuranceInitialValues,
  useSaveInsuranceCoverage,
} from '@nuna/coverage'
import { FillButton, GhostButton, GhostButtonLink, OutlineButtonLink, toast } from '@nuna/tunic'

import { useInsuranceIntakeContext } from '../../../../InsuranceIntakeContext'
import { IntakeNavigation } from '../../../../IntakeNavigation'
import { useIntakeCoverageStatus } from '../../../../hooks/useIntakeCoverageStatus'
import { useIntakeNavigation } from '../../../../hooks/useIntakeNavigation'
import { CoverageResult, CoverageResultIllustration } from '../../../CoverageResults/CoverageResult'
import { InvalidInsuranceNextSteps } from '../../../CoverageResults/InvalidInsuranceNextSteps/InvalidInsuranceNextSteps'
import scribbleSrc from '../../../CoverageResults/img/scribble.png'
import { CoverageNextButton } from '../CoverageNextButton'
import { IntakeCoverageStatus } from '../IntakeCoverageStatus'
import { ECheckLoadingDialog } from './components/ECheckLoadingDialog'

const schema = Yup.object().shape({
  insuranceValues: insuranceCoverageSchema,
})

export function IntakeInsuranceForm() {
  const { patientContextData, setIsMutationLoading, refetchIntakeProgress } = useInsuranceIntakeContext()
  const { nextPath } = useIntakeNavigation()
  const { coverages, refetch, needsAdditionalZeroCapCoverage, hasUploadedInsuranceCard } = useIntakeCoverageStatus()
  const [isEditing, setIsEditing] = useState(false)
  const [saveInsuranceCoverage, { loading: isVerifyingInsurance }] = useSaveInsuranceCoverage()
  const [savePatientIntake] = useSavePatientIntakeMutation()

  if (!patientContextData) return null

  const coverage = coverages[PaymentPreference.Insurance]
  const patient = patientContextData.patientContext.patient

  const handleSubmit = async (values: { insuranceValues: InsuranceCoverageFormValues }) => {
    setIsMutationLoading(true)
    const response = await saveInsuranceCoverage({
      variables: {
        input: buildInsuranceCoverageInput(values, patientContextData.patientContext.patient),
      },
    })

    if (!response.didSucceed) {
      toast.urgent(response.error?.message)
      setIsMutationLoading(false)

      // need to refetch intake to get policy id even if it's invalid in case we need to upload insurance card images
      refetchIntakeProgress()
      setIsEditing(false)

      return
    }

    await savePatientIntake({
      variables: { id: patient.id, input: { paymentPreference: PaymentPreference.Insurance } },
    })

    refetchIntakeProgress()
    refetch()
    setIsEditing(false)
    setIsMutationLoading(false)
  }

  return (
    <>
      {(() => {
        if (coverage && !isEditing) {
          const isInsuranceValid = coverageService.isInsuranceValid(coverage.details)

          return (
            <>
              <CoverageResult
                successText="We've verified your policy"
                errorText="Looks like we can't verify your insurance"
                illustration={
                  isInsuranceValid ? undefined : (
                    <CoverageResultIllustration src={scribbleSrc} alt="" style={{ maxWidth: 130 }} />
                  )
                }
                status={isInsuranceValid ? 'success' : 'invalid'}
                bottomSection={
                  !isInsuranceValid ? (
                    <InvalidInsuranceNextSteps onEditClick={() => setIsEditing(true)} className="mt-3" />
                  ) : undefined
                }
              >
                <IntakeCoverageStatus coverage={coverage} onEditClick={() => setIsEditing(true)} />
              </CoverageResult>

              {!isInsuranceValid && !needsAdditionalZeroCapCoverage && (
                <GhostButtonLink className="mt-5" to={`../../${nextPath}`}>
                  Skip adding insurance
                </GhostButtonLink>
              )}

              <CoverageNextButton
                disabled={needsAdditionalZeroCapCoverage && !hasUploadedInsuranceCard}
                nextPath={nextPath}
              />
            </>
          )
        }

        return (
          <Formik
            initialValues={{ insuranceValues: insuranceInitialValues }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <h2 className="h4 mb-2">Insurance setup</h2>
                <p className="mb-4 text-secondary paragraphs">
                  {needsAdditionalZeroCapCoverage
                    ? `${
                        coverages[PaymentPreference.Employer]?.details.employerName
                      } uses insurance to cover sessions. Please add your insurance to continue.`
                    : `Tava accepts most insurance carriers in the US, except Medicaid and Medicare. Enter yours to check if you’re
                  covered.`}
                </p>
                <InsuranceCoverageForm
                  audience="client"
                  inDrawer={false}
                  state={patient.state ?? ''}
                  patient={patient}
                />

                {isEditing && (
                  <GhostButton variant="secondary" className="mt-3" onClick={() => setIsEditing(false)}>
                    Cancel
                  </GhostButton>
                )}

                {!coverage && !needsAdditionalZeroCapCoverage && (
                  <GhostButtonLink data-testid="intake-insurance-skip" className="mt-5" to={`../../${nextPath}`}>
                    My insurance isn't listed
                  </GhostButtonLink>
                )}

                <IntakeNavigation
                  className="mt-4"
                  nextButton={props => (
                    <>
                      <FillButton type="submit" {...props}>
                        Verify insurance
                      </FillButton>
                      <OutlineButtonLink to={`../../${nextPath}`} className="ml-2">
                        Skip
                      </OutlineButtonLink>
                    </>
                  )}
                />
              </form>
            )}
          </Formik>
        )
      })()}
      <ECheckLoadingDialog isLoading={isVerifyingInsurance} />
    </>
  )
}

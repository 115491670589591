import React from 'react'

import { HealthAdvocateSsoFinishContent, HealthAdvocateSsoWelcomeContent } from './HealthAdvocateSsoContent'

export type SsoOrgCustomization = {
  WelcomeContent: React.FC<{ supportPhoneNumber?: string }>
  FinishSetupContent: React.FC<{ numSessions?: string; supportPhoneNumber?: string }>
}

export const SsoOrgCustomizations: Record<string, SsoOrgCustomization> = {
  'health-advocate': {
    WelcomeContent: HealthAdvocateSsoWelcomeContent,
    FinishSetupContent: HealthAdvocateSsoFinishContent,
  },
  'health-advocate-staging': {
    WelcomeContent: HealthAdvocateSsoWelcomeContent,
    FinishSetupContent: HealthAdvocateSsoFinishContent,
  },
}

export const DefaultSsoCustomizations = SsoOrgCustomizations['health-advocate']

export const getCustomizationsForSsoOrg = (orgSlug: string) => {
  return SsoOrgCustomizations[orgSlug] ?? DefaultSsoCustomizations
}

import { styled } from '@mui/material'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import {
  useAppointmentForVideoQuery,
  usePatientContextForVideoQuery,
  useProviderForVideoQuery,
  useVideoTokenInfoQuery,
} from '@nuna/api'
import { routeService } from '@nuna/core'
import { DailyVideoScene, TavaVideoUser } from '@nuna/sundance'
import { supportService } from '@nuna/telemetry'
import { tealSet, toast } from '@nuna/tunic'

import { environment } from '../../environments/environment'

export function PatientVideo() {
  const navigate = useNavigate()
  const { appointmentId = '' } = useParams()
  const { data: videoData, error: videoDataError } = useVideoTokenInfoQuery({
    variables: { appointmentId, useDaily: true },
    fetchPolicy: 'network-only',
  })
  const { data: patientData } = usePatientContextForVideoQuery()
  const { data: appointmentData } = useAppointmentForVideoQuery({ variables: { appointmentId } })

  const providerId = videoData?.videoInfoForAppointment.providerId ?? ''
  const patientId = videoData?.videoInfoForAppointment.patientId ?? ''
  const patientAutonotesEnabled = videoData?.videoInfoForAppointment.patientAutonotesEnabled ?? true
  const providerAutonotesEnabled = videoData?.videoInfoForAppointment.providerAutonotesEnabled ?? false

  const { data: providerData } = useProviderForVideoQuery({
    variables: {
      id: providerId,
    },
    skip: !providerId,
  })

  useEffect(() => {
    if (videoDataError && navigate) {
      console.error('Error fetching video data', videoDataError)
      toast.urgent(
        'There was an error connecting to the video call. Try loading again from your appointment list on the home screen.',
        {
          action: { onClick: () => navigate('/'), buttonText: 'Go to home screen' },
        },
      )
    }
  }, [videoDataError, navigate])

  const localUser: TavaVideoUser | undefined = useMemo(() => {
    if (!patientData) return undefined

    return {
      ...patientData.patientContext.patient,
      loginId: patientData.patientContext.patient.login.id,
      isProvider: false,
      patientId,
      initialAutonotesEnabled: patientAutonotesEnabled,
    }
  }, [patientData, patientId, patientAutonotesEnabled])

  const expectedUsers: TavaVideoUser[] = useMemo(() => {
    if (!providerData) return []

    return [
      {
        ...providerData.provider,
        isProvider: true,
        providerId,
        initialAutonotesEnabled: providerAutonotesEnabled,
      },
    ]
  }, [providerData, providerId, providerAutonotesEnabled])

  if (videoDataError) {
    return null
  }

  const endTime = appointmentData?.internalAppointment?.endDatetime
  if (environment.production && endTime && moment(endTime).add(1, 'hour').isBefore(moment())) {
    return <Navigate to={routeService.videoSession()} />
  }

  if (!videoData || !patientData || !providerData || !localUser) return <Background />

  const chatToken = videoData.videoInfoForAppointment.chatToken
  const roomUrl = videoData.videoInfoForAppointment.roomUrl

  if (!roomUrl) {
    // TODO: We can make roomUrl non-nullable in the backend now that we don't have to support Twilio then we can get rid of this check
    toast.urgent(
      `Video call is not available at this time. Please contact ${supportService.supportEmails.clientSupport}.`,
    )
    console.error('Did not receive roomUrl for Daily call')
    return null
  }

  return (
    <DailyVideoScene
      appointmentId={appointmentId}
      chatToken={chatToken}
      expectedUsers={expectedUsers}
      localUser={localUser}
      roomUrl={roomUrl}
    />
  )
}

const Background = styled('div')`
  background-color: ${tealSet[90].hex};
  width: 100vw;
  height: 100vh;
`

import { IconProps } from './IconProps'

export function IconEvening({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (22 / 25) * size

  return (
    <svg width={size} height={height} viewBox="0 0 25 22" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M19.3172 18.9241C20.0026 18.3169 19.2867 17.3042 18.3711 17.3042C14.1627 17.3042 10.7511 13.9205 10.7511 9.74657C10.7511 8.4906 11.06 7.30619 11.6066 6.26399C12.0426 5.43263 11.4765 4.2908 10.5967 4.6183C7.34808 5.82763 5.03613 8.93652 5.03613 12.581C5.03613 17.2767 8.87419 21.0833 13.6087 21.0833C15.8011 21.0833 17.8013 20.267 19.3172 18.9241Z" />
      <path d="M21.0674 6.08325L21.8968 8.25382L24.0674 9.08325L21.8968 9.91269L21.0674 12.0833L20.2379 9.91269L18.0674 9.08325L20.2379 8.25382L21.0674 6.08325Z" />
      <path d="M15.5361 0.083252L15.9618 1.65757L17.5361 2.08325L15.9618 2.50893L15.5361 4.08325L15.1105 2.50893L13.5361 2.08325L15.1105 1.65757L15.5361 0.083252Z" />
      <path d="M2.43164 4.08325L2.7509 5.26399L3.93164 5.58325L2.7509 5.90251L2.43164 7.08325L2.11238 5.90251L0.931641 5.58325L2.11238 5.26399L2.43164 4.08325Z" />
    </svg>
  )
}

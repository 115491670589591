import * as Yup from 'yup'
import { Formik } from 'formik'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import {
  Gender,
  IntakeProgressQuery,
  PatientIntakeEmergencyContactInput,
  PatientIntakeInput,
  useSaveEmergencyContactMutation,
  useSavePatientIntakeMutation,
} from '@nuna/api'
import { usePatientId } from '@nuna/auth'
import { AddressForm, UsAddressValues, addressValidationSchemaChecks } from '@nuna/common'
import { formService, userService } from '@nuna/core'
import { ChipGroup, Grid, IconGlobe, PhoneTextField, Radio, TextButtonLink, phoneUSAValidationRegex } from '@nuna/tunic'

import { TextField } from '../../../shared/components/TextField'
import { Tip } from '../../../shared/components/Tip'
import { getInternationalRoute } from '../../../util/routes'
import { Container } from '../InsuranceIntake'
import { useInsuranceIntakeContext } from '../InsuranceIntakeContext'
import { IntakeNavigation } from '../IntakeNavigation'
import { useIntakeConditions } from '../hooks/useIntakeConditions'
import { IntakePaths } from '../intakeConstants'
import { StepHeader } from '../shared/StepHeader'
import { SectionHeading } from '../shared/Typography'

const { oopsRequired, makeSelection, getFieldProps } = formService
const { humanReadableGenders } = userService

export interface InsurancePersonalInformationFormValues extends UsAddressValues {
  mobilePhone: string
  gender: string
  emergencyContactName: string
  emergencyContactPhone: string
  emergencyContactRelationship: string
}

const insurancePersonalInformationSchema = Yup.object().shape({
  mobilePhone: Yup.string()
    .required(oopsRequired('phone number'))
    .matches(phoneUSAValidationRegex, 'Please enter a valid US phone number'),
  ...addressValidationSchemaChecks,
  gender: Yup.string().required(makeSelection),
  emergencyContactName: Yup.string().required('Oops, you forgot their name'),
  emergencyContactPhone: Yup.string()
    .required('Oops, you forgot their phone number')
    .matches(phoneUSAValidationRegex, 'Please enter a valid US phone number'),
  emergencyContactRelationship: Yup.string().required('Oops, you forgot to indicate your relationship'),
})

export function InsurancePersonalInformation() {
  const patientId = usePatientId()
  const { shouldShowTherapistPreferences } = useIntakeConditions()
  const navigate = useNavigate()
  const { intakeProgressData, setIsMutationLoading } = useInsuranceIntakeContext()

  const [savePatientIntake] = useSavePatientIntakeMutation({
    refetchQueries: ['PatientContext'], // without this the med management logic gets messed up
  })
  const [saveEmergencyContact] = useSaveEmergencyContactMutation()

  const handleSubmit = async (values: InsurancePersonalInformationFormValues) => {
    setIsMutationLoading(true)

    // ensure that this saves first or you may run into redirect bugs if the mobilePhone field is null
    await savePatientIntake({
      variables: {
        id: patientId ?? '',
        input: getPatientIntakeInput(values),
      },
      refetchQueries: ['PatientContext'],
    })

    const patientIntakeResponse = await saveEmergencyContact({
      variables: {
        id: patientId ?? '',
        contactInfo: getEmergencyContactInput(values),
      },
    })

    const stateMismatch =
      (patientIntakeResponse.data?.savePatientEmergencyContact.patientProviderStateMismatch ?? []).length > 0

    setIsMutationLoading(false)

    if (stateMismatch) {
      navigate(`../${IntakePaths.stateMismatch}`)
    } else {
      navigate(`../${IntakePaths.sponsor}`)
    }
  }

  if (!intakeProgressData) {
    return null
  }

  return (
    <Formik
      initialValues={buildInitialValues(intakeProgressData)}
      validationSchema={insurancePersonalInformationSchema}
      onSubmit={handleSubmit}
    >
      {formikProps => {
        const { values, setFieldValue, handleSubmit } = formikProps
        return (
          <form onSubmit={handleSubmit}>
            <Container>
              <StepHeader
                className="mb-0"
                smallHeading="Account Setup: Personal Info"
                largeHeading="First, let's capture the basics"
              />

              <section>
                <SectionHeading className="mb-3">Your contact info</SectionHeading>

                <PhoneTextField
                  {...getFieldProps('mobilePhone', formikProps)}
                  onChange={value => setFieldValue('mobilePhone', value)}
                  value={values.mobilePhone}
                  label="Mobile phone"
                  inputProps={{
                    'data-testid': 'intake-phone',
                  }}
                />

                <Tip
                  intro="Affects notifications:"
                  description="You'll get a reminder text 15 min prior to each appointment beginning."
                  className="mb-3"
                  style={{ marginTop: '1.5rem' }}
                />

                <AddressForm gridSpacing={4} dataTestPrefix="intake" />

                {shouldShowTherapistPreferences && (
                  <Tip
                    intro="Affects therapist results:"
                    description="Your therapist must be licensed in the state you reside."
                  />
                )}

                <div className="mt-3 v-align">
                  <IconGlobe size={16} className="mr-1" />
                  <span>
                    Living outside the USA?{' '}
                    <TextButtonLink variant="secondary" to={getInternationalRoute()}>
                      Get started here
                    </TextButtonLink>
                  </span>
                </div>
              </section>

              <section className="mt-6">
                <SectionHeading id="gender-heading" className="mb-3">
                  Your gender
                </SectionHeading>

                {(() => {
                  const { error, helperText, onChange, onBlur, name } = getFieldProps('gender', formikProps)

                  return (
                    <ChipGroup error={error} helperText={helperText} aria-labelledby="gender-heading">
                      <Grid container>
                        {Object.values(Gender)
                          .filter(value => value !== Gender.PreferNotToAnswer)
                          .map(value => {
                            return (
                              <Grid
                                key={value}
                                size={{
                                  xs: 12,
                                  sm: 6,
                                }}
                              >
                                <Radio
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  name={name}
                                  value={value}
                                  checked={values.gender === value}
                                  inline
                                  dataTestId={`${name}-${value}`}
                                >
                                  {humanReadableGenders[value]}
                                </Radio>
                              </Grid>
                            )
                          })}
                      </Grid>
                    </ChipGroup>
                  )
                })()}

                {shouldShowTherapistPreferences && (
                  <Tip
                    intro="Affects therapist results:"
                    description="A few of our therapists specialize in helping specific genders."
                  />
                )}
              </section>

              <section className="mt-6">
                <SectionHeading className="mb-3">Who should we contact in case of an emergency</SectionHeading>
                <Grid container spacing={4}>
                  <Grid size={12}>
                    <TextField
                      {...getFieldProps('emergencyContactName', formikProps)}
                      label="Emergency Contact's Full Name"
                      className="fs-exclude"
                      inputProps={{
                        'data-testid': 'intake-emergency-name',
                      }}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      sm: 6,
                    }}
                  >
                    <PhoneTextField
                      {...getFieldProps('emergencyContactPhone', formikProps)}
                      label="Their Mobile Phone"
                      className="fs-exclude"
                      onChange={value => setFieldValue('emergencyContactPhone', value)}
                      value={values.emergencyContactPhone}
                      inputProps={{
                        'data-testid': 'intake-emergency-phone',
                      }}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      sm: 6,
                    }}
                  >
                    <TextField
                      {...getFieldProps('emergencyContactRelationship', formikProps)}
                      label="Relationship"
                      inputProps={{
                        'data-testid': 'intake-emergency-relationship',
                      }}
                    />
                  </Grid>
                </Grid>
                <Tip description="We only contact this person in cases where your therapist believes you are in immediate danger. For your safety, please ensure their contact number is up to date." />
              </section>

              <IntakeNavigation className="mt-5" previousButton={null} />
            </Container>
          </form>
        )
      }}
    </Formik>
  )
}

function buildInitialValues(data: IntakeProgressQuery): InsurancePersonalInformationFormValues {
  return {
    mobilePhone: data.intakeProgress.mobilePhone ?? '',
    addressLineOne: data.intakeProgress.addressLineOne ?? '',
    addressLineTwo: data.intakeProgress.addressLineTwo ?? '',
    city: data.intakeProgress.city ?? '',
    state: data.intakeProgress.state ?? '',
    zipCode: data.intakeProgress.zipCode ?? '',
    gender: data.intakeProgress.gender ?? '',
    emergencyContactName: data.intakeProgress.emergencyContactName ?? '',
    emergencyContactPhone: data.intakeProgress.emergencyContactPhone ?? '',
    emergencyContactRelationship: data.intakeProgress.emergencyContactRelationship ?? '',
  }
}

function getEmergencyContactInput(values: InsurancePersonalInformationFormValues): PatientIntakeEmergencyContactInput {
  return {
    name: values.emergencyContactName,
    phone: values.emergencyContactPhone,
    relationship: values.emergencyContactRelationship,
  }
}

function getPatientIntakeInput(values: InsurancePersonalInformationFormValues): PatientIntakeInput {
  return {
    mobilePhone: values.mobilePhone,
    addressLineOne: values.addressLineOne,
    addressLineTwo: values.addressLineTwo,
    city: values.city,
    state: values.state,
    county: values.county,
    zipCode: values.zipCode,
    gender: values.gender,
    timezone: moment.tz.guess(),
  }
}

import { styled } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'

import { BasicProviderFragment, CancelPolicyStatusFragment } from '@nuna/api'
import { DrawerStepper } from '@nuna/common'
import { Avatar, Drawer, DrawerProps, IconButton, IconClose, eggshell } from '@nuna/tunic'

import { LateFeePolicyForm } from '../../LateFeePolicyForm'

interface Props extends DrawerProps {
  cancelPolicyStatus?: CancelPolicyStatusFragment
  provider?: BasicProviderFragment
}

export function AcceptProviderFeesDrawer({ cancelPolicyStatus, isOpen, onClose, provider, ...rest }: Props) {
  useEffect(() => {
    if (isOpen && provider) mixpanel.track('saw late policy acceptance', { providerId: provider.id })
  }, [isOpen, provider])

  return (
    <StyledDrawer size="min(400px, 100vw)" isOpen={isOpen} onClose={onClose} {...rest}>
      <div className="flex-column content-wrapper p-2">
        {provider && cancelPolicyStatus?.availableCancelPolicy && (
          <>
            <div className="v-align pb-2">
              <Avatar url={provider?.avatarUrl} size="xs" />
              <p className="h5 mb-0 ml-1-5">
                {provider?.firstName} {provider?.lastName}'s Policies
              </p>
              <IconButton variant="secondary" small tooltip="Close" className="ml-auto shrink-0" onClick={onClose}>
                <IconClose size={20} />
              </IconButton>
            </div>
            <LateFeePolicyForm
              cancelPolicy={cancelPolicyStatus.availableCancelPolicy}
              paymentPreference={cancelPolicyStatus.paymentPreference ?? undefined}
              provider={provider}
              renderButtons={loading => (
                <DrawerStepper
                  className="mt-6"
                  loading={loading}
                  nextButtonText="Accept Policy"
                  nextButtonProps={{ 'data-testid': 'accept-policy-submit', type: 'submit' }}
                />
              )}
              onSubmitSuccess={onClose}
            />
          </>
        )}
      </div>
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${eggshell};
  }
`

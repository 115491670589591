import { styled } from '@mui/material'
import { useTheme } from '@mui/material'
import { capitalize } from 'lodash'
import React, { useState } from 'react'

import { Provider } from '@nuna/api'
import { Avatar, BelowTablet, borderGrey, error as errorColor, interactiveFill, shadowDepth } from '@nuna/tunic'

export interface ProviderRadioCardProps extends Omit<React.FunctionComponent, 'children'> {
  provider: Pick<Provider, 'id' | 'firstName' | 'lastName' | 'avatarUrl' | 'type'>
  checked: boolean
  disabled?: boolean
  error?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function ProviderRadioCard({
  provider,
  checked,
  disabled = false,
  error = false,
  onChange,
  ...props
}: ProviderRadioCardProps) {
  const [isHovered, setIsHovered] = useState(false)
  const theme = useTheme()

  return (
    <Container breakpoint={theme.breakpoints.up('lg')} {...props}>
      <HiddenRadio
        aria-hidden="false"
        checked={checked}
        disabled={disabled}
        id={provider.id}
        onChange={onChange}
        value={provider.id}
      />

      <CardLabel
        checked={checked && !error}
        disabled={disabled}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        htmlFor={provider.id}
      >
        <Avatar size="md" url={provider.avatarUrl} isHoveredExternal={isHovered} />

        <span className="h5" style={{ display: 'block', marginBottom: '0.25rem' }}>
          {provider.firstName} {provider.lastName}
        </span>

        <span style={{ marginBottom: '0.75rem', display: 'block' }}>{capitalize(provider.type)}</span>

        <CTA>
          {disabled ? (
            <InfoText>There are no other providers of this kind in your area</InfoText>
          ) : checked && !error ? (
            <InfoText>You'll browse other therapists before changes are made</InfoText>
          ) : (
            <SelectText error={error} aria-hidden="true">
              Select
            </SelectText>
          )}
        </CTA>
      </CardLabel>
    </Container>
  )
}

const Container = styled('div')<{ breakpoint: string }>`
  ${props => `
    ${props.breakpoint} {
      margin: auto;
      width: 211px;
    }
  `}
`

const HiddenRadio = styled('input')`
  display: none;
`
HiddenRadio.defaultProps = { type: 'radio' }

const CardLabel = styled('label')<{ checked: boolean; disabled: boolean }>`
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid white;
  cursor: pointer;
  display: block;
  padding: 1.5rem 1rem;
  text-align: center;
  width: 100%;

  &:hover {
    border-color: ${borderGrey};
  }

  ${props =>
    props.checked &&
    !props.disabled &&
    `
    border-color: ${borderGrey};
    box-shadow: ${shadowDepth(1.5)};
  `}

  ${props =>
    props.disabled &&
    `
    cursor: default;
    filter: grayscale(1);
    opacity: 0.7;

    &:hover {
      border-color: transparent;
    }
  `}

  @media (${BelowTablet}) {
    border-color: ${borderGrey};
  }
`

const CTA = styled('span')`
  display: block;
  min-height: 32px;
`

const InfoText = styled('span')`
  display: block;
  line-height: 1.14;
`
InfoText.defaultProps = { className: 'text-secondary italic caption' }

const SelectText = styled('span')<{ error: boolean }>`
  color: ${props => (props.error ? errorColor : interactiveFill)};
  display: block;
  font-weight: 600;

  ${CardLabel}:hover & {
    text-decoration: underline;
  }
`

import { ApolloQueryResult } from '@apollo/client'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

import { IntakeProgressQuery, PatientContextQuery, useIntakeProgressQuery, usePatientContextQuery } from '@nuna/api'
import { useFeatureFlagContext } from '@nuna/feature-flag'

export interface InsuranceIntakeContextValue {
  intakeProgressData: IntakeProgressQuery | undefined
  patientContextData: PatientContextQuery | undefined
  mutationLoading: boolean
  appointmentCanceled: boolean
  setAppointmentCanceled: (value: boolean) => void
  setIsMutationLoading: (value: boolean) => void
  refetchIntakeProgress: () => Promise<ApolloQueryResult<IntakeProgressQuery>>
}

const InsuranceIntakeContext = createContext<InsuranceIntakeContextValue>({} as InsuranceIntakeContextValue)

export function InsuranceIntakeContextProvider({ children }: { children: ReactNode }) {
  const { identify } = useFeatureFlagContext()
  const { data: patientContextData } = usePatientContextQuery()
  const {
    data: intakeProgressData,
    refetch,
    stopPolling,
  } = useIntakeProgressQuery({
    variables: { id: patientContextData?.patientContext.patient.id ?? '' },
    skip: !patientContextData,
    pollInterval: 5000,
  })

  //
  useEffect(() => {
    if (intakeProgressData?.intakeProgress.assessmentBundle) {
      stopPolling()
    }
  }, [intakeProgressData?.intakeProgress.assessmentBundle, stopPolling])

  // we don't re-query patient context when appointment is canceled because it would cause the
  // insurance coverage result to re-render and content to disappear sunddenly. So instead, we have to
  // track the cancelation state in memory
  const [appointmentCanceled, setAppointmentCanceled] = useState(false)
  const [isMutationLoading, setIsMutationLoading] = useState(false)

  useEffect(() => {
    if (!isMutationLoading) {
      identify()
    }
  }, [isMutationLoading, identify])

  return (
    <InsuranceIntakeContext.Provider
      value={{
        intakeProgressData,
        patientContextData,
        mutationLoading: isMutationLoading,
        setIsMutationLoading,
        appointmentCanceled,
        setAppointmentCanceled,
        refetchIntakeProgress: refetch,
      }}
    >
      {children}
    </InsuranceIntakeContext.Provider>
  )
}

export function useInsuranceIntakeContext() {
  return useContext(InsuranceIntakeContext)
}

import { styled } from '@mui/material'
import { MouseEventHandler, forwardRef } from 'react'

import { BelowDesktopLarge, BelowTablet, FillButton, GhostButtonLink, Grid } from '@nuna/tunic'

import { Container } from '../../../shared/components/Container'
import bicycleIllustrationSrc from '../img/bicycle-illustration.png'

interface FooterProps {
  onGetStartedClick: MouseEventHandler<HTMLButtonElement>
}

export const Footer = forwardRef<HTMLDivElement, FooterProps>(({ onGetStartedClick }, ref) => {
  return (
    <FooterContainer ref={ref}>
      <Container>
        <Grid container>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 4,
            }}
          >
            <h1 className="h1">
              Your best
              <br />
              self awaits.
            </h1>
          </Grid>

          <BikeGrid
            size={{
              xs: 12,
              sm: 12,
              md: 4,
            }}
          >
            <img src={bicycleIllustrationSrc} alt="" />
          </BikeGrid>

          <ButtonGrid
            className="center-above-mobile"
            size={{
              xs: 12,
              sm: 6,
              md: 4,
            }}
          >
            <FillButton onClick={onGetStartedClick} className="mr-4">
              Get Started
            </FillButton>
            <GhostButtonLink to="/login" variant="secondary" scheme="dark">
              Sign In
            </GhostButtonLink>
          </ButtonGrid>
        </Grid>
      </Container>
    </FooterContainer>
  )
})

const BikeGrid = styled(Grid)`
  text-align: center;

  @media (max-width: 960px) {
    order: 3;
    margin-top: 3rem;
    padding-bottom: 3rem;
  }
`

const ButtonGrid = styled(Grid)`
  padding-top: 3rem;

  @media (${BelowDesktopLarge}) {
    padding-top: 2rem;
  }
`

const FooterContainer = styled('footer')`
  background-color: rgba(1, 52, 61, 0.93);
  color: #fff;
  min-height: 620px;
  padding-top: 8rem;

  @media (${BelowTablet}) {
    min-height: 662px;
  }

  .center-above-mobile {
    @media (min-width: 769px) {
      text-align: center;
    }
  }
`

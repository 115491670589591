import { useContext, useState } from 'react'

import { BasicAppointmentFragment, useProviderDetailsQuery } from '@nuna/api'

import { DrawerProps } from '../Drawer'
import { ProviderDrawer } from '../ProviderDrawer'
import { ProviderBio } from './ProviderBio'
import { ProviderProfileDrawerContext } from './ProviderProfileDrawerContext'

export function ProviderProfileDrawer(props: Omit<DrawerProps, 'isOpen' | 'onClose' | 'children'>) {
  const {
    providerId: [providerId, setProviderId],
    setDefaultOpenTimeSlot,
  } = useContext(ProviderProfileDrawerContext)

  const [confirmedAppointment, setConfirmedAppointment] = useState<BasicAppointmentFragment | null>(null)

  const { data: providerData } = useProviderDetailsQuery({
    variables: {
      id: providerId as string,
    },
    skip: !providerId,
  })

  const activeProvider = providerData?.provider

  const handleClose = () => {
    setProviderId(null)
    setDefaultOpenTimeSlot(null)
    setConfirmedAppointment(null)
  }

  return (
    <ProviderDrawer
      {...props}
      provider={activeProvider}
      isOpen={!!providerId}
      onClose={handleClose}
      fullHeight={!!confirmedAppointment}
      showCloseButton={!confirmedAppointment}
    >
      <ProviderBio provider={activeProvider} />
    </ProviderDrawer>
  )
}

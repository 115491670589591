import { styled } from '@mui/material'

import { supportService } from '@nuna/telemetry'
import {
  BelowTablet,
  FillButtonLink,
  GhostButton,
  TabletPortraitMin,
  borderGrey,
  shadowDepth,
  toast,
} from '@nuna/tunic'

export function handleNevermindClick(therapistName: string) {
  toast.info(`We'll keep you with ${therapistName} for now. Remember, you can change at any time.`)
}

export function handleGetAdviceClick() {
  supportService.openChat()
}

export const Main = styled('main')``

export const Container = styled('section')`
  background-color: #fff;
  border-radius: var(--border-radius);
  border: 1px solid ${borderGrey};
  padding: 1.5rem;
  overflow: hidden;
`

export const Header = styled('header')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  h1 {
    @media (${BelowTablet}) {
      font-size: 2rem;
    }
  }
`
Header.defaultProps = { className: 'mb-4' }

export const ContinueButton = styled(FillButtonLink)`
  margin-left: auto;

  @media (${BelowTablet}) {
    width: 100%;
    height: 62px;
    font-size: 1.25rem;
    box-shadow: ${shadowDepth(8)};
  }
`

export const BackButton = styled(GhostButton)`
  @media (${BelowTablet}) {
    height: 62px;
    font-size: 1.25rem;
    width: 62px;
    box-shadow: ${shadowDepth(8)};
  }
`

export const ButtonContainer = styled('div')<{ isShownMobile: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @media (${BelowTablet}) {
    bottom: 21px; // random non-rem number to align with intercom
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    left: 1.26rem;
    top: auto;
    transform: translateY(${props => (props.isShownMobile ? 0 : 'calc(100% + 21px + .5rem)')});
    transition: transform 0.3s;
    width: calc(100% - (2 * 1.26rem) - 68px);
    z-index: 10;
  }
`

export const FormHeading = styled('h2')`
  @media (${TabletPortraitMin}) {
    line-height: 44px; // keeps it aligned with buttons
  }
`
FormHeading.defaultProps = { className: 'h6 mb-2' }

export const Divider = styled('span')`
  display: inline-block;
  height: 1.5rem;
  width: 0;
  border-right: 1px solid ${borderGrey};
  margin-left: 1rem;
  margin-right: 1rem;
`

export const FormHeader = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  position: relative;

  @media (${BelowTablet}) {
    margin-bottom: 0;
  }
`

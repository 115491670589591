import { styled } from '@mui/material'
import { useFormikContext } from 'formik'

import { FocusAwareInput } from '@nuna/common'
import { formService } from '@nuna/core'
import { Employer, EmployerAutoComplete } from '@nuna/coverage'
import {
  BelowTabletLandscape,
  CountrySelect,
  FillButton,
  Grid,
  IconGlobe,
  StatusLabel,
  body1,
  interactiveFill,
} from '@nuna/tunic'

const { composeHelperTextWithError } = formService

export interface InternationalSignupFormValues {
  country: string
  employer: Employer | null
}

export function InternationalSignupForm({ isLoading = false }) {
  const { handleSubmit, values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext<InternationalSignupFormValues>()

  return (
    <Form onSubmit={handleSubmit}>
      <StatusLabel>
        <span className="v-align-inline">
          <IconGlobe size={12} className="mr-xs" /> Global Signup
        </span>
      </StatusLabel>

      <h2 className="h3 mb-4 mt-4">Check my access</h2>

      <FocusAwareInput>
        {(isFocused, setIsFocused) => {
          return (
            <Grid container spacing={1} alignItems="flex-start" wrap="nowrap">
              <Grid>
                <IconGlobe style={{ marginTop: '1.25rem' }} color={isFocused ? interactiveFill : body1} />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                }}
              >
                <CountrySelect
                  label="Your country of residence"
                  value={values.country}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  onChange={countryOption => {
                    if (countryOption) {
                      setFieldValue('country', countryOption.value)
                    }
                  }}
                />
              </Grid>
            </Grid>
          )
        }}
      </FocusAwareInput>

      <div className="mt-2">
        <EmployerAutoComplete
          value={values.employer ?? ''}
          onChange={employer => {
            setFieldValue('employer', employer)
            setFieldTouched('employer', true)
          }}
          label={'Your sponsoring organization'}
          {...composeHelperTextWithError('', errors.employer, touched.employer)}
          tooltip="Global coverage is offered exclusively through sponsoring organizations."
        />
      </div>

      <FillButton data-testid="international-signup-get-started" isLoading={isLoading} className="mt-5" type="submit">
        Get Started
      </FillButton>
    </Form>
  )
}

const Form = styled('form')`
  @media (${BelowTabletLandscape}) {
    padding: 4rem 0 2rem;
  }
`

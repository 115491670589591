import { useLocation, useNavigate } from 'react-router-dom'

import {
  AssessmentBundleType,
  AssessmentSource,
  AssessmentType,
  useIntakeProgressLazyQuery,
  useRequestAssessmentBundleMutation,
} from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { useEffectOnce } from '@nuna/common'

import { useInsuranceIntakeContext } from '../InsuranceIntakeContext'
import { IntakePaths } from '../intakeConstants'
import { useIntakeNavigation } from './useIntakeNavigation'

export function useIntakeAssessmentBundle() {
  const location = useLocation()
  const { intakeProgressData, patientContextData, refetchIntakeProgress } = useInsuranceIntakeContext()
  const [intakeProgressQuery] = useIntakeProgressLazyQuery({
    variables: { id: patientContextData?.patientContext.patient.id ?? '' },
  })
  const { intakeProgress } = intakeProgressData || {}
  const { assessmentBundle } = intakeProgress || {}
  const { login } = useAuthDataContext()
  const { id } = login || {}
  const [createAssessmentBundle] = useRequestAssessmentBundleMutation()
  const { nextPath, currentStepPathIndex, stepPaths } = useIntakeNavigation()
  const navigate = useNavigate()

  const navigateWithinAssessmentBundle = async () => {
    if (nextPath === IntakePaths.assessment3Cssrs) {
      // This has to be done dynamically here at submit time due to
      // useIntakeNavigation having stale data due to race conditions.
      // We could consider refactoring useIntakeNavigation to return a
      // function that takes current intakeProgress and returns the next path
      // if we ever need to use this pattern again.
      // smartNavigate in useAssessmentRouting does something similar.
      const { data } = await intakeProgressQuery()
      const hasCssrsInBundle = data?.intakeProgress?.assessmentBundle?.assessments.find(
        a => a.type === AssessmentType.CSsrs,
      )
      // if there isn't a cssrs assessment, skip the cssrs step
      if (!hasCssrsInBundle) {
        return navigate(stepPaths[currentStepPathIndex + 2])
      }
    }
    return nextPath && nextPath !== location.pathname && navigate(nextPath)
  }

  useEffectOnce(() => {
    const createBundle = async (forUserLoginId: string) => {
      try {
        await createAssessmentBundle({
          variables: {
            forUserLoginId,
            bundleType: AssessmentBundleType.MentalHealthAssessment,
            source: AssessmentSource.Intake,
          },
        })
        await refetchIntakeProgress()
      } catch (e) {
        console.error(e)
      }
    }

    if (id) createBundle(id)
  }, !!id && intakeProgress && !assessmentBundle)

  return {
    navigateWithinAssessmentBundle,
    assessmentBundle,
  }
}

import { SpecialtiesFilterCategory } from '../filter-controls/FilterCategories'
import { SpecialtiesAccordion } from '../filter-controls/SpecialtiesAccordion'
import { CategoryHeader } from './CategoryHeader'

export function SpecialtiesSection() {
  return (
    <>
      <CategoryHeader pl={19}>
        <SpecialtiesFilterCategory iconSize={24} iconContainerWidth={38} />
      </CategoryHeader>
      <SpecialtiesAccordion className="pl-2" style={{ paddingTop: 11 }} mobile />
    </>
  )
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useNavigate, useParams } from 'react-router-dom'

import { BasicProviderFragment, SwitchProviderChangeReason, useProviderDetailsQuery } from '@nuna/api'

import { ProviderDrawer } from '../../shared/components/ProviderDrawer'
import { ProviderBio } from '../../shared/components/ProviderProfileDrawer/ProviderBio'
import { SearchProviderFilterState } from '../../shared/components/SearchProvider/SearchProviderFilterState'

interface Props {
  isOpen: boolean
  oldProvider: BasicProviderFragment
  changeReason: SwitchProviderChangeReason
  freeFormReason: string
  filters?: SearchProviderFilterState
}

export function SwitchProviderScheduleDrawer({
  isOpen,
  oldProvider,
  changeReason,
  filters,
  freeFormReason,
  ...props
}: Props) {
  const { newProviderId = '', providerId = '' } = useParams<{ newProviderId: string; providerId: string }>()
  const navigate = useNavigate()
  const { data } = useProviderDetailsQuery({ variables: { id: newProviderId } })

  const newProvider = data?.provider

  const handleClose = () => {
    navigate(`/switch-provider/${providerId}/select`)
  }

  return (
    <ProviderDrawer
      {...props}
      isOpen={isOpen}
      provider={newProvider}
      onClose={handleClose}
      fullHeight={false}
      showCloseButton={true}
      filters={filters}
      switchingProvider={{
        oldProvider,
        changeReason,
        freeFormReason,
      }}
    >
      <ProviderBio provider={newProvider} />
    </ProviderDrawer>
  )
}

import { styled } from '@mui/material'

import { BelowTablet, DesktopLarge, DesktopXLarge } from '@nuna/tunic'

// these sizes are used on the signup page
export const Container = styled('div')`
  max-width: 1024px;
  margin: auto;
  padding: 0 1.575rem;

  @media (${DesktopLarge}) {
    max-width: 1280px;
  }

  @media (${DesktopXLarge}) {
    max-width: 1440px;
  }
`

export const containerSizes = {
  default: 1024,
  large: 1280,
  xl: 1440,
}

export const AppLayoutTopPadding = '2.75rem'

// use this for pages inside the app
export const AppContainer = styled('div')<{ offsetTopPadding?: boolean }>`
  margin: auto;
  max-width: 1200px;
  width: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  ${props => props.offsetTopPadding && `margin-top: -${AppLayoutTopPadding};`}

  @media (${BelowTablet}) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
`

import { styled } from '@mui/material'
import moment from 'moment'
import { HTMLAttributes } from 'react'
import { useMediaQuery } from 'react-responsive'

import { AppointmentChangeReason, DetailedAppointmentFragment, useCancelAppointmentAsPatientMutation } from '@nuna/api'
import { AuthorizedAvatar } from '@nuna/common'
import { appointmentService, errorService } from '@nuna/core'
import { supportService } from '@nuna/telemetry'
import {
  BelowTablet,
  Card,
  IconCancelAppointment,
  OutlineButton,
  OutlineButtonExternalLink,
  StatusLabel,
  TabletPortraitMin,
  csx,
  greySet,
  toast,
} from '@nuna/tunic'

import { useInsuranceIntakeContext } from '../../../InsuranceIntakeContext'

const { isCancelableOnZocdoc } = appointmentService

interface Props extends HTMLAttributes<HTMLDivElement> {
  appointment: DetailedAppointmentFragment
}

export function CancelAppointmentCard({ appointment, className, ...props }: Props) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  const { appointmentCanceled, setAppointmentCanceled } = useInsuranceIntakeContext()
  const [cancelAppointment, { loading: cancelLoading }] = useCancelAppointmentAsPatientMutation({
    fetchPolicy: 'no-cache',
  })
  const { startDatetime, endDatetime } = appointment

  const handleCancel = async () => {
    try {
      await cancelAppointment({
        variables: {
          id: appointment.id,
          reason: AppointmentChangeReason.InvalidCoverage,
          reasonData: 'Invalid insurance',
        },
      })
      setAppointmentCanceled(true)
    } catch (e) {
      toast.urgent(
        errorService.transformGraphQlError(
          e,
          `There was an error canceling the appointment. Please contact ${supportService.supportEmails.clientSupport}.`,
        ),
      )
    }
  }

  const amPmDifferent = moment(startDatetime).format('a') !== moment(endDatetime).format('a')
  const appointmentTimeString = isMobile
    ? `${moment(startDatetime).format(`ddd, MMM Do [at] h:mma`)}`
    : `${moment(startDatetime).format(`dddd, MMM Do [from] h:mm${amPmDifferent ? 'a' : ''}`)}-
  ${moment(endDatetime).format('h:mma')}`

  return (
    <StyledCard
      {...props}
      className={csx([className, 'p-2 v-align', { canceled: appointmentCanceled }])}
      depth={appointmentCanceled ? -4 : undefined}
    >
      <div>
        <div className="text-medium large appointment-time">{appointmentTimeString}</div>
        <div className="v-align provider-container mt-xs">
          <AuthorizedAvatar url={appointment.provider.avatarUrl} size="mini" />
          <span className="text-secondary ml-1">
            {appointment.provider.firstName} {appointment.provider.lastName}
          </span>
        </div>
      </div>
      {appointmentCanceled && (
        <div className="cancel-label-container">
          <StatusLabel intent="caution">Canceled</StatusLabel>
        </div>
      )}
      {!appointmentCanceled && (
        <div className="text-center button-container">
          {isCancelableOnZocdoc(appointment) ? (
            <>
              <OutlineButtonExternalLink className="cancel-button" href="https://www.zocdoc.com/signin">
                <IconCancelAppointment className="mr-1" isHovered={false} /> Cancel Session
              </OutlineButtonExternalLink>
              <div className="italic caption mt-xs" style={{ color: greySet[50].hex }}>
                Redirect to Zocdoc
              </div>
            </>
          ) : (
            <OutlineButton type="button" isLoading={cancelLoading} className="cancel-button" onClick={handleCancel}>
              <IconCancelAppointment className="mr-1" isHovered={false} /> Cancel Session
            </OutlineButton>
          )}
        </div>
      )}
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;

  .button-container {
    width: 100%;
  }

  .cancel-button {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: var(--spacing-3);
  }

  .cancel-label-container {
    margin-top: var(--spacing-2);
    width: 100%;
    text-align: center;

    > span {
      width: 100%;
    }
  }

  &.canceled {
    .appointment-time {
      text-decoration: line-through;
    }
  }

  @media (${TabletPortraitMin}) {
    flex-direction: row;
    justify-content: space-between;

    .button-container {
      width: auto;
    }

    .cancel-button {
      margin-top: 0;
    }

    .cancel-label-container {
      margin-top: 0;
      width: auto;
    }
  }
`

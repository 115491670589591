import { styled } from '@mui/material'
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { interactiveFill, shadowDepth } from '@nuna/tunic'
import { BelowTablet, TabletPortraitMin } from '@nuna/tunic'

interface ChipButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  hoverText: string
}

export function ChipButton({ hoverText, children, ...props }: ChipButtonProps) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const [isHovering, setIsHovering] = useState(false)
  return (
    <Button {...props} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      {isHovering && !isMobile ? hoverText : children}
    </Button>
  )
}

const Button = styled('button')`
  background-color: #fff;
  border-radius: 6px;
  box-shadow: ${shadowDepth(1)};
  font-weight: 500;
  padding: 0.75rem 1rem;
  min-width: 100px;
  transition: 0.3s background-color, 0.3s color;

  @media (${TabletPortraitMin}) {
    margin-bottom: 1rem;
  }

  &:hover {
    background-color: ${interactiveFill};
    color: #fff;
  }
`

import { styled } from '@mui/material'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'

import { usePartnerBrandingQuery, usePatientContextQuery } from '@nuna/api'
import { AssessmentsProvider } from '@nuna/assessments'
import { useAuthDataContext } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { BelowTablet, greySet, tealSet } from '@nuna/tunic'

import { IntakeProgressHeader } from '../scenes/intake/IntakeProgressHeader'
import { useIntakeAssessmentBundle } from '../scenes/intake/hooks/useIntakeAssessmentBundle'
import { IntakePaths } from '../scenes/intake/intakeConstants'
import p4BgSrc from './img/p4-confirmation-bg.png'

const Content = styled('div')`
  height: 100%;
  margin-bottom: auto;
  padding: 1.26rem;
  flex-grow: 1;
`

interface IntakeLayoutProps {
  children: React.ReactNode
}

export function IntakeLayout({ children }: IntakeLayoutProps) {
  const { data } = usePatientContextQuery()
  const { login } = useAuthDataContext()
  const location = useLocation()
  const { navigateWithinAssessmentBundle, assessmentBundle } = useIntakeAssessmentBundle()
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const company = data?.patientContext?.company

  // Look up custom branding based on SSO organization or associated sponsor company
  // Prefer SSO org, so if the user is logged in with SSO but associated with a differently-branded
  // sponsor company then use the SSO org.
  const { data: customBranding } = usePartnerBrandingQuery({
    variables: {
      companyId: !login?.organizationSlug ? company?.id : undefined,
      organizationSlug: login?.organizationSlug ?? undefined,
    },
    skip: (!login?.organizationSlug && !company?.id) || !isMobile,
  })
  const customLogoUrl = customBranding?.partnerBranding?.logoUrl

  if (!data) {
    return null
  }

  const isP4Confirmation = location.pathname === `${routeService.intake()}/${IntakePaths.p4Confirmation}`

  return (
    <AssessmentsProvider value={{ assessmentBundle, navigateWithinAssessmentBundle }}>
      <div
        style={{
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: isP4Confirmation ? tealSet[5].hex : greySet[0].hex,
          ...(isP4Confirmation
            ? { backgroundImage: `url(${p4BgSrc})`, backgroundSize: 'cover', overflow: 'hidden' }
            : {}),
        }}
      >
        <IntakeProgressHeader />
        {isMobile && customLogoUrl && <CustomLogo src={customLogoUrl} alt="" />}

        <Content>{children}</Content>
      </div>
    </AssessmentsProvider>
  )
}

const CustomLogo = styled('img')`
  display: block;
  max-width: 200px;
  width: 100%;
  margin-top: var(--margin-6);
  padding-top: var(--margin-2);
  margin-left: var(--margin-4);
  margin-bottom: -75px;
`

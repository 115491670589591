import { DemographicsAccordion } from '../filter-controls/DemographicsAccordion'
import { DemographicsFilterCategory } from '../filter-controls/FilterCategories'
import { CategoryHeader } from './CategoryHeader'

export function DemographicsSection() {
  return (
    <>
      <CategoryHeader pl={19}>
        <DemographicsFilterCategory iconSize={24} iconContainerWidth={38} />
      </CategoryHeader>
      <DemographicsAccordion className="pl-2" style={{ paddingTop: 11 }} mobile />
    </>
  )
}

import { Navigate, Route, Routes } from 'react-router-dom'

import { Container } from '../../InsuranceIntake'
import { StepHeader } from '../../shared/StepHeader'
import { IntakeCashForm } from './components/IntakeCashForm'
import { IntakeInsuranceForm } from './components/IntakeInsuranceForm'
import { IntakeSponsorForm } from './components/IntakeSponsorForm'

export function IntakeCoverage() {
  return (
    <Container>
      <StepHeader
        smallHeading="Account Setup: Coverage"
        largeHeading="Let's set up your coverage"
        className="mb-0"
        introParagraph={
          <span className="large">
            We know that cost can be a big barrier to therapy. We'll walk you through your coverage options to make sure
            your care is as affordable as possible.
          </span>
        }
      />

      <Routes>
        <Route path="/sponsor" element={<IntakeSponsorForm />} />
        <Route path="/insurance" element={<IntakeInsuranceForm />} />
        <Route path="/cash" element={<IntakeCashForm />} />
        {/* Adding a wildcard redirect here to avoid cases wehre we go to /intake/coverage or /intake/coverage/foo */}
        <Route path="/*" element={<Navigate replace to="../sponsor" />} />
      </Routes>
    </Container>
  )
}

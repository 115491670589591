import { useCallback, useReducer } from 'react'

import {
  CurrentAvailability,
  DayOfWeek,
  GeneralAvailabilityInput,
  OfficeHours,
  PaymentMethodInput,
  PreferredProviderGender,
} from '@nuna/api'

import {
  ProviderFilterCategory,
  ProviderFilterSpecialtyOption,
  SpecialtyFilterCategory,
  searchProviderUtils,
} from './search-provider.utils'

type SpecialtySelections = Record<SpecialtyFilterCategory, ProviderFilterSpecialtyOption[]>

interface SearchProviderAvailableFilterValues {
  ageRange: [number, number]
  coverage: PaymentMethodInput[]
  specialities: SpecialtySelections
}

export interface SearchProviderFilterState extends SpecialtySelections {
  availableValues?: SearchProviderAvailableFilterValues
  currentAvailability: CurrentAvailability[]
  generalAvailability: GeneralAvailabilityInput
  coverage: PaymentMethodInput[]
  gender?: PreferredProviderGender
  ageRange: [number, number]
  search: string
  favoritesOnly: boolean
  dirty: boolean
}

interface ToggleSpecialtyMulti {
  type: 'toggleSpecialtyMulti'
  payload: { specialty: ProviderFilterSpecialtyOption; category: SpecialtyFilterCategory }
}

interface ToggleSpecialtySingle {
  type: 'toggleSpecialtySingle'
  payload: { specialty: ProviderFilterSpecialtyOption; category: SpecialtyFilterCategory }
}

interface ToggleCurrentAvailability {
  type: 'toggleCurrentAvailability'
  payload: CurrentAvailability
}

interface ToggleGeneralAvailabilityHours {
  type: 'toggleGeneralAvailabilityHours'
  payload: OfficeHours
}

interface ToggleGeneralAvailabilityDays {
  type: 'toggleGeneralAvailabilityDays'
  payload: DayOfWeek
}

interface ToggleCoverage {
  type: 'toggleCoverage'
  payload: PaymentMethodInput
}

interface SetGender {
  type: 'setGender'
  payload: PreferredProviderGender
}

interface SetAgeRange {
  type: 'setAgeRange'
  payload: { ageRange: [number, number]; keepPristine?: boolean }
}

interface SetSearchText {
  type: 'setSearchText'
  payload: string
}

interface ClearFilters {
  type: 'clearFilters'
  payload?: boolean
}

interface SetFilterState {
  type: 'setFilterState'
  payload: SearchProviderFilterState
}

interface SetInitialFilterState {
  type: 'setInitialFilterState'
  payload: {
    available: SearchProviderAvailableFilterValues
    default: Pick<SearchProviderFilterState, 'ageRange' | 'coverage' | 'gender' | 'generalAvailability'> &
      SpecialtySelections
  }
}

interface SetFavoritesOnlyState {
  type: 'setFavoritesOnly'
  payload: boolean
}

type Actions =
  | ToggleSpecialtyMulti
  | ToggleCurrentAvailability
  | ToggleGeneralAvailabilityHours
  | ToggleGeneralAvailabilityDays
  | ToggleCoverage
  | SetGender
  | SetAgeRange
  | ToggleSpecialtySingle
  | SetSearchText
  | ClearFilters
  | SetFilterState
  | SetInitialFilterState
  | SetFavoritesOnlyState

export const DEFAULT_AGE_RANGE = [0, 100] as [number, number]

export function intialState(): SearchProviderFilterState {
  const specialtiesState = Object.values(SpecialtyFilterCategory).reduce(
    (prev: Partial<SpecialtySelections>, category) => {
      prev[category] = []
      return prev
    },
    {},
  ) as unknown as SpecialtySelections

  return {
    ...specialtiesState,
    generalAvailability: { days: [], hours: [] },
    currentAvailability: [CurrentAvailability.Month],
    coverage: [],
    gender: undefined,
    ageRange: DEFAULT_AGE_RANGE,
    search: '',
    favoritesOnly: false,
    dirty: false,
  }
}

function reducer(state: SearchProviderFilterState, action: Actions): SearchProviderFilterState {
  function setState(changes: Partial<SearchProviderFilterState>): SearchProviderFilterState {
    const changedState: SearchProviderFilterState = { ...state, ...changes }
    return { ...changedState, dirty: searchProviderUtils.filterCount(changedState) > 0 }
  }

  switch (action.type) {
    case 'toggleCurrentAvailability':
      return setState({ currentAvailability: [action.payload] })
    case 'toggleGeneralAvailabilityDays':
      return setState({
        generalAvailability: {
          ...state.generalAvailability,
          days: toggleInArray(state.generalAvailability.days ?? [], action.payload),
        },
      })
    case 'toggleGeneralAvailabilityHours':
      return setState({
        generalAvailability: {
          ...state.generalAvailability,
          hours: toggleInArray(state.generalAvailability.hours ?? [], action.payload),
        },
      })
    case 'toggleSpecialtyMulti': {
      return setState({
        [action.payload.category]: toggleInArray(
          state[action.payload.category],
          action.payload.specialty,
          option => option.id === action.payload.specialty.id,
        ),
      })
    }
    case 'toggleSpecialtySingle': {
      const currentSpecialty = state[action.payload.category]
      return setState({
        [action.payload.category]: currentSpecialty.find(specialty => specialty.id === action.payload.specialty.id)
          ? []
          : [action.payload.specialty],
      })
    }
    case 'setGender': {
      return setState({ gender: state.gender === action.payload ? undefined : action.payload })
    }
    case 'setAgeRange': {
      const { ageRange, keepPristine } = action.payload
      return { ...setState({ ageRange }), dirty: keepPristine ? false : true }
    }
    case 'setSearchText': {
      return setState({ search: action.payload })
    }
    case 'toggleCoverage': {
      return setState({
        coverage: toggleInArray(
          state.coverage,
          action.payload,
          paymentPreference => paymentPreference.id === action.payload.id,
        ),
      })
    }
    case 'clearFilters': {
      const preserveCoverage = action.payload ?? false
      const clearedState = { ...intialState(), availableValues: state.availableValues }
      if (preserveCoverage) {
        clearedState.coverage = state.coverage
        clearedState.dirty = true
      }
      if (state.availableValues?.ageRange) clearedState.ageRange = state.availableValues.ageRange
      return clearedState
    }
    case 'setFilterState': {
      return { ...action.payload }
    }
    case 'setInitialFilterState': {
      return setState({ ...action.payload.default, availableValues: action.payload.available })
    }
    case 'setFavoritesOnly': {
      return setState({ favoritesOnly: action.payload })
    }
    default: {
      return { ...state }
    }
  }
}

export function useSearchProviderFilterReducer() {
  const [appliedFilters, dispatch] = useReducer(reducer, {}, () => intialState())

  const toggleCurrentAvailability = useCallback(
    (currentAvailability: CurrentAvailability) =>
      dispatch({ type: 'toggleCurrentAvailability', payload: currentAvailability }),
    [],
  )

  const toggleGeneralAvailabilityDays = useCallback(
    (payload: DayOfWeek) => dispatch({ type: 'toggleGeneralAvailabilityDays', payload }),
    [],
  )

  const toggleGeneralAvailabilityHours = useCallback(
    (payload: OfficeHours) => dispatch({ type: 'toggleGeneralAvailabilityHours', payload }),
    [],
  )

  const toggleSpecialtyMulti = useCallback(
    (specialty: ProviderFilterSpecialtyOption, category: SpecialtyFilterCategory) => {
      dispatch({ type: 'toggleSpecialtyMulti', payload: { specialty, category } })
    },
    [],
  )

  const toggleSpecialtySingle = useCallback(
    (specialty: ProviderFilterSpecialtyOption, category: SpecialtyFilterCategory) => {
      dispatch({ type: 'toggleSpecialtySingle', payload: { specialty, category } })
    },
    [],
  )

  const togglePaymentMethod = useCallback(
    (paymentMethod: PaymentMethodInput) => dispatch({ type: 'toggleCoverage', payload: paymentMethod }),
    [],
  )

  const setGender = useCallback(
    (gender: PreferredProviderGender) => dispatch({ type: 'setGender', payload: gender }),
    [],
  )

  const setAgeRange = useCallback(
    (ageRange: [number, number], keepPristine?: boolean) =>
      dispatch({ type: 'setAgeRange', payload: { ageRange, keepPristine } }),
    [],
  )

  const setSearchText = useCallback(
    (searchText: string) => dispatch({ type: 'setSearchText', payload: searchText }),
    [],
  )

  const setFilterState = useCallback(
    (state: SearchProviderFilterState) => dispatch({ type: 'setFilterState', payload: state }),
    [],
  )

  const setInitialFilterState = useCallback(
    (initialValues: SetInitialFilterState['payload']) =>
      dispatch({ type: 'setInitialFilterState', payload: initialValues }),
    [],
  )

  const setFavoritesOnly = useCallback((state: boolean) => dispatch({ type: 'setFavoritesOnly', payload: state }), [])

  const clearFilters = useCallback(
    (preserveCoverage?: boolean) => dispatch({ type: 'clearFilters', payload: preserveCoverage }),
    [],
  )

  const aggregateSpecialtyFilters = useCallback(
    (categories: SpecialtyFilterCategory[]) =>
      searchProviderUtils.aggregateSpecialtyFilters(categories, appliedFilters),
    [appliedFilters],
  )

  const filterCount = useCallback(
    (category?: ProviderFilterCategory) => searchProviderUtils.filterCount(appliedFilters, category),
    [appliedFilters],
  )

  return {
    appliedFilters,
    toggleCurrentAvailability,
    toggleGeneralAvailabilityDays,
    toggleGeneralAvailabilityHours,
    toggleSpecialtyMulti,
    toggleSpecialtySingle,
    togglePaymentMethod,
    aggregateSpecialtyFilters,
    setGender,
    setAgeRange,
    setSearchText,
    setFavoritesOnly,
    setFilterState,
    setInitialFilterState,
    clearFilters,
    filterCount,
  }
}

// UTILITY FUNCTIONS

function toggleInArray<T>(arr: T[], value: T, predicate?: (v: T) => boolean) {
  if (!predicate) {
    predicate = (v: T) => v === value
  }
  const found = arr.find(predicate)
  if (found) {
    return arr.filter(v => v !== found)
  }

  return [...arr, value]
}

import { Checkbox } from '@nuna/tunic'

import { useSearchProviderContext } from '../SearchProviderContext'
import { FilterSection } from './FilterAccordion'

export function FavoriteFilterSelect() {
  const { appliedFilters, setFavoritesOnly } = useSearchProviderContext()

  return (
    <FilterSection>
      <Checkbox
        onChange={v => setFavoritesOnly(v.currentTarget.checked)}
        checked={appliedFilters.favoritesOnly}
        className="checkbox"
      >
        Show Favorites
      </Checkbox>
    </FilterSection>
  )
}

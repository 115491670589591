import { styled } from '@mui/material'
import { MouseEventHandler, ReactNode } from 'react'

import { OrganizationRelationship, PaymentPreference } from '@nuna/api'
import { EAPCoverageFragment } from '@nuna/core'
import { VerificationStatus } from '@nuna/coverage'
import { BelowTablet, FillButton, TabletPortraitMin, borderGrey, errorIllustration, greySet } from '@nuna/tunic'

import pendingSrc from './img/pending.png'
import successSrc from './img/success.png'
import zeroCapSrc from './img/zerocap.png'

interface CoverageResultProps {
  children: ReactNode
  status: VerificationStatus | null
  employerAssociation?: OrganizationRelationship | null
  employerName?: string
  bottomSection?: ReactNode
  successText?: string
  errorText?: string
  onAddCoverageClick?: MouseEventHandler<HTMLButtonElement>
  illustration?: ReactNode
  coverage?: EAPCoverageFragment
}
export function CoverageResult({
  status,
  employerAssociation,
  employerName,
  children,
  bottomSection,
  onAddCoverageClick,
  successText = "You're covered.",
  errorText = "Uh oh, we can't verify your coverage.",
  illustration,
  coverage,
}: CoverageResultProps) {
  let nextCoverageButtonText = ''
  const [heading, imageSrc] = (() => {
    if (status === 'zeroCapEAP') {
      if (coverage?.details.alternateCoverageSuggestion === PaymentPreference.Cash) {
        nextCoverageButtonText = 'Add Credit Card'
        return [`Add your credit card to continue`, zeroCapSrc]
      }
      nextCoverageButtonText = 'Add Insurance'
      return [`${employerName} uses insurance to cover sessions`, zeroCapSrc]
    }

    if (status === 'success') {
      return [successText, successSrc]
    }

    if (status === 'invalid') {
      if (employerAssociation && employerAssociation !== OrganizationRelationship.Employee) {
        return ["So sorry, they aren't offering Tava to dependents.", errorIllustration]
      }

      return [errorText, errorIllustration]
    }

    return ["We'll verify things while you finish up.", pendingSrc]
  })()

  const innerContent = (
    <>
      <div className="text-center mr-2 header-container">
        <h3 className="h4 mb-3 text-center balance-wrap px-2">{heading}</h3>

        {illustration ?? <CoverageResultIllustration src={imageSrc} alt="" />}

        {status === 'zeroCapEAP' && (
          <div className="mt-3">
            <FillButton data-testid="zero-cap-add-insurance-button" onClick={onAddCoverageClick}>
              {nextCoverageButtonText}
            </FillButton>
          </div>
        )}
      </div>
      <div className="ml-auto card-container">{children}</div>
    </>
  )

  if (bottomSection) {
    return (
      <Container>
        <div className="v-align top-section">{innerContent}</div>
        <div className="bottom-section">{bottomSection}</div>
      </Container>
    )
  }

  return <Container className="v-align">{innerContent}</Container>
}

const Container = styled('div')`
  background-color: ${greySet[5].hex};
  border: 1px solid ${borderGrey};
  border-radius: var(--border-radius);
  padding: var(--margin-3);

  > div:first-child {
    flex: 0 1 100%;
  }

  .card-container {
    flex: 0 0 325px;

    > div:first-child {
      margin-bottom: var(--margin-2);
    }
  }

  button.coverage-result-button {
    margin-top: var(--margin-1);

    &:first-of-type {
      margin-right: var(--margin-2);
    }
  }

  @media (${TabletPortraitMin}) {
    .top-section {
      .header-container {
        width: 50%;
      }
    }
  }

  @media (${BelowTablet}) {
    flex-direction: column;

    .top-section {
      flex-direction: column;
    }

    .card-container {
      margin: var(--margin-3) auto var(--margin-1) auto;
      flex-basis: auto;
      width: 100%;
      max-width: 375px;
    }

    button.coverage-result-button {
      display: block;
      margin-left: 0;
      margin-top: var(--margin-3);
    }

    button.coverage-result-button + button.coverage-result-button {
      margin-top: var(--margin-2);
    }
  }
`

export const CoverageResultIllustration = styled('img')`
  max-width: 288px;
  width: 100%;
`

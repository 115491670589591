export const getSignupRoute = () => {
  return `/signup`
}

export const getInternationalRoute = () => {
  return `/global`
}

export const getProfileRoute = () => {
  return `/profile`
}

export const getSelectProviderRoute = (providerId?: string) => {
  if (providerId) {
    return `/select-provider/${providerId}`
  } else {
    return `/select-provider`
  }
}

export const getPaymentSettingsRoute = () => {
  return '/manage-payment'
}

export const getProviderSuggestionRoute = () => {
  return '/provider-suggestion'
}

export const getMentalHealthLibraryRoute = (postId?: string) => {
  return `/mental-health-library${postId ? `/${postId}` : ''}`
}

import { styled } from '@mui/material'
import { first } from 'lodash'
import mixpanel from 'mixpanel-browser'
import React, { HTMLAttributes, useState } from 'react'
import ReactPlayer from 'react-player'

import { VideoAskResponse } from '@nuna/api'
import { Avatar, Dialog } from '@nuna/tunic'

import { VideoAskPlayButton } from './VideoAskPlayButton'

interface VideoAskAvatarProps extends HTMLAttributes<HTMLDivElement> {
  responses: VideoAskResponse[]
  profileUrl: string
  showGif: boolean
  providerId: string
  size?: 'sm' | 'md'
}

const PlayButton = styled(VideoAskPlayButton)`
  position: absolute;
  right: 0.75rem;
  bottom: 0.75rem;
  width: 2.4rem;
  cursor: pointer;
  transition: all 0.2s;
  transform-origin: center;

  :hover {
    transform: scaleX(1.1) scaleY(1.1);
  }
`

const VideoContainer = styled('div')`
  text-align: center;

  video {
    max-height: 80vh;
    width: auto;
    height: auto;
    max-width: 90%;
  }
`

export function VideoAskAvatar({
  responses = [],
  profileUrl,
  showGif,
  style,
  providerId,
  size = 'md',
  ...props
}: VideoAskAvatarProps) {
  const [showResponses, setShowResponses] = useState(false)

  const hasResponses = !!responses.length

  const handleOpen = () => {
    if (hasResponses) {
      setShowResponses(true)
      mixpanel.track('opened video bio', { providerId })
    }
  }

  const handleClose = () => {
    setShowResponses(false)
    mixpanel.track('closed video bio', { providerId })
  }

  const gifUrl = first(responses)?.gifUrl

  return (
    <div style={{ cursor: 'pointer', ...style }} {...props}>
      <div style={{ position: 'relative' }}>
        <Avatar
          isHoveredExternal={showGif}
          size={size}
          onClick={handleOpen}
          url={showGif && gifUrl ? gifUrl : profileUrl}
          wanderSpace={30}
          style={{ verticalAlign: 'middle', marginBottom: '-5px' }}
        />
        {hasResponses && <PlayButton className="videoask-avatar-play-button" onClick={handleOpen} />}
      </div>
      <Dialog isOpen={showResponses} noChrome onClose={handleClose}>
        <ReactPlayer
          url={first(responses)?.mediaUrl}
          height="100%"
          width="100%"
          wrapper={VideoContainer}
          controls={true}
          playing={showResponses}
        />
      </Dialog>
    </div>
  )
}

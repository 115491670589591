import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode, useState } from 'react'

import { addressService } from '@nuna/core'
import { TextButton, listFormat, makeTypographyComponent } from '@nuna/tunic'

const { getStateNameFromAbbreviation } = addressService

interface PublicProviderStatesProps extends HTMLAttributes<HTMLDivElement> {
  states: string[]
}

const MAX_STATES = 9

export function PublicProviderStates({ states, ...props }: PublicProviderStatesProps) {
  const [showAll, setShowAll] = useState(false)

  let stateText: ReactNode
  if (states.length <= MAX_STATES) {
    stateText = listFormat(states.map(state => getStateNameFromAbbreviation(state) ?? ''))
  } else if (!showAll) {
    stateText = (
      <>
        {states.slice(0, MAX_STATES).map(getStateNameFromAbbreviation).join(', ')}
        {', '}
        <TextButton variant="secondary" onClick={() => setShowAll(true)}>
          more...
        </TextButton>
      </>
    )
  } else {
    stateText = (
      <>
        {listFormat(states.map(state => getStateNameFromAbbreviation(state) ?? ''))}{' '}
        <TextButton variant="secondary" onClick={() => setShowAll(false)}>
          less...
        </TextButton>
      </>
    )
  }

  return <Container {...props}>in {stateText}</Container>
}

const Container = styled(makeTypographyComponent('text-light text-dark-grey mt-1 mb-0', 'p'))`
  line-height: 1.5;
`

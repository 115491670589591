import { useNavigate } from 'react-router-dom'

import { AssessmentBundleType, AssessmentSource, useRequestAssessmentBundleMutation } from '@nuna/api'
import {
  AssessmentsDashboard,
  AssessmentsDashboardSkeleton,
  NoAssessments,
  useClientAssessments,
} from '@nuna/assessments'
import { useAuthDataContext } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { FillButton, GhostButtonLink, IconAssessment, toast } from '@nuna/tunic'

import { Subheader } from '../../shared/components/Subheader'

export function ClientAssessments() {
  const navigate = useNavigate()
  const { login } = useAuthDataContext()
  const patientLoginId = login?.id || ''
  const { assessments, isComplete, loading: isLoading } = useClientAssessments()
  const hasCompletedAssessment = assessments && assessments?.length > 0

  const [createAssessment, { loading: isCreatingAssessment }] = useRequestAssessmentBundleMutation({
    variables: {
      bundleType: AssessmentBundleType.MentalHealthAssessment,
      forUserLoginId: patientLoginId,
      source: AssessmentSource.PatientRequested,
    },
  })

  const createAndRouteToNewAssessment = async () => {
    if (patientLoginId) {
      try {
        const { data: newAssessmentData } = await createAssessment()
        const newAssessmentId = newAssessmentData?.requestAssessmentBundle?.id
        if (newAssessmentId) {
          navigate(routeService.assessmentBundle(newAssessmentId))
        }
      } catch (e) {
        console.error(e)
        toast.urgent('Failed to create assessment')
      }
    }
  }

  return (
    <>
      <Subheader className="mb-5" />
      <div className="v-align space-between flex-wrap ml-1">
        <div className="v-align mb-2">
          <IconAssessment /> <h1 className="h4 mb-0 ml-1">Assessments</h1>
        </div>
        {hasCompletedAssessment && (
          <FillButton onClick={createAndRouteToNewAssessment} isLoading={isCreatingAssessment} className="mb-2">
            Take Assessment
          </FillButton>
        )}
      </div>
      {hasCompletedAssessment && isComplete ? (
        <AssessmentsDashboard />
      ) : isLoading ? (
        AssessmentsDashboardSkeleton()
      ) : (
        <NoAssessments
          header="No assessments have sent"
          subheader={
            <>
              <GhostButtonLink to={routeService.intake()}>Complete intake</GhostButtonLink> to receive assessments from
              your provider
            </>
          }
        />
      )}
    </>
  )
}

import { styled } from '@mui/material'
import React, { useContext, useState } from 'react'

import { BasicProviderFragment, ProviderProfileFragment, SwitchProviderChangeReason } from '@nuna/api'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { BelowTablet, FillButton } from '@nuna/tunic'

import { CloseButton } from '../CloseButton'
import { Drawer, WidthContainer } from '../Drawer'
import { PlaceholderShimmer, PlaceholderShimmerCircle } from '../LoadingShimmer'
import { SearchProviderFilterState } from '../SearchProvider/SearchProviderFilterState'
import { searchProviderUtils } from '../SearchProvider/search-provider.utils'
import { VideoAskAvatar } from '../VideoAskAvatar'
import { ProviderProfileDrawerContext } from './ProviderProfileDrawerContext'

export interface SwitchProviderData {
  oldProvider: BasicProviderFragment
  changeReason: SwitchProviderChangeReason
  freeFormReason: string
}

interface ProviderDrawerHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  provider: ProviderProfileFragment | undefined
  onClose?: (event: React.SyntheticEvent<HTMLElement>) => void
  tabs?: React.ReactNode
  showCloseButton?: boolean
  switchingProvider?: SwitchProviderData
  filters?: SearchProviderFilterState
}

export function ProviderDrawerHeader({
  onClose,
  provider,
  showCloseButton = true,
  tabs = null,
  switchingProvider,
  filters,
  ...props
}: ProviderDrawerHeaderProps) {
  const [isHovered, setIsHovered] = useState(false)
  const { openScheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()
  const {
    providerId: [, setProvider],
  } = useContext(ProviderProfileDrawerContext)

  return (
    <DrawerHeaderWithAvatar {...props} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <HeaderContent>
        {provider ? (
          <VideoAskAvatar
            size="sm"
            showGif={isHovered}
            profileUrl={provider.avatarUrl || ''}
            responses={provider.videoAskResponses || []}
            providerId={provider.id ?? ''}
            style={{ display: 'inline-block' }}
          />
        ) : (
          <div style={{ padding: '15px' }}>
            <PlaceholderShimmerCircle size="64px" />
          </div>
        )}

        <HeadingButton>
          <Heading>
            {provider ? (
              `${provider.firstName} ${provider.lastName}`
            ) : (
              <PlaceholderShimmer height="32px" width="200px" />
            )}
          </Heading>

          {provider && (
            <ScheduleButton
              onClick={() => {
                if (switchingProvider) {
                  openScheduleAppointmentDrawer(provider.id, {
                    oldProviderId: switchingProvider.oldProvider.id,
                    state: {
                      changeReason: switchingProvider.changeReason,
                      freeFormReason: switchingProvider.freeFormReason,
                      filters: filters ? searchProviderUtils.readableFilters(filters) : {},
                    },
                  })
                } else {
                  openScheduleAppointmentDrawer(provider.id)
                }
                setProvider(null)
              }}
            >
              Schedule a session
            </ScheduleButton>
          )}

          {tabs}
        </HeadingButton>

        {showCloseButton && <CloseButtonStyled showEscText={false} onClick={onClose} />}
      </HeaderContent>
    </DrawerHeaderWithAvatar>
  )
}

// There is additional whitespace added because of the Blob Avatar so the padding is unnecessary
const DrawerHeaderWithAvatar = styled(Drawer.Header)`
  padding-top: 0;
  padding-bottom: 0;
`

const HeaderContent = styled(WidthContainer)`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  min-height: 64px;

  @media (${BelowTablet}) {
    display: block;
  }
`

const HeadingButton = styled('div')`
  display: flex;
  align-items: center;

  @media (${BelowTablet}) {
    display: block;
  }
`

const Heading = styled('h4')`
  margin: 0 3rem 0 1rem;

  @media (${BelowTablet}) {
    margin: 0.5rem 0;
    font-size: 1.5rem;
  }
`

const CloseButtonStyled = styled(CloseButton)`
  margin-left: auto;

  @media (${BelowTablet}) {
    position: absolute;
    top: 0;
    right: 1rem;
  }
`

const ScheduleButton = styled(FillButton)`
  @media (${BelowTablet}) {
    margin-bottom: var(--margin-2);
  }
`

import { keyframes, styled } from '@mui/material'
import { useFormikContext } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useParams } from 'react-router-dom'

import { BasicProviderFragment, ProviderType, SwitchProviderChangeReason, usePatientContextQuery } from '@nuna/api'
import { BelowTablet, Grid, IconChevronThick, IconCommentFilled, RadioCard, TextField } from '@nuna/tunic'

import { SwitchProviderCancelButton } from './SwitchProviderCancelButton'
import {
  BackButton,
  ButtonContainer,
  Container,
  ContinueButton,
  FormHeader,
  FormHeading,
  Header,
} from './switch-provider.utils'

const reasons = [
  {
    value: SwitchProviderChangeReason.Availability,
    text: 'Availability',
    subtext: 'My therapist’s schedule does not fit or they can’t meet often enough',
  },
  {
    value: SwitchProviderChangeReason.Personality,
    text: 'Personality',
    subtext: "I just don't feel like my therapist is a good fit",
  },
  {
    value: SwitchProviderChangeReason.Expertise,
    text: 'Expertise',
    subtext: 'My therapist is lacking the specialization I need',
  },
  {
    value: SwitchProviderChangeReason.SomethingElse,
    text: 'Something Else',
    subtext: 'Enter my own reason',
  },
]

interface SwitchProviderReasonProps {
  oldProvider: BasicProviderFragment | undefined
}

export function SwitchProviderReason({ oldProvider }: SwitchProviderReasonProps) {
  const { data: patientContextData } = usePatientContextQuery()
  const { providerId = '' } = useParams<{ providerId: string }>()
  const navigate = useNavigate()

  const therapists =
    patientContextData?.patientContext.patient.providers.filter(provider => provider.type === ProviderType.Therapist) ??
    []

  const { setFieldValue, values, errors, touched, handleChange, validateForm, setTouched } = useFormikContext<{
    changeReason: string
    freeFormReason: string
  }>()

  const noteInputRef = useRef<HTMLTextAreaElement>(null)
  const [isInputVisible, setIsInputVisible] = useState(false)

  useEffect(() => {
    setFieldValue('oldProviderId', providerId)
  }, [providerId, setFieldValue])

  useEffect(() => {
    if (isInputVisible && values.changeReason) {
      noteInputRef.current?.focus()
      /**
       * The material-ui note input animation messes up the expand animation if called simultaneously.
       * Let onAnimationEnd handle the first focus and then let this handle focusing after
       * a radio change once the note has animated in
       */
    }
  }, [isInputVisible, values.changeReason])

  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  const noteInput = (
    <div className="mt-2">
      <TextField
        $inputRef={noteInputRef}
        name="freeFormReason"
        renderIcon={color => <IconCommentFilled size={20} color={color} />}
        rows={isMobile ? 4 : 2}
        value={values.freeFormReason}
        onChange={handleChange}
        required={values.changeReason === SwitchProviderChangeReason.SomethingElse}
        error={!!(touched.freeFormReason && errors.freeFormReason)}
        multiline
        placeholder={
          values.changeReason === SwitchProviderChangeReason.SomethingElse
            ? 'Why are you considering a change? (required)'
            : `Add a comment (optional)`
        }
      />
    </div>
  )

  const handleExpandAnimationEnd = (e: React.AnimationEvent) => {
    if (e.target === e.currentTarget) {
      noteInputRef.current?.focus()
      setIsInputVisible(true)
    }
  }

  return (
    <>
      <Header>
        <h1 className="h3">Why are you considering a change?</h1>
        <SwitchProviderCancelButton provider={oldProvider} className="desktop-only" />
      </Header>

      <Container>
        <FormHeader>
          <FormHeading>Choose a reason</FormHeading>

          <ButtonContainer isShownMobile>
            {therapists.length > 1 && (
              <BackButton className="mr-1" onClick={() => navigate('/switch-provider')}>
                <IconChevronThick size={16} />
                <span className="ml-1 desktop-only"> Back</span>
              </BackButton>
            )}

            <ContinueButton
              to={`/switch-provider/${providerId}/select`}
              className={`${values.changeReason ? '' : ' disabled'}`}
              onClick={async e => {
                e.preventDefault()
                const href = new URL(e.currentTarget.href).pathname
                const errors = await validateForm()

                if (Object.keys(errors).length === 0) {
                  navigate(href)
                } else {
                  setTouched(
                    Object.keys(errors).reduce<{ [key: string]: boolean }>((fields, key) => {
                      fields[key] = true
                      return fields
                    }, {}),
                  )
                }
              }}
            >
              Continue
            </ContinueButton>
          </ButtonContainer>
        </FormHeader>

        <div style={{ maxWidth: '1100px', margin: 'auto' }}>
          <Grid container spacing={3} justifyContent="center">
            {reasons.map(reason => (
              <React.Fragment key={reason.value}>
                <CompressedGrid
                  size={{
                    xs: 12,
                    sm: 6,
                    md: 3,
                  }}
                >
                  <RadioCard
                    checked={values.changeReason === reason.value}
                    text={reason.text}
                    subtext={reason.subtext}
                    value={reason.value}
                    name="changeReason"
                    onChange={handleChange}
                    error={!!(touched.changeReason && errors.changeReason)}
                  />
                </CompressedGrid>
                {isMobile && values.changeReason === reason.value && (
                  <CompressedGrid
                    size={{
                      xs: 12,
                    }}
                  >
                    <NoteInputExpander onAnimationEnd={handleExpandAnimationEnd}>{noteInput}</NoteInputExpander>
                  </CompressedGrid>
                )}
              </React.Fragment>
            ))}
          </Grid>

          {!isMobile && values.changeReason && (
            <NoteInputExpander
              onAnimationEnd={handleExpandAnimationEnd}
              key="desktop-note-input"
              style={{ padding: '2rem 0.5rem 0' }}
            >
              {noteInput}
            </NoteInputExpander>
          )}
        </div>

        <p className="mt-3 text-secondary text-center">
          {!values.changeReason
            ? "It's normal to see a few therapists before finding the right fit. In fact, we encourage it"
            : `Add a comment (not shared with ${oldProvider?.firstName})`}
          <br />
          <span className="text-secondary">and no changes will be made until after you choose a new therapist.</span>
        </p>
      </Container>
    </>
  )
}

const expand = keyframes`
  from {
    max-height: 0;
  }

  to {
    max-height: 150px;
  }
`

const NoteInputExpander = styled('div')`
  animation: ${expand} 0.3s;
  overflow: hidden;
`

const CompressedGrid = styled(Grid)`
  &&.MuiGrid-item {
    @media (${BelowTablet}) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`

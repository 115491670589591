import { styled } from '@mui/material'
import { ReactNode } from 'react'

import { Grid, Skeleton, csx } from '@nuna/tunic'

import intakeIllustrationSrc from '../img/intake-illustration.png'
import { Illustration } from '../shared/Illustration'

interface StepHeaderProps {
  smallHeading: ReactNode
  largeHeading: ReactNode
  introParagraph?: ReactNode
  hideIllustration?: boolean
  imageSrc?: string
  className?: string
  loading?: boolean
  children?: JSX.Element | null
}

export function StepHeader({
  smallHeading,
  largeHeading,
  introParagraph = null,
  hideIllustration = false,
  imageSrc = intakeIllustrationSrc,
  loading,
  className = 'mb-4',
  children,
}: StepHeaderProps) {
  return (
    <Grid component="header" container direction="row-reverse" alignItems="center" className={csx(['pb-4', className])}>
      {!hideIllustration && (
        <Grid className="text-center" size={{ xs: 12, sm: 3 }} sx={{ alignSelf: 'flex-start' }}>
          <Illustration src={imageSrc} alt="" />
        </Grid>
      )}

      <Grid size={{ xs: 12, sm: hideIllustration ? 12 : 9 }}>
        <p className="text-medium text-secondary mb-3">{smallHeading}</p>

        {loading ? (
          <>
            <h2 className="h3 mb-3">
              <Skeleton height={2} className="mb-1" />
              <Skeleton height={2} />
            </h2>
            {introParagraph && (
              <div>
                <Skeleton height={1} className="mb-1" />
                <Skeleton height={1} className="mb-1" />
                <Skeleton height={1} />
              </div>
            )}
          </>
        ) : (
          <>
            <h2 className="h3 mb-4">{largeHeading}</h2>
            {introParagraph && <IntroParagraph className="text-secondary">{introParagraph}</IntroParagraph>}
          </>
        )}

        {children}
      </Grid>
    </Grid>
  )
}

const IntroParagraph = styled('p')`
  max-width: 35em;
`

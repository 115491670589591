import { styled } from '@mui/material'

export const CenterContent = styled('div')`
  display: flex;
  justify-content: center;
`

export const FlexContainer = styled('div')`
  display: flex;
  align-items: center;
`

export const SplitContent = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

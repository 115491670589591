import { styled } from '@mui/material'
import React from 'react'

interface TickerProps {
  children: string
  style?: React.CSSProperties
}

export function Ticker({ children, ...props }: TickerProps) {
  return (
    <span {...props}>
      {children.split('').map(character => (
        <TickerCharacter key={character}>{character}</TickerCharacter>
      ))}
    </span>
  )
}

const TickerCharacter = styled('span')`
  align-items: center;
  background: linear-gradient(176deg, #0070a7 0%, #0070a7 50%, #005b8f 51%); //TODO: fix these colors
  border-radius: 6px;
  color: #fff;
  display: inline-flex;
  font-size: 36px;
  height: 47px;
  justify-content: center;
  margin-right: 0.25rem;
  width: 39px;
`

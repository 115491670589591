import { times } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { HTMLAttributes, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import { BelowTablet, Grid, Skeleton } from '@nuna/tunic'

import { useSearchProviderContext } from '../SearchProviderContext'
import { ProviderResultCard } from './ProviderResultCard'
import { UndesiredResultsPrompt } from './UndesiredResultsPrompt'

export function ResultsList() {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { appliedFilters, results, onProviderCardClick, resultsLoading } = useSearchProviderContext()

  const cardVerticalPadding = isMobile ? 8 : 12

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{ visibility: resultsLoading ? 'hidden' : 'visible', paddingBottom: 100, paddingTop: isMobile ? 0 : 12 }}
      >
        {results.length > 0 && (
          <Grid container spacing={isMobile ? 2 : 4} className="pb-5">
            {results.map(result => (
              <Grid
                key={result.id}
                size={{
                  xs: 12,
                  md: 6,
                }}
                style={{ paddingBottom: cardVerticalPadding, paddingTop: cardVerticalPadding }}
              >
                <ProviderResultCard provider={result} onClick={() => onProviderCardClick(result.id)} />
              </Grid>
            ))}
          </Grid>
        )}
        {results.length > 0 && appliedFilters.dirty && !resultsLoading && <UndesiredResultsPrompt />}
        {results.length === 0 && !resultsLoading && <NoMatchingProviders />}
      </div>

      <ResultSkeleton
        isMobile={isMobile}
        style={{ visibility: resultsLoading ? 'visible' : 'hidden', position: 'absolute', top: 0 }}
      />
    </div>
  )
}

interface ResultSkeletonProps extends HTMLAttributes<HTMLDivElement> {
  isMobile: boolean
}

function NoMatchingProviders() {
  useEffect(() => {
    mixpanel.track('No matching providers')
  }, [])

  return <UndesiredResultsPrompt title="No matching providers available" />
}

function ResultSkeleton({ isMobile, ...props }: ResultSkeletonProps) {
  return (
    <Grid container sx={{ pt: !isMobile ? 2 : undefined, pb: 6, width: '100%' }} spacing={isMobile ? 2 : 4} {...props}>
      {times(isMobile ? 2 : 6).map(v => (
        <Grid
          key={`skelton-contaoner-${v}`}
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <Skeleton height={10} />
        </Grid>
      ))}
    </Grid>
  )
}

import { styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

import { ConversationIconLink } from '@nuna/messaging'
import { Logo, greySet } from '@nuna/tunic'

import { FlexContainer, SplitContent } from '../../shared/components/Layout'
import { PatientNavMenu } from './PatientNavMenu'

const HeaderBar = styled('section')`
  background-color: white;
  box-shadow: 0px 1px 0px ${greySet[15].hex};
  padding: 1rem;
  position: relative;
`

export function Navbar({ disableMenu = false, disableMessagesLink = false }) {
  return (
    <HeaderBar>
      <SplitContent>
        <FlexContainer>
          <NavLink to="/">
            <Logo height={36} />
          </NavLink>
        </FlexContainer>

        {!disableMessagesLink && <ConversationIconLink className="ml-auto" />}

        {!disableMenu && <PatientNavMenu />}
      </SplitContent>
    </HeaderBar>
  )
}

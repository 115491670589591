export const faqItems = [
  {
    question: 'Is this service really free?',
    answer: (
      <p>
        Yes! If you are covered by your employer in your region, the Tava benefit allows you and each of your eligible
        dependents to attend a number of free therapy sessions per case per year. This means, if your employer covers 8
        sessions per case per year, you have 8 sessions to address any single mental health challenge, and may attend an
        additional 8 free sessions in the same year if another mental health challenge arises that is distinct from and
        unrelated to the original mental health challenge.
      </p>
    ),
  },
  {
    question: 'Can my employer see who is using Tava?',
    answer: (
      <p>
        No. Tava does not allow employers to view individual usage data. Any usage data that Tava shares with your
        employer is always de-identified and aggregated, protecting confidentiality and the identities of our individual
        clients. Written records of all services are kept private and are unavailable to employers or others without the
        written consent of the patient (or legal guardian) unless disclosure of information is required by law in your
        region.
      </p>
    ),
  },
  {
    question: 'Will my personal information be kept safely?',
    answer: (
      <p>
        Yes. All personally identifiable information is stored in a secure, HIPAA-compliant database and will never be
        sold, shared, or transmitted for any reason.
      </p>
    ),
  },
  {
    question: 'Is this service available after business hours?',
    answer: (
      <p>
        Yes. Our staff is available to support you 24 hours a day, 7 days a week. After you have been matched with your
        therapist, you willbe able to schedule sessions according to your needs and availability.
      </p>
    ),
  },
  {
    question: 'What if I need help immediately?',
    answer: (
      <p>
        If you have an emergency or urgent matter, seek help from local police or emergency responders, or go to the
        nearest emergency room or hospital.
      </p>
    ),
  },
  {
    question: 'How will I talk with my therapist?',
    answer: (
      <p>
        Many clients meet with their therapist through the convenience of online video chat or phone. All you need is an
        internet-connected device with a camera (e.g., computer, smartphone, tablet). If this option is not available to
        you, you may also have the ability to meet in person.
      </p>
    ),
  },
  {
    question: 'What are the qualifications of my therapist?',
    answer: (
      <p>
        Therapy sessions are provided by mental health professionals licensed to practice in your particular geography.
        Our clinicians’ credentials are verified, and we require their licensure be maintained in good standing.
      </p>
    ),
  },
  {
    question: 'What issues does Tava help resolve?',
    answer: (
      <p>
        Tava has therapists who understand and treat many types of issues. Our therapists provide talk therapy (i.e.
        psychotherapy) to help you identify ways to understand, manage, and resolve problems, including unhealthy
        thought patterns and behaviors. Therapists cannot prescribe medications. Your therapist will work with you to
        determine what challenges can be addressed within the number of sessions included in this benefit. If you
        determine that more long-term support would be helpful, your therapist can refer you to additional resources.
        Call to discuss what approach would be best for your situation.
      </p>
    ),
  },
  {
    question: 'Will my therapist and I be a good match?',
    answer: (
      <p>
        A good therapist-client match is crucial to healing. Before your first therapy session, one of our team members
        will discuss your situation with you and match you with a therapist that meets your needs and preferences.
      </p>
    ),
  },
  {
    question: 'Is remote therapy effective?',
    answer: (
      <p>
        Yes. Research has shown that remote therapy is equivalent to in-person care in diagnostic accuracy, treatment
        effectiveness, quality of care, and patient satisfaction, and is supported by the American Psychiatric
        Association.
      </p>
    ),
  },
]

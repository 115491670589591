import { styled } from '@mui/material'

import { Skeleton } from '@nuna/tunic'

import { Container } from './InsuranceIntake'

export function InsuranceIntakeSkeleton() {
  return (
    <Container>
      <Grid>
        <Skeleton height={10} />
        <Skeleton height={10} />

        <Skeleton className="full-column mt-5" height={30} />
      </Grid>
    </Container>
  )
}

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;

  > div {
    width: 100%;
  }

  .full-column {
    grid-column: 1 / 3;
  }
`

import { styled } from '@mui/material'
import { startCase } from 'lodash'
import moment from 'moment'

import { PatientPaymentMethod } from '@nuna/api'
import { paymentMethodService } from '@nuna/core'
import { Card, CardProps, IconCreditCard, StatusLabel, makeTypographyComponent, salmonSet } from '@nuna/tunic'

interface Props extends CardProps {
  paymentMethod: PatientPaymentMethod
}

export function PaymentMethodCard({ className, paymentMethod, ...props }: Props) {
  const descriptionCard = paymentMethod.description?.toLowerCase().includes('card') ? '' : ' Card'
  const descriptionHsa = paymentMethod.isHsa ? ' HSA' : ''
  const name = `${paymentMethod.description}${descriptionHsa}${descriptionCard}`

  const expirationDate = paymentMethod.expirationDate && moment(paymentMethod.expirationDate)
  const isExpired = !!expirationDate && expirationDate.isBefore(moment())

  return (
    <div className="v-align mb-1">
      <CardStyled className={`v-align p-1 full-width ${className}`} depth={isExpired ? -1 : 1} {...props}>
        <IconCreditCard className="ml-1 flex-static" size={24} />

        <div>
          <CreditCardInfo>{startCase(name)}</CreditCardInfo>
          <CreditCardInfo style={{ color: isExpired ? salmonSet[80].hex : undefined }}>
            {paymentMethodService.maskCardNumber(paymentMethod.accountLastFour, 9)}
          </CreditCardInfo>

          {isExpired && <CardLabel intent="urgent">Expired {expirationDate.format('MM/YY')}</CardLabel>}
        </div>
      </CardStyled>
    </div>
  )
}

const CreditCardInfo = makeTypographyComponent('text-medium ml-1 nowrap', 'span')
const CardLabel = styled(StatusLabel)`
  margin-left: var(--margin-1);
`
const CardStyled = styled(Card)`
  min-height: 52px;
`

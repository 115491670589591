import { IconProps } from './IconProps'

export function IconAudio({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (17 / 23) * size

  return (
    <svg width={size} height={height} viewBox="0 0 23 17" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7609 2.67042C8.19069 2.67042 5.38197 5.43664 5.38197 8.74559H2.9519C2.9519 4.00201 6.94305 0.240356 11.7609 0.240356C16.5788 0.240356 20.5699 4.00201 20.5699 8.74559H18.1398C18.1398 5.43664 15.3311 2.67042 11.7609 2.67042Z"
        fill={color}
      />
      <path
        d="M18.1399 8.74561H21C22.1046 8.74561 23 9.64104 23 10.7456V14.0358C23 15.1404 22.1046 16.0358 21 16.0358H18.1399V8.74561Z"
        fill={color}
      />
      <path
        d="M5.38208 8.74561H2.52194C1.41737 8.74561 0.521944 9.64104 0.521944 10.7456V14.0358C0.521944 15.1404 1.41737 16.0358 2.52194 16.0358H5.38208V8.74561Z"
        fill={color}
      />
    </svg>
  )
}

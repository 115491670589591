import { useNavigate } from 'react-router-dom'

import { useUpcomingAppointmentQuery } from '@nuna/api'
import { NoCurrentSessionModal } from '@nuna/sundance'
import { supportService } from '@nuna/telemetry'

export function PatientNoCurrentSessionModal() {
  const navigate = useNavigate()
  const { loading, data: appointmentData } = useUpcomingAppointmentQuery()

  const appointment = appointmentData?.upcomingAppointmentForPatient

  const handleClose = () => {
    navigate('/')
  }

  return (
    <NoCurrentSessionModal
      loading={loading}
      appointment={appointment}
      onClose={handleClose}
      onHelpClick={supportService.openChat}
    />
  )
}

import { ReactNode, useCallback, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { scrollIntoView } from 'scroll-js'

import { PartnerBrandingFragment } from '@nuna/api'
import { useSignupSearchParams } from '@nuna/auth'
import { FAQItem } from '@nuna/common'
import { routeService } from '@nuna/core'
import {
  IconButtonLink,
  IconGlobe,
  Logo,
  SignupLayout as SignupLayoutComponents,
  TextButton,
  TextButtonLink,
  tealSet,
} from '@nuna/tunic'

import { ClientFooter } from '../../shared/components/ClientFooter'
import { getInternationalRoute, getSignupRoute } from '../../util/routes'
import { Footer } from './components/Footer'
import { type FAQ } from './faqItems'
import scribble from './img/scribble.png'
import { InternationalFooter } from './international/InternationalFooter'

const {
  BrandedContainer,
  BrandedHalf,
  CenteredContent,
  FAQContainer,
  FAQText,
  FloatingGetStartedButton,
  GreyBackground,
  Header,
  SignInLink,
  Left,
  Right,
  RightAlignedHeader,
  SignupContainer,
  Top,
  TypographyContainer,
} = SignupLayoutComponents

interface SignupLayoutProps {
  renderForm: ReactNode
  renderLeftContent: ReactNode
  faqItems: FAQ[]
  backgroundColor?: string
  backgroundImage?: string
  customBranding?: PartnerBrandingFragment | null
  showInternationalLinks?: boolean
}

export function SignupLayout({
  renderForm,
  renderLeftContent,
  faqItems,
  backgroundColor = tealSet[70].hex,
  backgroundImage = scribble,
  customBranding,
  showInternationalLinks = false,
}: SignupLayoutProps) {
  const { oauthToken } = useSignupSearchParams()
  const faqSectionRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<HTMLDivElement>(null)

  const [inViewFormRef, isFormInView] = useInView()
  const [inViewFooterRef, isFooterInView] = useInView({ threshold: 0.25 })

  const scrollToForm = () => {
    if (formRef.current) {
      scrollIntoView(formRef.current)
    }
  }

  const scrollToFaq = () => {
    if (faqSectionRef.current) {
      scrollIntoView(faqSectionRef.current)
    }
  }

  // Since the oauth signup flow requires a hard redirect, ensure the user jumps back to the
  // form instead of being reset to the top of the page on mobile.
  const scrollAfterOauthRef = useCallback(
    (node: HTMLDivElement) => {
      if (node && oauthToken) node.scrollIntoView()
    },
    [oauthToken],
  )

  const primaryColor = customBranding?.primaryColor
  const LeftHalf = ({ children }: { children: React.ReactNode }) => {
    if (primaryColor) {
      const secondaryColor = customBranding?.secondaryColor ?? primaryColor
      return <BrandedHalf children={children} $gradientStart={primaryColor} $gradientEnd={secondaryColor} />
    }

    return <Left children={children} $backgroundColor={backgroundColor} $backgroundImage={backgroundImage} />
  }

  const content = (
    <>
      <FloatingGetStartedButton hidden={isFormInView || isFooterInView} onClick={scrollToForm}>
        Get Started
      </FloatingGetStartedButton>

      <Top>
        <LeftHalf>
          <Header>
            <Logo color={primaryColor ? 'white' : undefined} scheme="dark" height={36} />
          </Header>

          <CenteredContent>
            {renderLeftContent}

            <FAQText>
              Questions? Check out the{' '}
              <TextButton scheme="dark" onClick={scrollToFaq}>
                FAQ
              </TextButton>
            </FAQText>
          </CenteredContent>
        </LeftHalf>

        <Right ref={formRef}>
          <RightAlignedHeader className="v-align" ref={scrollAfterOauthRef}>
            {showInternationalLinks ? (
              <TextButtonLink variant="secondary" to={getSignupRoute()}>
                US Sign up
              </TextButtonLink>
            ) : (
              <>
                <SignInLink>
                  Have an account? <TextButtonLink to={routeService.login()}>Sign in</TextButtonLink>
                </SignInLink>

                <IconButtonLink
                  customSize={30}
                  variant="secondary"
                  to={getInternationalRoute()}
                  tooltip="Global signup"
                >
                  <IconGlobe size={16} />
                </IconButtonLink>
              </>
            )}
          </RightAlignedHeader>

          <CenteredContent ref={inViewFormRef} maxWidth={468}>
            {renderForm}
          </CenteredContent>
        </Right>
      </Top>

      <GreyBackground>
        <FAQContainer ref={faqSectionRef}>
          <SignupContainer>
            <h2>Frequently asked questions about Tava</h2>
            {faqItems.map(({ question, answer }) => (
              <FAQItem key={`faq-${question}`} fullWidth question={question} answer={answer} />
            ))}
          </SignupContainer>
        </FAQContainer>
      </GreyBackground>

      {showInternationalLinks ? (
        <InternationalFooter showTOS={false} ref={inViewFooterRef} />
      ) : (
        <>
          <Footer onGetStartedClick={scrollToForm} ref={inViewFooterRef} />
          <ClientFooter />
        </>
      )}
    </>
  )

  if (customBranding?.buttonColor && customBranding.buttonHoverColor) {
    return (
      <BrandedContainer $buttonColor={customBranding.buttonColor} $hoverColor={customBranding.buttonHoverColor}>
        {content}
      </BrandedContainer>
    )
  }

  return <TypographyContainer>{content}</TypographyContainer>
}

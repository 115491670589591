import React, { useEffect, useRef, useState } from 'react'

import { Grid } from '@nuna/tunic'

import { TextField } from '../../../../shared/components/TextField'

export function MedicationFormRow({
  getFieldProps,
  name,
  index,
  rowValues,
  rowErrors,
  rowTouched,
  arrayHelpers,
  formikHandleBlur,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  getFieldProps(`${name}.${index}.name`)
  getFieldProps(`${name}.${index}.dose`)
  getFieldProps(`${name}.${index}.frequency`)

  const [isAnyFieldFocused, setIsAnyFieldFocused] = useState(false)
  const [hasBeenFocused, setHasBeenFocused] = useState(false)

  const medicationNameRef = useRef<HTMLInputElement>(null)
  const doseRef = useRef<HTMLInputElement>(null)
  const frequencyRef = useRef<HTMLInputElement>(null)

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      e.relatedTarget !== medicationNameRef.current &&
      e.relatedTarget !== doseRef.current &&
      e.relatedTarget !== frequencyRef.current
    ) {
      setIsAnyFieldFocused(false)
    }

    formikHandleBlur(e)
  }

  const handleFocus = () => {
    if (!hasBeenFocused) {
      setHasBeenFocused(true)
    }
    setIsAnyFieldFocused(true)
  }

  useEffect(() => {
    const isLineEmpty = () => !rowValues.name && !rowValues.dose && !rowValues.frequency

    if (isLineEmpty() && !isAnyFieldFocused && hasBeenFocused) {
      arrayHelpers.remove(index)
    }
  }, [isAnyFieldFocused, index, arrayHelpers, hasBeenFocused, rowValues])

  return (
    <>
      <Grid
        size={{
          xs: 12,
          sm: 4,
        }}
      >
        <TextField
          {...getFieldProps(`${name}[${index}].name`)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          label="Medication"
          inputRef={medicationNameRef}
          value={rowValues.name}
          error={(rowTouched?.name && rowErrors?.name) as boolean}
          helperText={rowTouched?.name ? rowErrors?.name : ''}
          autoFocus
        />
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 4,
        }}
      >
        <TextField
          {...getFieldProps(`${name}[${index}].dose`)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          label="Dose"
          inputRef={doseRef}
          value={rowValues.dose}
          error={(rowTouched?.dose && rowErrors?.dose) as boolean}
          helperText={rowTouched?.dose ? rowErrors?.dose : ''}
        />
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 4,
        }}
      >
        <TextField
          {...getFieldProps(`${name}[${index}].frequency`)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          label="Frequency"
          inputRef={frequencyRef}
          value={rowValues.frequency}
          error={(rowTouched?.frequency && rowErrors?.frequency) as boolean}
          helperText={rowTouched?.frequency ? rowErrors?.frequency : ''}
        />
      </Grid>
    </>
  )
}

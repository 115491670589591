import { QuestionAnswerPair } from '@nuna/api'

export const inviteFAQs: Pick<QuestionAnswerPair, 'question' | 'answer'>[] = [
  {
    question: 'How should I suggest that someone try therapy?',
    answer: `Here are the main points that you should make whenever you refer someone to therapy:
    <ol>
        <li>Seems like you’re having a hard time, so therapy might be worth a shot.</li>
        <li>It's up to you.</li>
        <li>You’re not committing to a lifetime, just one initial session.</li>
        <li>There’s no guarantee it’ll be worthwhile, but the only way to find out is by trying.</li>
    </ol>
    You can phrase this any way you like, but here’s an example of how you could convey your message:
    <br/><br />“You seem to be struggling a lot and I want to be supportive. I’ve been thinking about you and I have liked using this tool to find therapy. Obviously, it’s up to you if you want to go. That’s no guarantee but it might be able to help. You may have to shop around a bit to find someone who is a good fit. Either way, if you want to check it out, it could be helpful.”`,
  },
  {
    question: 'What’s the right time to suggest therapy?',
    answer:
      'This is a sensitive subject, so do it privately and respectfully during a time where they are not currently in emotional breakdown or crisis. And most importantly, do NOT recommend therapy during an argument, because this will just hurt the person and potentially discourage them from help.',
  },
  {
    question: 'Should I have a group of people together to recommend therapy to this person? ',
    answer:
      'No. You might be nervous to talk alone, or want to show them that they have many people who are concerned about them. However, “intervention-style” will just make the person feel cornered. In addition, no one likes to know they’ve been talked about behind their backs. Have this conversation just be between you and them; that way it will feel more like a conversation than an ambush.',
  },
  {
    question: 'What if they have questions?',
    answer: `Validate their feelings and answer their questions to the best of your ability. Provide reassurance that getting therapy is healthy and beneficial, and acknowledge that it can be hard. This is not an argument to be won, but rather a life to be improved. If they’re not interested or ready right now, that’s ok.`,
  },
  {
    question: 'How will they pay for it?',
    answer: `Cost is one of the biggest barriers to therapy. Our priority is to make it easy and affordable to get help. We partner with insurance and employer sponsors to cover care, and they can always pay out of pocket and then submit an out-of-network claim. `,
  },
]

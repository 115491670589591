import { styled } from '@mui/material'
import range from 'lodash/range'
import React, { MouseEventHandler, useContext, useMemo, useState } from 'react'

import { BasicSpecialtyFragment, Provider, usePatientSpecialtiesQuery } from '@nuna/api'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { specialtiesService } from '@nuna/core'
import { Card, Chip, ChipGroup, FillButton, Stack, TextButton } from '@nuna/tunic'

import { ProviderProfileDrawerContext } from '../ProviderProfileDrawer'
import { ProviderProfileDrawerTabs } from '../ProviderProfileDrawer/ProviderProfileDrawerContext'
import { VideoAskAvatar } from '../VideoAskAvatar'

interface PickedProvider extends Pick<Provider, 'id' | 'videoAskResponses' | 'firstName' | 'lastName' | 'avatarUrl'> {
  specialties: BasicSpecialtyFragment[]
}

interface ProviderSelectCardProps {
  provider: PickedProvider
  renderScheduleButton?: React.ReactNode
  providerSuggestionRank?: number
  onChallengesClick?: MouseEventHandler<HTMLButtonElement>
}

export function ProviderSelectCard({
  provider,
  providerSuggestionRank,
  renderScheduleButton,
  onChallengesClick,
  ...props
}: ProviderSelectCardProps) {
  const { openScheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()

  const [isHovered, setIsHovered] = useState(false)
  const {
    providerId: [, setProvider],
    selectedTab: [, setSelectedTabId],
    providerSuggestionRank: [, setProviderSuggestionRank],
  } = useContext(ProviderProfileDrawerContext)

  const { data: patientSpecialtiesData, loading: isPatientSpecialtiesLoading } = usePatientSpecialtiesQuery()

  const providerLanguages = useMemo<BasicSpecialtyFragment[]>(() => {
    return specialtiesService.getLanguagesSpokenSpecialties(provider.specialties ?? [])
  }, [provider.specialties])

  const providerChallenges = useMemo<BasicSpecialtyFragment[]>(() => {
    const patientChallenges = specialtiesService.getChallenges(
      patientSpecialtiesData?.patientContext.patient.specialties ?? [],
    )

    return specialtiesService.getChallenges(provider.specialties ?? []).sort((a, b) => {
      const hasA = patientChallenges.find(challenge => challenge.id === a.id)
      const hasB = patientChallenges.find(challenge => challenge.id === b.id)

      if (hasB && !hasA) {
        return 1
      }

      if (hasA && hasB) {
        return 0
      }

      return -1
    })
  }, [provider.specialties, patientSpecialtiesData?.patientContext.patient.specialties])

  const openDrawer = (tab: ProviderProfileDrawerTabs) => {
    if (tab === ProviderProfileDrawerTabs.Availability) {
      openScheduleAppointmentDrawer(provider.id)
    } else {
      if (providerSuggestionRank !== undefined) {
        setProviderSuggestionRank(providerSuggestionRank)
      }

      setSelectedTabId(tab)
      setProvider(provider.id ?? null)
    }
  }

  return (
    <Card onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} {...props}>
      <Stack sx={{ alignItems: 'center', p: 2 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <VideoAskAvatar
            showGif={isHovered}
            profileUrl={provider.avatarUrl || ''}
            responses={provider.videoAskResponses || []}
            providerId={provider.id ?? ''}
            style={{ margin: 'auto' }}
          />
        </div>

        <h2 className="h4" style={{ margin: 0 }}>
          {provider.firstName} {provider.lastName}
        </h2>

        <p className="text-normal mb-1">{specialtiesService.getTherapistTypeString(provider.specialties ?? [])}</p>

        <ChipGroup
          className="mt-2 mb-2"
          style={{ flexWrap: 'wrap', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {isPatientSpecialtiesLoading ? (
            <>
              {range(3).map(i => (
                <Chip key={i} className="loading" small disabled>
                  Loading
                </Chip>
              ))}
            </>
          ) : (
            <>
              {providerLanguages
                .filter(pl => pl.name !== 'English')
                .slice(0, 1)
                .map(challenge => (
                  <ChipStyled small disabled key={challenge.id}>
                    {challenge.name}
                  </ChipStyled>
                ))}
              {providerChallenges.slice(0, 3).map(challenge => (
                <ChipStyled small disabled key={challenge.id}>
                  {challenge.name}
                </ChipStyled>
              ))}
              {providerChallenges.length - 3 > 0 ? (
                <TextButton
                  onClick={e => {
                    if (onChallengesClick) {
                      onChallengesClick(e)
                    } else {
                      openDrawer(ProviderProfileDrawerTabs.About)
                    }
                  }}
                >
                  +&nbsp;{providerChallenges.length - 3}
                </TextButton>
              ) : null}
            </>
          )}
        </ChipGroup>

        {renderScheduleButton === undefined ? (
          <FillButton onClick={() => openDrawer(ProviderProfileDrawerTabs.Availability)}>
            Schedule Appointment
          </FillButton>
        ) : (
          renderScheduleButton
        )}
      </Stack>
    </Card>
  )
}

const ChipStyled = styled(Chip)`
  label {
    height: auto;
    padding: 10px 0.75rem;
    line-height: 1.2;
    text-overflow: ellipsis;
  }
`

import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { ResultDetails, Severity, resultsContent, useAssessmentsContext } from '@nuna/assessments'
import { BelowTablet, FillButtonWithChevron, Grid, OutlineButtonWithChevron } from '@nuna/tunic'

import { IntakeNavigation } from '../../IntakeNavigation'
import { useIntakeAssessmentResults } from '../../hooks/useIntakeAssessmentResults'
import { useIntakeConditions } from '../../hooks/useIntakeConditions'
import { useSkipIntake } from '../../hooks/useSkipIntake'
import { IntakePaths } from '../../intakeConstants'
import { StepHeader } from '../../shared/StepHeader'

export function AssessmentIntakeResults() {
  const { assessmentBundle } = useAssessmentsContext()
  const { hasAppointment } = useIntakeConditions()
  const { assessments } = assessmentBundle || {}
  const { hasModerateToSevereRiskForIntake, maxSeverity, shownProvider, tookCssrs } = useIntakeAssessmentResults()

  const navigate = useNavigate()
  const { skipIntake, loading } = useSkipIntake()

  const resultDetails: ResultDetails = {
    [Severity.Minimal]: resultsContent[Severity.Minimal],
    [Severity.Mild]: resultsContent[Severity.Mild],
    [Severity.Moderate]: resultsContent[Severity.Moderate],
    [Severity.Severe]: resultsContent[Severity.Severe],
    suicideRisk: resultsContent.suicideRisk,
  }
  const { body, heading, illustration } =
    hasModerateToSevereRiskForIntake || tookCssrs ? resultDetails.suicideRisk : resultDetails[maxSeverity]

  const content =
    shownProvider || hasAppointment ? (
      <FillButtonWithChevron
        data-testid="complete-intake"
        isLoading={loading}
        className="mt-2"
        onClick={async () => skipIntake('/')}
      >
        Complete intake
      </FillButtonWithChevron>
    ) : hasModerateToSevereRiskForIntake ? null : (
      <Grid size={12} sx={{ mt: 8 }}>
        <IntakeNavigation
          nextButton={defaultButtonProps => (
            <OutlineButtonWithChevron {...defaultButtonProps} onClick={() => navigate(`../${IntakePaths.challenges}`)}>
              {'Next'}
            </OutlineButtonWithChevron>
          )}
        />
      </Grid>
    )

  return (
    <Container>
      <StepHeader
        className="pb-0"
        loading={!assessments}
        smallHeading={''}
        largeHeading={heading}
        introParagraph={body}
        imageSrc={illustration}
      >
        {content}
      </StepHeader>
    </Container>
  )
}

const Container = styled('main')`
  @media (${BelowTablet}) {
    margin-top: 2rem;
  }
`

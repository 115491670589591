import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { BelowTablet, makeTypographyComponent } from '@nuna/tunic'

import { useInsuranceIntakeContext } from '../../../InsuranceIntakeContext'
import { useIntakeConditions } from '../../../hooks/useIntakeConditions'
import stepOneSrc from '../img/Step_1.png'
import stepTwoSrc from '../img/Step_2.png'
import { CancelAppointmentCard } from './CancelAppointmentCard'
import { InvalidInsuranceTroubleshooting } from './InvalidInsuranceTroubleshooting'

interface Props extends HTMLAttributes<HTMLDivElement> {
  onEditClick: () => void
}

export function InvalidInsuranceNextSteps({ onEditClick, ...props }: Props) {
  const { hasAppointment } = useIntakeConditions()
  const { patientContextData } = useInsuranceIntakeContext()

  if (!patientContextData?.patientContext) {
    return null
  }

  const appointment = patientContextData.patientContext.patient.appointments[0]

  return (
    <Container {...props}>
      {(() => {
        if (hasAppointment) {
          return (
            <>
              <StepHeader>
                <img src={stepOneSrc} alt="step 1" className="mr-1" />
                Cancel your appointment
              </StepHeader>
              <CancelAppointmentCard appointment={appointment} />
              <div className="caption text-secondary mt-1">
                Out of respect for your provider's time, please cancel your session until you can resolve your coverage.
              </div>
              <StepHeader className="mt-6 mb-2 resolve-header">
                <img src={stepTwoSrc} alt="step 2" className="mr-1" />
                Resolve your coverage by trying the following:
              </StepHeader>
              <InvalidInsuranceTroubleshooting onEditClick={onEditClick} />
            </>
          )
        }

        return (
          <>
            <StepHeader className="mt-1 mb-2">Resolve your coverage by trying the following:</StepHeader>
            <InvalidInsuranceTroubleshooting onEditClick={onEditClick} />
          </>
        )
      })()}
    </Container>
  )
}

const StepHeader = makeTypographyComponent('text-bold sans-serif v-align', 'h6')

const Container = styled('div')`
  @media (${BelowTablet}) {
    .resolve-header {
      margin-top: var(--spacing-4);
    }
  }
`

import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode } from 'react'
import { resolvePath } from 'react-router-dom'

import { routeService } from '@nuna/core'
import { styledUtils } from '@nuna/tunic'
import { BelowTablet, ButtonProps, IntakeFormStepper } from '@nuna/tunic'

import { useIntakeNavigation } from '../intake/hooks/useIntakeNavigation'
import { useInsuranceIntakeContext } from './InsuranceIntakeContext'

const { transientPropOptions } = styledUtils

interface IntakeNavigationProps extends HTMLAttributes<HTMLDivElement> {
  previousButton?: ReactNode
  nextButton?: (props: Partial<ButtonProps>) => ReactNode
  skipButton?: (props: Partial<ButtonProps>) => ReactNode
  disabled?: boolean
  overrideMargins?: boolean
}

export function IntakeNavigation({ disabled, overrideMargins = false, ...props }: IntakeNavigationProps) {
  const { previousPath } = useIntakeNavigation()
  const { mutationLoading } = useInsuranceIntakeContext()

  return (
    <StyledIntakeFormStepper
      mutationLoading={mutationLoading}
      disabled={mutationLoading || disabled}
      previousPath={resolvePath(previousPath, routeService.intake())}
      $overrideMargins={overrideMargins}
      {...props}
    />
  )
}

const StyledIntakeFormStepper = styled(IntakeFormStepper, transientPropOptions)<{ $overrideMargins: boolean }>`
  ${({ $overrideMargins }) =>
    $overrideMargins
      ? ''
      : `
          margin-bottom: 5rem;

          @media (${BelowTablet}) {
            margin-bottom: 2.5rem;
          }
        `}
`

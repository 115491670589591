import { HTMLAttributes } from 'react'

import { SpecialtiesFilterCategory } from '../filter-controls/FilterCategories'
import { SpecialtiesAccordion } from '../filter-controls/SpecialtiesAccordion'
import { FilterMenu } from './FilterMenu'

export function SpecialtiesFilterMenu(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <FilterMenu {...props} menu={<SpecialtiesAccordion />}>
      <SpecialtiesFilterCategory />
    </FilterMenu>
  )
}

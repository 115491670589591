import * as Yup from 'yup'
import { AnimatePresence } from 'framer-motion'

import { useSignupSearchParams } from '@nuna/auth'
import { isMinor } from '@nuna/client'
import { formService, signupService } from '@nuna/core'
import { EmployerAssociationForm, employerCoverageSchema } from '@nuna/coverage'
import { Checkbox, CollapsePresence, DOBTextField, TextButtonExternalLink, TextField } from '@nuna/tunic'

import { Tip } from '../../../shared/components/Tip'
import { SignupFormStepProps } from '../SignupForm'

const { composeHelperTextWithError } = formService
const { agreesToTermsSchema, dobSchema, parentGuardianEmailSchema } = signupService

export interface NameEmailValues {
  dob: string
  parentGuardianEmail: string
}

export const finishOauthSchema = (associationRequired: boolean, parentGuardianEmailRequired: boolean) =>
  Yup.object().shape({
    agreesToTerms: agreesToTermsSchema,
    dob: dobSchema,
    parentGuardianEmail: parentGuardianEmailRequired ? parentGuardianEmailSchema : Yup.object().nullable(),
    employerCoverage: associationRequired ? employerCoverageSchema : Yup.object().nullable(),
  })

export function FinishOAuthStep({ formikProps }: SignupFormStepProps) {
  const { handleChange, errors, touched, values, handleBlur } = formikProps
  const { companyId, tokenId } = useSignupSearchParams()
  const associationRequired = values.employerCoverage?.employer?.associationRequired

  const onBlurOnChange = {
    onBlur: handleBlur,
    onChange: handleChange,
  }

  const commonProps = {
    ...onBlurOnChange,
    name: 'employerCoverage.employerAssociation',
  }

  const employerAssociationEmployeeProps = {
    ...onBlurOnChange,
    fullWidth: true,
    name: 'employerCoverage.employerAssociationEmployee',
  }

  return (
    <div className="mb-1">
      <h2 className="h3 mb-5">Finish account setup</h2>

      <DOBTextField
        id="dob"
        label="Date of birth"
        value={values.dob}
        placeholder="MM/DD/YYYY"
        name="dob"
        fullWidth
        onBlur={handleBlur}
        onChange={handleChange}
        {...composeHelperTextWithError('', errors.dob, touched.dob)}
        slotProps={{ htmlInput: { 'data-testid': 'signup-dob' } }}
        autoFocus
      />

      <AnimatePresence>
        {!tokenId && isMinor(values.dob) && (
          <CollapsePresence>
            <Tip
              className="mb-2 mt-1"
              description="We’ll need your parent or guardian’s consent to get you set up with a therapist. We’ll email them everything they need to know."
            />
            <TextField
              label="Parent or guardian's email"
              type="email"
              name="parentGuardianEmail"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.parentGuardianEmail}
              inputProps={{ 'data-testid': 'signup-parent-guardian-email' }}
              {...composeHelperTextWithError('', errors.parentGuardianEmail, touched.parentGuardianEmail)}
            />
          </CollapsePresence>
        )}
      </AnimatePresence>

      {companyId && associationRequired && (
        <div className="mt-4">
          <EmployerAssociationForm
            {...{
              employerValues: values.employerCoverage,
              touched,
              errors,
              commonProps,
              employerAssociationEmployeeProps,
              isCheckout: false,
            }}
          />
        </div>
      )}

      <Checkbox
        className="mt-3"
        error={(errors.agreesToTerms && touched.agreesToTerms) as boolean}
        checked={values.agreesToTerms}
        name="agreesToTerms"
        onChange={handleChange}
        labelProps={{
          'data-testid': 'signup-tos',
        }}
      >
        <span>
          I agree to the{' '}
          <TextButtonExternalLink href="https://www.tavahealth.com/consent-to-care">
            Consent to Care
          </TextButtonExternalLink>{' '}
          &amp;{' '}
          <TextButtonExternalLink href="https://www.tavahealth.com/notice-of-privacy-practices">
            Notice of Privacy Practices
          </TextButtonExternalLink>
        </span>
      </Checkbox>
    </div>
  )
}

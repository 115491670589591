import { useStytchB2BClient } from '@stytch/react/b2b'
import { useLocation } from 'react-router-dom'

import { useEnvironmentContext } from '@nuna/environment'
import { FillButton } from '@nuna/tunic'

import { getFromPath } from '../util/utils'

export type SsoOrganization = {
  name: string
  slug: string
  connectionId: string
}

export function SsoLogin({ organization }: { organization: SsoOrganization }) {
  const { API_ENDPOINT } = useEnvironmentContext()
  const location = useLocation()
  const stytchClient = useStytchB2BClient()

  const fromUrl = new URL(getFromPath(location) ?? '', window.location.protocol + window.location.host)
  const authRedirectURL = new URL(`${API_ENDPOINT}/sso/authenticate`)
  authRedirectURL.searchParams.append('from', encodeURIComponent(fromUrl.href))

  const handleSsoClick = async () => {
    await stytchClient.sso.start({
      connection_id: organization.connectionId,
      login_redirect_url: authRedirectURL.href,
      signup_redirect_url: authRedirectURL.href,
    })
  }

  return (
    <div className="flex-column">
      <h1 className="h3">Welcome!</h1>
      <p className="pb-5 paragraphs">
        {organization.name} is providing you access to licensed mental health professionals through Tava Health's secure
        and confidential online platform.
      </p>
      <FillButton onClick={handleSsoClick}>Continue to {organization.name}</FillButton>
    </div>
  )
}

import { styled } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import { useEffect, useRef, useState } from 'react'

import { supportService } from '@nuna/telemetry'
import {
  Grid,
  IconBook,
  IconBrain,
  IconButtonExternalLink,
  IconTherapist,
  MediumMin,
  TabletPortraitMin,
  TextButtonExternalLink,
  tealSet,
  white,
  whiteTintSet,
} from '@nuna/tunic'

import { AppContainer } from '../Container'

function trackLink(this: HTMLAnchorElement) {
  mixpanel.track('clicked footer link', { href: this.href })
}

export function ClientFooter(props: React.HTMLAttributes<HTMLElement>) {
  const year = useRef(new Date().getFullYear())
  const [footerEl, setFooterEl] = useState<HTMLElement | null>(null)
  const [allLinks, setAllLinks] = useState<NodeListOf<HTMLAnchorElement> | null>(null)

  // We want to see how often these get clicked and unfortunately mixpanel.track_links does not work on dynamically added A tags so I had to cook this up
  useEffect(() => {
    if (footerEl) {
      setAllLinks(footerEl.querySelectorAll('a'))
    }
  }, [footerEl])

  useEffect(() => {
    if (allLinks) {
      allLinks.forEach(linkEl => {
        linkEl.addEventListener('click', trackLink)
      })

      return () => {
        allLinks.forEach(linkEl => {
          linkEl.removeEventListener('click', trackLink)
        })
      }
    }
  }, [allLinks])

  return (
    <Background ref={setFooterEl} {...props}>
      <Content>
        <Top>
          <Grid container spacing={4}>
            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <IconTherapist />
              <p>
                Need help right now? See our{' '}
                <TextButtonExternalLink scheme="dark" href="https://www.tavahealth.com/get-help">
                  crisis resources
                </TextButtonExternalLink>{' '}
                for help during mental health emergencies.
              </p>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <IconBrain />
              <p>
                Looking for tips, tricks, and news? Join our{' '}
                <TextButtonExternalLink
                  scheme="dark"
                  href="https://tavahealth.typeform.com/to/bRiJrCDZ?utm_source=website&utm_medium=web"
                >
                  text tip line
                </TextButtonExternalLink>{' '}
                and subscribe to our{' '}
                <TextButtonExternalLink
                  scheme="dark"
                  href="https://tavahealth.typeform.com/to/Iz07D8FQ?utm_source=website&utm_medium=web"
                >
                  newsletter
                </TextButtonExternalLink>
              </p>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <IconBook />
              <p>
                Eager to keep learning?
                <br />
                Check out these{' '}
                <TextButtonExternalLink scheme="dark" href={supportService.articles.mentalHealthLibrary}>
                  mental health resources
                </TextButtonExternalLink>
              </p>
            </Grid>
          </Grid>
        </Top>

        <Bottom className="caption">
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <LegalLinks
              size={{
                xs: 12,
                sm: 12,
                md: 'auto',
              }}
            >
              <a target="_blank" rel="noreferrer noopener" href="https://www.tavahealth.com/privacy-policy">
                Privacy policy
              </a>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.tavahealth.com/notice-of-privacy-practices"
              >
                Notice of privacy practices
              </a>
              <a target="_blank" rel="noreferrer noopener" href="https://www.tavahealth.com/terms-and-conditions">
                Terms and conditions
              </a>

              <a target="_blank" rel="noreferrer noopener" href="https://www.tavahealth.com/consent-to-care">
                Consent to care
              </a>
            </LegalLinks>

            <SocialLinks
              size={{
                xs: 12,
                sm: 12,
                md: 'grow',
              }}
            >
              <IconButtonExternalLink variant="dark" tooltip="Email us" href="mailto:hello@tavahealth.com">
                <img
                  src="https://assets.website-files.com/615fb409caa14b1bd72ef3fb/62d9c9970389f027ec3c040c_email.svg"
                  alt="Email us"
                />
              </IconButtonExternalLink>
              <IconButtonExternalLink
                variant="dark"
                tooltip="Tava on LinkedIn"
                href="https://www.linkedin.com/company/tava-health"
              >
                <img
                  src="https://assets.website-files.com/615fb409caa14b1bd72ef3fb/62d9c9b65437fb43662bfb1b_linkedin.svg"
                  alt="Tava on LinkedIn"
                />
              </IconButtonExternalLink>
              <IconButtonExternalLink variant="dark" tooltip="Tava on Twitter" href="https://twitter.com/tavahealth">
                <img
                  src="https://assets.website-files.com/615fb409caa14b1bd72ef3fb/62d9c9c4320d41b81bb7df9d_twitter.svg"
                  alt="Tava on Twitter"
                />
              </IconButtonExternalLink>
              <IconButtonExternalLink
                variant="dark"
                tooltip="Tava on Instagram"
                href="https://www.instagram.com/tava_health/"
              >
                <img
                  src="https://assets.website-files.com/615fb409caa14b1bd72ef3fb/62d9c9ce4b0cecd4bd7222de_instagram.svg"
                  alt="Tava on Instagram"
                />
              </IconButtonExternalLink>
            </SocialLinks>
          </Grid>

          <p className="mt-2">&copy; {year.current} Tava Health, Inc. All rights reserved.</p>
        </Bottom>
      </Content>
    </Background>
  )
}

const Background = styled('footer')`
  background-color: ${tealSet[100].hex};
  color: white;
  padding: 2.5rem 1.26rem 3.5rem;
`

const Content = styled(AppContainer)`
  line-height: 1.4;
`

const Top = styled('section')`
  svg {
    color: ${tealSet[50].hex};
    height: 25px;
  }
`

const Bottom = styled('section')`
  border-top: 1px solid ${tealSet[80].hex};
  color: ${whiteTintSet[50]};
  margin-top: var(--margin-3);
  padding-top: var(--margin-2);
`

const SocialLinks = styled(Grid)`
  @media (${MediumMin}) {
    text-align: right;
  }

  a {
    color: ${white.hex};
  }
`

const LegalLinks = styled(Grid)`
  line-height: 2;

  a {
    color: inherit;
    display: block;
    margin-right: var(--margin-1);
  }

  @media (${TabletPortraitMin}) {
    line-height: unset;

    a {
      display: unset;

      &:hover {
        color: ${white.hex};

        &::after {
          color: ${whiteTintSet[50]};
        }
      }

      &:not(:last-child)::after {
        content: '|';
        margin-left: var(--margin-1);
      }
    }
  }
`

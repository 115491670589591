import { ReactNode, createContext, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BelowTablet, TabletPortraitMin } from '@nuna/tunic'

import { getMentalHealthLibraryRoute } from '../../util/routes'
import { Language, PostFragment } from './generated/sanctuary-graphql'
import { useActivePost } from './hooks/useActivePost'

export const FeaturedPostBreakPointMax = BelowTablet
export const FeaturedPostBreakPointMin = TabletPortraitMin

interface MentalHealthLibraryContextValues {
  selectedCategories: Set<string>
  toggleCategory: (category: string) => void
  activePost: PostFragment | null
  openPost: (post: PostFragment) => void
  closePost: () => void
  language: string
}

const MentalHealthLibraryContext = createContext<MentalHealthLibraryContextValues | undefined>(undefined)

export function MentalHealthLibraryContextProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate()
  const activePost = useActivePost()
  const [selectedCategories, setSelectedCategories] = useState<Set<string>>(new Set())
  const [language] = useState(Language.English) // We don't have a design for multilingual support yet

  const toggleCategory = (category: string) =>
    setSelectedCategories(prevCategories => {
      const newCategories = new Set(prevCategories)
      newCategories.has(category) ? newCategories.delete(category) : newCategories.add(category)
      return newCategories
    })

  const openPost = (post: PostFragment) => navigate(getMentalHealthLibraryRoute(post.id))
  const closePost = () => navigate(getMentalHealthLibraryRoute())

  return (
    <MentalHealthLibraryContext.Provider
      value={{ activePost, openPost, closePost, selectedCategories, toggleCategory, language }}
    >
      {children}
    </MentalHealthLibraryContext.Provider>
  )
}

export function useMentalHealthLibraryContext() {
  const context = useContext(MentalHealthLibraryContext)
  if (!context) {
    throw new Error('useMentalHealthLibraryContext must be used within a MentalHealthLibraryContextProvider')
  }
  return context
}

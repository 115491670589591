import { styled } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import { HTMLAttributes, useState } from 'react'
import ReactPlayer from 'react-player'

import { Dialog, greySet, shadowDepth } from '@nuna/tunic'

import playButton from './img/play-button.svg'

interface PublicProviderVideoBioProps extends HTMLAttributes<HTMLDivElement> {
  mediaUrl: string
  gifUrl: string
  providerId: string
}

export function PublicProviderVideoBio({ providerId, gifUrl, mediaUrl, ...props }: PublicProviderVideoBioProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Container {...props}>
      <PreviewContainer>
        <PreviewImage src={gifUrl} alt="" />
        <PlayButton
          onClick={() => {
            mixpanel.track('opened public provider video bio', { providerId })
            setIsOpen(true)
          }}
        >
          <PlayIcon src={playButton} alt="Play" />
        </PlayButton>
      </PreviewContainer>
      <Dialog isOpen={isOpen} noChrome onClose={() => setIsOpen(false)}>
        <ReactPlayer
          url={mediaUrl}
          height="100%"
          width="100%"
          wrapper={VideoContainer}
          controls={true}
          playing={isOpen}
        />
      </Dialog>
    </Container>
  )
}

const Container = styled('div')`
  align-items: center;
  border: 1px solid ${greySet[15].hex};
  box-shadow: ${shadowDepth(1)};
  display: flex;
  border-radius: var(--border-radius);
  overflow: hidden;
  width: 80%;
  max-width: 500px;
`

const PreviewContainer = styled('div')`
  position: relative;
  width: 100%;
`

const PlayButton = styled('button')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
`

const PlayIcon = styled('img')`
  width: 2.5rem;
  height: auto;
`

const PreviewImage = styled('img')`
  width: 100%;
  height: 56.25%;
  object-fit: cover;
  object-position: center;
`

const VideoContainer = styled('div')`
  text-align: center;

  video {
    max-height: 80vh;
    width: auto;
    height: auto;
    max-width: 90%;
  }
`

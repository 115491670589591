import { styled } from '@mui/material'
import { Formik, FormikHelpers } from 'formik'
import moment from 'moment'
import { Navigate, useNavigate } from 'react-router-dom'

import { usePatientSignupWithTokenMutation } from '@nuna/api'
import { OrDivider, SocialLogin, useAuthDataContext, useSignupSearchParams } from '@nuna/auth'
import { errorService, routeService } from '@nuna/core'
import { FillButton, SignupLayout as SignupLayoutComponents, UserChip, tealSet, toast } from '@nuna/tunic'

import { getSignupRoute } from '../../../util/routes'
import { SignupLayout } from '../SignupLayout'
import { faqs } from '../faqItems'
import {
  PasswordDOBStep,
  PasswordDOBValues,
  passwordDOBInitialValues,
  passwordDOBSchema,
} from '../steps/PasswordDOBStep'
import zocdocLogoSrc from './img/zocdoc-logo.svg'

const { Heading } = SignupLayoutComponents

export function PreconfiguredSignup() {
  const [signupWithToken, { loading }] = usePatientSignupWithTokenMutation()
  const { onLogin } = useAuthDataContext()
  const navigate = useNavigate()

  const { firstName, email, tokenId, patientSource, providerName } = useSignupSearchParams()

  if (!tokenId) return <Navigate to={getSignupRoute()} />

  const handleSubmit = async (values: PasswordDOBValues, { setFieldError }: FormikHelpers<PasswordDOBValues>) => {
    try {
      const response = await signupWithToken({
        variables: {
          tokenInfo: {
            password: values.password,
            token: tokenId,
            verificationInfo: { dob: moment.utc(values.dob, 'MM/DD/YYYY').toISOString() },
          },
        },
      })

      if (response.data && onLogin) {
        onLogin({ login: response.data.patientSignupWith.login })
        navigate(routeService.intake())
      } else {
        throw new Error('Could not login after signup')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.message.includes('DOB doesn’t match')) {
        setFieldError('dob', "Oops! Looks like that doesn't match the records we have.")
      } else if (e.message.includes('Token is expired')) {
        toast.urgent('This link has expired. Please sign in instead.', {
          action: { buttonText: 'Sign in', onClick: () => navigate(routeService.login()) },
        })
      } else {
        toast.urgent(
          errorService.transformGraphQlError(e, 'Error completing signup. Please try again or contact support.'),
        )
      }
    }
  }

  const getHeading = () => {
    if (patientSource === 'providerform') {
      return (
        <>
          <Heading className="mb-1">You've been invited to join Tava by your therapist:</Heading>
          <UserChip className="mb-3">{providerName}</UserChip>
        </>
      )
    }

    return (
      <Heading>
        Welcome to Tava
        {firstName ? (
          <>
            , <span>{firstName}</span>
          </>
        ) : (
          ''
        )}
      </Heading>
    )
  }

  const getIntroParagraph = () => {
    if (patientSource === 'providerform') {
      return 'I’m excited to use Tava to connect for our therapy sessions together. Let’s start by answering a few questions so we can develop the right care plan for you.'
    }

    return `${patientSource === 'zocdoc' ? 'Thanks for booking an appointment. ' : ''}You’ve taken the first step and
    we’re with you the rest of the way. Let’s start by answering a few questions so we can develop the right
    care plan for you.`
  }

  return (
    <SignupLayout
      renderForm={
        <Formik initialValues={passwordDOBInitialValues} onSubmit={handleSubmit} validationSchema={passwordDOBSchema}>
          {formikProps => {
            return (
              <Container>
                <SocialLogin
                  audience="client"
                  from="/"
                  heading={
                    <>
                      <h2 className="h3">Finish account setup</h2>
                      <p className="caption text-bold mb-1 mt-2">Personal email:</p>
                      <p className="mb-2">{email}</p>
                    </>
                  }
                  intent="signup"
                />
                <OrDivider className="text-center mb-4 px-3">or</OrDivider>

                <form onSubmit={formikProps.handleSubmit}>
                  <PasswordDOBStep />

                  <FillButton data-testid="signup-get-started" isLoading={loading} className="mt-5" type="submit">
                    Get started
                  </FillButton>
                </form>
              </Container>
            )
          }}
        </Formik>
      }
      renderLeftContent={
        <MobileSpacer>
          {getHeading()}

          <p className="large mb-3">{getIntroParagraph()}</p>

          {patientSource === 'zocdoc' && (
            <ThanksContainer className="v-align mt-4">
              Thanks for booking with <img className="ml-1" src={zocdocLogoSrc} alt="Zocdoc" />
            </ThanksContainer>
          )}
        </MobileSpacer>
      }
      faqItems={faqs}
    />
  )
}

const Container = styled('div')`
  padding: 6rem 0 2rem;
`

const ThanksContainer = styled('div')`
  background-color: ${tealSet[80].hex};
  border-radius: var(--border-radius);
  font-weight: 500;
  padding: 0.75rem 1.5rem;
  display: inline-flex;
`

const MobileSpacer = styled('div')`
  padding: 5rem 0;
`

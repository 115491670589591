import { HTMLAttributes } from 'react'

import { CoverageFiltersSelect } from '../filter-controls/CoverageFiltersSelect'
import { CoverageFilterCategory } from '../filter-controls/FilterCategories'
import { FilterMenu } from './FilterMenu'

export function CoverageFilterMenu(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <FilterMenu {...props} menu={<CoverageFiltersSelect />}>
      <CoverageFilterCategory iconSize={12} />
    </FilterMenu>
  )
}

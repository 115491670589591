import { IconChevronThick, OutlineButton, OutlineButtonLink } from '@nuna/tunic'

import { IntakeNavigation } from '../../../IntakeNavigation'

interface Props {
  nextPath: string | undefined
  disabled?: boolean
  overrideMargins?: boolean
  className?: string
}

export function CoverageNextButton({ nextPath, className = '', overrideMargins = false, disabled = false }: Props) {
  const icon = <IconChevronThick direction="right" className="ml-xs" size={16} />

  return (
    <IntakeNavigation
      overrideMargins={overrideMargins}
      className={`mt-5 ${className}`}
      nextButton={() => {
        if (disabled) {
          return <OutlineButton disabled={disabled}>Next {icon}</OutlineButton>
        }

        return (
          <OutlineButtonLink data-testid="intake-next" to={`../../${nextPath}`}>
            Next {icon}
          </OutlineButtonLink>
        )
      }}
    />
  )
}

import { InvalidSponsorReason } from '@nuna/core'
import { IconCaution, IconCheckCircle, IconError, IconProps, salmonSet, tealSet, yellowSet } from '@nuna/tunic'

export function IntakeCoverageStatusBoot({
  status,
}: {
  status: 'valid' | 'invalid' | 'pending' | InvalidSponsorReason
}) {
  const isSponsorInvalid =
    status === InvalidSponsorReason.notOnTava || status === InvalidSponsorReason.notAvailableToDependents

  const iconProps: IconProps = {
    size: 16,
    className: 'mr-xs',
    color:
      status === 'valid'
        ? tealSet[80].hex
        : isSponsorInvalid
        ? yellowSet[80].hex
        : status === 'invalid'
        ? salmonSet[30].hex
        : undefined,
  }

  return (
    <>
      {status === 'valid' ? (
        <IconCheckCircle {...iconProps} />
      ) : status === 'invalid' ? (
        <IconError {...iconProps} />
      ) : isSponsorInvalid ? (
        <IconCaution {...iconProps} />
      ) : null}
      <span className="caption text-medium">
        {status === 'valid' ? 'Verified' : status === 'invalid' ? 'Invalid' : isSponsorInvalid ? status : 'Pending'}
      </span>
    </>
  )
}

import { FavoriteFilterSelect } from '../filter-controls/FavoriteFilterSelect'
import { FavoriteFilterCategory } from '../filter-controls/FilterCategories'
import { CategoryHeader } from './CategoryHeader'

export function FavoriteSection() {
  return (
    <>
      <CategoryHeader pl={21}>
        <FavoriteFilterCategory iconSize={26} iconContainerWidth={35} />
      </CategoryHeader>
      <div style={{ padding: '11px 28px 0 28px' }}>
        <FavoriteFilterSelect />
      </div>
    </>
  )
}

import { Slider, SliderProps } from '@mui/material'

export interface RangeFieldProps extends Omit<SliderProps, 'onChange'> {
  onChange?: (event: Event, value: number[]) => void
}

export function RangeField({ onChange, ...props }: RangeFieldProps) {
  const handleChange = (event: Event, newValue: number | number[]) => {
    if (onChange) {
      onChange(event, newValue as number[])
    }
  }
  return (
    <Slider
      {...props}
      onChange={handleChange}
      aria-labelledby="range-slider"
      valueLabelDisplay="on"
      getAriaValueText={v => `${v}`}
      // slots={{ valueLabel: ValueLabelComponent, thumb: ThumbComponent }}
    />
  )
}

// const ThumbComponent = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>(({ children, ...props }, ref) => {
//   return (
//     <span {...props} ref={ref}>
//       <span className="callout"></span>
//       {children}
//     </span>
//   )
// })

// const ValueLabelComponent = forwardRef<
//   HTMLDivElement,
//   { children: React.ReactElement; value: number; className: string }
// >(({ children, value, className }, ref) => {
//   return React.cloneElement(
//     children,
//     {
//       className: csx([children.props.className]),
//       ref: ref, // Forward the ref to the cloned element
//     },
//     <StyledValueLabel className={csx([className, 'caption text-medium'])}>{value}</StyledValueLabel>,
//   )
// })

// const StyledValueLabel = styled('span')`
//   z-index: 1;
//   transform-origin: bottom center;
//   color: #000;
// `

// eslint-disable-next-line @typescript-eslint/ban-types
// const muiStyles: Styles<Theme, {}, 'track' | 'rail' | 'thumb'> = {
//   track: {
//     backgroundColor: tealSet[50].hex,
//   },
//   rail: {
//     backgroundColor: greySet[15].hex,
//   },
//   thumb: {
//     borderRadius: '4px',
//     transform: 'translateY(14px) translateX(-6.5px)',
//     backgroundColor: eggshell,
//     border: `1px solid ${borderGrey}`,
//     height: '25px',
//     width: '25px',
//     padding: '5px',
//     '&.MuiSlider-active': {
//       boxShadow: 'none',
//     },
//     '&.Mui-focusVisible': {
//       boxShadow: `0 0 0 4px ${greySet.tint[40]}`,
//     },
//     '&:hover': {
//       boxShadow: shadowDepth(1),
//       backgroundColor: '#fff',
//       borderWidth: '2px',
//       '& .callout': {
//         '&::before': {
//           borderBottomColor: '#fff',
//           top: '0',
//         },
//       },
//     },
//     '& .callout': {
//       width: '0px',
//       height: '0px',
//       border: '4px solid transparent',
//       position: 'absolute',
//       left: '33%',
//       top: '-13px',
//       borderBottom: `8px solid ${borderGrey}`,
//       '&::before': {
//         content: '""',
//         width: '0px',
//         height: '0px',
//         border: '4px solid transparent',
//         position: 'absolute',
//         right: '-4px',
//         top: '-2px',
//         borderBottom: `8px solid ${eggshell}`,
//       },
//     },
//   },
// }

import mixpanel from 'mixpanel-browser'
import { useNavigate } from 'react-router-dom'

import { useSavePatientIntakeMutation } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'

export function useSkipIntake() {
  const { login } = useAuthDataContext()
  const patientId = login?.patientId || ''
  const [savePatientIntake, { loading }] = useSavePatientIntakeMutation({
    refetchQueries: ['PatientContext'], // without this the med management logic gets messed up
  })
  const navigate = useNavigate()

  const skipIntake = async (toRoute = '/') => {
    await savePatientIntake({
      variables: {
        id: patientId,
        input: { intakeCompleted: true },
      },
    })

    mixpanel.track('completed intake - frontend', {})
    navigate(toRoute)
  }

  return {
    skipIntake,
    loading,
  }
}

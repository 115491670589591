import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Illustration } from '@nuna/common'
import { BelowTablet, FillButtonWithChevron, GhostButtonLink, Grid, Skeleton } from '@nuna/tunic'

import { useAssessmentsContext } from '../context/AssessmentsContext'
import { useAssessmentResults } from '../hooks/useAssessmentResults'
import { ResultDetails, Severity } from '../types/types'
import { resultsContent } from '../util/resultsContent'

export function AssessmentResults({ defaultButtonOnClick }: { defaultButtonOnClick: (providerId: string) => void }) {
  const navigate = useNavigate()
  const { assessmentBundle } = useAssessmentsContext()
  const { assessments } = assessmentBundle || {}
  const { hasSuicidalityRisk, maxSeverity, shownProvider } = useAssessmentResults()

  const defaultButton = (
    <FillButtonWithChevron onClick={() => defaultButtonOnClick(shownProvider?.id || '')}>
      Schedule your next session
    </FillButtonWithChevron>
  )

  const backToHomeButtonLink = <GhostButtonLink to="/">Back to home</GhostButtonLink>

  const resultDetails: ResultDetails = {
    [Severity.Minimal]: {
      ...resultsContent[Severity.Minimal],
      button: defaultButton,
      secondaryButton: backToHomeButtonLink,
    },
    [Severity.Mild]: {
      ...resultsContent[Severity.Mild],
      button: defaultButton,
      secondaryButton: backToHomeButtonLink,
    },
    [Severity.Moderate]: {
      ...resultsContent[Severity.Moderate],
      button: defaultButton,
      secondaryButton: backToHomeButtonLink,
    },
    [Severity.Severe]: {
      ...resultsContent[Severity.Severe],
      button: defaultButton,
      secondaryButton: backToHomeButtonLink,
    },
    suicideRisk: {
      ...resultsContent.suicideRisk,
      button: (
        <FillButtonWithChevron onClick={() => navigate('/messages')}>Message your provider</FillButtonWithChevron>
      ),
    },
  }
  const { body, heading, illustration, button, secondaryButton } = hasSuicidalityRisk
    ? resultDetails.suicideRisk
    : resultDetails[maxSeverity]

  const skeletonContent = () => (
    <>
      <h2 className="h3 mb-3">
        <Skeleton height={2} className="mb-1" />
        <Skeleton height={2} />
      </h2>
      <div>
        <Skeleton height={1} className="mb-1" />
        <Skeleton height={1} className="mb-1" />
        <Skeleton height={1} />
      </div>
    </>
  )

  return (
    <Container className="px-3 pb-4">
      <Grid container spacing={4} direction="row-reverse" alignItems="center">
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Illustration src={illustration} alt="" />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 8,
          }}
        >
          <div style={{ paddingRight: '2rem' }}>
            {!assessments ? (
              skeletonContent()
            ) : (
              <>
                <h2 className="h3 mb-3">{heading}</h2>
                <div className="text-secondary paragraphs">{body}</div>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <ButtonsWrapper>
        {button}
        <div>{secondaryButton}</div>
      </ButtonsWrapper>
    </Container>
  )
}

const Container = styled('main')`
  @media (${BelowTablet}) {
    margin-top: 2rem;
  }
`

const ButtonsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-3);
  align-items: center;
  margin-top: var(--spacing-3);
  flex-direction: row;

  @media (${BelowTablet}) {
    flex-direction: column;
  }
`

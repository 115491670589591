import { IconProps } from './IconProps'

export function IconPlay({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (38 / 30) * size

  return (
    <svg width={size} height={height} viewBox="0 0 30 38" xmlns="http://www.w3.org/2000/svg" fill={color} {...props}>
      <path
        d="M28.0077 15.6718C30.3826 17.2551 30.3826 20.7449 28.0077 22.3282L6.21881 36.8541C3.56059 38.6263 0 36.7207 0 33.5259V4.47407C0 1.27929 3.56058 -0.626279 6.2188 1.14587L28.0077 15.6718Z"
        fill="white"
      />
    </svg>
  )
}

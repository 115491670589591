import { styled } from '@mui/material'
import { AnimateSharedLayout, motion } from 'framer-motion'

import { usePatientId } from '@nuna/auth'
import { FeatureFlagCheck } from '@nuna/feature-flag'
import { PolicyAcceptanceAlert } from '@nuna/fee-policies'
import { IconBook, IconCalendar, TabletPortraitMin } from '@nuna/tunic'

import { AppLayout } from '../../layouts/AppLayout'
import { AppointmentList } from '../../shared/components/AppointmentList'
import { ClientPaymentStatusAlert } from '../../shared/components/ClientPaymentStatusAlert'
import { AppContainer } from '../../shared/components/Container'
import { SplitContent } from '../../shared/components/Layout'
import { ProviderList } from '../../shared/components/ProviderList'
import { MentalHealthLibraryWidget } from '../mental-health-library'
import { IntakeMessage } from './IntakeMessage'
import { InviteMessage } from './InviteMessage'

export function Home() {
  const patientId = usePatientId()

  return (
    <AppLayout>
      <Container>
        <IntakeMessage />
        <Alerts>
          {!!patientId && <ClientPaymentStatusAlert scene={'home'} options={{ dismissButtonText: 'Billing Info' }} />}

          <PolicyAcceptanceAlert />
        </Alerts>
        <main>
          <SplitContent>
            <h2 className="h4 v-align">
              <IconCalendar className="mr-1" size={32} /> Appointments
            </h2>
          </SplitContent>

          <AnimateSharedLayout>
            <AppointmentList />

            <motion.div layout>
              <ProviderList />
            </motion.div>

            <FeatureFlagCheck flags={['mentalHealthLibrary']} featureType="component">
              <h2 className="h4 v-align mt-6 mb-3">
                <IconBook size={26} className="mr-1" /> Mental Health Library
              </h2>
              <MentalHealthLibraryWidget />
            </FeatureFlagCheck>

            <InviteMessage />
          </AnimateSharedLayout>
        </main>
      </Container>
    </AppLayout>
  )
}

const Container = styled(AppContainer)`
  padding-bottom: var(--margin-7);

  // makes extra space for x of x cap notifier
  @media (${TabletPortraitMin}) {
    padding-left: 4.25rem;
    padding-right: 4.25rem;
  }
`
const Alerts = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

import { useState } from 'react'

import { DocumentType, useDocumentUpload, useSaveInsuranceCardImagesMutation } from '@nuna/api'

export function useUploadInsuranceCardImages(patientId: string, policyId: string) {
  const [loading, setIsLoading] = useState(false)
  const { uploadDocument } = useDocumentUpload()
  const [saveInsuranceCardImages] = useSaveInsuranceCardImagesMutation()

  const uploadImages = async (front: File, back: File) => {
    setIsLoading(true)

    try {
      const uploadedDocuments = await Promise.all([
        uploadDocument(front, DocumentType.InsuranceCardFront, fileName(policyId, 'cardfront', front.name)),
        uploadDocument(back, DocumentType.InsuranceCardBack, fileName(policyId, 'cardback', back.name)),
      ])

      const updatedPolicy = await saveInsuranceCardImages({
        variables: {
          insurancePolicyInput: { patientId, id: policyId },
          imageUpload: { cardFrontDocumentId: uploadedDocuments[0].id, cardBackDocumentId: uploadedDocuments[1].id },
        },
      })

      setIsLoading(false)

      return updatedPolicy.data?.saveInsurancePolicy
    } catch (e) {
      setIsLoading(false)
      throw e
    }
  }

  return { loading, uploadImages }
}

function fileName(policyId: string, side: 'cardfront' | 'cardback', filename: string) {
  return `${policyId}_${side}.${filename.split('.').pop()}`
}

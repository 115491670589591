import { keyframes } from '@mui/material'
import { styled } from '@mui/material'
import React from 'react'

import { greySet } from '@nuna/tunic'

interface PlaceholderShimmerProps {
  height?: string
  width?: string
  style?: React.CSSProperties
}

export function PlaceholderShimmer({ height = '2rem', width = '100%', style = {}, ...props }: PlaceholderShimmerProps) {
  return <Shimmer style={{ ...style, width, height }} {...props} />
}

export function PlaceholderShimmerCircle({ size = '48px', style = {}, ...props }) {
  return <Shimmer style={{ ...style, width: size, height: size, borderRadius: '50%' }} {...props} />
}

const shimmer = keyframes`
  0% {
    background-color: ${greySet[30].hex};
  }

  50% {
    background-color: ${greySet[15].hex};
  }

  100% {
    background-color: ${greySet[30].hex};
  }
`

const Shimmer = styled('div')`
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: ${shimmer};
  animation-timing-function: linear;
  border-radius: 3px;

  & + & {
    margin-top: 0.5rem;
  }
`

import { css, styled } from '@mui/material'
import { motion } from 'framer-motion'
import mixpanel from 'mixpanel-browser'
import moment from 'moment-timezone'
import React, { useState } from 'react'

import { BasicAppointmentFragment, DetailedAppointmentFragment } from '@nuna/api'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { appointmentService } from '@nuna/core'
import { shadowDepth } from '@nuna/tunic'
import { BelowTablet, RedirectDialog, eggshell, greySet } from '@nuna/tunic'

import { AppointmentCardDetails } from './AppointmentCardDetails'

const CardStyles = css`
  border-radius: var(--border-radius);
  border: 1px solid ${greySet[5].hex};
  box-shadow: ${shadowDepth(1)};
  padding: 1.5rem 2rem;
`

const { isCancelableOnZocdoc } = appointmentService

interface CommonProps {
  isRefreshingAppointments: boolean
  disableAnimations?: boolean
  outOfNetwork?: boolean
  inNetwork?: boolean
  compact?: boolean
  error?: boolean
  canceled?: boolean
  layoutId?: string
  className?: string
  style?: React.CSSProperties
}

type ConditionalProps =
  | { hideActions: true; appointment: BasicAppointmentFragment }
  | { hideActions?: false; appointment: DetailedAppointmentFragment }

export type AppointmentCardProps = CommonProps & ConditionalProps

export function AppointmentCard({
  appointment,
  isRefreshingAppointments,
  disableAnimations = false,
  hideActions = false,
  error = false,
  canceled = false,
  layoutId = undefined,
  compact = false,
  outOfNetwork = false,
  inNetwork = false,
  ...props
}: AppointmentCardProps) {
  const { openCancelAppointmentDrawer } = useAppointmentDrawerSearchParams()
  const [showZocdocRedirectNotice, setShowZocdocRedirectNotice] = useState(false)

  const handleJoinSessionClick = (startDatetime: string) => {
    const minutesBeforeAppointment = moment().diff(startDatetime, 'minutes')

    mixpanel.track('joined session from app', {
      minutesBeforeAppointment,
    })
  }

  const isZocdocCancel = 'source' in appointment && isCancelableOnZocdoc(appointment)

  const handleCancelClick = () => {
    if (isZocdocCancel) {
      setShowZocdocRedirectNotice(true)
      return
    }

    openCancelAppointmentDrawer(appointment.id)
  }

  const animationProps = disableAnimations ? { transition: { duration: 0 } } : {}

  return (
    <>
      <Card
        layout
        layoutId={layoutId ?? appointment.id}
        canceled={canceled}
        compact={compact}
        {...animationProps}
        {...props}
      >
        <AppointmentCardDetails
          appointment={appointment}
          onCancelClick={handleCancelClick}
          onJoinSessionClick={handleJoinSessionClick}
          isRefreshingAppointments={isRefreshingAppointments}
          hideActions={hideActions}
          error={error}
          canceled={canceled}
          layoutId={layoutId ?? appointment.id}
          disableAnimations={disableAnimations}
          compact={compact}
          outOfNetwork={outOfNetwork}
          inNetwork={inNetwork}
        />
      </Card>

      {isZocdocCancel && (
        <RedirectDialog
          isOpen={showZocdocRedirectNotice}
          onClose={() => setShowZocdocRedirectNotice(false)}
          redirectUrl="https://www.zocdoc.com/signin"
          delay={3000}
          target="_self" // This opens the link in the same tab after the delay because otherwise it gets pop-up blocked
        >
          We're redirecting you to Zocdoc to cancel
        </RedirectDialog>
      )}
    </>
  )
}

type AppointmentCardLoaderProps = Pick<AppointmentCardProps, 'compact'>

export const AppointmentCardLoader = ({ compact = false }: AppointmentCardLoaderProps) => (
  <Card style={{ height: compact ? 86 : 132 }} compact={compact} className="loading" canceled={false} />
)

const Card = styled(motion.div)<{ compact: boolean; canceled: boolean }>`
  ${CardStyles}
  align-items: center;
  background-color: ${props => (props.canceled ? eggshell : '#fff')};
  display: flex;
  margin-bottom: 1.25rem;
  padding: ${props => (props.compact ? '1rem' : '1.5rem 2rem')};
  position: relative;
  gap: 1rem;
  flex-wrap: wrap;

  ${props =>
    props.canceled &&
    `
    color: ${greySet[70].transparency(0.5)};

    img {
      opacity: 0.5;
    }
  `}

  @media (${BelowTablet}) {
    gap: 0.5rem;
    align-items: flex-start;
    flex-direction: column;
  }
`

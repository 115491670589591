import { FormControl, MenuItem, Select as MuiSelect, SelectChangeEvent, styled } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'

import { PublicAddress, type PublicAvailabilitySlot, type TimeSlot, usePublicProviderQuery } from '@nuna/api'
import { useAuthDataContext, useSourceReferralSearchParams } from '@nuna/auth'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { addressService, appointmentService, errorService } from '@nuna/core'
import {
  BelowTablet,
  Box,
  FillButton,
  Grid,
  OutlineButton,
  Stack,
  StatusLabel,
  greySet,
  serifFontStack,
  tealSet,
  toast,
  white,
} from '@nuna/tunic'

import { ChipButton } from '../../shared/components/ChipButton'

interface PublicProviderCardScheduleProps {
  selectedInsurance?: string | null
}

enum MeetingType {
  Virtually = 'virtually',
  InPerson = 'in person',
}

export function PublicProviderCardSchedule({ selectedInsurance }: PublicProviderCardScheduleProps) {
  const { login } = useAuthDataContext()
  const { providerSlug = '' } = useParams()
  const { source, sourceChannel } = useSourceReferralSearchParams()
  const { data, error } = usePublicProviderQuery({
    variables: { slug: providerSlug, patientId: login?.patientId, source, sourceChannel },
    skip: !providerSlug,
  })
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const provider = data?.publicProvider

  const providerAddressesLookup = useMemo(() => {
    return new Map<string, PublicAddress>(provider?.therapyLocations.map(location => [location.id, location]) ?? [])
  }, [provider])

  const [selectedMeetingType, setSelectedMeetingType] = useState<string>(MeetingType.Virtually)
  const availableSlotsByLocationAndDate = useMemo(
    () => groupAndSortTimeSlots(provider?.availableTimes ?? []),
    [provider],
  )

  const availableLocations = useMemo(() => {
    return Object.keys(availableSlotsByLocationAndDate)
      .filter(location => location !== MeetingType.Virtually)
      .map(location => ({
        id: location,
        address: providerAddressesLookup.get(location),
      }))
      .filter(location => !!location.address)
  }, [availableSlotsByLocationAndDate, providerAddressesLookup])

  const hasInPersonLocations = availableLocations.length > 0
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(availableLocations[0]?.id ?? null)

  const filteredSlotsBySelectedLocation = useMemo(() => {
    const locationKey = selectedMeetingType === MeetingType.Virtually ? MeetingType.Virtually : selectedAddressId
    if (!locationKey) return {}
    return availableSlotsByLocationAndDate[locationKey] ?? {}
  }, [selectedMeetingType, selectedAddressId, availableSlotsByLocationAndDate])

  const handleMeetingTypeChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setSelectedMeetingType(event.target.value)
      if (event.target.value === MeetingType.Virtually) {
        setSelectedAddressId(null)
      } else {
        setSelectedAddressId(availableLocations[0]?.id ?? null)
      }
    },
    [availableLocations],
  )

  const soonestOpening = Object.values(filteredSlotsBySelectedLocation).flat()[0] ?? null
  const soonestOpeningText = useMemo(() => {
    if (!soonestOpening) return 'Unavailable'

    const start = moment(soonestOpening.start)
    const today = moment().startOf('day')
    const diff = start.diff(today, 'days')
    if (diff === 0) return 'Today'
    if (diff === 1) return 'Tomorrow'
    if (diff > 1) return start.fromNow()
  }, [soonestOpening])

  const { openScheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()

  useEffect(() => {
    if (error) {
      toast.urgent(errorService.transformGraphQlError(error, "Unable to load provider's schedule"))
    }
  }, [error])

  const handleAddressChange = useCallback((event: SelectChangeEvent<string>) => {
    setSelectedAddressId(event.target.value)
  }, [])

  const handleScheduleClick = useCallback(
    (timeSlot?: Pick<TimeSlot, 'start' | 'end'>, registerThenTimeSlot = false) => {
      mixpanel.track('opened scheduling drawer from public provider card')
      const timeSlotTimeStamp = timeSlot ? appointmentService.timeSlotToTimeStamp(timeSlot) : undefined

      if (provider) {
        openScheduleAppointmentDrawer(provider.id, {
          timeSlot: timeSlotTimeStamp,
          addressId: selectedAddressId === MeetingType.Virtually ? undefined : selectedAddressId ?? undefined,
          state: {
            payerName: selectedInsurance ?? undefined,
            providerAcceptsInsurance: provider.insuranceEnrollments.length > 0,
          },
          registerThenTimeSlot,
        })
      }
    },
    [provider, selectedAddressId, selectedInsurance, openScheduleAppointmentDrawer],
  )

  const renderTimeSlots = useCallback(
    (
      slots: { start: string; end: string }[],
      day: string,
      slotsRemaining: number,
      maxColumns = 3,
      fillEmptySlots = true,
    ) => {
      const timeslotElements = slots.map(slot => {
        const formattedChipTime = moment(slot.start).format('h:mma')
        return (
          <StyledChipButton key={slot.start} hoverText={formattedChipTime} onClick={() => handleScheduleClick(slot)}>
            <time dateTime={slot.start}>{formattedChipTime}</time>
          </StyledChipButton>
        )
      })

      const rows = []
      let slotsAdded = 0

      for (let i = 0; i < timeslotElements.length && slotsAdded < slotsRemaining; i += maxColumns) {
        const rowSlots = timeslotElements.slice(i, i + maxColumns)
        if (fillEmptySlots) {
          while (rowSlots.length < maxColumns) {
            rowSlots.push(<EmptySlot key={`empty-${i + rowSlots.length}`} />)
          }
        }
        rows.push(
          <Row key={i}>
            {i === 0 ? (
              <>
                <Header className="text-bold body">{moment(day).format('ddd, M/D')}</Header>
                <SlotContainer>{rowSlots}</SlotContainer>
              </>
            ) : (
              <>
                <Placeholder />
                <SlotContainer>{rowSlots}</SlotContainer>
              </>
            )}
          </Row>,
        )
        slotsAdded += rowSlots.length
      }

      return { rows, slotsAdded }
    },
    [handleScheduleClick],
  )

  const renderSlots = useCallback(
    (maximumSlotsPerRow = 3, fillEmptySlots = true) => {
      let slotsRendered = 0
      const maxSlots = 9
      const result = []

      for (const [day, slots] of Object.entries(filteredSlotsBySelectedLocation)) {
        if (slotsRendered >= maxSlots) break

        // Calculate the remaining slots we can render without exceeding maxSlots
        const slotsRemaining = maxSlots - slotsRendered
        const { rows, slotsAdded } = renderTimeSlots(
          slots.slice(0, slotsRemaining),
          day,
          slotsRemaining,
          maximumSlotsPerRow,
          fillEmptySlots,
        )

        slotsRendered += slotsAdded
        result.push(<div key={day}>{rows}</div>)
      }

      return result
    },
    [filteredSlotsBySelectedLocation, renderTimeSlots],
  )

  return (
    <div data-testid="public-provider-card-schedule">
      <div>
        {hasInPersonLocations ? (
          <Stack direction={['column', 'row']} alignItems="baseline" spacing={[0, 1]}>
            <MeetHeader className="mb-2">Choose a time to meet&nbsp;</MeetHeader>
            <FormControl sx={{ minWidth: 100 }}>
              <MuiSelect
                value={selectedMeetingType}
                onChange={handleMeetingTypeChange}
                sx={{
                  color: tealSet[70].hex,
                  fontSize: '24px',
                  '&::after': {
                    border: `5px solid ${tealSet['tint'][40]}`,
                    transform: 'scaleX(1)  translateX(0)',
                  },
                }}
                inputProps={{ style: { fontSize: '1.5rem', fontFamily: serifFontStack, color: tealSet[70].hex } }}
              >
                <MenuItem value={MeetingType.Virtually}>virtually</MenuItem>
                <MenuItem value={MeetingType.InPerson}>in person</MenuItem>
              </MuiSelect>
            </FormControl>
          </Stack>
        ) : (
          <MeetHeader className="mb-2">Choose a time to meet</MeetHeader>
        )}
        {selectedMeetingType === MeetingType.InPerson && hasInPersonLocations && (
          <FormControl sx={{ minWidth: 100, mb: 3, mt: [2, 0] }}>
            <MuiSelect value={selectedAddressId ?? ''} onChange={handleAddressChange} sx={{ mt: 2 }}>
              {availableLocations.map(({ id, address }) => (
                <MenuItem key={id} value={id}>
                  {address ? addressService.formatAddress(address) : 'Address not available'}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
        )}
        <Grid container className="mb-1">
          <Grid
            size={{
              xs: 12,
              sm: 'grow',
            }}
          >
            <Box
              component="span"
              className="soonest-opening"
              sx={{ color: greySet[80].hex, my: 2, display: 'inline-block' }}
            >
              Soonest Opening:
            </Box>
            <StatusLabel intent="information" className="ml-1">
              {soonestOpeningText}
            </StatusLabel>
          </Grid>
        </Grid>
        <div>{renderSlots(isMobile ? 9 : undefined, !isMobile)}</div>
      </div>
      <Grid container spacing={2}>
        <Grid>
          <OutlineButton
            data-testid="public-provider-more-times-button"
            className="mt-2"
            onClick={() => handleScheduleClick()}
          >
            More times
          </OutlineButton>
        </Grid>
        <Grid>
          <FillButton
            data-testid="public-provider-schedule-button"
            className="mt-2"
            onClick={() => handleScheduleClick(undefined, true)}
          >
            Schedule
          </FillButton>
        </Grid>
      </Grid>
    </div>
  )
}

function groupAndSortTimeSlots(
  timeSlots: PublicAvailabilitySlot[],
): Record<string, Record<string, PublicAvailabilitySlot[]>> {
  const grouped: Record<string, Record<string, PublicAvailabilitySlot[]>> = {}

  timeSlots.forEach(slot => {
    const date = slot.start.split('T')[0]
    const addressId = slot.addressId
    const isVirtualAllowed = slot.virtualAllowed

    if (addressId) {
      grouped[addressId] = grouped[addressId] ?? {}
      grouped[addressId][date] = grouped[addressId][date] ?? []
      grouped[addressId][date].push(slot)
    }

    if (isVirtualAllowed) {
      grouped[MeetingType.Virtually] = grouped[MeetingType.Virtually] ?? {}
      grouped[MeetingType.Virtually][date] = grouped[MeetingType.Virtually][date] ?? []
      grouped[MeetingType.Virtually][date].push(slot)
    }
  })

  return grouped
}

const Row = styled('div')`
  display: grid;
  grid-template-columns: 95px 1fr;
  align-items: center;
  margin-bottom: 8px;
  align-items: baseline;

  @media (max-width: 768px) {
    grid-template-columns: 55px 1fr;
  }
`

const SlotContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
`

const Placeholder = styled('div')`
  width: 95px;
`

const StyledChipButton = styled(ChipButton)`
  margin-bottom: 0;
  padding: 1rem;
  min-width: 95px;
  border: 1px solid ${greySet[15].hex};
  color: ${tealSet[70].hex};

  &:hover {
    border: 1px solid ${tealSet[30].hex};
    background: ${tealSet[5].hex};
    color: ${tealSet[80].hex};
  }
`

const Header = styled('h6')`
  text-align: left;
  padding-bottom: 4px;
  background-color: ${white.hex};
  color: ${greySet[80].hex};
  font-weight: 500;
`

const MeetHeader = styled('h5')`
  color: ${tealSet[100].hex};
`

const EmptySlot = styled('div')`
  width: 95px;
  height: 44px;
`

import { styled } from '@mui/material'
import { motion } from 'framer-motion'
import { isNil } from 'lodash'
import { Fragment, useContext } from 'react'

import {
  CapNotifier,
  CapNotifierType,
  DetailedAppointmentFragment,
  Maybe,
  PatientPaymentStatus,
  usePatientContextQuery,
} from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { appointmentService, patientService } from '@nuna/core'
import { CapProgress } from '@nuna/coverage'

import { LargeRectangleListLoader } from '../../../loaders/LargeRectangleListLoader'
import { SessionLimitsModalContext } from '../../../scenes/home/SessionLimitsModal'
import { AppointmentCard } from '../AppointmentCard'
import { AddAppointmentButton } from './AddAppointmentButton'
import { CapResetDivider } from './CapResetDivider'

type AppointmentWithNotifier = [DetailedAppointmentFragment | null, Maybe<CapNotifier>] // [appointment, notifier]

export function AppointmentList() {
  const isAdmin = useIsAdmin()
  const { loading, error, data } = usePatientContextQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })
  const { openScheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()
  const [, setIsOpen] = useContext(SessionLimitsModalContext)

  if (loading && !data) {
    return (
      <div className="">
        <LargeRectangleListLoader numItems={1} />
      </div>
    )
  }

  if (error || !data) {
    return null
  }

  const {
    patientContext: {
      patient,
      capInfo: { sessionNumberLimit, capNotifiers },
    },
  } = data

  const isOverduePatientPaymentStatus = patient.paymentStatus === PatientPaymentStatus.Overdue
  const isDisabledAddAppointmentButton = isOverduePatientPaymentStatus && !isAdmin

  // Only show future appointments that haven't been canceled
  const appointments = appointmentService.upcomingValidAppointments(patient.appointments)

  // notifiers to be shown at the top
  const unassociatedNotifiers = capNotifiers
    .filter(notifier => notifier.adjacentIdentifier === null)
    .map(notifier => [null, notifier])

  // create tuples of appointments & adjacent notifiers
  const appointmentsWithNotifiers: AppointmentWithNotifier[] = appointments.reduce(
    (accumulator: AppointmentWithNotifier[], appointment) => {
      const adjacentNotifier: Maybe<CapNotifier> =
        capNotifiers.find(notifier => notifier.adjacentIdentifier === appointment.id) ?? null

      accumulator.push([appointment, adjacentNotifier])

      return accumulator
    },
    [...unassociatedNotifiers] as AppointmentWithNotifier[],
  )

  return (
    <Container layout>
      {!isNil(sessionNumberLimit) && sessionNumberLimit > 0 && (
        <motion.div layout>
          <CapProgress className="mb-2" onLearnMoreClick={() => setIsOpen(true)} />
        </motion.div>
      )}

      {appointmentsWithNotifiers.map(([appointment, notifier]) => (
        <Fragment key={`${appointment?.id}-${notifier?.displayLabel}`}>
          {notifier && notifier?.type === CapNotifierType.SessionCountReset && (
            <CapResetDivider layout layoutId={`divider-${notifier.type}`} className={loading ? 'loading' : ''}>
              {notifier.displayLabel}
            </CapResetDivider>
          )}

          {appointment && (
            <AppointmentCard layoutId={appointment.id} appointment={appointment} isRefreshingAppointments={loading} />
          )}

          {notifier && notifier?.type !== CapNotifierType.SessionCountReset && (
            <CapResetDivider layout layoutId={`divider-${notifier.type}`} className={loading ? 'loading' : ''}>
              {notifier.displayLabel}
            </CapResetDivider>
          )}
        </Fragment>
      ))}

      <motion.div layout>
        <AddAppointmentButton
          disabled={isDisabledAddAppointmentButton}
          data-testid="add-appointment-button"
          onClick={() => {
            const provider = patientService.getDefaultProvider(patient?.providers)
            if (provider) {
              openScheduleAppointmentDrawer(provider.id)
            }
          }}
        />
      </motion.div>
    </Container>
  )
}

const Container = styled(motion.div)`
  margin-bottom: 3.5rem;
`

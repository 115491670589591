import { ReactNode } from 'react'

import { AppointmentDrawer } from '@nuna/appointment-drawer'
import { useAuthDataContext } from '@nuna/auth'

import { ClientFooter } from '../shared/components/ClientFooter'
import { Navbar } from './AppLayout/Navbar'

interface PublicProviderLayoutProps {
  children: ReactNode
}

export function PublicProviderLayout({ children }: PublicProviderLayoutProps) {
  const { loggedIn } = useAuthDataContext()

  return (
    <>
      <Navbar disableMenu={!loggedIn} disableMessagesLink={!loggedIn} />
      <main>{children}</main>
      <ClientFooter />
      <AppointmentDrawer audience="client" />
    </>
  )
}

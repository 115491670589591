import { styled } from '@mui/material'

import {
  CoverageValidationOptions,
  type DiscriminatedPatientCoverageFragment,
  type InvalidSponsorCoverageFragment,
  coverageService,
} from '@nuna/core'
import { CoverageStatus } from '@nuna/coverage'
import { StatusLabel, interactiveText, interactiveTextHover } from '@nuna/tunic'

import { IntakeCoverageStatusBoot } from './IntakeCoverageStatusBoot'

interface Props {
  coverage: DiscriminatedPatientCoverageFragment | InvalidSponsorCoverageFragment
  onClick?: (coverage: DiscriminatedPatientCoverageFragment | InvalidSponsorCoverageFragment) => void
  onEditClick?: () => void
  validationOptions?: CoverageValidationOptions
  isPrimary?: boolean
}

export function IntakeCoverageStatus({ coverage, validationOptions, isPrimary = false, onClick, onEditClick }: Props) {
  const details = coverage.details

  const invalidSponsor = 'invalidSponsorReason' in details
  const isValid = invalidSponsor ? false : coverageService.isCoverageValid(details, validationOptions)

  return (
    <CoverageStatus
      data-testid={`intake-coverage-slot-${coverage.type}`}
      intent={invalidSponsor ? 'caution' : isValid ? 'information' : 'urgent'}
      coverage={coverage}
      onClick={onClick}
      expanded
      headerSlot={
        <>
          {isPrimary && <StatusLabel className="mr-xs">Primary</StatusLabel>}
          {onEditClick && (
            <EditButton onClick={onEditClick} className="mr-xs">
              Edit
            </EditButton>
          )}
        </>
      }
      boot={
        <IntakeCoverageStatusBoot
          status={isValid ? 'valid' : invalidSponsor ? details.invalidSponsorReason : 'invalid'}
        />
      }
    />
  )
}

const EditButton = styled('button')`
  color: ${interactiveText};
  font-weight: 600;

  &:hover {
    color: ${interactiveTextHover};
    text-decoration: underline;
  }
`

import { useMediaQuery } from 'react-responsive'

import { BelowTablet } from '@nuna/tunic'

import { useSearchProviderContext } from './SearchProviderContext'
import { FilterBar } from './desktop/FilterBar'
import { FilterDialog } from './mobile/FilterDialog'
import { ResultsList } from './results/ResultsList'

export function SearchProvider() {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { filterDialogOpen, setFilterDialogOpen } = useSearchProviderContext()
  return (
    <div>
      {!isMobile && <FilterBar className="mb-3 mt-2" />}
      <FilterDialog isOpen={filterDialogOpen} onClose={() => setFilterDialogOpen(false)} />
      <ResultsList />
    </div>
  )
}

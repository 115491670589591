import { styled } from '@mui/material'
import { forwardRef, useRef } from 'react'

import {
  Grid,
  IconButtonExternalLink,
  Logo,
  MediumMin,
  PhoneMin,
  greySet,
  tealSet,
  white,
  whiteTintSet,
} from '@nuna/tunic'

import { AppContainer } from '../../../shared/components/Container'

interface InternationalFooterProps extends React.HTMLAttributes<HTMLElement> {
  showTOS: boolean
}

export const InternationalFooter = forwardRef<HTMLDivElement, InternationalFooterProps>(
  ({ showTOS, ...props }, ref) => {
    const year = useRef(new Date().getFullYear())

    return (
      <Background ref={ref} {...props}>
        <Content>
          <Bottom className="caption">
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <LegalLinks
                size={{
                  xs: 12,
                  sm: 12,
                  md: 'auto',
                }}
              >
                <div>
                  <Logo height={24} renderText={false} color="white" scheme="dark" className="mr-1" />
                  {showTOS && (
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://tavahealth.com/international-terms-of-use"
                    >
                      Terms of use
                    </a>
                  )}
                  <span>&copy; {year.current} Tava Health, Inc. All rights reserved</span>
                </div>
              </LegalLinks>

              <SocialLinks
                size={{
                  xs: 12,
                  sm: 12,
                  md: 'grow',
                }}
              >
                <IconButtonExternalLink
                  variant="dark"
                  tooltip="Tava on LinkedIn"
                  href="https://www.linkedin.com/company/tava-health"
                >
                  <img
                    src="https://assets.website-files.com/615fb409caa14b1bd72ef3fb/62d9c9b65437fb43662bfb1b_linkedin.svg"
                    alt="Tava on LinkedIn"
                  />
                </IconButtonExternalLink>
                <IconButtonExternalLink variant="dark" tooltip="Tava on Twitter" href="https://twitter.com/tavahealth">
                  <img
                    src="https://assets.website-files.com/615fb409caa14b1bd72ef3fb/62d9c9c4320d41b81bb7df9d_twitter.svg"
                    alt="Tava on Twitter"
                  />
                </IconButtonExternalLink>
                <IconButtonExternalLink
                  variant="dark"
                  tooltip="Tava on Instagram"
                  href="https://www.instagram.com/tava_health/"
                >
                  <img
                    src="https://assets.website-files.com/615fb409caa14b1bd72ef3fb/62d9c9ce4b0cecd4bd7222de_instagram.svg"
                    alt="Tava on Instagram"
                  />
                </IconButtonExternalLink>
              </SocialLinks>
            </Grid>
          </Bottom>
        </Content>
      </Background>
    )
  },
)

const Background = styled('footer')`
  background-color: ${tealSet[100].hex};
  color: white;
  padding: 2.5rem 1.26rem 3.5rem;
`

const Content = styled(AppContainer)`
  line-height: 1.4;
`

const Bottom = styled('section')`
  color: ${whiteTintSet[50]};
  margin-top: var(--margin-3);
`

const SocialLinks = styled(Grid)`
  @media (${MediumMin}) {
    text-align: right;
  }

  a {
    color: ${white.hex};
    &:first-child {
      margin-left: -12px;
    }
  }
`

const LegalLinks = styled(Grid)`
  > div {
    line-height: 2.5;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  a {
    color: ${greySet[30].hex};
    display: block;
    margin-right: var(--margin-1);
    white-space: nowrap;

    @media (${PhoneMin}) {
      &:hover {
        color: ${white.hex};
      }

      &:not(:last-child)::after {
        content: '|';
        margin-left: var(--margin-1);
        color: ${whiteTintSet[50]};
      }
    }
  }
`

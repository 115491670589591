import { styled } from '@mui/material'
import { HTMLAttributes, useMemo } from 'react'

import { GhostButtonLink, csx } from '@nuna/tunic'

import { getMentalHealthLibraryRoute } from '../../../util/routes'
import { FeaturedPostBreakPointMax } from '../MentalHealthLibraryContext'
import { PostFragment, usePostListQuery } from '../generated/sanctuary-graphql'
import { Post, PostSkeleton } from './Post'

interface FeaturedPostsProps extends HTMLAttributes<HTMLDivElement> {
  showMoreLink?: boolean
  hideTime?: boolean
}

export function FeaturedPosts({ showMoreLink = false, hideTime = false, ...props }: FeaturedPostsProps) {
  const { data } = usePostListQuery()

  const selectedPosts: (PostFragment | null)[] = useMemo(
    function getRandomPosts() {
      if (!data) return new Array(4).fill(null)

      return [...data.getPosts].sort(() => Math.random() - Math.random()).slice(0, 4)
    },
    [data],
  )

  return (
    <GridContainer {...props}>
      {selectedPosts.map((post, index) => {
        const large = index === 0

        if (!post) {
          return <PostSkeleton key={index} className={csx([{ 'featured-large': large }])} />
        }

        return (
          <Post
            key={post.id}
            hideTime={hideTime}
            post={post}
            large={large}
            imageOptions={large ? { width: 1000 } : undefined}
            truncateDescription
          />
        )
      })}

      {showMoreLink && (
        <ShowMoreLink>
          <GhostButtonLink to={getMentalHealthLibraryRoute()}>see more...</GhostButtonLink>
        </ShowMoreLink>
      )}
    </GridContainer>
  )
}

const GridContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  column-gap: var(--margin-4);
  row-gap: var(--margin-2);
  margin-bottom: 96px; // makes up for featured post having absolute positioning for grid alignment

  @media (${FeaturedPostBreakPointMax}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin-bottom: 0;
  }
`

const ShowMoreLink = styled('div')`
  grid-column: 2;

  @media (${FeaturedPostBreakPointMax}) {
    grid-column: 1;
  }
`

// could not figure out how to import the proper types from framer-motion so I gave up
export interface Animation {
  // eslint-disable-next-line @typescript-eslint/ban-types
  initial: object
  // eslint-disable-next-line @typescript-eslint/ban-types
  animate: object
  // eslint-disable-next-line @typescript-eslint/ban-types
  exit?: object
}

export const simpleFade: Animation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

export const fadeInUpAnimation: Animation = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
  },
}

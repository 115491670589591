import { GhostButtonLink, IconChevronThick, IconGlobe, StatusLabel } from '@nuna/tunic'

import { getSignupRoute } from '../../../util/routes'

export function RedirectNotice() {
  return (
    <div>
      <StatusLabel>
        <span className="v-align-inline">
          <IconGlobe size={12} className="mr-xs" /> Global Signup
        </span>
      </StatusLabel>

      <p style={{ fontSize: '1.625rem' }} className="my-4 text-light">
        One second… we’re redirecting you to the US signup to get started.
      </p>

      <GhostButtonLink to={getSignupRoute()}>
        Take me there now <IconChevronThick size={18} direction="right" />
      </GhostButtonLink>
    </div>
  )
}

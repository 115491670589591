import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {"context":{"clientName":"sanctuary"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTimeISO: any;
};

/** Accent */
export enum Accent {
  American = 'american',
  British = 'british',
  Dutch = 'dutch',
  Flemish = 'flemish',
  French = 'french',
  German = 'german',
  Italian = 'italian',
  Romanian = 'romanian',
  Spanish = 'spanish'
}

export type AccentAttributeWhereOperators = {
  /** EndsWith operator */
  _endsWith?: InputMaybe<Scalars['String']>;
  /** Equals operator */
  _eq?: InputMaybe<Accent>;
  /** ILike operator */
  _iLike?: InputMaybe<Scalars['String']>;
  /** IRegexp operator */
  _iRegexp?: InputMaybe<Scalars['String']>;
  /** Like operator */
  _like?: InputMaybe<Scalars['String']>;
  /** NotEquals operator */
  _ne?: InputMaybe<Accent>;
  /** NotILike operator */
  _notILike?: InputMaybe<Scalars['String']>;
  /** NotIRegexp operator */
  _notIRegexp?: InputMaybe<Scalars['String']>;
  /** NotLike operator */
  _notLike?: InputMaybe<Scalars['String']>;
  /** NotRegexp operator */
  _notRegexp?: InputMaybe<Scalars['String']>;
  /** Or operator */
  _or?: InputMaybe<Array<Accent>>;
  /** Regexp operator */
  _regexp?: InputMaybe<Scalars['String']>;
  /** StartsWith operator */
  _startsWith?: InputMaybe<Scalars['String']>;
  /** Substring operator */
  _substring?: InputMaybe<Scalars['String']>;
};

/** Article media */
export type ArticleMedia = {
  __typename?: 'ArticleMedia';
  /** Approximate time to read article */
  approximateTime: Scalars['Int'];
  /** Rich text content of article given as JSON string of HTML */
  contentHTML?: Maybe<Scalars['String']>;
  /** Rich text preview of article given as JSON string of HTML */
  contentHTMLPreview?: Maybe<Scalars['String']>;
  /** Specific country article is produced for */
  country: Array<Country>;
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Creator of article */
  creator: Creator;
  /** Creator id of article */
  creatorId: Scalars['String'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** UUID of article */
  id: Scalars['String'];
  /** Language of article */
  language: Language;
  /** Photo for article */
  photo: File;
  /** Post of article */
  post: Post;
  /** Post id of article */
  postId: Creator;
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};

/** Article media version */
export type ArticleMediaVersion = {
  __typename?: 'ArticleMediaVersion';
  /** Approximate time to read article */
  approximateTime: Scalars['Int'];
  /** Details of article */
  articleDetails: PostDetails;
  /** Rich text content of article given as JSON string of HTML */
  articleHTML: Scalars['String'];
  /** Rich text preview of article given as JSON string of HTML */
  articleHTMLPreview?: Maybe<Scalars['String']>;
  /** UUID of article */
  articleId: Scalars['String'];
  /** Language of article */
  articleLanguage: Language;
  /** Specific country article is produced for */
  country: Array<Country>;
  /** Creator of article version */
  creator: Creator;
  /** Creator id of article version */
  creatorId: Scalars['String'];
  /** Photo for article */
  photo?: Maybe<File>;
  /** Post of article version */
  post: Post;
  /** Post id of article version */
  postId: Scalars['String'];
  /** MD5 hash of version change state */
  versionHash: Scalars['String'];
};

export type ArticleMediaWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<ArticleMediaWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<ArticleMediaWhere>>;
  /** Approximate time to read article */
  approximateTime?: InputMaybe<NumberAttributeWhereOperators>;
  /** Rich text content of article given as JSON string of HTML */
  contentHTML?: InputMaybe<StringAttributeWhereOperators>;
  /** Rich text preview of article given as JSON string of HTML */
  contentHTMLPreview?: InputMaybe<StringAttributeWhereOperators>;
  /** Specific country article is produced for */
  country?: InputMaybe<CountryArrayAttributeWhereOperators>;
  /** Creator id of article */
  creatorId?: InputMaybe<UuidAttributeWhereOperators>;
  /** UUID of article */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Language of article */
  language?: InputMaybe<LanguageAttributeWhereOperators>;
  /** Post id of article */
  postId?: InputMaybe<UuidAttributeWhereOperators>;
};

/** Audio media */
export type AudioMedia = {
  __typename?: 'AudioMedia';
  /** Approximate time to listen to audio */
  approximateTime: Scalars['Int'];
  /** Specific country audio is produced for */
  country: Array<Country>;
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Creator of audio */
  creator: Creator;
  /** Creator id of audio */
  creatorId: Scalars['String'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** UUID of audio */
  id: Scalars['String'];
  /** Language of audio */
  language: Language;
  /** Media for audio */
  media: File;
  /** Photo for audio */
  photo: File;
  /** Post of audio */
  post: Post;
  /** Post id of audio */
  postId: Scalars['String'];
  /** Rich text content of audio script given as JSON string of HTML */
  scriptHTML?: Maybe<Scalars['String']>;
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};

/** Video media version */
export type AudioMediaVersion = {
  __typename?: 'AudioMediaVersion';
  /** Approximate time to listen to audio */
  approximateTime: Scalars['Int'];
  /** Details of audio */
  audioDetails: PostDetails;
  /** UUID of audio */
  audioId: Scalars['String'];
  /** Language of audio */
  audioLanguage?: Maybe<Language>;
  /** URL of audio */
  audioUrl?: Maybe<Scalars['String']>;
  /** Specific country audio is produced for */
  country: Array<Country>;
  /** Creator of audio version */
  creator: Creator;
  /** Creator id of audio version */
  creatorId: Scalars['String'];
  /** Photo for audio */
  photo: File;
  /** Post of audio version */
  post: Post;
  /** Post id of audio version */
  postId: Scalars['String'];
  /** Rich text content of audio given as JSON string of HTML */
  scriptHTML?: Maybe<Scalars['String']>;
  /** MD5 hash of version change state */
  versionHash: Scalars['String'];
};

export type AudioMediaWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<AudioMediaWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<AudioMediaWhere>>;
  /** Approximate time to listen to audio */
  approximateTime?: InputMaybe<NumberAttributeWhereOperators>;
  /** Specific country audio is produced for */
  country?: InputMaybe<CountryArrayAttributeWhereOperators>;
  /** Creator id of audio */
  creatorId?: InputMaybe<UuidAttributeWhereOperators>;
  /** UUID of audio */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Language of audio */
  language?: InputMaybe<LanguageAttributeWhereOperators>;
  /** Post id of audio */
  postId?: InputMaybe<UuidAttributeWhereOperators>;
  /** Rich text content of audio script given as JSON string of HTML */
  scriptHTML?: InputMaybe<StringAttributeWhereOperators>;
};

export type BooleanAttributeWhereOperators = {
  /** Equals operator */
  _eq?: InputMaybe<Scalars['Boolean']>;
  /** NotEquals operator */
  _ne?: InputMaybe<Scalars['Boolean']>;
};

/** Video closed caption */
export type Caption = {
  __typename?: 'Caption';
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** UUID of subtitle */
  id: Scalars['String'];
  /** Language of subtitle */
  language: Language;
  /** SRT file for subtitle */
  srt: File;
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
  /** Video for subtitle */
  videoMedia: VideoMedia;
  /** Video id for subtitle */
  videoMediaId: Scalars['String'];
};

export type CaptionWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<CaptionWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<CaptionWhere>>;
  /** UUID of subtitle */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Language of subtitle */
  language?: InputMaybe<LanguageAttributeWhereOperators>;
  /** Video id for subtitle */
  videoMediaId?: InputMaybe<UuidAttributeWhereOperators>;
};

/** Card (language invariant) */
export type Card = {
  __typename?: 'Card';
  /** Card pack details list of card */
  cardPackDetails: CardPackDetails;
  /** Id of card pack details of ingredient list item */
  cardPackDetailsId?: Maybe<Scalars['String']>;
  /** Rich text content of card given as JSON string of HTML */
  contentHTML?: Maybe<Scalars['String']>;
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** UUID of card */
  id: Scalars['String'];
  /** Photo for card */
  photo?: Maybe<File>;
  /** Position of card in card pack */
  position: Scalars['Int'];
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};

/** Card Pack (language invariant) */
export type CardPack = {
  __typename?: 'CardPack';
  /** Approximate preparation time for card pack given in minutes */
  approximateTime: Scalars['Int'];
  /** Details list of card pack */
  cardPackDetailsList: Array<CardPackDetails>;
  /** List of categories card pack is included in */
  category: Array<Category>;
  /** Content value of card pack */
  contentValue: Scalars['Int'];
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** UUID of card pack */
  id: Scalars['String'];
  /** Photo for card pack */
  photo: File;
  /** Topic of the card pack */
  topic: Array<ContentTopic>;
  /** Universal title of card pack */
  universalTitle: Scalars['String'];
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};


/** Card Pack (language invariant) */
export type CardPackCardPackDetailsListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardPackDetailsWhere>;
};

/** Card Pack details (language specific) */
export type CardPackDetails = {
  __typename?: 'CardPackDetails';
  /** Card pack */
  cardPack: Card;
  /** Crd pack id */
  cardPackId: Scalars['String'];
  /** Cards for card pack */
  cards: Array<Card>;
  /** Specific country card pack is produced for */
  country: Array<Country>;
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** Description for the card pack */
  description: Scalars['String'];
  /** UUID of card pack details */
  id: Scalars['String'];
  /** Keywords for the card pack */
  keywords?: Maybe<Array<Scalars['String']>>;
  /** Language of card pack details */
  language: Language;
  /** Learning outcome for the card pack */
  learningOutcome?: Maybe<Scalars['String']>;
  /** Title for the card pack */
  title: Scalars['String'];
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};


/** Card Pack details (language specific) */
export type CardPackDetailsCardsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardWhere>;
};

export type CardPackDetailsWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<CardPackDetailsWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<CardPackDetailsWhere>>;
  /** Card pack id */
  cardPackId?: InputMaybe<UuidAttributeWhereOperators>;
  /** Specific country card pack is produced for */
  country?: InputMaybe<CountryArrayAttributeWhereOperators>;
  /** Description for the card pack */
  description?: InputMaybe<StringAttributeWhereOperators>;
  /** UUID of card pack details */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Keywords for the card pack */
  keywords?: InputMaybe<StringArrayAttributeWhereOperators>;
  /** Language of card pack details */
  language?: InputMaybe<LanguageAttributeWhereOperators>;
  /** Learning outcome for the card pack */
  learningOutcome?: InputMaybe<StringAttributeWhereOperators>;
  /** Title for the card pack */
  title?: InputMaybe<StringAttributeWhereOperators>;
};

export type CardPackWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<CardPackWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<CardPackWhere>>;
  /** Approximate time for card pack given in minutes */
  approximateTime?: InputMaybe<NumberAttributeWhereOperators>;
  /** List of categories card pack is included in */
  category?: InputMaybe<CategoryArrayAttributeWhereOperators>;
  /** Content value of card pack */
  contentValue?: InputMaybe<NumberAttributeWhereOperators>;
  /** UUID of card pack */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Topic of the card pack */
  topic?: InputMaybe<TopicArrayAttributeWhereOperators>;
  /** Universal title of card pack */
  universalTitle?: InputMaybe<StringAttributeWhereOperators>;
};

export type CardWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<CardWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<CardWhere>>;
  /** Rich text content of card given as JSON string of HTML */
  contentHTML?: InputMaybe<StringAttributeWhereOperators>;
  /** UUID of card */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Universal title of card */
  position?: InputMaybe<NumberAttributeWhereOperators>;
};

/** Content category */
export enum Category {
  ConditionAddictionAlcoholusedisorder = 'condition_addiction_alcoholusedisorder',
  ConditionAddictionBingeeating = 'condition_addiction_bingeeating',
  ConditionAddictionGamblingdisorder = 'condition_addiction_gamblingdisorder',
  ConditionAddictionNicotinedependence = 'condition_addiction_nicotinedependence',
  ConditionAddictionOpioidusedisorder = 'condition_addiction_opioidusedisorder',
  ConditionAddictionSexandpornaddiction = 'condition_addiction_sexandpornaddiction',
  ConditionAddictionSubstanceusedisorder = 'condition_addiction_substanceusedisorder',
  ConditionAnxietyPanicGeneralizedAnxietyDisorder = 'condition_anxietyPanic_generalizedAnxietyDisorder',
  ConditionAnxietyPanicOcd = 'condition_anxietyPanic_ocd',
  ConditionAnxietyPanicPanicDisorders = 'condition_anxietyPanic_panicDisorders',
  ConditionAnxietyPanicPhobias = 'condition_anxietyPanic_phobias',
  ConditionAnxietyPanicPtsd = 'condition_anxietyPanic_ptsd',
  ConditionAnxietyPanicSocialAnxiety = 'condition_anxietyPanic_socialAnxiety',
  ConditionCardioVascularAtrialFibrillation = 'condition_cardioVascular_atrialFibrillation',
  ConditionCardioVascularCoronaryArteryDisease = 'condition_cardioVascular_coronaryArteryDisease',
  ConditionCardioVascularHeartFailure = 'condition_cardioVascular_heartFailure',
  ConditionCardioVascularHighCholesterol = 'condition_cardioVascular_highCholesterol',
  ConditionCardioVascularHypertension = 'condition_cardioVascular_hypertension',
  ConditionCardioVascularOtherArrhythmias = 'condition_cardioVascular_otherArrhythmias',
  ConditionCardioVascularPeripheralArteryDisease = 'condition_cardioVascular_peripheralArteryDisease',
  ConditionCardioVascularValvularHeartDisease = 'condition_cardioVascular_valvularHeartDisease',
  ConditionCopdChronicBronchitis = 'condition_copd_chronicBronchitis',
  ConditionCopdEmphysema = 'condition_copd_emphysema',
  ConditionDepressionBipolar = 'condition_depression_bipolar',
  ConditionDepressionDysthymia = 'condition_depression_dysthymia',
  ConditionDepressionMajorDepression = 'condition_depression_majorDepression',
  ConditionDepressionPostPartum = 'condition_depression_postPartum',
  ConditionDepressionSeasonalAffective = 'condition_depression_seasonalAffective',
  ConditionDiabetesGestationalDiabetes = 'condition_diabetes_gestationalDiabetes',
  ConditionDiabetesPrediabetes = 'condition_diabetes_prediabetes',
  ConditionDiabetesType1Diabetes = 'condition_diabetes_type1Diabetes',
  ConditionDiabetesType2Diabetes = 'condition_diabetes_type2Diabetes',
  ConditionMskAclInjury = 'condition_msk_aclInjury',
  ConditionMskBackPain = 'condition_msk_backPain',
  ConditionMskCarpalTunnelSyndrome = 'condition_msk_carpalTunnelSyndrome',
  ConditionMskChronicPain = 'condition_msk_chronicPain',
  ConditionMskFibromyalgia = 'condition_msk_fibromyalgia',
  ConditionMskFractures = 'condition_msk_fractures',
  ConditionMskMusclespasms = 'condition_msk_musclespasms',
  ConditionMskOsteoarthritis = 'condition_msk_osteoarthritis',
  ConditionMskPsoriaticArthritis = 'condition_msk_psoriaticArthritis',
  ConditionMskRheumatoidArthritis = 'condition_msk_rheumatoidArthritis',
  ConditionMskSprains = 'condition_msk_sprains',
  ConditionMskTendonitis = 'condition_msk_tendonitis',
  ConditionSleepDisordersInsomnia = 'condition_sleepDisorders_insomnia',
  ConditionSleepDisordersNarcolepsy = 'condition_sleepDisorders_narcolepsy',
  ConditionSleepDisordersParasomnias = 'condition_sleepDisorders_parasomnias',
  ConditionSleepDisordersRestlessLegSyndrome = 'condition_sleepDisorders_restlessLegSyndrome',
  ConditionSleepDisordersSleepApnea = 'condition_sleepDisorders_sleepApnea',
  ConditionWeightUnhealthyWeight = 'condition_weight_unhealthyWeight',
  ConditionWomensHealthBreastcancer = 'condition_womensHealth_breastcancer',
  ConditionWomensHealthCervicalcancer = 'condition_womensHealth_cervicalcancer',
  ConditionWomensHealthEndometriosis = 'condition_womensHealth_endometriosis',
  ConditionWomensHealthFibroids = 'condition_womensHealth_fibroids',
  ConditionWomensHealthInfertility = 'condition_womensHealth_infertility',
  ConditionWomensHealthIrregularperiods = 'condition_womensHealth_irregularperiods',
  ConditionWomensHealthMenopause = 'condition_womensHealth_menopause',
  ConditionWomensHealthOvariancysts = 'condition_womensHealth_ovariancysts',
  ConditionWomensHealthPainfulintercourse = 'condition_womensHealth_painfulintercourse',
  ConditionWomensHealthPcos = 'condition_womensHealth_pcos',
  ConditionWomensHealthPelvicInflammatoryDisease = 'condition_womensHealth_pelvicInflammatoryDisease',
  ConditionWomensHealthPelvicfloordysfunction = 'condition_womensHealth_pelvicfloordysfunction',
  ConditionWomensHealthPregnancy = 'condition_womensHealth_pregnancy',
  ConditionWomensHealthSexdrive = 'condition_womensHealth_sexdrive',
  ConditionWomensHealthSti = 'condition_womensHealth_sti',
  ConditionWomensHealthUrinaryIncontinence = 'condition_womensHealth_urinaryIncontinence',
  ConditionWomensHealthUti = 'condition_womensHealth_uti',
  ConditionWomensHealthVaginalinfections = 'condition_womensHealth_vaginalinfections',
  SurgeryAbdominalDigestiveAppendectomy = 'surgery_abdominalDigestive_appendectomy',
  SurgeryAbdominalDigestiveColonoscopy = 'surgery_abdominalDigestive_colonoscopy',
  SurgeryAbdominalDigestiveErcp = 'surgery_abdominalDigestive_ercp',
  SurgeryAbdominalDigestiveFecalOccultBloodTests = 'surgery_abdominalDigestive_fecalOccultBloodTests',
  SurgeryAbdominalDigestiveGallbladderRemoval = 'surgery_abdominalDigestive_gallbladderRemoval',
  SurgeryAbdominalDigestiveGastricByPass = 'surgery_abdominalDigestive_gastricByPass',
  SurgeryAbdominalDigestiveHerniaSurgery = 'surgery_abdominalDigestive_herniaSurgery',
  SurgeryAbdominalDigestiveUpperEndoscopy = 'surgery_abdominalDigestive_upperEndoscopy',
  SurgeryBoneJointAClReconstructionSurgery = 'surgery_boneJoint_aCLReconstructionSurgery',
  SurgeryBoneJointAnkleReplacementSurgery = 'surgery_boneJoint_ankleReplacementSurgery',
  SurgeryBoneJointArthroscopy = 'surgery_boneJoint_arthroscopy',
  SurgeryBoneJointBoneFractureRepair = 'surgery_boneJoint_boneFractureRepair',
  SurgeryBoneJointCarpalTunnelRelease = 'surgery_boneJoint_carpalTunnelRelease',
  SurgeryBoneJointCervicalDiscSurgery = 'surgery_boneJoint_cervicalDiscSurgery',
  SurgeryBoneJointClubfootCorrection = 'surgery_boneJoint_clubfootCorrection',
  SurgeryBoneJointHerniatedDiskSurgery = 'surgery_boneJoint_herniatedDiskSurgery',
  SurgeryBoneJointHipReplacementSurgery = 'surgery_boneJoint_hipReplacementSurgery',
  SurgeryBoneJointHipResurfacing = 'surgery_boneJoint_hipResurfacing',
  SurgeryBoneJointJointFusionSurgery = 'surgery_boneJoint_jointFusionSurgery',
  SurgeryBoneJointKneeReplacementSurgery = 'surgery_boneJoint_kneeReplacementSurgery',
  SurgeryBoneJointKyphoplasty = 'surgery_boneJoint_kyphoplasty',
  SurgeryBoneJointLaminectomy = 'surgery_boneJoint_laminectomy',
  SurgeryBoneJointMild = 'surgery_boneJoint_mild',
  SurgeryBoneJointOsteotomy = 'surgery_boneJoint_osteotomy',
  SurgeryBoneJointRotatorCuffSurgery = 'surgery_boneJoint_rotatorCuffSurgery',
  SurgeryBoneJointShoulderReplacementSurgery = 'surgery_boneJoint_shoulderReplacementSurgery',
  SurgeryBoneJointSpinalFusion = 'surgery_boneJoint_spinalFusion',
  SurgeryBoneJointTornPclSurgery = 'surgery_boneJoint_tornPCLSurgery',
  SurgeryBoneJointUclReconstruction = 'surgery_boneJoint_uclReconstruction',
  SurgeryBoneJointVertebroplasty = 'surgery_boneJoint_vertebroplasty',
  SurgeryCancerBoneMarrowAspiration = 'surgery_cancer_boneMarrowAspiration',
  SurgeryCancerBoneScan = 'surgery_cancer_boneScan',
  SurgeryCancerBreastMri = 'surgery_cancer_breastMRI',
  SurgeryCancerBreastUltrasound = 'surgery_cancer_breastUltrasound',
  SurgeryCancerCarcinoembryonicAntigenTestCeaTest = 'surgery_cancer_carcinoembryonicAntigenTestCEATest',
  SurgeryCancerChemotherapy = 'surgery_cancer_chemotherapy',
  SurgeryCancerLiverSpleenScan = 'surgery_cancer_liverSpleenScan',
  SurgeryCancerLungBiopsy = 'surgery_cancer_lungBiopsy',
  SurgeryCancerMammography = 'surgery_cancer_mammography',
  SurgeryCancerProstateBiopsy = 'surgery_cancer_prostateBiopsy',
  SurgeryCancerThyroidBiopsy = 'surgery_cancer_thyroidBiopsy',
  SurgeryCancerTumorMarkerTests = 'surgery_cancer_tumorMarkerTests',
  SurgeryEyeCataractSurgery = 'surgery_eye_cataractSurgery',
  SurgeryEyeCornealTransplant = 'surgery_eye_cornealTransplant',
  SurgeryEyeLaserSurgeryForGlaucoma = 'surgery_eye_laserSurgeryForGlaucoma',
  SurgeryEyeLasik = 'surgery_eye_lasik',
  SurgeryEyePRkPhotorefractiveKeratectomy = 'surgery_eye_pRKPhotorefractiveKeratectomy',
  SurgeryEyeVitrectomy = 'surgery_eye_vitrectomy',
  SurgeryHeartAneurysmRepair = 'surgery_heart_aneurysmRepair',
  SurgeryHeartAngioplastyAndStentPlacement = 'surgery_heart_angioplastyAndStentPlacement',
  SurgeryHeartCardiacCatheterization = 'surgery_heart_cardiacCatheterization',
  SurgeryHeartCardioversion = 'surgery_heart_cardioversion',
  SurgeryHeartCarotidSurgery = 'surgery_heart_carotidSurgery',
  SurgeryHeartHeartBypassSurgery = 'surgery_heart_heartBypassSurgery',
  SurgeryHeartHeartValveSurgery = 'surgery_heart_heartValveSurgery',
  SurgeryHeartImplantableDefibrillators = 'surgery_heart_implantableDefibrillators',
  SurgeryHeartLeftVentricularAssistDevice = 'surgery_heart_leftVentricularAssistDevice',
  SurgeryHeartPacemakers = 'surgery_heart_pacemakers',
  SurgeryHeartTransmyocardialRevascularization = 'surgery_heart_transmyocardialRevascularization',
  SurgeryImagingBariumEnema = 'surgery_imaging_bariumEnema',
  SurgeryImagingCtScan = 'surgery_imaging_ctScan',
  SurgeryImagingElectroencephalogramEeg = 'surgery_imaging_electroencephalogramEEG',
  SurgeryImagingElectromyographyEmg = 'surgery_imaging_electromyographyEMG',
  SurgeryImagingLiverSpleenScan = 'surgery_imaging_liverSpleenScan',
  SurgeryImagingMri = 'surgery_imaging_mri',
  SurgeryImagingPetScan = 'surgery_imaging_petScan',
  SurgeryImagingProctoscopy = 'surgery_imaging_proctoscopy',
  SurgeryImagingSigmoidoscopy = 'surgery_imaging_sigmoidoscopy',
  SurgeryImagingUltrasound = 'surgery_imaging_ultrasound',
  SurgeryImagingUpperEndoscopy = 'surgery_imaging_upperEndoscopy',
  SurgeryImagingVirtualColonoscopy = 'surgery_imaging_virtualColonoscopy',
  SurgeryImagingXRay = 'surgery_imaging_xRay',
  SurgeryNeurologicalEeg = 'surgery_neurological_eeg',
  SurgeryNeurologicalEmg = 'surgery_neurological_emg',
  SurgeryNeurologicalEpilepsySurgery = 'surgery_neurological_epilepsySurgery',
  SurgeryNeurologicalLumbarPuncture = 'surgery_neurological_lumbarPuncture',
  SurgeryNeurologicalMri = 'surgery_neurological_mri',
  SurgeryObstetricGynecologicalCSection = 'surgery_obstetricGynecological_cSection',
  SurgeryObstetricGynecologicalColposcopy = 'surgery_obstetricGynecological_colposcopy',
  SurgeryObstetricGynecologicalHysterectomy = 'surgery_obstetricGynecological_hysterectomy',
  SurgeryObstetricGynecologicalIudPlacementAndRemoval = 'surgery_obstetricGynecological_iudPlacementAndRemoval',
  SurgeryObstetricGynecologicalLarcPlacementAndRemoval = 'surgery_obstetricGynecological_larcPlacementAndRemoval',
  SurgeryObstetricGynecologicalLeep = 'surgery_obstetricGynecological_leep',
  SurgeryObstetricGynecologicalPapTest = 'surgery_obstetricGynecological_papTest',
  SurgeryOrganTransplantsSkinGrafting = 'surgery_organTransplants_SkinGrafting',
  SurgeryOrganTransplantsBoneMarrowStemCellTransplant = 'surgery_organTransplants_boneMarrowStemCellTransplant',
  SurgeryOrganTransplantsCorneaTransplant = 'surgery_organTransplants_corneaTransplant',
  SurgeryOrganTransplantsHeartTransplant = 'surgery_organTransplants_heartTransplant',
  SurgeryOrganTransplantsKidneyTransplant = 'surgery_organTransplants_kidneyTransplant',
  SurgeryOrganTransplantsLiverTransplant = 'surgery_organTransplants_liverTransplant',
  SurgeryOrganTransplantsLungTransplant = 'surgery_organTransplants_lungTransplant',
  SurgeryOrganTransplantsPancreasTransplant = 'surgery_organTransplants_pancreasTransplant',
  WellbeingAgeingMental = 'wellbeing_ageing_mental',
  WellbeingAgeingPhysical = 'wellbeing_ageing_physical',
  WellbeingAgeingSocialAndEmotional = 'wellbeing_ageing_socialAndEmotional',
  WellbeingFinancialBorrowingAndCredit = 'wellbeing_financial_borrowingAndCredit',
  WellbeingFinancialBudgeting = 'wellbeing_financial_budgeting',
  WellbeingFinancialInvesting = 'wellbeing_financial_investing',
  WellbeingFinancialSaving = 'wellbeing_financial_saving',
  WellbeingFinancialTaxes = 'wellbeing_financial_taxes',
  WellbeingFitnessAdvanced = 'wellbeing_fitness_advanced',
  WellbeingFitnessBeginners = 'wellbeing_fitness_beginners',
  WellbeingFitnessGetInShape = 'wellbeing_fitness_getInShape',
  WellbeingFitnessIntermediate = 'wellbeing_fitness_intermediate',
  WellbeingFitnessMobility = 'wellbeing_fitness_mobility',
  WellbeingFitnessSenior = 'wellbeing_fitness_senior',
  WellbeingFitnessStrength = 'wellbeing_fitness_strength',
  WellbeingMeditationAnxiety = 'wellbeing_meditation_anxiety',
  WellbeingMeditationBodyConfidence = 'wellbeing_meditation_bodyConfidence',
  WellbeingMeditationBreathwork = 'wellbeing_meditation_breathwork',
  WellbeingMeditationFocus = 'wellbeing_meditation_focus',
  WellbeingMeditationGong = 'wellbeing_meditation_gong',
  WellbeingMeditationGrief = 'wellbeing_meditation_grief',
  WellbeingMeditationKids = 'wellbeing_meditation_kids',
  WellbeingMeditationMorning = 'wellbeing_meditation_morning',
  WellbeingMeditationParental = 'wellbeing_meditation_parental',
  WellbeingMeditationSleep = 'wellbeing_meditation_sleep',
  WellbeingMeditationSoundscapes = 'wellbeing_meditation_soundscapes',
  WellbeingMeditationWomensHealth = 'wellbeing_meditation_womensHealth',
  WellbeingRecipesBreakfast = 'wellbeing_recipes_breakfast',
  WellbeingRecipesDessert = 'wellbeing_recipes_dessert',
  WellbeingRecipesGlutenFree = 'wellbeing_recipes_glutenFree',
  WellbeingRecipesLowCalorie = 'wellbeing_recipes_lowCalorie',
  WellbeingRecipesMainMeals = 'wellbeing_recipes_mainMeals',
  WellbeingRecipesMeatFish = 'wellbeing_recipes_meatFish',
  WellbeingRecipesQuickEasy = 'wellbeing_recipes_quickEasy',
  WellbeingRecipesSnacks = 'wellbeing_recipes_snacks',
  WellbeingRecipesVegan = 'wellbeing_recipes_vegan',
  WellbeingRecipesVegetarian = 'wellbeing_recipes_vegetarian',
  WellbeingSleepMindfulness = 'wellbeing_sleep_mindfulness',
  WellbeingSleepSoundscapes = 'wellbeing_sleep_soundscapes',
  WellbeingSleepStories = 'wellbeing_sleep_stories',
  WellbeingYogaAdvanced = 'wellbeing_yoga_advanced',
  WellbeingYogaBeginners = 'wellbeing_yoga_beginners',
  WellbeingYogaCore = 'wellbeing_yoga_core',
  WellbeingYogaIntermediate = 'wellbeing_yoga_intermediate',
  WellbeingYogaMobility = 'wellbeing_yoga_mobility',
  WellbeingYogaMorning = 'wellbeing_yoga_morning',
  WellbeingYogaPregnancy = 'wellbeing_yoga_pregnancy',
  WellbeingYogaRelaxation = 'wellbeing_yoga_relaxation',
  WellbeingYogaStrength = 'wellbeing_yoga_strength'
}

export type CategoryArrayAttributeWhereOperators = {
  /** Equals operator */
  _eq?: InputMaybe<Array<Category>>;
  /** NotEquals operator */
  _ne?: InputMaybe<Array<Category>>;
  /** Overlap operator */
  _overlap?: InputMaybe<Array<Category>>;
};

/** Topic */
export enum ContentTopic {
  Causes = 'causes',
  Diagnosis = 'diagnosis',
  Lifestyle = 'lifestyle',
  Medication = 'medication',
  Overview = 'overview',
  Risks = 'risks',
  SideEffects = 'sideEffects',
  Support = 'support',
  Symptoms = 'symptoms'
}

/** Country */
export enum Country {
  Uk = 'uk',
  Us = 'us'
}

export type CountryArrayAttributeWhereOperators = {
  /** Equals operator */
  _eq?: InputMaybe<Array<Country>>;
  /** NotEquals operator */
  _ne?: InputMaybe<Array<Country>>;
  /** Overlap operator */
  _overlap?: InputMaybe<Array<Country>>;
};

/** Course (language invariant) */
export type Course = {
  __typename?: 'Course';
  /** List of categories course is included in */
  category: Array<Category>;
  /** Content value of course */
  contentValue: Scalars['Int'];
  /** Courses of the course */
  courseDetailsList: Array<CourseDetails>;
  /** Courses of the course */
  courseItems: Array<CourseItem>;
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** UUID of course */
  id: Scalars['String'];
  /** Photo for article */
  photo: File;
  /** Topic of the course */
  topic: Array<ContentTopic>;
  /** Universal title of course */
  universalTitle: Scalars['String'];
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};


/** Course (language invariant) */
export type CourseCourseDetailsListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CourseDetailsWhere>;
};


/** Course (language invariant) */
export type CourseCourseItemsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CourseItemWhere>;
};

/** Course details (language invariant) */
export type CourseDetails = {
  __typename?: 'CourseDetails';
  /** Course */
  course: Course;
  /** Course id */
  courseId: Scalars['String'];
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** Description for the course */
  description: Scalars['String'];
  /** UUID of course details */
  id: Scalars['String'];
  /** Keywords for the course */
  keywords?: Maybe<Array<Scalars['String']>>;
  /** Language of course details */
  language: Language;
  /** Learning outcome for the course */
  learningOutcome?: Maybe<Scalars['String']>;
  /** Title for the course */
  title: Scalars['String'];
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};

export type CourseDetailsWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<CourseDetailsWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<CourseDetailsWhere>>;
  /** Description for the course */
  description?: InputMaybe<StringAttributeWhereOperators>;
  /** UUID of course details */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Keywords for the course */
  keywords?: InputMaybe<StringArrayAttributeWhereOperators>;
  /** Language of course details */
  language?: InputMaybe<LanguageAttributeWhereOperators>;
  /** Learning outcome for the course */
  learningOutcome?: InputMaybe<StringAttributeWhereOperators>;
  /** Title for the course */
  title?: InputMaybe<StringAttributeWhereOperators>;
};

/** Course item */
export type CourseItem = {
  __typename?: 'CourseItem';
  /** Content object associated with this course item */
  content: CourseItemContentUnion;
  /** UUID of content object */
  contentId?: Maybe<Scalars['String']>;
  /** Type of content object */
  contentType: CourseItemContentType;
  /** Courses of the course item */
  course: Course;
  /** UUID of course */
  courseId?: Maybe<Scalars['String']>;
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** UUID of course item */
  id: Scalars['String'];
  /** Position of course item in course */
  position: Scalars['Float'];
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};

/** Course item content type */
export enum CourseItemContentType {
  CardPack = 'cardPack',
  Post = 'post'
}

/** Post media */
export type CourseItemContentUnion = CardPack | Post;

export type CourseItemWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<CourseItemWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<CourseItemWhere>>;
  /** UUID of course item */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Position of course item in course */
  position?: InputMaybe<NumberAttributeWhereOperators>;
};

export type CourseWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<CourseWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<CourseWhere>>;
  /** List of categories course is included in */
  category?: InputMaybe<CategoryArrayAttributeWhereOperators>;
  /** Content value of course */
  contentValue?: InputMaybe<NumberAttributeWhereOperators>;
  /** UUID of course */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Topic of the course */
  topic?: InputMaybe<TopicArrayAttributeWhereOperators>;
  /** Universal title of course */
  universalTitle?: InputMaybe<StringAttributeWhereOperators>;
};

/** Creator */
export type Creator = {
  __typename?: 'Creator';
  /** Creator's accent */
  accent: Accent;
  /** Article versions by creator */
  articleVersions: Array<ArticleMediaVersion>;
  /** Articles by creator */
  articles: Array<ArticleMedia>;
  /** Audio versions by creator */
  audioVersions: Array<AudioMediaVersion>;
  /** Audios by creator */
  audios: Array<AudioMedia>;
  /** Creator's bio */
  bio: Scalars['String'];
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** Creator's gender */
  gender: Gender;
  /** UUID of creator */
  id: Scalars['String'];
  /** Creator's language */
  language: Language;
  /** Creator's name */
  name: Scalars['String'];
  /** Photo of creator */
  photo: File;
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
  /** Video versions by creator */
  videoVersions: Array<VideoMediaVersion>;
  /** Videos by creator */
  videos: Array<VideoMedia>;
};


/** Creator */
export type CreatorArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArticleMediaWhere>;
};


/** Creator */
export type CreatorAudiosArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AudioMediaWhere>;
};


/** Creator */
export type CreatorVideosArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoMediaWhere>;
};

export type CreatorWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<CreatorWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<CreatorWhere>>;
  /** Creator's accent */
  accent?: InputMaybe<AccentAttributeWhereOperators>;
  /** Creator's bio */
  bio?: InputMaybe<StringAttributeWhereOperators>;
  /** Creator's gender */
  gender?: InputMaybe<GenderAttributeWhereOperators>;
  /** UUID of creator */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Creator's language */
  language?: InputMaybe<LanguageAttributeWhereOperators>;
  /** Creator's name */
  name?: InputMaybe<StringAttributeWhereOperators>;
};

/** Video dubb */
export type Dubb = {
  __typename?: 'Dubb';
  /** Audio file for dubb */
  audio: File;
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** UUID of dubb */
  id: Scalars['String'];
  /** Language of dubb */
  language: Language;
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
  /** Video for dubb */
  videoMedia: VideoMedia;
  /** Video id for dubb */
  videoMediaId: Scalars['String'];
};

export type DubbWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<DubbWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<DubbWhere>>;
  /** UUID of dubb */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Language of dubb */
  language?: InputMaybe<LanguageAttributeWhereOperators>;
  /** Video id for dubb */
  videoMediaId?: InputMaybe<UuidAttributeWhereOperators>;
};

/** File */
export type File = {
  __typename?: 'File';
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** UUID of file */
  id: Scalars['String'];
  /** MIME type of file */
  mimetype: Scalars['String'];
  /** File details object associated with this file */
  parent: FileParentUnion;
  /** UUID of parent object */
  parentId?: Maybe<Scalars['String']>;
  /** Type of parent object */
  parentType: FileDetailsParentType;
  /** Filename of file */
  path: Scalars['String'];
  /** Size of file in Bytes (before delivery optimization) */
  size: Scalars['Int'];
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
  /** URL of file */
  url: Scalars['String'];
};

/** Parent object type */
export enum FileDetailsParentType {
  ArticlePhoto = 'articlePhoto',
  AudioMedia = 'audioMedia',
  AudioPhoto = 'audioPhoto',
  CaptionSrt = 'captionSrt',
  CardPackPhoto = 'cardPackPhoto',
  CardPhoto = 'cardPhoto',
  CoursePhoto = 'coursePhoto',
  CreatorPhoto = 'creatorPhoto',
  DubbAudio = 'dubbAudio',
  OrganizationImageOverlayLandscape = 'organizationImageOverlayLandscape',
  OrganizationImageOverlayPortrait = 'organizationImageOverlayPortrait',
  OrganizationPostRollLandscape = 'organizationPostRollLandscape',
  OrganizationPostRollPortrait = 'organizationPostRollPortrait',
  OrganizationPreRollLandscape = 'organizationPreRollLandscape',
  OrganizationPreRollPortrait = 'organizationPreRollPortrait',
  OrganizationWhitelabelFavicon = 'organizationWhitelabelFavicon',
  OrganizationWhitelabelLogo = 'organizationWhitelabelLogo',
  RecipePhoto = 'recipePhoto',
  VideoMediaLandscape = 'videoMediaLandscape',
  VideoMediaPortrait = 'videoMediaPortrait',
  VideoPhoto = 'videoPhoto'
}

/** File parent */
export type FileParentUnion = ArticleMedia | AudioMedia | Caption | Creator | Dubb | Post | RecipeDetails | VideoMedia;

/** Gender */
export enum Gender {
  Female = 'female',
  Male = 'male'
}

export type GenderAttributeWhereOperators = {
  /** EndsWith operator */
  _endsWith?: InputMaybe<Scalars['String']>;
  /** Equals operator */
  _eq?: InputMaybe<Gender>;
  /** ILike operator */
  _iLike?: InputMaybe<Scalars['String']>;
  /** IRegexp operator */
  _iRegexp?: InputMaybe<Scalars['String']>;
  /** Like operator */
  _like?: InputMaybe<Scalars['String']>;
  /** NotEquals operator */
  _ne?: InputMaybe<Gender>;
  /** NotILike operator */
  _notILike?: InputMaybe<Scalars['String']>;
  /** NotIRegexp operator */
  _notIRegexp?: InputMaybe<Scalars['String']>;
  /** NotLike operator */
  _notLike?: InputMaybe<Scalars['String']>;
  /** NotRegexp operator */
  _notRegexp?: InputMaybe<Scalars['String']>;
  /** Or operator */
  _or?: InputMaybe<Array<Gender>>;
  /** Regexp operator */
  _regexp?: InputMaybe<Scalars['String']>;
  /** StartsWith operator */
  _startsWith?: InputMaybe<Scalars['String']>;
  /** Substring operator */
  _substring?: InputMaybe<Scalars['String']>;
};

/** Ingredient item  */
export type IngredientListItem = {
  __typename?: 'IngredientListItem';
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Plural of custom unit */
  customUnitPlural?: Maybe<Scalars['String']>;
  /** Singular of custom unit */
  customUnitSingular?: Maybe<Scalars['String']>;
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** UUID of ingredient list item */
  id: Scalars['String'];
  /** Ingredient */
  ingredient: Scalars['String'];
  /** Quantity of ingredient */
  quantity: Scalars['Float'];
  /** Recipe details of ingredient list item */
  recipeDetails: RecipeDetails;
  /** Id of recipe details of ingredient list item */
  recipeDetailsId?: Maybe<Scalars['String']>;
  /** Unit of quantity */
  unit: Unit;
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};

export type IngredientListItemWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<IngredientListItemWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<IngredientListItemWhere>>;
  /** Plural of custom unit */
  customUnitPlural?: InputMaybe<StringAttributeWhereOperators>;
  /** Singular of custom unit */
  customUnitSingular?: InputMaybe<StringAttributeWhereOperators>;
  /** UUID of ingredient list item */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Ingredient */
  ingredient?: InputMaybe<StringAttributeWhereOperators>;
  /** Quantity of ingredient */
  quantity?: InputMaybe<NumberAttributeWhereOperators>;
  /** Id of recipe of ingredient list item */
  recipeDetailsId?: InputMaybe<UuidAttributeWhereOperators>;
  /** Unit of quantity */
  unit?: InputMaybe<RecipeUnitAttributeWhereOperators>;
};

/** Language */
export enum Language {
  Dutch = 'dutch',
  English = 'english',
  Flemish = 'flemish',
  French = 'french',
  German = 'german',
  Italian = 'italian',
  Romanian = 'romanian',
  Spanish = 'spanish'
}

export type LanguageAttributeWhereOperators = {
  /** EndsWith operator */
  _endsWith?: InputMaybe<Scalars['String']>;
  /** Equals operator */
  _eq?: InputMaybe<Language>;
  /** ILike operator */
  _iLike?: InputMaybe<Scalars['String']>;
  /** IRegexp operator */
  _iRegexp?: InputMaybe<Scalars['String']>;
  /** Like operator */
  _like?: InputMaybe<Scalars['String']>;
  /** NotEquals operator */
  _ne?: InputMaybe<Language>;
  /** NotILike operator */
  _notILike?: InputMaybe<Scalars['String']>;
  /** NotIRegexp operator */
  _notIRegexp?: InputMaybe<Scalars['String']>;
  /** NotLike operator */
  _notLike?: InputMaybe<Scalars['String']>;
  /** NotRegexp operator */
  _notRegexp?: InputMaybe<Scalars['String']>;
  /** Or operator */
  _or?: InputMaybe<Array<Language>>;
  /** Regexp operator */
  _regexp?: InputMaybe<Scalars['String']>;
  /** StartsWith operator */
  _startsWith?: InputMaybe<Scalars['String']>;
  /** Substring operator */
  _substring?: InputMaybe<Scalars['String']>;
};

/** Post media */
export type MediaUnion = ArticleMedia | AudioMedia | VideoMedia;

export type MediaUnionWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<MediaUnionWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<MediaUnionWhere>>;
  /** Approximate time to read article */
  approximateTime?: InputMaybe<NumberAttributeWhereOperators>;
  /** Creator id of article */
  creatorId?: InputMaybe<UuidAttributeWhereOperators>;
  /** UUID of article */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Language of article */
  language?: InputMaybe<LanguageAttributeWhereOperators>;
  /** Post id of article */
  postId?: InputMaybe<UuidAttributeWhereOperators>;
};

/** Post media version */
export type MediaVersionUnion = ArticleMediaVersion | AudioMediaVersion | VideoMediaVersion;

export type NumberAttributeWhereOperators = {
  /** Equals operator */
  _eq?: InputMaybe<Scalars['Int']>;
  /** GT operator */
  _gt?: InputMaybe<Scalars['Int']>;
  /** GTE operator */
  _gte?: InputMaybe<Scalars['Int']>;
  /** LT operator */
  _lt?: InputMaybe<Scalars['Int']>;
  /** LTE operator */
  _lte?: InputMaybe<Scalars['Int']>;
  /** NotEquals operator */
  _ne?: InputMaybe<Scalars['Int']>;
  /** Or operator */
  _or?: InputMaybe<Array<Scalars['Int']>>;
};

/** Post (language invariant) */
export type Post = {
  __typename?: 'Post';
  /** List of categories post is included in */
  category: Array<Category>;
  /** Flag indicating if content has been clinically verified */
  clinicallyVerified: Scalars['Boolean'];
  /** Content value of post */
  contentValue: Scalars['Int'];
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** ICD-10 code for the post */
  icd10: Array<Scalars['String']>;
  /** UUID of post */
  id: Scalars['String'];
  /** Media of post */
  media: Array<MediaUnion>;
  /** Media of post */
  mediaType: PostMediaType;
  /** Media versions of post */
  mediaVersions: Array<MediaVersionUnion>;
  /** Details list of post */
  postDetailsList: Array<PostDetails>;
  /** Topic of the post */
  topic: Array<ContentTopic>;
  /** Universal title of post */
  universalTitle: Scalars['String'];
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};


/** Post (language invariant) */
export type PostMediaArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MediaUnionWhere>;
};


/** Post (language invariant) */
export type PostPostDetailsListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostDetailsWhere>;
};

/** Post details (language specific) */
export type PostDetails = {
  __typename?: 'PostDetails';
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** Description for the post */
  description: Scalars['String'];
  /** UUID of post details */
  id: Scalars['String'];
  /** Keywords for the post */
  keywords?: Maybe<Array<Scalars['String']>>;
  /** Language of post details */
  language: Language;
  /** Learning outcome for the post */
  learningOutcome?: Maybe<Scalars['String']>;
  /** Post */
  post: Post;
  /** Post id */
  postId: Scalars['String'];
  /** Title for the post */
  title: Scalars['String'];
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};

export type PostDetailsWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<PostDetailsWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<PostDetailsWhere>>;
  /** Description for the post */
  description?: InputMaybe<StringAttributeWhereOperators>;
  /** UUID of post details */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Keywords for the post */
  keywords?: InputMaybe<StringArrayAttributeWhereOperators>;
  /** Language of post details */
  language?: InputMaybe<LanguageAttributeWhereOperators>;
  /** Learning outcome for the post */
  learningOutcome?: InputMaybe<StringAttributeWhereOperators>;
  /** Post id */
  postId?: InputMaybe<UuidAttributeWhereOperators>;
  /** Title for the post */
  title?: InputMaybe<StringAttributeWhereOperators>;
};

/** Post media type */
export enum PostMediaType {
  Article = 'article',
  Audio = 'audio',
  Video = 'video'
}

export type PostMediaTypeAttributeWhereOperators = {
  /** EndsWith operator */
  _endsWith?: InputMaybe<Scalars['String']>;
  /** Equals operator */
  _eq?: InputMaybe<PostMediaType>;
  /** ILike operator */
  _iLike?: InputMaybe<Scalars['String']>;
  /** IRegexp operator */
  _iRegexp?: InputMaybe<Scalars['String']>;
  /** Like operator */
  _like?: InputMaybe<Scalars['String']>;
  /** NotEquals operator */
  _ne?: InputMaybe<PostMediaType>;
  /** NotILike operator */
  _notILike?: InputMaybe<Scalars['String']>;
  /** NotIRegexp operator */
  _notIRegexp?: InputMaybe<Scalars['String']>;
  /** NotLike operator */
  _notLike?: InputMaybe<Scalars['String']>;
  /** NotRegexp operator */
  _notRegexp?: InputMaybe<Scalars['String']>;
  /** Or operator */
  _or?: InputMaybe<Array<PostMediaType>>;
  /** Regexp operator */
  _regexp?: InputMaybe<Scalars['String']>;
  /** StartsWith operator */
  _startsWith?: InputMaybe<Scalars['String']>;
  /** Substring operator */
  _substring?: InputMaybe<Scalars['String']>;
};

export type PostWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<PostWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<PostWhere>>;
  /** List of categories post is included in */
  category?: InputMaybe<CategoryArrayAttributeWhereOperators>;
  /** Flag indicating if content has been clinically verified */
  clinicallyVerified?: InputMaybe<BooleanAttributeWhereOperators>;
  /** Content value of post */
  contentValue?: InputMaybe<NumberAttributeWhereOperators>;
  /** ICD-10 code for the post */
  icd10?: InputMaybe<StringArrayAttributeWhereOperators>;
  /** UUID of post */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Media of post */
  mediaType?: InputMaybe<PostMediaTypeAttributeWhereOperators>;
  /** Topic of the post */
  topic?: InputMaybe<TopicArrayAttributeWhereOperators>;
  /** Universal title of post */
  universalTitle?: InputMaybe<StringAttributeWhereOperators>;
};

export type Query = {
  __typename?: 'Query';
  /** Get list of CardPackDetails */
  getCardPackDetails: Array<CardPackDetails>;
  /** Get list of Card Packs */
  getCardPacks: Array<CardPack>;
  /** Get list of CourseDetails */
  getCourseDetails: Array<CourseDetails>;
  /** Get list of Courses */
  getCourses: Array<Course>;
  /** Get list of Creators */
  getCreators: Array<Creator>;
  /** Get list of PostDetails */
  getPostDetails: Array<PostDetails>;
  /** Get list of Posts */
  getPosts: Array<Post>;
  /** Get list of RecipeDetails */
  getRecipeDetails: Array<RecipeDetails>;
  /** Get list of Recipes */
  getRecipes: Array<Recipe>;
};


export type QueryGetCardPackDetailsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardPackDetailsWhere>;
};


export type QueryGetCardPacksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardPackWhere>;
};


export type QueryGetCourseDetailsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CourseDetailsWhere>;
};


export type QueryGetCoursesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CourseWhere>;
};


export type QueryGetCreatorsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CreatorWhere>;
};


export type QueryGetPostDetailsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostDetailsWhere>;
};


export type QueryGetPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhere>;
};


export type QueryGetRecipeDetailsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeDetailsWhere>;
};


export type QueryGetRecipesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeWhere>;
};

/** Recipe (language invariant) */
export type Recipe = {
  __typename?: 'Recipe';
  /** Approximate preparation time for recipe given in minutes */
  approximateTime: Scalars['Int'];
  /** Approximate calorie count per serving of prepared food */
  calories: Scalars['Int'];
  /** List of categories recipe is included in */
  category: Array<Category>;
  /** Content value of recipe */
  contentValue: Scalars['Int'];
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** UUID of recipe */
  id: Scalars['String'];
  /** Photo for article */
  photo: File;
  /** Recipes of the recipe */
  recipeDetailsList: Array<RecipeDetails>;
  /** Expected servings for recipe */
  servings: Scalars['Int'];
  /** Tags of recipe */
  tags: Array<RecipeTag>;
  /** Topic of the recipe */
  topic: Array<ContentTopic>;
  /** Universal title of recipe */
  universalTitle: Scalars['String'];
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};


/** Recipe (language invariant) */
export type RecipeRecipeDetailsListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeDetailsWhere>;
};

/** Recipe details (language specific) */
export type RecipeDetails = {
  __typename?: 'RecipeDetails';
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** Description for the recipe */
  description: Scalars['String'];
  /** Unit format for the ingredients (Imperial/ Metric) */
  format: RecipeFormat;
  /** UUID of recipe details */
  id: Scalars['String'];
  /** Ingredients list for recipe */
  ingredientsList: Array<IngredientListItem>;
  /** Keywords for the recipe */
  keywords?: Maybe<Array<Scalars['String']>>;
  /** Language of recipe details */
  language: Language;
  /** Preparation steps for the recipe */
  method: Array<Scalars['String']>;
  /** Preview of preparation steps for the recipe */
  methodPreview: Array<Scalars['String']>;
  /** Parent of recipe */
  recipe: Recipe;
  /** Parent id of recipe */
  recipeId?: Maybe<Scalars['String']>;
  /** Title for the recipe */
  title: Scalars['String'];
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};


/** Recipe details (language specific) */
export type RecipeDetailsIngredientsListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IngredientListItemWhere>;
};

export type RecipeDetailsWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<RecipeDetailsWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<RecipeDetailsWhere>>;
  /** Description for the recipe */
  description?: InputMaybe<StringAttributeWhereOperators>;
  /** Unit format for the ingredients (Imperial/ Metric) */
  format?: InputMaybe<RecipeFormatAttributeWhereOperators>;
  /** UUID of recipe */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Keywords for the recipe */
  keywords?: InputMaybe<StringArrayAttributeWhereOperators>;
  /** Language of recipe details */
  language?: InputMaybe<LanguageAttributeWhereOperators>;
  /** Preparation steps for the recipe */
  method?: InputMaybe<StringArrayAttributeWhereOperators>;
  /** Preview of preparation steps for the recipe */
  methodPreview?: InputMaybe<StringArrayAttributeWhereOperators>;
  /** Parent id of recipe */
  recipeId?: InputMaybe<UuidAttributeWhereOperators>;
  /** Title for the recipe */
  title?: InputMaybe<StringAttributeWhereOperators>;
};

/** Recipe format */
export enum RecipeFormat {
  Imperial = 'imperial',
  Metric = 'metric'
}

export type RecipeFormatAttributeWhereOperators = {
  /** EndsWith operator */
  _endsWith?: InputMaybe<Scalars['String']>;
  /** Equals operator */
  _eq?: InputMaybe<RecipeFormat>;
  /** ILike operator */
  _iLike?: InputMaybe<Scalars['String']>;
  /** IRegexp operator */
  _iRegexp?: InputMaybe<Scalars['String']>;
  /** Like operator */
  _like?: InputMaybe<Scalars['String']>;
  /** NotEquals operator */
  _ne?: InputMaybe<RecipeFormat>;
  /** NotILike operator */
  _notILike?: InputMaybe<Scalars['String']>;
  /** NotIRegexp operator */
  _notIRegexp?: InputMaybe<Scalars['String']>;
  /** NotLike operator */
  _notLike?: InputMaybe<Scalars['String']>;
  /** NotRegexp operator */
  _notRegexp?: InputMaybe<Scalars['String']>;
  /** Or operator */
  _or?: InputMaybe<Array<RecipeFormat>>;
  /** Regexp operator */
  _regexp?: InputMaybe<Scalars['String']>;
  /** StartsWith operator */
  _startsWith?: InputMaybe<Scalars['String']>;
  /** Substring operator */
  _substring?: InputMaybe<Scalars['String']>;
};

/** Recipe tag */
export enum RecipeTag {
  ContainsNuts = 'containsNuts',
  DairyFree = 'dairyFree',
  GlutenFree = 'glutenFree',
  Ketogenic = 'ketogenic',
  Pescatarian = 'pescatarian',
  Vegan = 'vegan',
  Vegetarian = 'vegetarian'
}

export type RecipeTagArrayAttributeWhereOperators = {
  /** Equals operator */
  _eq?: InputMaybe<Array<RecipeTag>>;
  /** NotEquals operator */
  _ne?: InputMaybe<Array<RecipeTag>>;
  /** Overlap operator */
  _overlap?: InputMaybe<Array<RecipeTag>>;
};

export type RecipeUnitAttributeWhereOperators = {
  /** EndsWith operator */
  _endsWith?: InputMaybe<Scalars['String']>;
  /** Equals operator */
  _eq?: InputMaybe<Unit>;
  /** ILike operator */
  _iLike?: InputMaybe<Scalars['String']>;
  /** IRegexp operator */
  _iRegexp?: InputMaybe<Scalars['String']>;
  /** Like operator */
  _like?: InputMaybe<Scalars['String']>;
  /** NotEquals operator */
  _ne?: InputMaybe<Unit>;
  /** NotILike operator */
  _notILike?: InputMaybe<Scalars['String']>;
  /** NotIRegexp operator */
  _notIRegexp?: InputMaybe<Scalars['String']>;
  /** NotLike operator */
  _notLike?: InputMaybe<Scalars['String']>;
  /** NotRegexp operator */
  _notRegexp?: InputMaybe<Scalars['String']>;
  /** Or operator */
  _or?: InputMaybe<Array<Unit>>;
  /** Regexp operator */
  _regexp?: InputMaybe<Scalars['String']>;
  /** StartsWith operator */
  _startsWith?: InputMaybe<Scalars['String']>;
  /** Substring operator */
  _substring?: InputMaybe<Scalars['String']>;
};

export type RecipeWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<RecipeWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<RecipeWhere>>;
  /** Approximate preparation time for recipe given in minutes */
  approximateTime?: InputMaybe<NumberAttributeWhereOperators>;
  /** Approximate calorie count per serving of prepared food */
  calories?: InputMaybe<NumberAttributeWhereOperators>;
  /** List of categories recipe is included in */
  category?: InputMaybe<CategoryArrayAttributeWhereOperators>;
  /** Content value of recipe */
  contentValue?: InputMaybe<NumberAttributeWhereOperators>;
  /** UUID of recipe */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Expected servings for recipe */
  servings?: InputMaybe<NumberAttributeWhereOperators>;
  /** Tags of recipe */
  tags?: InputMaybe<RecipeTagArrayAttributeWhereOperators>;
  /** Topic of the recipe */
  topic?: InputMaybe<TopicArrayAttributeWhereOperators>;
  /** Universal title of recipe */
  universalTitle?: InputMaybe<StringAttributeWhereOperators>;
};

export type StringArrayAttributeWhereOperators = {
  /** Equals operator */
  _eq?: InputMaybe<Array<Scalars['String']>>;
  /** NotEquals operator */
  _ne?: InputMaybe<Array<Scalars['String']>>;
  /** Overlap operator */
  _overlap?: InputMaybe<Array<Scalars['String']>>;
};

export type StringAttributeWhereOperators = {
  /** EndsWith operator */
  _endsWith?: InputMaybe<Scalars['String']>;
  /** Equals operator */
  _eq?: InputMaybe<Scalars['String']>;
  /** ILike operator */
  _iLike?: InputMaybe<Scalars['String']>;
  /** IRegexp operator */
  _iRegexp?: InputMaybe<Scalars['String']>;
  /** Like operator */
  _like?: InputMaybe<Scalars['String']>;
  /** NotEquals operator */
  _ne?: InputMaybe<Scalars['String']>;
  /** NotILike operator */
  _notILike?: InputMaybe<Scalars['String']>;
  /** NotIRegexp operator */
  _notIRegexp?: InputMaybe<Scalars['String']>;
  /** NotLike operator */
  _notLike?: InputMaybe<Scalars['String']>;
  /** NotRegexp operator */
  _notRegexp?: InputMaybe<Scalars['String']>;
  /** Or operator */
  _or?: InputMaybe<Array<Scalars['String']>>;
  /** Regexp operator */
  _regexp?: InputMaybe<Scalars['String']>;
  /** StartsWith operator */
  _startsWith?: InputMaybe<Scalars['String']>;
  /** Substring operator */
  _substring?: InputMaybe<Scalars['String']>;
};

export type TopicArrayAttributeWhereOperators = {
  /** Equals operator */
  _eq?: InputMaybe<Array<ContentTopic>>;
  /** NotEquals operator */
  _ne?: InputMaybe<Array<ContentTopic>>;
  /** Overlap operator */
  _overlap?: InputMaybe<Array<ContentTopic>>;
};

export type UuidAttributeWhereOperators = {
  /** Equals operator */
  _eq?: InputMaybe<Scalars['String']>;
  /** NotEquals operator */
  _ne?: InputMaybe<Scalars['String']>;
};

/** Unit */
export enum Unit {
  Cups = 'cups',
  Custom = 'custom',
  Grams = 'grams',
  Milliliters = 'milliliters',
  None = 'none',
  Ounces = 'ounces',
  Teaspoons = 'teaspoons'
}

/** Video media */
export type VideoMedia = {
  __typename?: 'VideoMedia';
  /** Approximate time to watch video */
  approximateTime: Scalars['Int'];
  /** Subtitles for video */
  captions: Array<Caption>;
  /** Specific country video is produced for */
  country: Array<Country>;
  /** Creation time given as the ISO Date string */
  createdAt: Scalars['DateTimeISO'];
  /** Creator of video */
  creator: Creator;
  /** Creator id of video */
  creatorId: Scalars['String'];
  /** Soft delete time given as the ISO Date string */
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  /** Dubbings of video */
  dubbing: Array<Dubb>;
  /** UUID of video */
  id: Scalars['String'];
  /** Landscape version of video */
  landscapeVideo: File;
  /** Language of video */
  language: Language;
  /** Photo for video */
  photo: File;
  /** Portrait version of video */
  portraitVideo?: Maybe<File>;
  /** Post of video */
  post: Post;
  /** Post id of video */
  postId: Scalars['String'];
  /** Rich text content of video given as JSON string of HTML */
  scriptHTML?: Maybe<Scalars['String']>;
  /** Last update time given as the ISO Date string */
  updatedAt: Scalars['DateTimeISO'];
};


/** Video media */
export type VideoMediaCaptionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CaptionWhere>;
};


/** Video media */
export type VideoMediaDubbingArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DubbWhere>;
};

/** Video media version */
export type VideoMediaVersion = {
  __typename?: 'VideoMediaVersion';
  /** Approximate time to watch video */
  approximateTime: Scalars['Int'];
  /** Details of captions */
  captionDetails?: Maybe<PostDetails>;
  /** UUID of caption */
  captionId?: Maybe<Scalars['String']>;
  /** Language of captions */
  captionLanguage?: Maybe<Language>;
  /** Specific country video is produced for */
  country: Array<Country>;
  /** Creator of video version */
  creator: Creator;
  /** Creator id of video version */
  creatorId: Scalars['String'];
  /** Details of dubbing */
  dubbingDetails?: Maybe<PostDetails>;
  /** UUID of dubbing */
  dubbingId?: Maybe<Scalars['String']>;
  /** Language of dubbing */
  dubbingLanguage?: Maybe<Language>;
  /** Details of native video */
  nativeVideoDetails: PostDetails;
  /** UUID of native video */
  nativeVideoId: Scalars['String'];
  /** Language of native video */
  nativeVideoLanguage: Language;
  /** Photo for video */
  photo: File;
  /** Post of video version */
  post: Post;
  /** Post id of video version */
  postId: Scalars['String'];
  /** Rich text content of video given as JSON string of HTML */
  scriptHTML?: Maybe<Scalars['String']>;
  /** MD5 hash of version change state */
  versionHash: Scalars['String'];
  /** URL of native landscape video */
  versionLandscapeUrl: Scalars['String'];
  /** URL of native portrait video */
  versionPortraitUrl?: Maybe<Scalars['String']>;
};

export type VideoMediaWhere = {
  /** AND operator */
  _and?: InputMaybe<Array<VideoMediaWhere>>;
  /** OR operator */
  _or?: InputMaybe<Array<VideoMediaWhere>>;
  /** Approximate time to watch video */
  approximateTime?: InputMaybe<NumberAttributeWhereOperators>;
  /** Specific country video is produced for */
  country?: InputMaybe<CountryArrayAttributeWhereOperators>;
  /** Creator id of video */
  creatorId?: InputMaybe<UuidAttributeWhereOperators>;
  /** UUID of video */
  id?: InputMaybe<UuidAttributeWhereOperators>;
  /** Language of video */
  language?: InputMaybe<LanguageAttributeWhereOperators>;
  /** Post id of video */
  postId?: InputMaybe<UuidAttributeWhereOperators>;
  /** Rich text content of audio vidoe given as JSON string of HTML */
  scriptHTML?: InputMaybe<StringAttributeWhereOperators>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Accent: Accent;
  AccentAttributeWhereOperators: AccentAttributeWhereOperators;
  ArticleMedia: ResolverTypeWrapper<ArticleMedia>;
  ArticleMediaVersion: ResolverTypeWrapper<ArticleMediaVersion>;
  ArticleMediaWhere: ArticleMediaWhere;
  AudioMedia: ResolverTypeWrapper<AudioMedia>;
  AudioMediaVersion: ResolverTypeWrapper<AudioMediaVersion>;
  AudioMediaWhere: AudioMediaWhere;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  BooleanAttributeWhereOperators: BooleanAttributeWhereOperators;
  Caption: ResolverTypeWrapper<Caption>;
  CaptionWhere: CaptionWhere;
  Card: ResolverTypeWrapper<Card>;
  CardPack: ResolverTypeWrapper<CardPack>;
  CardPackDetails: ResolverTypeWrapper<CardPackDetails>;
  CardPackDetailsWhere: CardPackDetailsWhere;
  CardPackWhere: CardPackWhere;
  CardWhere: CardWhere;
  Category: Category;
  CategoryArrayAttributeWhereOperators: CategoryArrayAttributeWhereOperators;
  ContentTopic: ContentTopic;
  Country: Country;
  CountryArrayAttributeWhereOperators: CountryArrayAttributeWhereOperators;
  Course: ResolverTypeWrapper<Course>;
  CourseDetails: ResolverTypeWrapper<CourseDetails>;
  CourseDetailsWhere: CourseDetailsWhere;
  CourseItem: ResolverTypeWrapper<Omit<CourseItem, 'content'> & { content: ResolversTypes['CourseItemContentUnion'] }>;
  CourseItemContentType: CourseItemContentType;
  CourseItemContentUnion: ResolversTypes['CardPack'] | ResolversTypes['Post'];
  CourseItemWhere: CourseItemWhere;
  CourseWhere: CourseWhere;
  Creator: ResolverTypeWrapper<Creator>;
  CreatorWhere: CreatorWhere;
  DateTimeISO: ResolverTypeWrapper<Scalars['DateTimeISO']>;
  Dubb: ResolverTypeWrapper<Dubb>;
  DubbWhere: DubbWhere;
  File: ResolverTypeWrapper<Omit<File, 'parent'> & { parent: ResolversTypes['FileParentUnion'] }>;
  FileDetailsParentType: FileDetailsParentType;
  FileParentUnion: ResolversTypes['ArticleMedia'] | ResolversTypes['AudioMedia'] | ResolversTypes['Caption'] | ResolversTypes['Creator'] | ResolversTypes['Dubb'] | ResolversTypes['Post'] | ResolversTypes['RecipeDetails'] | ResolversTypes['VideoMedia'];
  Float: ResolverTypeWrapper<Scalars['Float']>;
  Gender: Gender;
  GenderAttributeWhereOperators: GenderAttributeWhereOperators;
  IngredientListItem: ResolverTypeWrapper<IngredientListItem>;
  IngredientListItemWhere: IngredientListItemWhere;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Language: Language;
  LanguageAttributeWhereOperators: LanguageAttributeWhereOperators;
  MediaUnion: ResolversTypes['ArticleMedia'] | ResolversTypes['AudioMedia'] | ResolversTypes['VideoMedia'];
  MediaUnionWhere: MediaUnionWhere;
  MediaVersionUnion: ResolversTypes['ArticleMediaVersion'] | ResolversTypes['AudioMediaVersion'] | ResolversTypes['VideoMediaVersion'];
  NumberAttributeWhereOperators: NumberAttributeWhereOperators;
  Post: ResolverTypeWrapper<Omit<Post, 'media' | 'mediaVersions'> & { media: Array<ResolversTypes['MediaUnion']>, mediaVersions: Array<ResolversTypes['MediaVersionUnion']> }>;
  PostDetails: ResolverTypeWrapper<PostDetails>;
  PostDetailsWhere: PostDetailsWhere;
  PostMediaType: PostMediaType;
  PostMediaTypeAttributeWhereOperators: PostMediaTypeAttributeWhereOperators;
  PostWhere: PostWhere;
  Query: ResolverTypeWrapper<{}>;
  Recipe: ResolverTypeWrapper<Recipe>;
  RecipeDetails: ResolverTypeWrapper<RecipeDetails>;
  RecipeDetailsWhere: RecipeDetailsWhere;
  RecipeFormat: RecipeFormat;
  RecipeFormatAttributeWhereOperators: RecipeFormatAttributeWhereOperators;
  RecipeTag: RecipeTag;
  RecipeTagArrayAttributeWhereOperators: RecipeTagArrayAttributeWhereOperators;
  RecipeUnitAttributeWhereOperators: RecipeUnitAttributeWhereOperators;
  RecipeWhere: RecipeWhere;
  String: ResolverTypeWrapper<Scalars['String']>;
  StringArrayAttributeWhereOperators: StringArrayAttributeWhereOperators;
  StringAttributeWhereOperators: StringAttributeWhereOperators;
  TopicArrayAttributeWhereOperators: TopicArrayAttributeWhereOperators;
  UUIDAttributeWhereOperators: UuidAttributeWhereOperators;
  Unit: Unit;
  VideoMedia: ResolverTypeWrapper<VideoMedia>;
  VideoMediaVersion: ResolverTypeWrapper<VideoMediaVersion>;
  VideoMediaWhere: VideoMediaWhere;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccentAttributeWhereOperators: AccentAttributeWhereOperators;
  ArticleMedia: ArticleMedia;
  ArticleMediaVersion: ArticleMediaVersion;
  ArticleMediaWhere: ArticleMediaWhere;
  AudioMedia: AudioMedia;
  AudioMediaVersion: AudioMediaVersion;
  AudioMediaWhere: AudioMediaWhere;
  Boolean: Scalars['Boolean'];
  BooleanAttributeWhereOperators: BooleanAttributeWhereOperators;
  Caption: Caption;
  CaptionWhere: CaptionWhere;
  Card: Card;
  CardPack: CardPack;
  CardPackDetails: CardPackDetails;
  CardPackDetailsWhere: CardPackDetailsWhere;
  CardPackWhere: CardPackWhere;
  CardWhere: CardWhere;
  CategoryArrayAttributeWhereOperators: CategoryArrayAttributeWhereOperators;
  CountryArrayAttributeWhereOperators: CountryArrayAttributeWhereOperators;
  Course: Course;
  CourseDetails: CourseDetails;
  CourseDetailsWhere: CourseDetailsWhere;
  CourseItem: Omit<CourseItem, 'content'> & { content: ResolversParentTypes['CourseItemContentUnion'] };
  CourseItemContentUnion: ResolversParentTypes['CardPack'] | ResolversParentTypes['Post'];
  CourseItemWhere: CourseItemWhere;
  CourseWhere: CourseWhere;
  Creator: Creator;
  CreatorWhere: CreatorWhere;
  DateTimeISO: Scalars['DateTimeISO'];
  Dubb: Dubb;
  DubbWhere: DubbWhere;
  File: Omit<File, 'parent'> & { parent: ResolversParentTypes['FileParentUnion'] };
  FileParentUnion: ResolversParentTypes['ArticleMedia'] | ResolversParentTypes['AudioMedia'] | ResolversParentTypes['Caption'] | ResolversParentTypes['Creator'] | ResolversParentTypes['Dubb'] | ResolversParentTypes['Post'] | ResolversParentTypes['RecipeDetails'] | ResolversParentTypes['VideoMedia'];
  Float: Scalars['Float'];
  GenderAttributeWhereOperators: GenderAttributeWhereOperators;
  IngredientListItem: IngredientListItem;
  IngredientListItemWhere: IngredientListItemWhere;
  Int: Scalars['Int'];
  LanguageAttributeWhereOperators: LanguageAttributeWhereOperators;
  MediaUnion: ResolversParentTypes['ArticleMedia'] | ResolversParentTypes['AudioMedia'] | ResolversParentTypes['VideoMedia'];
  MediaUnionWhere: MediaUnionWhere;
  MediaVersionUnion: ResolversParentTypes['ArticleMediaVersion'] | ResolversParentTypes['AudioMediaVersion'] | ResolversParentTypes['VideoMediaVersion'];
  NumberAttributeWhereOperators: NumberAttributeWhereOperators;
  Post: Omit<Post, 'media' | 'mediaVersions'> & { media: Array<ResolversParentTypes['MediaUnion']>, mediaVersions: Array<ResolversParentTypes['MediaVersionUnion']> };
  PostDetails: PostDetails;
  PostDetailsWhere: PostDetailsWhere;
  PostMediaTypeAttributeWhereOperators: PostMediaTypeAttributeWhereOperators;
  PostWhere: PostWhere;
  Query: {};
  Recipe: Recipe;
  RecipeDetails: RecipeDetails;
  RecipeDetailsWhere: RecipeDetailsWhere;
  RecipeFormatAttributeWhereOperators: RecipeFormatAttributeWhereOperators;
  RecipeTagArrayAttributeWhereOperators: RecipeTagArrayAttributeWhereOperators;
  RecipeUnitAttributeWhereOperators: RecipeUnitAttributeWhereOperators;
  RecipeWhere: RecipeWhere;
  String: Scalars['String'];
  StringArrayAttributeWhereOperators: StringArrayAttributeWhereOperators;
  StringAttributeWhereOperators: StringAttributeWhereOperators;
  TopicArrayAttributeWhereOperators: TopicArrayAttributeWhereOperators;
  UUIDAttributeWhereOperators: UuidAttributeWhereOperators;
  VideoMedia: VideoMedia;
  VideoMediaVersion: VideoMediaVersion;
  VideoMediaWhere: VideoMediaWhere;
};

export type ArticleMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleMedia'] = ResolversParentTypes['ArticleMedia']> = {
  approximateTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  contentHTML?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentHTMLPreview?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Array<ResolversTypes['Country']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Creator'], ParentType, ContextType>;
  creatorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['Language'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  post?: Resolver<ResolversTypes['Post'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['Creator'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleMediaVersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleMediaVersion'] = ResolversParentTypes['ArticleMediaVersion']> = {
  approximateTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  articleDetails?: Resolver<ResolversTypes['PostDetails'], ParentType, ContextType>;
  articleHTML?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  articleHTMLPreview?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  articleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  articleLanguage?: Resolver<ResolversTypes['Language'], ParentType, ContextType>;
  country?: Resolver<Array<ResolversTypes['Country']>, ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Creator'], ParentType, ContextType>;
  creatorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>;
  post?: Resolver<ResolversTypes['Post'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  versionHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AudioMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['AudioMedia'] = ResolversParentTypes['AudioMedia']> = {
  approximateTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  country?: Resolver<Array<ResolversTypes['Country']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Creator'], ParentType, ContextType>;
  creatorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['Language'], ParentType, ContextType>;
  media?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  post?: Resolver<ResolversTypes['Post'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scriptHTML?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AudioMediaVersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AudioMediaVersion'] = ResolversParentTypes['AudioMediaVersion']> = {
  approximateTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  audioDetails?: Resolver<ResolversTypes['PostDetails'], ParentType, ContextType>;
  audioId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  audioLanguage?: Resolver<Maybe<ResolversTypes['Language']>, ParentType, ContextType>;
  audioUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Array<ResolversTypes['Country']>, ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Creator'], ParentType, ContextType>;
  creatorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  post?: Resolver<ResolversTypes['Post'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scriptHTML?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  versionHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Caption'] = ResolversParentTypes['Caption']> = {
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['Language'], ParentType, ContextType>;
  srt?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  videoMedia?: Resolver<ResolversTypes['VideoMedia'], ParentType, ContextType>;
  videoMediaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CardResolvers<ContextType = any, ParentType extends ResolversParentTypes['Card'] = ResolversParentTypes['Card']> = {
  cardPackDetails?: Resolver<ResolversTypes['CardPackDetails'], ParentType, ContextType>;
  cardPackDetailsId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentHTML?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>;
  position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CardPackResolvers<ContextType = any, ParentType extends ResolversParentTypes['CardPack'] = ResolversParentTypes['CardPack']> = {
  approximateTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cardPackDetailsList?: Resolver<Array<ResolversTypes['CardPackDetails']>, ParentType, ContextType, Partial<CardPackCardPackDetailsListArgs>>;
  category?: Resolver<Array<ResolversTypes['Category']>, ParentType, ContextType>;
  contentValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  topic?: Resolver<Array<ResolversTypes['ContentTopic']>, ParentType, ContextType>;
  universalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CardPackDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CardPackDetails'] = ResolversParentTypes['CardPackDetails']> = {
  cardPack?: Resolver<ResolversTypes['Card'], ParentType, ContextType>;
  cardPackId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cards?: Resolver<Array<ResolversTypes['Card']>, ParentType, ContextType, Partial<CardPackDetailsCardsArgs>>;
  country?: Resolver<Array<ResolversTypes['Country']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  keywords?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  language?: Resolver<ResolversTypes['Language'], ParentType, ContextType>;
  learningOutcome?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Course'] = ResolversParentTypes['Course']> = {
  category?: Resolver<Array<ResolversTypes['Category']>, ParentType, ContextType>;
  contentValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  courseDetailsList?: Resolver<Array<ResolversTypes['CourseDetails']>, ParentType, ContextType, Partial<CourseCourseDetailsListArgs>>;
  courseItems?: Resolver<Array<ResolversTypes['CourseItem']>, ParentType, ContextType, Partial<CourseCourseItemsArgs>>;
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  topic?: Resolver<Array<ResolversTypes['ContentTopic']>, ParentType, ContextType>;
  universalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseDetails'] = ResolversParentTypes['CourseDetails']> = {
  course?: Resolver<ResolversTypes['Course'], ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  keywords?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  language?: Resolver<ResolversTypes['Language'], ParentType, ContextType>;
  learningOutcome?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseItem'] = ResolversParentTypes['CourseItem']> = {
  content?: Resolver<ResolversTypes['CourseItemContentUnion'], ParentType, ContextType>;
  contentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentType?: Resolver<ResolversTypes['CourseItemContentType'], ParentType, ContextType>;
  course?: Resolver<ResolversTypes['Course'], ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseItemContentUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseItemContentUnion'] = ResolversParentTypes['CourseItemContentUnion']> = {
  __resolveType: TypeResolveFn<'CardPack' | 'Post', ParentType, ContextType>;
};

export type CreatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Creator'] = ResolversParentTypes['Creator']> = {
  accent?: Resolver<ResolversTypes['Accent'], ParentType, ContextType>;
  articleVersions?: Resolver<Array<ResolversTypes['ArticleMediaVersion']>, ParentType, ContextType>;
  articles?: Resolver<Array<ResolversTypes['ArticleMedia']>, ParentType, ContextType, Partial<CreatorArticlesArgs>>;
  audioVersions?: Resolver<Array<ResolversTypes['AudioMediaVersion']>, ParentType, ContextType>;
  audios?: Resolver<Array<ResolversTypes['AudioMedia']>, ParentType, ContextType, Partial<CreatorAudiosArgs>>;
  bio?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Gender'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['Language'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  videoVersions?: Resolver<Array<ResolversTypes['VideoMediaVersion']>, ParentType, ContextType>;
  videos?: Resolver<Array<ResolversTypes['VideoMedia']>, ParentType, ContextType, Partial<CreatorVideosArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeIsoScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTimeISO'], any> {
  name: 'DateTimeISO';
}

export type DubbResolvers<ContextType = any, ParentType extends ResolversParentTypes['Dubb'] = ResolversParentTypes['Dubb']> = {
  audio?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['Language'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  videoMedia?: Resolver<ResolversTypes['VideoMedia'], ParentType, ContextType>;
  videoMediaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileResolvers<ContextType = any, ParentType extends ResolversParentTypes['File'] = ResolversParentTypes['File']> = {
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mimetype?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<ResolversTypes['FileParentUnion'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parentType?: Resolver<ResolversTypes['FileDetailsParentType'], ParentType, ContextType>;
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileParentUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileParentUnion'] = ResolversParentTypes['FileParentUnion']> = {
  __resolveType: TypeResolveFn<'ArticleMedia' | 'AudioMedia' | 'Caption' | 'Creator' | 'Dubb' | 'Post' | 'RecipeDetails' | 'VideoMedia', ParentType, ContextType>;
};

export type IngredientListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['IngredientListItem'] = ResolversParentTypes['IngredientListItem']> = {
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  customUnitPlural?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customUnitSingular?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ingredient?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  recipeDetails?: Resolver<ResolversTypes['RecipeDetails'], ParentType, ContextType>;
  recipeDetailsId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unit?: Resolver<ResolversTypes['Unit'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaUnion'] = ResolversParentTypes['MediaUnion']> = {
  __resolveType: TypeResolveFn<'ArticleMedia' | 'AudioMedia' | 'VideoMedia', ParentType, ContextType>;
};

export type MediaVersionUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaVersionUnion'] = ResolversParentTypes['MediaVersionUnion']> = {
  __resolveType: TypeResolveFn<'ArticleMediaVersion' | 'AudioMediaVersion' | 'VideoMediaVersion', ParentType, ContextType>;
};

export type PostResolvers<ContextType = any, ParentType extends ResolversParentTypes['Post'] = ResolversParentTypes['Post']> = {
  category?: Resolver<Array<ResolversTypes['Category']>, ParentType, ContextType>;
  clinicallyVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contentValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  icd10?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  media?: Resolver<Array<ResolversTypes['MediaUnion']>, ParentType, ContextType, Partial<PostMediaArgs>>;
  mediaType?: Resolver<ResolversTypes['PostMediaType'], ParentType, ContextType>;
  mediaVersions?: Resolver<Array<ResolversTypes['MediaVersionUnion']>, ParentType, ContextType>;
  postDetailsList?: Resolver<Array<ResolversTypes['PostDetails']>, ParentType, ContextType, Partial<PostPostDetailsListArgs>>;
  topic?: Resolver<Array<ResolversTypes['ContentTopic']>, ParentType, ContextType>;
  universalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostDetails'] = ResolversParentTypes['PostDetails']> = {
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  keywords?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  language?: Resolver<ResolversTypes['Language'], ParentType, ContextType>;
  learningOutcome?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  post?: Resolver<ResolversTypes['Post'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  getCardPackDetails?: Resolver<Array<ResolversTypes['CardPackDetails']>, ParentType, ContextType, Partial<QueryGetCardPackDetailsArgs>>;
  getCardPacks?: Resolver<Array<ResolversTypes['CardPack']>, ParentType, ContextType, Partial<QueryGetCardPacksArgs>>;
  getCourseDetails?: Resolver<Array<ResolversTypes['CourseDetails']>, ParentType, ContextType, Partial<QueryGetCourseDetailsArgs>>;
  getCourses?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, Partial<QueryGetCoursesArgs>>;
  getCreators?: Resolver<Array<ResolversTypes['Creator']>, ParentType, ContextType, Partial<QueryGetCreatorsArgs>>;
  getPostDetails?: Resolver<Array<ResolversTypes['PostDetails']>, ParentType, ContextType, Partial<QueryGetPostDetailsArgs>>;
  getPosts?: Resolver<Array<ResolversTypes['Post']>, ParentType, ContextType, Partial<QueryGetPostsArgs>>;
  getRecipeDetails?: Resolver<Array<ResolversTypes['RecipeDetails']>, ParentType, ContextType, Partial<QueryGetRecipeDetailsArgs>>;
  getRecipes?: Resolver<Array<ResolversTypes['Recipe']>, ParentType, ContextType, Partial<QueryGetRecipesArgs>>;
};

export type RecipeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Recipe'] = ResolversParentTypes['Recipe']> = {
  approximateTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  calories?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  category?: Resolver<Array<ResolversTypes['Category']>, ParentType, ContextType>;
  contentValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  recipeDetailsList?: Resolver<Array<ResolversTypes['RecipeDetails']>, ParentType, ContextType, Partial<RecipeRecipeDetailsListArgs>>;
  servings?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['RecipeTag']>, ParentType, ContextType>;
  topic?: Resolver<Array<ResolversTypes['ContentTopic']>, ParentType, ContextType>;
  universalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecipeDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecipeDetails'] = ResolversParentTypes['RecipeDetails']> = {
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  format?: Resolver<ResolversTypes['RecipeFormat'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ingredientsList?: Resolver<Array<ResolversTypes['IngredientListItem']>, ParentType, ContextType, Partial<RecipeDetailsIngredientsListArgs>>;
  keywords?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  language?: Resolver<ResolversTypes['Language'], ParentType, ContextType>;
  method?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  methodPreview?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  recipe?: Resolver<ResolversTypes['Recipe'], ParentType, ContextType>;
  recipeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoMedia'] = ResolversParentTypes['VideoMedia']> = {
  approximateTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  captions?: Resolver<Array<ResolversTypes['Caption']>, ParentType, ContextType, Partial<VideoMediaCaptionsArgs>>;
  country?: Resolver<Array<ResolversTypes['Country']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Creator'], ParentType, ContextType>;
  creatorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeISO']>, ParentType, ContextType>;
  dubbing?: Resolver<Array<ResolversTypes['Dubb']>, ParentType, ContextType, Partial<VideoMediaDubbingArgs>>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  landscapeVideo?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['Language'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  portraitVideo?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>;
  post?: Resolver<ResolversTypes['Post'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scriptHTML?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeISO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoMediaVersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoMediaVersion'] = ResolversParentTypes['VideoMediaVersion']> = {
  approximateTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  captionDetails?: Resolver<Maybe<ResolversTypes['PostDetails']>, ParentType, ContextType>;
  captionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  captionLanguage?: Resolver<Maybe<ResolversTypes['Language']>, ParentType, ContextType>;
  country?: Resolver<Array<ResolversTypes['Country']>, ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Creator'], ParentType, ContextType>;
  creatorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dubbingDetails?: Resolver<Maybe<ResolversTypes['PostDetails']>, ParentType, ContextType>;
  dubbingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dubbingLanguage?: Resolver<Maybe<ResolversTypes['Language']>, ParentType, ContextType>;
  nativeVideoDetails?: Resolver<ResolversTypes['PostDetails'], ParentType, ContextType>;
  nativeVideoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nativeVideoLanguage?: Resolver<ResolversTypes['Language'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  post?: Resolver<ResolversTypes['Post'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scriptHTML?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  versionHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  versionLandscapeUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  versionPortraitUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  ArticleMedia?: ArticleMediaResolvers<ContextType>;
  ArticleMediaVersion?: ArticleMediaVersionResolvers<ContextType>;
  AudioMedia?: AudioMediaResolvers<ContextType>;
  AudioMediaVersion?: AudioMediaVersionResolvers<ContextType>;
  Caption?: CaptionResolvers<ContextType>;
  Card?: CardResolvers<ContextType>;
  CardPack?: CardPackResolvers<ContextType>;
  CardPackDetails?: CardPackDetailsResolvers<ContextType>;
  Course?: CourseResolvers<ContextType>;
  CourseDetails?: CourseDetailsResolvers<ContextType>;
  CourseItem?: CourseItemResolvers<ContextType>;
  CourseItemContentUnion?: CourseItemContentUnionResolvers<ContextType>;
  Creator?: CreatorResolvers<ContextType>;
  DateTimeISO?: GraphQLScalarType;
  Dubb?: DubbResolvers<ContextType>;
  File?: FileResolvers<ContextType>;
  FileParentUnion?: FileParentUnionResolvers<ContextType>;
  IngredientListItem?: IngredientListItemResolvers<ContextType>;
  MediaUnion?: MediaUnionResolvers<ContextType>;
  MediaVersionUnion?: MediaVersionUnionResolvers<ContextType>;
  Post?: PostResolvers<ContextType>;
  PostDetails?: PostDetailsResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Recipe?: RecipeResolvers<ContextType>;
  RecipeDetails?: RecipeDetailsResolvers<ContextType>;
  VideoMedia?: VideoMediaResolvers<ContextType>;
  VideoMediaVersion?: VideoMediaVersionResolvers<ContextType>;
};


export const PostFragmentDoc = gql`
    fragment Post on Post {
  id
  universalTitle
  mediaType
  category
  postDetailsList {
    description
  }
  media {
    ... on AudioMedia {
      language
      approximateTime
      photo {
        url
      }
      media {
        mimetype
        url
      }
    }
    ... on VideoMedia {
      language
      approximateTime
      photo {
        url
      }
      landscapeVideo {
        mimetype
        url
      }
      portraitVideo {
        mimetype
        url
      }
    }
    ... on ArticleMedia {
      language
      approximateTime
      photo {
        url
      }
      contentHTML
    }
  }
}
    `;
export const PostListDocument = gql`
    query PostList {
  getPosts {
    ...Post
  }
}
    ${PostFragmentDoc}`;

/**
 * __usePostListQuery__
 *
 * To run a query within a React component, call `usePostListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostListQuery(baseOptions?: Apollo.QueryHookOptions<PostListQuery, PostListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostListQuery, PostListQueryVariables>(PostListDocument, options);
      }
export function usePostListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostListQuery, PostListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostListQuery, PostListQueryVariables>(PostListDocument, options);
        }
export type PostListQueryHookResult = ReturnType<typeof usePostListQuery>;
export type PostListLazyQueryHookResult = ReturnType<typeof usePostListLazyQuery>;
export type PostListQueryResult = Apollo.QueryResult<PostListQuery, PostListQueryVariables>;
export type PostFragment = { __typename?: 'Post', id: string, universalTitle: string, mediaType: PostMediaType, category: Array<Category>, postDetailsList: Array<{ __typename?: 'PostDetails', description: string }>, media: Array<{ __typename?: 'ArticleMedia', language: Language, approximateTime: number, contentHTML?: string | null, photo: { __typename?: 'File', url: string } } | { __typename?: 'AudioMedia', language: Language, approximateTime: number, photo: { __typename?: 'File', url: string }, media: { __typename?: 'File', mimetype: string, url: string } } | { __typename?: 'VideoMedia', language: Language, approximateTime: number, photo: { __typename?: 'File', url: string }, landscapeVideo: { __typename?: 'File', mimetype: string, url: string }, portraitVideo?: { __typename?: 'File', mimetype: string, url: string } | null }> };

export type PostListQueryVariables = Exact<{ [key: string]: never; }>;


export type PostListQuery = { __typename?: 'Query', getPosts: Array<{ __typename?: 'Post', id: string, universalTitle: string, mediaType: PostMediaType, category: Array<Category>, postDetailsList: Array<{ __typename?: 'PostDetails', description: string }>, media: Array<{ __typename?: 'ArticleMedia', language: Language, approximateTime: number, contentHTML?: string | null, photo: { __typename?: 'File', url: string } } | { __typename?: 'AudioMedia', language: Language, approximateTime: number, photo: { __typename?: 'File', url: string }, media: { __typename?: 'File', mimetype: string, url: string } } | { __typename?: 'VideoMedia', language: Language, approximateTime: number, photo: { __typename?: 'File', url: string }, landscapeVideo: { __typename?: 'File', mimetype: string, url: string }, portraitVideo?: { __typename?: 'File', mimetype: string, url: string } | null }> }> };

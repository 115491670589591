import { styled } from '@mui/material'

import { PlaceholderButton } from '@nuna/tunic'

export interface AddAppointmentButtonProps {
  disabled?: boolean
  onClick: () => void
}

export function AddAppointmentButton({ onClick, ...props }: AddAppointmentButtonProps) {
  return (
    <AddAnotherButton onClick={onClick} {...props}>
      {'Add Another Appointment'}
    </AddAnotherButton>
  )
}

const AddAnotherButton = styled(PlaceholderButton)`
  margin-top: 1.25rem;
`

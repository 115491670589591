import * as Sentry from '@sentry/browser'
import { ApolloProvider } from '@apollo/client'
import { FullStory, init as initFullStory } from '@fullstory/browser'
import { fullStoryIntegration } from '@sentry/fullstory'
import 'focus-visible'
import mixpanel from 'mixpanel-browser'
import { createRoot } from 'react-dom/client'

import { Role } from '@nuna/api'
import { AuthDataProvider } from '@nuna/auth'

import { App } from './App/App'
import { environment } from './environments/environment'
import './index.scss'
import { client } from './util/apollo'

if (import.meta.env.PROD) {
  initFullStory({ orgId: 'NXA0K' })

  Sentry.init({
    dsn: 'https://214e2588b4e64b759914a9cc4985e81f@o339347.ingest.us.sentry.io/1875472',
    integrations: [fullStoryIntegration('339347', { client: FullStory })],
    environment: import.meta.env.VITE_ENVIRONMENT || 'UNKNOWN_ENV',
  })
}

try {
  mixpanel.init(environment.MIXPANEL_TOKEN as string, {
    test: environment.CI,
    opt_out_tracking_by_default: environment.CI,
  })
} catch (err) {
  console.warn('Mixpanel could not load! booting anyway ', err)
}

const rootElement = document.getElementById('root')

if (rootElement) {
  const root = createRoot(rootElement)
  root.render(
    <ApolloProvider client={client}>
      <AuthDataProvider allowedRoles={[Role.Patient]}>
        <App />
      </AuthDataProvider>
    </ApolloProvider>,
  )

  // @ts-expect-error - value replaced by vite
  // eslint-disable-next-line no-console
  console.info('Deployed at', __BUILD_TIMESTAMP__, 'production:', environment.production)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()

import { Checkbox } from '@nuna/tunic'

import { useSearchProviderContext } from '../SearchProviderContext'
import { FilterSection } from './FilterAccordion'

export function CoverageFiltersSelect() {
  const { appliedFilters, paymentMethodOptions, togglePaymentMethod } = useSearchProviderContext()

  return (
    <FilterSection>
      {paymentMethodOptions.map(option => (
        <Checkbox
          key={option.id}
          onChange={() => togglePaymentMethod(option)}
          checked={!!appliedFilters.coverage.find(p => p.id === option.id)}
          className="checkbox"
        >
          {option.name}
        </Checkbox>
      ))}
    </FilterSection>
  )
}

import * as Yup from 'yup'
import { Formik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PublicCompanyFragment, usePublicCompanyLazyQuery } from '@nuna/api'
import { formService } from '@nuna/core'
import { Employer } from '@nuna/coverage'
import { supportService } from '@nuna/telemetry'
import { SignupLayout as SignupLayoutComponents, tealSet, toast } from '@nuna/tunic'

import { getSignupRoute } from '../../../util/routes'
import { SignupLayout } from '../SignupLayout'
import intlBgSrc from '../img/intl-bg.png'
import { InternationalCoverage } from './InternationalCoverage'
import { InternationalSignupForm, InternationalSignupFormValues } from './InternationalSignupForm'
import { RedirectNotice } from './RedirectNotice'
import { faqItems } from './faqItems'

const { oopsRequired } = formService
const { Heading } = SignupLayoutComponents

export interface FakeCompany {
  name: string
  offersTava: boolean
}

const internationalSignupSchema = Yup.object().shape<InternationalSignupFormValues>({
  country: Yup.string().required(oopsRequired('country')),
  employer: Yup.object<Employer>().nullable().required(oopsRequired('employer')),
})

const initialValues = {
  country: '',
  employer: null,
}

export function InternationalSignup() {
  const [publicCompanyQuery, { loading }] = usePublicCompanyLazyQuery()
  const navigate = useNavigate()

  const [fetchedCompany, setFetchedCompany] = useState<PublicCompanyFragment | FakeCompany | null>(null)
  const [isRedirectingToUS, setIsRedirectingToUS] = useState(false)

  const handleSubmit = async (values: InternationalSignupFormValues) => {
    if (!values.employer) {
      console.error('Submitted form without employer')
      return
    }

    if (values.country === 'US') {
      setIsRedirectingToUS(true)
      setTimeout(() => {
        navigate(getSignupRoute())
      }, 3000)
      return
    }

    if (!values.employer.offersTava) {
      setFetchedCompany(values.employer)
      return
    }

    try {
      const { data } = await publicCompanyQuery({ variables: { id: values.employer.id } })
      if (data) {
        setFetchedCompany(data.publicCompany)
      } else {
        toast.urgent('Could not get company information')
      }
    } catch (e) {
      toast.urgent('Could not get company information')
      console.error(e)
    }
  }

  return (
    <>
      <style>{supportService.HIDE_LAUNCHER_CSS}</style>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={internationalSignupSchema}>
        {!fetchedCompany ? (
          <SignupLayout
            showInternationalLinks
            backgroundColor={tealSet[90].hex}
            backgroundImage={intlBgSrc}
            faqItems={faqItems}
            renderForm={isRedirectingToUS ? <RedirectNotice /> : <InternationalSignupForm isLoading={loading} />}
            renderLeftContent={
              <Heading>
                Life can be hard.
                <br />
                Tava can help you too.
              </Heading>
            }
          />
        ) : (
          <InternationalCoverage publicCompany={fetchedCompany} resetQuery={() => setFetchedCompany(null)} />
        )}
      </Formik>
    </>
  )
}

import { HTMLAttributes } from 'react'

import { BasicProviderFragment } from '@nuna/api'
import { OutlineButtonLink } from '@nuna/tunic'
import { Avatar } from '@nuna/tunic'

import { handleNevermindClick } from './switch-provider.utils'

interface SwitchProviderCancelButtonProps extends HTMLAttributes<HTMLAnchorElement> {
  provider: BasicProviderFragment | undefined
  to?: string
}

export function SwitchProviderCancelButton({ provider, to = '/', ...props }: SwitchProviderCancelButtonProps) {
  if (!provider) return null

  return (
    <OutlineButtonLink onClick={() => handleNevermindClick(provider.firstName)} to={to} {...props}>
      <Avatar url={provider.avatarUrl} size="mini" style={{ marginRight: '0.5rem' }} />
      Stick with {provider.firstName}
    </OutlineButtonLink>
  )
}

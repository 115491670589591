import { styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

import { IconChevronThick, body2, borderGrey } from '@nuna/tunic'

export function Subheader({ className = '' }: { className?: string }) {
  return (
    <Container className={className} aria-label="Breadcrumbs">
      <NavLink to="/" className="v-align">
        <IconChevronThick className="mr-1" size={18} /> Back to Home
      </NavLink>
    </Container>
  )
}

const Container = styled('nav')`
  border-bottom: 1px solid ${borderGrey};
  padding: 1.5rem 0;

  a {
    color: ${body2};
  }
`

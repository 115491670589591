import { makeTypographyComponent } from '@nuna/tunic'

export function HealthAdvocateSsoWelcomeContent() {
  return (
    <div className="flex-column gap-3">
      <Heading>
        Start your mental health
        <br />
        journey on the
        <br />
        right foot.
      </Heading>
      <p className="body paragraphs pb-2">
        Your psychological well-being can affect your physical health, relationships, and work performance. Find a
        therapist to help you step out of the fog, and get back to a happier, more fulfilled you.
      </p>
      <p className="body paragraphs">
        For questions please contact{' '}
        <a href="https://www.healthadvocate.com/site/contact" className="sso-external-link dark">
          Health Advocate
        </a>
      </p>
    </div>
  )
}

export function HealthAdvocateSsoFinishContent({
  numSessions,
  supportPhoneNumber,
}: {
  numSessions?: string
  supportPhoneNumber?: string
}) {
  return (
    <div className="flex-column gap-3">
      <Heading>
        Get started with
        <br />
        your free online
        <br />
        mental health benefit.
      </Heading>
      <p className="body paragraphs pb-2">
        Health Advocate is providing you with {numSessions ?? ''} covered sessions with licensed mental health
        professionals through Tava's secure and confidential online platform.
      </p>
      {supportPhoneNumber && (
        <p className="body paragraphs">
          For questions please contact Health Advocate at{' '}
          <a href={`tel:+1${supportPhoneNumber.replace(/-/g, '')}`} className="sso-external-link dark">
            {supportPhoneNumber.replace(/-/g, '.')}
          </a>
        </p>
      )}
    </div>
  )
}

const Heading = makeTypographyComponent('h3', 'h1')

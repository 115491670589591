import { styled } from '@mui/material'
import { FormHelperText } from '@mui/material'

import {
  Chip,
  IconAfternoon,
  IconEvening,
  IconMorning,
  borderGrey,
  interactiveText,
  plumSet,
  primary,
} from '@nuna/tunic'

import { MiscAnswer } from './MiscAnswer'
import { ALL_OF_THE_ABOVE } from './TherapistPreferences'
import { preferencesSubheadingSize } from './shared'

const availabilityDisplayValues = {
  mornings: {
    color: plumSet.primary.hex,
    display: 'Morning',
    icon: <IconMorning />,
  },
  afternoons: {
    color: primary,
    display: 'Afternoon',
    icon: <IconAfternoon />,
  },
  evenings: {
    color: interactiveText,
    display: 'Evenings',
    icon: <IconEvening />,
  },
}

const timesOfDay: (keyof typeof availabilityDisplayValues)[] = ['mornings', 'afternoons', 'evenings']

export function TherapistAvailability({
  handleBestTimesChange,
  bestTimesFieldProps,
  values,
  setFieldValue,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  return (
    <>
      <h2 className={preferencesSubheadingSize}>Therapist Availability</h2>
      <p className="mb-4">Select all that apply</p>

      {timesOfDay.map(timeOfDay => (
        <div key={timeOfDay} className="mb-3">
          <Fieldset>
            <Legend style={{ width: 114, color: availabilityDisplayValues[timeOfDay].color }}>
              <span className="text-center">
                {availabilityDisplayValues[timeOfDay].icon}
                <br />
                {availabilityDisplayValues[timeOfDay].display}
              </span>
            </Legend>

            <MobileChipWrapper>
              {['weekday', 'weekend'].map(timeOfWeek => (
                <Chip
                  key={timeOfWeek}
                  name={bestTimesFieldProps.name}
                  value={`${timeOfWeek}_${timeOfDay}`}
                  type="checkbox"
                  checked={values[bestTimesFieldProps.name].includes(`${timeOfWeek}_${timeOfDay}`)}
                  onChange={handleBestTimesChange}
                  dataTestId={`${bestTimesFieldProps.name}-${timeOfWeek}_${timeOfDay}`}
                >
                  {timeOfWeek === 'weekday' ? 'Weekday' : 'Weekend'}
                </Chip>
              ))}
            </MobileChipWrapper>
          </Fieldset>
        </div>
      ))}

      {bestTimesFieldProps.helperText && (
        <FormHelperText error={bestTimesFieldProps.error}>{bestTimesFieldProps.helperText}</FormHelperText>
      )}

      <MiscAnswer>
        <Chip
          type="checkbox"
          checked={values[bestTimesFieldProps.name].includes(ALL_OF_THE_ABOVE)}
          value={ALL_OF_THE_ABOVE}
          name={bestTimesFieldProps.name}
          onChange={() => setFieldValue(bestTimesFieldProps.name, [ALL_OF_THE_ABOVE])}
          dataTestId={`${bestTimesFieldProps.name}-${ALL_OF_THE_ABOVE}`}
        >
          Any time works
        </Chip>
      </MiscAnswer>
    </>
  )
}

const MobileChipWrapper = styled('div')`
  @media (min-width: 411px) {
    display: flex;
    align-items: center;
  }

  // TODO: DOM specific selectors aren't great here but since this is an experiment it's good for now
  & > div {
    vertical-align: middle;
  }

  & > div + div {
    margin-left: 0.75rem;
  }

  @media (max-width: 410px) {
    & > div + div {
      margin-top: 0.75rem;
      margin-left: 0;
    }

    & > div,
    & > div > label {
      width: 100%;
    }

    & > div > label {
      justify-content: center;
    }
  }
`

const Legend = styled('p')`
  align-items: center;
  border-right: 1px solid ${borderGrey};
  display: inline-flex;
  font-size: 1rem;
  font-weight: 400;
  justify-content: center;
  margin: -1.125rem 1.125rem -1.125rem -1.125rem;
  padding: 1.5rem;
`

const Fieldset = styled('div')`
  border: 1px solid ${borderGrey};
  border-radius: 12px;
  display: inline-flex;
  padding: 1.125rem;
`
Fieldset.defaultProps = { role: 'group' }

import { useStytchB2BClient } from '@stytch/react/b2b'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { usePartnerBrandingQuery } from '@nuna/api'
import type { SsoOrganization } from '@nuna/auth'
import { routeService } from '@nuna/core'

import { SsoLayout } from './SsoLayout'

export function SsoScreen({ postFlow = false }: { postFlow?: boolean }) {
  const navigate = useNavigate()
  const { ssoOrgSlug } = useParams()
  const stytchClient = useStytchB2BClient()
  const [loading, setLoading] = useState(true)
  const [organization, setOrganization] = useState<SsoOrganization>()

  const { data, loading: brandingLoading } = usePartnerBrandingQuery({
    variables: { organizationSlug: ssoOrgSlug ?? '' },
  })
  const customBranding = data?.partnerBranding

  useEffect(() => {
    ;(async () => {
      if (ssoOrgSlug) {
        try {
          const { organization: stytchOrg } = await stytchClient.organization.getBySlug({
            organization_slug: ssoOrgSlug,
          })

          if (stytchOrg) {
            if (!stytchOrg.sso_default_connection_id) throw new Error('No connection found for SSO organization')
            setOrganization({
              name: stytchOrg.organization_name,
              slug: ssoOrgSlug,
              connectionId: stytchOrg.sso_default_connection_id,
            })
          } else {
            navigate(routeService.login())
          }
        } catch (error) {
          console.error('Failed to fetch SSO organization', error)

          navigate(routeService.login())
        } finally {
          setLoading(false)
        }
      }
    })()
  }, [navigate, ssoOrgSlug, stytchClient])

  if (loading || brandingLoading || !organization) return null

  return <SsoLayout customBranding={customBranding} organization={organization} postFlow={postFlow} />
}

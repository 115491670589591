import { styled, useTheme } from '@mui/material'
import { motion } from 'framer-motion'

import { BelowTablet, Grid, OutlineButtonLink } from '@nuna/tunic'

import familyAndFriendsIllustration from '../../shared/img/family-and-friends-illustration.png'

export function InviteMessage() {
  const theme = useTheme()

  return (
    <Container layout>
      <Grid container alignItems="center" spacing={3} justifyContent="center">
        <Grid size={{ xs: 12, sm: 6 }} className="text-center">
          <Illustration className="mt-3 mb-3" src={familyAndFriendsIllustration} alt="" />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextContainer breakpoint={theme.breakpoints.down('sm')}>
            <h3>Share healing</h3>
            <p>
              If someone you know is struggling, suggesting therapy can help them feel seen and loved. Sending an invite
              may be just the nudge they need.
            </p>
            <OutlineButtonLink className="mt-2" to="/invite">
              Send an Invite
            </OutlineButtonLink>
          </TextContainer>
        </Grid>
      </Grid>
    </Container>
  )
}

const TextContainer = styled('div')<{ breakpoint: string }>`
  max-width: 24.125em;

  ${props => props.breakpoint} {
    margin-left: auto;
    margin-right: auto;
  }
`

const Illustration = styled('img')`
  max-width: 320px;

  @media (${BelowTablet}) {
    max-width: 270px;
  }
`

const Container = styled(motion.div)`
  padding-left: 12px;
  padding-right: 12px;

  margin-top: 8rem;

  @media (${BelowTablet}) {
    margin-top: 4rem;
  }
`

import { styled } from '@mui/material'

import { styledUtils } from '@nuna/tunic'
import { IconFavorite, IconFavoriteOutline, IconToggleButton, tealSet } from '@nuna/tunic'

import { useSearchProviderContext } from '../SearchProviderContext'

const { transientPropOptions } = styledUtils

export function FavoritesFilterToggle({ className = '' }: { className?: string }) {
  const { setFavoritesOnly, appliedFilters } = useSearchProviderContext()

  return (
    <FavoritesIconToggleButton
      tooltip={`${appliedFilters.favoritesOnly ? 'Hide' : 'Show'} Favorites`}
      className={className}
      $active={appliedFilters.favoritesOnly}
    >
      <>
        {!appliedFilters.favoritesOnly && <IconFavoriteOutline size={22} onClick={() => setFavoritesOnly(true)} />}
        {appliedFilters.favoritesOnly && <IconFavorite size={22} onClick={() => setFavoritesOnly(false)} />}
      </>
    </FavoritesIconToggleButton>
  )
}

const FavoritesIconToggleButton = styled(IconToggleButton, transientPropOptions)<{ $active: boolean }>`
  ${props =>
    props.$active &&
    `
  &, &:hover {
    background: ${tealSet[5].hex};
    border-color: ${tealSet[70].hex};
    outline: none;
  }

  &:hover::after, &:focus-visible::after {
    border-color: ${tealSet[70].hex};
  }
  `}
`

import { useSearchParams } from 'react-router-dom'

import { useCancelPolicyStatusQuery, usePatientContextQuery } from '@nuna/api'
import { ACCEPT_PROVIDER_FEES_PARAM } from '@nuna/core'

import { AcceptProviderFeesDrawer } from './AcceptProviderFeesDrawer'

export function AcceptProviderFeesDrawerLauncher() {
  const [searchParams, setSearchParams] = useSearchParams()
  const acceptProviderId = searchParams.get(ACCEPT_PROVIDER_FEES_PARAM)

  const { data: patientContextData } = usePatientContextQuery()
  const patient = patientContextData?.patientContext?.patient
  const provider = patient?.providers?.find(({ id }) => id === acceptProviderId)

  const { data: cancelPolicyData } = useCancelPolicyStatusQuery({
    variables: {
      providerId: provider?.id ?? '',
      clientId: patient?.id,
      defaultToTavaPolicy: false,
    },
    skip: !provider?.id || !patient?.id,
  })
  const cancelPolicyStatus = cancelPolicyData?.cancelPolicyStatus

  const closeProviderFeesDrawer = () =>
    setSearchParams(oldSearchParams => {
      oldSearchParams.delete(ACCEPT_PROVIDER_FEES_PARAM)
      return oldSearchParams
    })

  return (
    <AcceptProviderFeesDrawer
      cancelPolicyStatus={cancelPolicyStatus}
      provider={provider}
      isOpen={!!cancelPolicyStatus?.availableCancelPolicy && !!provider}
      onClose={closeProviderFeesDrawer}
    />
  )
}

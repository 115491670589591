import { AuthorizedInternationalRegionFragment, InternationalPhoneNumberType } from '@nuna/api'

export function getInternationalPhoneNumbers(internationalArea: AuthorizedInternationalRegionFragment | undefined) {
  if (!internationalArea) {
    return { tollFree: null, direct: null }
  }

  const tollFreeNumber = internationalArea.wpoPhoneNumbers.find(
    phoneNumber => phoneNumber.phoneNumber && phoneNumber.phoneType === InternationalPhoneNumberType.TollFree,
  )
  const directDialNumber = internationalArea.wpoPhoneNumbers.find(
    phoneNumber => phoneNumber.phoneNumber && phoneNumber.phoneType === InternationalPhoneNumberType.DirectDial,
  )

  return { tollFree: tollFreeNumber?.phoneNumber ?? null, direct: directDialNumber?.phoneNumber ?? null }
}

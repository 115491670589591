import { styled } from '@mui/material'

import { PatientPaymentStatus, usePatientContextQuery } from '@nuna/api'
import { routeService } from '@nuna/core'
import { ContextualAlert, IconCaution, TextButtonLink, salmonSet, yellowSet } from '@nuna/tunic'

type InputScene = 'home' | 'billing' | 'transaction'

type MessageType = 'caution' | 'urgent'

type Props = {
  scene: InputScene
  options?: {
    dismissButtonText?: string
  }
}

type AlertMessage = {
  [key in InputScene]: {
    [key in MessageType]: string
  }
}

export function ClientPaymentStatusAlert({ scene, options }: Props) {
  const { data } = usePatientContextQuery()

  if (!data) return null

  const {
    patientContext: {
      patient: { paymentStatus },
    },
  } = data

  if (!paymentStatus) return null

  const newBalanceMessage = 'You have a new balance. Please update your payment method.'
  const overdueBalanceMessage = 'You have an overdue balance. Please pay your balance.'
  const payBalanceMessage = 'To pay balance, select “Pay” or “Retry” to update payment method and submit payment'

  const alertMessage: AlertMessage = {
    home: {
      caution: newBalanceMessage,
      urgent: overdueBalanceMessage,
    },
    billing: {
      caution: newBalanceMessage,
      urgent: overdueBalanceMessage,
    },
    transaction: {
      caution: payBalanceMessage,
      urgent: payBalanceMessage,
    },
  }

  const alertState: MessageType = paymentStatus === PatientPaymentStatus.Overdue ? 'urgent' : 'caution'
  const textLinkVariant = alertState === 'urgent' ? 'destroy' : 'caution'
  const isUrgent = alertState === 'urgent'
  const showCautionIcon = scene === 'home' && isUrgent
  const iconColor = isUrgent ? salmonSet[80].hex : yellowSet[50].hex
  const cautionIcon = <IconCaution style={{ color: iconColor }} />

  const hasButton = options?.dismissButtonText

  return (
    <StyledContextualAlert
      className={alertState}
      intent={alertState}
      role="alert"
      icon={showCautionIcon ? cautionIcon : null}
      scribbleType="default"
    >
      {alertMessage[scene][alertState]}{' '}
      {hasButton && (
        <TextButtonLink variant={textLinkVariant} to={routeService.clientBilling()}>
          {options?.dismissButtonText}
        </TextButtonLink>
      )}
    </StyledContextualAlert>
  )
}

const StyledContextualAlert = styled(ContextualAlert)`
  margin-bottom: 16px;

  .inner-button {
    color: ${yellowSet[80].hex};
  }

  &.urgent {
    .inner-button {
      color: ${salmonSet[80].hex};
    }
  }
`

import { ReactNode } from 'react'

import { Badge, IconClock, IconDollarSymbol, IconFavoriteOutline, IconGlobe, IconGoalPath } from '@nuna/tunic'

import { useSearchProviderContext } from '../SearchProviderContext'

interface Props {
  icon: ReactNode
  children: ReactNode
  filterCount: number
  iconContainerWidth?: number
}

interface CategoryProps {
  iconSize?: number
  iconContainerWidth?: number
}

function FilterCategory({ icon, filterCount, iconContainerWidth = 28, children }: Props) {
  return (
    <>
      <span className="v-align h-align" style={{ width: iconContainerWidth }}>
        {filterCount > 0 ? <Badge>{filterCount}</Badge> : icon}
      </span>
      {children}
    </>
  )
}

export function AvailabilityFilterCategory({ iconSize = 18, iconContainerWidth }: CategoryProps) {
  const { filterCount } = useSearchProviderContext()
  return (
    <FilterCategory
      filterCount={filterCount('availability')}
      iconContainerWidth={iconContainerWidth}
      icon={<IconClock size={iconSize} />}
    >
      Availability
    </FilterCategory>
  )
}

export function CoverageFilterCategory({ iconSize = 18, iconContainerWidth }: CategoryProps) {
  const { filterCount } = useSearchProviderContext()
  return (
    <FilterCategory
      filterCount={filterCount('coverage')}
      icon={<IconDollarSymbol size={iconSize} />}
      iconContainerWidth={iconContainerWidth}
    >
      Coverage
    </FilterCategory>
  )
}

export function DemographicsFilterCategory({ iconSize = 18, iconContainerWidth }: CategoryProps) {
  const { filterCount } = useSearchProviderContext()
  return (
    <FilterCategory
      filterCount={filterCount('demographics')}
      iconContainerWidth={iconContainerWidth}
      icon={<IconGlobe size={iconSize} />}
    >
      Demographics
    </FilterCategory>
  )
}

export function FavoriteFilterCategory({ iconSize = 18, iconContainerWidth }: CategoryProps) {
  const { filterCount } = useSearchProviderContext()
  return (
    <FilterCategory
      filterCount={filterCount('favorites')}
      iconContainerWidth={iconContainerWidth}
      icon={<IconFavoriteOutline size={iconSize} />}
    >
      Favorites
    </FilterCategory>
  )
}

export function SpecialtiesFilterCategory({ iconSize = 18, iconContainerWidth }: CategoryProps) {
  const { filterCount } = useSearchProviderContext()
  return (
    <FilterCategory
      filterCount={filterCount('specialties')}
      icon={<IconGoalPath size={iconSize} />}
      iconContainerWidth={iconContainerWidth}
    >
      Specialties
    </FilterCategory>
  )
}

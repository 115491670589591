import { styled } from '@mui/material'

import { routeService } from '@nuna/core'
import { FillButtonLink, IconChevronThick } from '@nuna/tunic'

import { AppointmentCard } from '../../shared/components/AppointmentCard'
import { AppointmentCardLoader } from '../../shared/components/AppointmentCard/AppointmentCard'
import { Container } from './InsuranceIntake'
import { useInsuranceIntakeContext } from './InsuranceIntakeContext'
import linesSrc from './img/celebration-lines.png'
import { IntakePaths } from './intakeConstants'

export function P4Confirmation() {
  const { patientContextData } = useInsuranceIntakeContext()

  const appointment = patientContextData?.patientContext.patient.appointments[0]

  return (
    <Container>
      <h1 className="h3 mb-3" style={{ maxWidth: '17em' }}>
        Your appointment has been booked! Next, finish your registration
      </h1>

      <AppointmentContainer>
        {appointment ? (
          <AppointmentCard hideActions appointment={appointment} isRefreshingAppointments={false} />
        ) : (
          <AppointmentCardLoader />
        )}
      </AppointmentContainer>

      <p className="mt-5 mb-3">
        We need a little more information about you so your therapist can provide the best possible care.
      </p>

      <FillButtonLink to={`${routeService.intake()}/${IntakePaths.personalInformation}`}>
        Next: Personal Info <IconChevronThick direction="right" className="ml-xs" size={16} />
      </FillButtonLink>
    </Container>
  )
}

const AppointmentContainer = styled('div')`
  position: relative;

  &::after {
    background-image: url(${linesSrc});
    content: '';
    position: absolute;
    width: 129px;
    height: 130px;
    left: calc(100% - 40px);
    top: calc(100% - 65px);
  }
`

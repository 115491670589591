import { styled } from '@mui/material'
import { useState } from 'react'

import { Dialog, DialogProps, GhostButton, IconButton, IconClose, OutlineButton, tealSet, white } from '@nuna/tunic'

import { FilteredSummary } from '../FilteredSummary'
import { useSearchProviderContext } from '../SearchProviderContext'
import { FilterSearchField } from '../filter-controls/FilterSearchField'
import { AvailabilitySection } from './AvailabilitySection'
import { CoverageSection } from './CoverageSection'
import { DemographicsSection } from './DemographicsSection'
import { FavoriteSection } from './FavoriteSection'
import { SpecialtiesSection } from './SpecialtiesSection'

export function FilterDialog(props: DialogProps) {
  const { setFilterDialogOpen, appliedFilters, setFilterState, clearFilters } = useSearchProviderContext()
  const [previousFilterState, setPreviousFilterState] = useState(appliedFilters)

  const handleEnter = () => {
    setPreviousFilterState(appliedFilters)
  }

  const handleCancel = () => {
    setFilterState(previousFilterState)
    setFilterDialogOpen(false)
  }

  // Apply is enabled if the current filters have changed or if the current filters are pristine, but the previous
  // filters were not - implying the user clicked on "Clear filters"
  const applyEnabled = appliedFilters.dirty || (previousFilterState.dirty && !appliedFilters.dirty)

  return (
    <StyledDialog
      {...props}
      fullScreen
      PaperProps={{ style: { border: 'none', overflow: 'hidden' } }}
      TransitionProps={{ onEnter: handleEnter }}
    >
      <Header className="space-between v-align">
        <h6 className="mb-0">Therapist Filters</h6>
        <span className="v-align">
          <OutlineButton disabled={!applyEnabled} variant="dark" onClick={() => setFilterDialogOpen(false)}>
            Apply
          </OutlineButton>
          <IconButton variant="dark" className="ml-1" tooltip="Cancel" onClick={handleCancel}>
            <IconClose />
          </IconButton>
        </span>
      </Header>
      <div className="v-align space-between p-3">
        <GhostButton variant="secondary" onClick={() => clearFilters()}>
          Reset filters
        </GhostButton>
        <FilteredSummary className="v-align" style={{ textAlign: 'right', maxWidth: '58%', height: '28px' }} />
      </div>
      <FilterContainer className="flex-remaining-space pt-1 pb-3">
        <FilterSearchField className="px-3" style={{ paddingBottom: 5 }} />
        <AvailabilitySection />
        <CoverageSection />
        <SpecialtiesSection />
        <DemographicsSection />
        <FavoriteSection />
      </FilterContainer>
    </StyledDialog>
  )
}

const Header = styled('div')`
  background-color: ${tealSet[80].hex};
  color: ${white.hex};
  padding: var(--spacing-2) var(--spacing-1) var(--spacing-2) var(--spacing-3);
`

const FilterContainer = styled('div')`
  overflow-y: auto;
`

const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    padding: 12px;
  }
`

import { styled } from '@mui/material'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { pick } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  type Invite,
  type PatientOauthSignupInput,
  type PatientSignupBasicMutation,
  type PatientSignupInput,
  type PatientSignupOauthMutation,
  usePatientSignupBasicMutation,
  usePatientSignupOauthMutation,
  usePublicCompanyQuery,
} from '@nuna/api'
import { OrDivider, SocialLogin, useAuthDataContext, useSignupPrevalidation, useSignupSearchParams } from '@nuna/auth'
import { errorService, routeService, sessionStorageService } from '@nuna/core'
import { EmployerCoverageFormValues, employerInitialValues } from '@nuna/coverage'
import { supportService } from '@nuna/telemetry'
import { ContextualAlert, FillButton, toast } from '@nuna/tunic'

import { useCompanySlug } from './hooks/useCompanySlug'
import { EmployerAssociationStep, employerAssociationSchema } from './steps/EmployerAssociationStep'
import { FinishOAuthStep, finishOauthSchema } from './steps/FinishOAuthStep'
import {
  NameEmailStep,
  NameEmailStepLoadingSkeleton,
  NameEmailValues,
  getNameEmailInitialValues,
  nameEmailSchema,
} from './steps/NameEmailStep'
import { PasswordStep, PasswordValues, passwordInitialValues, passwordSchema } from './steps/PasswordStep'
import {
  VerifyEmailStep,
  VerifyEmailValues,
  verifyEmailInitialValues,
  verifyEmailSchema,
} from './steps/VerifyEmailStep'

interface Props {
  invite?: Pick<Invite, 'id' | 'recipientEmail' | 'recipientFirstName' | 'recipientLastName'>
  inviteLoading?: boolean
  hasInvite: boolean
  redirectToInvite: boolean
}

export interface SignupFormValues extends NameEmailValues, VerifyEmailValues, PasswordValues {
  employerCoverage?: EmployerCoverageFormValues
}

export interface SignupFormStepProps {
  formikProps: FormikProps<SignupFormValues>
}

enum FormStep {
  NameEmail,
  VerifyEmail,
  EmployerAssocation,
  Password,
  FinishOauth,
}

type LoginResult = PatientSignupBasicMutation['patientSignupBasic'] | PatientSignupOauthMutation['patientSignupOauth']

const { getSignupSourceParams } = sessionStorageService

function getSchemaForStep(step: FormStep, associationRequired: boolean, parentGuardianEmailRequired: boolean) {
  if (step === FormStep.NameEmail) {
    return nameEmailSchema
  } else if (step === FormStep.VerifyEmail) {
    return verifyEmailSchema
  } else if (step === FormStep.Password) {
    return passwordSchema
  } else if (step === FormStep.FinishOauth) {
    return finishOauthSchema(associationRequired, parentGuardianEmailRequired)
  } else if (step === FormStep.EmployerAssocation) {
    return employerAssociationSchema
  }
}

function getCTATextForStep(step: FormStep) {
  if (step === FormStep.NameEmail) {
    return 'Get started'
  } else if (step === FormStep.VerifyEmail) {
    return 'Continue'
  } else if (step === FormStep.Password) {
    return 'Sign up'
  } else if (step === FormStep.FinishOauth) {
    return 'Sign up'
  } else if (step === FormStep.EmployerAssocation) {
    return 'Continue'
  }
}

export function SignupForm({ invite, inviteLoading, hasInvite, redirectToInvite }: Props) {
  const { onLogin } = useAuthDataContext()
  const navigate = useNavigate()
  const { scrubbedCompanySlug } = useCompanySlug()

  const [prevalidatePatientSignup, { undismissedErrors, dismissError }, { loading }] = useSignupPrevalidation()
  const [patientSignupBasicMutation, { loading: signupMutationLoading }] = usePatientSignupBasicMutation()
  const [patientSignupOauthMutation, { loading: signupOauthMutationLoading }] = usePatientSignupOauthMutation()
  const { oauthToken, companyId, referralId, tokenId } = useSignupSearchParams()
  const [currentStep, setCurrentStep] = useState(oauthToken ? FormStep.FinishOauth : FormStep.NameEmail)
  const { data } = usePublicCompanyQuery({
    variables: { id: companyId || '', companySlug: scrubbedCompanySlug },
    skip: !companyId && !scrubbedCompanySlug,
  })
  const company = data?.publicCompany

  const initialValues: SignupFormValues = useMemo(
    () => ({
      ...getNameEmailInitialValues(invite),
      ...verifyEmailInitialValues,
      ...passwordInitialValues,
      ...(company
        ? {
            employerCoverage: {
              ...employerInitialValues,
              employer: {
                ...company,
                offersTava: true,
                __typename: undefined,
                associationRequired: company.contract.associationRequired,
              },
              referralId,
            },
          }
        : {}),
    }),
    [company, invite, referralId],
  )

  const getNextNavigation = () => {
    if (redirectToInvite) {
      return '/invite'
    }

    return routeService.intake()
  }

  const handleSubmit = async (values: SignupFormValues, { resetForm }: FormikHelpers<SignupFormValues>) => {
    const {
      source,
      channel: sourceChannel,
      isProviderSourced,
      providerSourcedProviderId: p4ProviderId,
    } = getSignupSourceParams()

    if (currentStep === FormStep.NameEmail) {
      const { valid } = await prevalidatePatientSignup(values.email)

      if (valid) {
        resetForm({ values })
        setCurrentStep(FormStep.VerifyEmail)
      }
    } else if (currentStep === FormStep.VerifyEmail) {
      resetForm({ values })
      setCurrentStep(
        companyId && company?.contract?.associationRequired ? FormStep.EmployerAssocation : FormStep.Password,
      )
    } else if (currentStep === FormStep.EmployerAssocation) {
      resetForm({ values })
      setCurrentStep(FormStep.Password)
    } else {
      let loginData: LoginResult | undefined
      const { employerAssociationEmployee, employerAssociation } = values.employerCoverage ?? {}

      try {
        if (currentStep === FormStep.FinishOauth) {
          const input: PatientOauthSignupInput = {
            ...pick(values, ['dob', 'parentGuardianEmail']),
            companyId: companyId || company?.id,
            relatedEmployeeName: employerAssociationEmployee,
            relationshipToOrganization: employerAssociation,
            referralId,
            inviteId: invite?.id,
            tokenId: tokenId,
            oauthToken: oauthToken ?? '',
            source,
            sourceChannel,
            sourceProviderId: isProviderSourced === 'true' ? p4ProviderId : undefined,
          }

          loginData = (await patientSignupOauthMutation({ variables: { input } }))?.data?.patientSignupOauth
        } else {
          const input: PatientSignupInput = {
            ...pick(values, ['dob', 'firstName', 'lastName', 'email', 'password', 'parentGuardianEmail']),
            companyId: companyId || company?.id,
            relatedEmployeeName: employerAssociationEmployee,
            relationshipToOrganization: employerAssociation,
            referralId,
            inviteId: invite?.id,
            source,
            sourceChannel,
            sourceProviderId: isProviderSourced ? p4ProviderId : undefined,
          }

          loginData = (await patientSignupBasicMutation({ variables: { input } }))?.data?.patientSignupBasic
        }

        if (loginData && onLogin) {
          onLogin({ login: loginData.login })
          mixpanel.track('signup', { userId: loginData.login.id })

          navigate(getNextNavigation())
        } else {
          throw new Error('Could not login after signup')
        }
      } catch (e) {
        let transformedError = errorService.transformGraphQlError(
          e,
          'Error completing signup. Please try again or contact support.',
        )
        if (transformedError === 'Invite has already been used') {
          transformedError += '. Please sign in instead.'
          navigate(routeService.login())
        }
        toast.urgent(transformedError)
      }
    }
  }

  if (hasInvite && inviteLoading) {
    return <NameEmailStepLoadingSkeleton />
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={getSchemaForStep(currentStep, company ? company.contract.associationRequired : false, !tokenId)}
      enableReinitialize
    >
      {formikProps => {
        return (
          <Container>
            {currentStep === FormStep.NameEmail && (
              <>
                <SocialLogin
                  audience="client"
                  from="/"
                  heading={
                    <>
                      <p className="text-medium text-light-grey uppercase">Client Signup</p>
                      <h2 className="h3">Get Started Today</h2>
                    </>
                  }
                  intent="signup"
                />
                <OrDivider className="text-center mb-4 px-3">or</OrDivider>
              </>
            )}
            <Form onSubmit={formikProps.handleSubmit}>
              {currentStep === FormStep.NameEmail && (
                <NameEmailStep
                  prevalidationErrors={undismissedErrors}
                  dismissError={dismissError}
                  formikProps={formikProps}
                />
              )}
              {formikProps.errors.employerCoverage &&
                (formikProps.errors.employerCoverage as unknown as { referralId: string }).referralId && (
                  <div className="mb-2">
                    <ContextualAlert intent="urgent">
                      There is a problem with your signup link. Please contact{' '}
                      {supportService.supportEmails.clientSupport} for assistance.
                      <br /> <br />
                      {(formikProps.errors.employerCoverage as unknown as { referralId: string }).referralId}.
                    </ContextualAlert>
                  </div>
                )}
              {currentStep === FormStep.VerifyEmail && <VerifyEmailStep formikProps={formikProps} />}
              {currentStep === FormStep.EmployerAssocation && <EmployerAssociationStep formikProps={formikProps} />}
              {currentStep === FormStep.Password && <PasswordStep />}
              {currentStep === FormStep.FinishOauth && <FinishOAuthStep formikProps={formikProps} />}

              <FillButton
                data-testid="signup-get-started"
                isLoading={loading || signupMutationLoading || signupOauthMutationLoading}
                className="full-width mt-2"
                type="submit"
              >
                {getCTATextForStep(currentStep)}
              </FillButton>
            </Form>
          </Container>
        )
      }}
    </Formik>
  )
}

const Container = styled('div')`
  padding: 3rem 0;
`

const Form = styled('form')``

import { styled } from '@mui/material'

import { useCancelPolicyStatusQuery, usePatientContextQuery } from '@nuna/api'
import { routeService } from '@nuna/core'
import { ContextualAlert, IconCaution, TextButtonLink } from '@nuna/tunic'

export function PolicyAcceptanceAlert() {
  const { data: patientContextData } = usePatientContextQuery()
  if (!patientContextData) return null

  const providerIds = patientContextData.patientContext.patient.providers.map(provider => provider.id) ?? []
  const clientId = patientContextData.patientContext.patient.id

  return (
    <BannerContainer>
      {providerIds.map(providerId => (
        <PolicyAcceptanceBannerForProvider key={providerId} providerId={providerId} clientId={clientId} />
      ))}
    </BannerContainer>
  )
}

function PolicyAcceptanceBannerForProvider({ providerId, clientId }: { providerId: string; clientId: string }) {
  const { data: cancelPolicyData } = useCancelPolicyStatusQuery({
    variables: {
      providerId: providerId,
      clientId: clientId,
      defaultToTavaPolicy: false,
    },
    fetchPolicy: 'cache-and-network',
  })

  const availableCancelPolicy = cancelPolicyData?.cancelPolicyStatus?.availableCancelPolicy

  if (!availableCancelPolicy) return null

  return (
    <ContextualAlert icon={<IconCaution />} className="mb-3" intent="caution">
      Your provider has a new policy to accept.{' '}
      <TextButtonLink variant="caution" to={`${routeService.clientBilling()}?acceptProviderFees=${providerId}`}>
        View policy
      </TextButtonLink>
    </ContextualAlert>
  )
}

const BannerContainer = styled('div')`
  & > :not(:first-of-type) {
    display: none; // If there are multiple providers only show the first banner and they should see the others after landing on the billing page
  }
`

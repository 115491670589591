import { styled } from '@mui/material'
import { useMediaQuery } from 'react-responsive'

import { usePatientContextQuery } from '@nuna/api'
import { BelowTablet, Grid } from '@nuna/tunic'

import { AppContainer } from '../shared/components/Container'
import { ProfileContactInfo } from '../shared/components/ProfileContactInfo'
import { AppLayout } from './AppLayout/AppLayout'
import manSpeechBubble from './img/man-speech-bubble.png'

export function GuardianConsentRequired() {
  const { data } = usePatientContextQuery()
  const patient = data?.patientContext?.patient
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  if (!patient) return null

  return (
    <AppLayout verticalCenter paddingTop={0}>
      <Container>
        <Grid container spacing={isMobile ? 2 : 10} direction="row-reverse" alignItems="center">
          <SpaceGirlImageContainer size={{ xs: 12, sm: 6 }}>
            <img src={manSpeechBubble} alt="" />
          </SpaceGirlImageContainer>
          <Grid size={{ xs: 12, sm: 6 }}>
            <h1 className="h3">We’ve emailed your parent or guardian.</h1>
            <p className="large mb-4">
              Keep an eye on your email. We’ll drop you a message as soon as they sign a few documents.
            </p>
            <ProfileContactInfo
              isLoading={false}
              heading="We'll contact you here:"
              description="Make sure your contact info looks right"
              patient={patient}
            />
          </Grid>
        </Grid>
      </Container>
    </AppLayout>
  )
}

const Container = styled(AppContainer)`
  padding-top: var(--margin-5);
  padding-bottom: var(--margin-6);

  @media (${BelowTablet}) {
    .h3 {
      font-size: 2rem;
    }
  }
`

const SpaceGirlImageContainer = styled(Grid)`
  img {
    width: 75%;
    max-width: 350px;
  }

  text-align: center;

  @media (${BelowTablet}) {
    display: flex;
    justify-content: center;
    padding-bottom: 0;

    img {
      max-width: 270px;
    }
  }
`

import { noop } from 'lodash'

import { AccordionSection, Checkbox, Radio } from '@nuna/tunic'

import { useSearchProviderContext } from '../SearchProviderContext'
import { READABLE_SPECIALTY_CATEGORIES, SpecialtyFilterCategory } from '../search-provider.utils'
import { FilterAccordionLabel, FilterSection } from './FilterAccordion'

export function SpecialtiesFilterSection(
  category: SpecialtyFilterCategory,
  type: 'radio' | 'checkbox' = 'checkbox',
): AccordionSection {
  const { appliedFilters, specialtyFilterOptions, toggleSpecialtyMulti, toggleSpecialtySingle } =
    useSearchProviderContext()
  return {
    label: props => (
      <FilterAccordionLabel {...props} filtersApplied={appliedFilters[category].length}>
        {READABLE_SPECIALTY_CATEGORIES[category]}
      </FilterAccordionLabel>
    ),
    content: (
      <FilterSection role={type === 'radio' ? 'group' : undefined}>
        {specialtyFilterOptions[category].map(option =>
          type === 'checkbox' ? (
            <Checkbox
              key={option.id}
              onChange={() => toggleSpecialtyMulti(option, category)}
              checked={!!appliedFilters[category].find(v => v.id === option.id)}
              className="checkbox"
              disabled={!!option.disabled}
            >
              {option.name}
            </Checkbox>
          ) : (
            <Radio
              name={`${category}_radio`}
              value={option.id}
              key={option.id}
              onChange={noop}
              onClick={() => {
                toggleSpecialtySingle(option, category)
              }}
              checked={!!appliedFilters[category].find(v => v.id === option.id)}
              className="radio"
              disabled={!!option.disabled}
            >
              {option.name}
            </Radio>
          ),
        )}
      </FilterSection>
    ),
  }
}

import { supportService } from '@nuna/telemetry'
import { GhostButtonExternalLink, TextButtonExternalLink } from '@nuna/tunic'

const doYouAcceptInsurance = 'Do you accept insurance?'
const whatIfYouDontAcceptMyInsurance = "What if you don't accept my insurance?"

export type FAQ = (typeof faqs)[0]

export const faqs = [
  {
    question: 'How do I join my session?',
    answer: (
      <p>
        Go to <TextButtonExternalLink href="/live-session">care.tavahealth.com/live-session</TextButtonExternalLink>.
        (And bookmark that link for future sessions, too!) If you’re having trouble logging in, try resetting your
        password. If you need help, click the chat bubble on the bottom right of your screen.
      </p>
    ),
  },
  {
    question: 'Is therapy right for me?',
    answer: (
      <p>
        Therapy can be a powerful tool to handle your unique challenges. It’s totally normal to see an expert for your
        physical health; why not try expert care for your mental health? No matter what you’re dealing with, a Tava
        therapist can help. 94% of people who try Tava report improved mental wellness after just a few sessions.
      </p>
    ),
  },
  {
    question: doYouAcceptInsurance,
    answer: <p>Yes! Sign up to see if your insurance is accepted.</p>,
  },
  {
    question: whatIfYouDontAcceptMyInsurance,
    answer: (
      <p>
        Cost is one of the biggest barriers to therapy. Our priority is to make it easy and affordable to get the help
        you need. Ask if your employer sponsors Tava. You can always pay out of pocket and then submit an out-of-network
        claim.
      </p>
    ),
  },
  {
    question: 'What is your typical wait time?',
    answer: <p>Most Tava clients are able to sign up and meet with their therapist within a few days.</p>,
  },
  {
    question: 'What hours are Tava therapists available?',
    answer: (
      <p>
        Therapists are available to meet during the day, in the evening, or on the weekend. Sign up and select a quality
        therapist within a few minutes, then choose a time to meet online that works for you.
      </p>
    ),
  },
  {
    question: 'Is online therapy effective?',
    answer: (
      <p>
        Yes. Tava was built on the idea that virtual care can be better. Research shows that online therapy is at least
        as effective as in-person sessions. It also happens to be way more convenient: you won’t need to pay for gas,
        find a parking spot, or hire a dog-sitter.
      </p>
    ),
  },
  {
    question: 'What can a therapist help me with?',
    answer: (
      <p>
        Have you ever felt like you can't quite articulate what you're feeling or struggling with? This is actually a
        cue that therapy could be right for you, because it can help you work through feelings, thoughts, and
        challenging situations. Tava therapists are experts in helping with anything from relationship stress to
        depression to anxiety; in all stages of life, from adolescence (age 13+) to pregnancy to retirement.
      </p>
    ),
  },
  {
    question: 'Can’t I just talk to a friend instead?',
    answer: (
      <p>
        While confiding in loved ones can help them be aware of what you’re going through, it isn’t the same as therapy.
        Not only is a therapist is unbiased and neutral, they have some training and expertise to help you with whatever
        is going on.
      </p>
    ),
  },
  {
    question: 'What should I expect in my first session?',
    answer: (
      <p>
        Check your email and text messages for a link to join the video call with your therapist. Your therapist will
        get to know you and you’ll decide together what would be helpful to explore. If you’re feeling anxious, just
        know that there’s no wrong way to do therapy. Your Tava therapist will meet you wherever you are and at your
        pace.
        <br />
        <br />
        <GhostButtonExternalLink href={supportService.articles.firstSession}>
          Read more about your first session here.
        </GhostButtonExternalLink>
      </p>
    ),
  },
  {
    question: 'Can I switch therapists?',
    answer: (
      <p>
        Yes. A good relationship with your therapist is a key to success, and it’s ok if it takes a couple tries to find
        the right fit. We make it super simple to switch therapists with a few clicks.
      </p>
    ),
  },
  {
    question: 'Is it confidential?',
    answer: (
      <p>
        Yes. The fact that you’re doing therapy at all and anything you discuss with your Tava therapist is
        confidential. We take your <TextButtonExternalLink href="/privacy-policy">privacy</TextButtonExternalLink> very
        seriously. Note that if you switch providers later, your record will be accessible to your next therapist, so
        that they can provide the best care possible. Otherwise your records are unavailable to anyone (including
        sponsoring employers) without your written consent, unless required by court order.
      </p>
    ),
  },
  {
    question: 'How do I make an appointment?',
    answer: (
      <p>
        After you choose a therapist, you will be able to book a time to meet online that works for your schedule. You
        can then attend your virtual session from anywhere with an internet connection.
      </p>
    ),
  },
]

export const companySpecificFAQs = (contractedSessions: string, customerName: string) => {
  const excludeQuestions = new Set([doYouAcceptInsurance, whatIfYouDontAcceptMyInsurance])
  const contractedSessionsFAQ = {
    question: `Can I continue my care after using my ${contractedSessions} free sessions?`,
    answer: (
      <p>
        {`Yes. In your settings you can add your health insurance or a credit card to cover session costs after you use your ${contractedSessions} annual free sessions paid for by ${customerName}.`}
      </p>
    ),
  }

  let excludedCount = 0
  const filteredFAQs = faqs.reduce<FAQ[]>((accumulatedFAQs, currentFAQ) => {
    if (excludeQuestions.has(currentFAQ.question)) {
      excludedCount++
      if (excludedCount === excludeQuestions.size && Number(contractedSessions) > 0) {
        accumulatedFAQs.push(contractedSessionsFAQ)
      }
      return accumulatedFAQs
    }
    accumulatedFAQs.push(currentFAQ)
    return accumulatedFAQs
  }, [])

  return filteredFAQs
}

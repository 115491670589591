import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import DebounceLink from 'apollo-link-debounce'

import { handleErrorLink, handleWrongRoleLink } from '@nuna/auth'

import { environment } from '../environments/environment'

const apiEndpoint = environment.API_ENDPOINT || 'http://localhost:5000'

const DEFAULT_DEBOUNCE_TIMEOUT = 100

const featherLink = ApolloLink.from([
  handleErrorLink,
  handleWrongRoleLink,
  new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
  new HttpLink({ uri: apiEndpoint, credentials: 'include' }),
])

const sanctuaryLink = new HttpLink({
  uri: environment.SANCTUARY_API_ENDPOINT,
  headers: { apikey: environment.SANCTUARY_API_KEY },
})

export const client = new ApolloClient({
  link: ApolloLink.split(operation => operation.getContext().clientName === 'sanctuary', sanctuaryLink, featherLink),
  cache: new InMemoryCache({
    typePolicies: {
      Patient: {
        fields: {
          appointmentConfiguration: {
            merge: true, // tell apollo it's ok to merge appt config for a patient
          },
        },
      },
      PatientContext: {
        merge: true,
        fields: {
          patient: {
            merge: true, // tell apollo that patient in patientContext will always represent the same patient
          },
        },
      },
      CoverageProvider: {
        keyFields: false,
      },
      TherapyTypeCoverageItem: {
        keyFields: false,
      },
    },
  }),
})

import { MostImportantTherapistAttribute } from '@nuna/api'
import { ChipGroup, Radio } from '@nuna/tunic'

import { preferencesSubheadingSize } from './shared'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function TherapistMostImportant({ importantAttributeProps, values }: any) {
  return (
    <>
      <h2 id="important-attributes-heading" className={`${preferencesSubheadingSize} mt-6`}>
        Which of these attributes is most important to finding a good match?
      </h2>
      <p className="mb-4">
        Sometimes we aren’t able to match you with a therapist that has all of your chosen attributes. In this case
        we’ll prioritize the attribute, that is most important to you.
      </p>

      <ChipGroup
        error={importantAttributeProps.error}
        helperText={importantAttributeProps.helperText}
        aria-labelledby="#important-attributes-heading"
      >
        {Object.entries(MostImportantTherapistAttribute).map(([displayValue, value]) => (
          <Radio
            key={value}
            inline
            onChange={importantAttributeProps.onChange}
            value={value}
            name={importantAttributeProps.name}
            checked={values[importantAttributeProps.name] === value}
            dataTestId={`${importantAttributeProps.name}-${value}`}
          >
            {displayValue}
          </Radio>
        ))}
      </ChipGroup>
    </>
  )
}

import { useState } from 'react'

interface HoverStateProps {
  children: (stateAndSetter: [boolean, (arg: boolean) => void]) => JSX.Element
}

export function HoverState({ children }: HoverStateProps) {
  const stateAndSetter = useState(false)

  return children(stateAndSetter)
}

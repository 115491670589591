import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import { ProviderType, useIntakeProgressQuery, usePatientContextQuery } from '@nuna/api'
import { routeService } from '@nuna/core'

import { ProviderSuggestionResults } from './ProviderSuggestionsResults'

export function ProviderSuggestion() {
  const [hadProviderOnLoad, setHadProviderOnLoad] = useState<boolean | null>(null)
  const { data, loading } = usePatientContextQuery()

  const patientId = data?.patientContext.patient.id ?? ''

  const { data: intakeProgressData } = useIntakeProgressQuery({
    variables: { id: patientId },
    skip: !patientId,
  })

  const intakeCompleted = intakeProgressData?.intakeProgress?.intakeCompleted

  useEffect(() => {
    const providers =
      data?.patientContext.patient.providers.filter(provider => provider.type === ProviderType.Therapist) ?? []

    if (hadProviderOnLoad === null && !loading && providers.length > 0) {
      setHadProviderOnLoad(true)
    }

    if (hadProviderOnLoad === null && !loading && providers.length === 0) {
      setHadProviderOnLoad(false)
    }
  }, [loading, hadProviderOnLoad, data?.patientContext.patient.providers])

  // have to keep this in state, otherwise they'll get redirected as soon as providers.length changes
  if (hadProviderOnLoad) {
    return <Navigate to="/" />
  }

  if (intakeCompleted === false) {
    return <Navigate to={routeService.intake()} replace={true} />
  }

  return <ProviderSuggestionResults />
}

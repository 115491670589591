import * as Yup from 'yup'
import { styled } from '@mui/material'
import { TextField } from '@mui/material'
import { omit } from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { formatPhoneNumber } from 'react-phone-number-input'

import {
  DocumentType,
  Gender,
  Patient,
  useDocumentUpload,
  usePatientProfileQuery,
  useRemovePatientAvatarMutation,
  useUpdatePatientProfileMutation,
} from '@nuna/api'
import {
  AddressForm,
  AuthorizedAvatar,
  LazyImageUpload,
  OmittedAddressFields,
  ProfileDisplayValue,
  ProfileSection,
  UsAddressValues,
  addressValidationSchemaChecks,
} from '@nuna/common'
import { userService } from '@nuna/core'
import {
  AvatarUploadPlaceholder,
  BelowTablet,
  DOBTextField,
  Grid,
  PhoneTextField,
  Radio,
  Select,
  TextButton,
  phoneUSAValidationRegex,
  toast,
} from '@nuna/tunic'

import { AppLayout } from '../../layouts/AppLayout'
import { AppContainer } from '../../shared/components/Container'
import { ProfileContactInfo } from '../../shared/components/ProfileContactInfo'
import { Subheader } from '../../shared/components/Subheader'

const { humanReadableGenders } = userService

export function Profile() {
  const { data, loading: isLoading, error } = usePatientProfileQuery()
  const [isReplacingPhoto, setIsReplacingPhoto] = useState(false)
  const [isAvatarUploading, setIsAvatarUploading] = useState(false)
  const { uploadDocument } = useDocumentUpload()
  const [updatePatientProfile, { loading: updateLoading }] = useUpdatePatientProfileMutation()

  const patient = data?.patientContext.patient

  const [removePatientAvatar] = useRemovePatientAvatarMutation({
    optimisticResponse: {
      removePatientAvatar: {
        __typename: 'Patient',
        id: patient?.id ?? '',
        avatarUrl: null,
      },
    },
  })

  useEffect(() => {
    if (error) {
      toast.urgent('Unable to load profile data')
    }
  }, [error])

  const handleAvatarUpload = async (file: File) => {
    setIsAvatarUploading(true)
    try {
      const document = await uploadDocument(file, DocumentType.PatientImage)
      if (!document) {
        return new Error('Unable to get upload url')
      }

      const { errors } = await updatePatientProfile({
        variables: {
          avatarDocumentId: document.id,
        },
      })

      if (errors && errors?.length > 0) throw errors[0]
    } catch (e) {
      toast.caution((e as Error).message)
    } finally {
      setIsAvatarUploading(false)
    }
  }

  const addressInitialValues: UsAddressValues & Record<string, unknown> = {
    addressLineOne: patient?.addressLineOne ?? '',
    addressLineTwo: patient?.addressLineTwo,
    city: patient?.city ?? '',
    state: patient?.state ?? '',
    zipCode: patient?.zipCode ?? '',
  }

  const timezoneInitialValues: Pick<Patient, 'timezone'> = {
    timezone: patient?.timezone ?? moment.tz.guess(),
  }

  const emergencyContactInitialValues: Pick<
    Patient,
    'emergencyContactName' | 'emergencyContactRelationship' | 'emergencyContactPhone'
  > = {
    emergencyContactName: patient?.emergencyContactName ?? '',
    emergencyContactRelationship: patient?.emergencyContactRelationship ?? '',
    emergencyContactPhone: patient?.emergencyContactPhone ?? '',
  }

  const birthdateInitialValues: Pick<Patient, 'dob'> = {
    dob: moment(patient?.dob).format('MM/DD/YYYY'),
  }

  const genderIntialValues: Pick<Patient, 'gender'> = {
    gender: patient?.gender ?? Gender.PreferNotToAnswer,
  }

  return (
    <AppLayout paddingTop={0} disableFooter>
      <Container>
        <Subheader />

        <Grid container spacing={6}>
          <Grid size={12}>
            {isLoading ? (
              <ProfileHeaderSkeleton />
            ) : (
              <>
                <Header style={{ display: isReplacingPhoto ? 'block' : 'none' }}>
                  <LazyImageUpload
                    inputId="avatar-file-upload"
                    onFileSelect={() => setIsReplacingPhoto(true)}
                    onUploadSuccess={() => setIsReplacingPhoto(false)}
                    onCancel={() => setIsReplacingPhoto(false)}
                    onError={() => setIsReplacingPhoto(false)}
                    onCropped={handleAvatarUpload}
                    isAvatarUploading={isAvatarUploading}
                  />
                </Header>

                <Header style={{ display: !isReplacingPhoto ? 'block' : 'none' }}>
                  <Grid container alignItems="center" justifyContent="center" spacing={2}>
                    <Grid
                      size={{
                        xs: 12,
                        sm: 4,
                        md: 3,
                      }}
                    >
                      {/* Cache and state bust previous avatarUrl by appending timestamp */}
                      {patient?.avatarUrl ? (
                        <AvatarResponsive
                          className="fs-exclude"
                          url={`${patient?.avatarUrl}?t=${Date.now()}`}
                          size="lg"
                        />
                      ) : (
                        <AvatarUploadPlaceholder htmlFor="avatar-file-upload" />
                      )}
                    </Grid>

                    <Grid
                      size={{
                        xs: 12,
                        sm: 8,
                        md: 9,
                      }}
                    >
                      <Name className="h4">
                        {patient?.firstName}
                        {patient?.middleName ? ` ${patient?.middleName} ` : ' '}
                        {patient?.lastName}
                      </Name>

                      {patient?.avatarUrl ? (
                        <div>
                          <TextButtonLabel htmlFor="avatar-file-upload">Replace Photo</TextButtonLabel>

                          <TextButton onClick={async () => removePatientAvatar()} className="ml-2">
                            Remove Photo
                          </TextButton>
                        </div>
                      ) : (
                        <div className="text-secondary">
                          Help your therapist connect with you...{' '}
                          <TextButtonLabel htmlFor="avatar-file-upload">Add your photo</TextButtonLabel>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Header>
              </>
            )}

            <ProfileContactInfo
              patient={patient}
              isLoading={isLoading}
              heading="Contact Info"
              description="You’ll receive reminders via text and email 24 hours and 15 min prior to each appointment beginning.
                  Your therapist will periodically check in with you via email as well."
            />

            <ProfileSection
              heading="Address"
              isLoading={isLoading}
              handleSubmit={values => updatePatientProfile({ variables: omit(values, OmittedAddressFields) })}
              updateLoading={updateLoading}
              validationSchema={Yup.object().shape({
                ...addressValidationSchemaChecks,
              })}
              initialValues={addressInitialValues}
              renderDisplayValue={
                <ProfileDisplayValue as="address">
                  {patient?.addressLineOne}
                  <br />
                  {patient?.addressLineTwo && (
                    <>
                      {patient?.addressLineTwo}
                      <br />
                    </>
                  )}
                  {patient?.city}, {patient?.state} {patient?.zipCode}
                </ProfileDisplayValue>
              }
              disabled={({ values, initialValues }) => initialValues.state !== values.state}
              renderForm={() => (
                <AddressForm
                  gridSpacing={2}
                  dataTestPrefix="client-profile"
                  displayStateChangeWarning
                  initialState={patient?.state}
                />
              )}
              description="Your therapist must be licensed in the state you reside."
            />

            <ProfileSection
              heading="Birthdate"
              isLoading={isLoading}
              handleSubmit={values => updatePatientProfile({ variables: values })}
              validationSchema={Yup.object().shape({
                dob: Yup.date()
                  .required('Date of birth is required')
                  .min(moment().subtract('125', 'years').toDate(), 'Date of birth is out of range')
                  .max(moment().subtract('13', 'years').toDate(), 'Sorry, therapy is only available to age 13+')
                  .typeError('Date is invalid'),
              })}
              initialValues={birthdateInitialValues}
              renderDisplayValue={<ProfileDisplayValue>{moment(patient?.dob).format('LL')}</ProfileDisplayValue>}
              renderForm={({ values, handleChange, handleBlur, touched, errors }) => (
                <DOBTextField
                  label="Date of Birth"
                  value={values.dob}
                  placeholder="MM/DD/YYYY"
                  fullWidth
                  name="dob"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.dob && touched.dob) as boolean}
                  helperText={touched.dob && !!errors.dob ? (errors.dob as string) : null}
                />
              )}
              description="Your birthdate is used to verify your eligibility to use therapy services."
            />

            <ProfileSection
              heading="Timezone"
              isLoading={isLoading}
              handleSubmit={values => updatePatientProfile({ variables: values })}
              validationSchema={Yup.object().shape({
                timezone: Yup.string().required(),
              })}
              initialValues={timezoneInitialValues}
              renderDisplayValue={<ProfileDisplayValue>{patient?.timezone ?? moment.tz.guess()}</ProfileDisplayValue>}
              renderForm={({ values, handleChange, handleBlur, touched, errors }) => (
                <Select
                  value={values.timezone ?? ''}
                  id="edit-profile-timezone"
                  label="Timezone"
                  name="timezone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.timezone && touched.timezone) as boolean}
                >
                  {moment.tz.zonesForCountry('US').map(timezone => (
                    <option key={timezone} value={timezone}>
                      {timezone.replace(/_/g, ' ')}
                    </option>
                  ))}
                </Select>
              )}
              description="In some cases, you and your therapist may be in different time zones. We notify you of your
              appointments in your respective time zone"
            />

            <ProfileSection
              heading="Gender"
              useOutlineSaveButton
              description="Your gender helps us to match you with providers based on the preferences they set."
              displayWidth="full"
              updateLoading={updateLoading}
              initialValues={genderIntialValues}
              handleSubmit={values => updatePatientProfile({ variables: { gender: values.gender as Gender } })}
              disabled={({ isValid }) => !isValid}
              renderDisplayValue={
                <ProfileDisplayValue>
                  {patient?.gender
                    ? humanReadableGenders[patient.gender as Gender]
                    : humanReadableGenders[Gender.PreferNotToAnswer]}
                </ProfileDisplayValue>
              }
              renderForm={({ values, handleChange }) => (
                <>
                  <label className="pb-3 text-secondary block">Please select one</label>

                  <div role="group" style={{ position: 'relative' }}>
                    {Object.values(Gender).map(value => (
                      <Radio
                        key={value}
                        name="gender"
                        onChange={handleChange}
                        value={value}
                        checked={values.gender === value}
                      >
                        {humanReadableGenders[value]}
                      </Radio>
                    ))}
                  </div>
                </>
              )}
              validationSchema={Yup.object().shape({
                gender: Yup.string().required('Gender is required'),
              })}
            ></ProfileSection>

            <ProfileSection
              heading="Emergency Contact"
              isLoading={isLoading}
              handleSubmit={values => updatePatientProfile({ variables: values })}
              validationSchema={Yup.object().shape({
                emergencyContactName: Yup.string().required(),
                emergencyContactRelationship: Yup.string().required(),
                emergencyContactPhone: Yup.string()
                  .required()
                  .matches(phoneUSAValidationRegex, 'Please enter a valid US phone number'),
              })}
              initialValues={emergencyContactInitialValues}
              renderDisplayValue={
                <ProfileDisplayValue className="fs-exclude">
                  {patient?.emergencyContactName ?? 'N/A'}{' '}
                  {patient?.emergencyContactRelationship ? ` / ${patient.emergencyContactRelationship}` : ''}
                  <br />
                  {formatPhoneNumber(patient?.emergencyContactPhone ?? '')}
                </ProfileDisplayValue>
              }
              renderForm={({ values, handleChange, handleBlur, touched, errors, setFieldValue }) => (
                <Grid container spacing={2}>
                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <TextField
                      className="fs-exclude"
                      name="emergencyContactName"
                      label="Full Name"
                      value={values.emergencyContactName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={(errors.emergencyContactName && touched.emergencyContactName) as boolean}
                    />
                  </Grid>

                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <TextField
                      className="fs-exclude"
                      name="emergencyContactRelationship"
                      label="Relationship"
                      value={values.emergencyContactRelationship}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={(errors.emergencyContactRelationship && touched.emergencyContactRelationship) as boolean}
                    />
                  </Grid>

                  <Grid
                    size={{
                      xs: 12,
                    }}
                  >
                    <PhoneTextField
                      className="fs-exclude"
                      name="emergencyContactPhone"
                      label="Mobile Phone"
                      value={values.emergencyContactPhone}
                      onChange={value => setFieldValue('emergencyContactPhone', value)}
                      onBlur={handleBlur}
                      fullWidth
                      error={(errors.emergencyContactPhone && touched.emergencyContactPhone) as boolean}
                      helperText={touched.emergencyContactPhone ? errors.emergencyContactPhone : null}
                    />
                  </Grid>
                </Grid>
              )}
              description="We only contact this person in cases where your therapist believes you are in immediate danger. Please
              ensure their contact number is up to date."
            />
          </Grid>
        </Grid>
      </Container>
    </AppLayout>
  )
}

function ProfileHeaderSkeleton() {
  return (
    <Header>
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        <Grid
          size={{
            xs: 12,
            sm: 4,
            md: 3,
            xl: 2,
          }}
        >
          <div className="v-align" style={{ justifyContent: 'center', width: '100%', height: 246 }}>
            <div style={{ width: 180, height: 180, borderRadius: '50%' }} className="loading" />
          </div>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 8,
            md: 9,
            xl: 10,
          }}
        >
          <h1 style={{ maxWidth: 300 }} className="loading">
            Loading
          </h1>
          <p className="loading" style={{ maxWidth: 400 }}>
            Loading
          </p>
        </Grid>
      </Grid>
    </Header>
  )
}

// using the as prop removes the inner span and makes the hover state cover the text. This is a workaround for that
function TextButtonLabel({ children, htmlFor }: { children: React.ReactNode; htmlFor: string }) {
  return (
    <TextButton as="label" htmlFor={htmlFor}>
      <span style={{ position: 'relative', zIndex: 1 }}>{children}</span>
    </TextButton>
  )
}

const Header = styled('header')`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @media (${BelowTablet}) {
    padding-bottom: 2rem;
    padding-top: 1.5rem;
    text-align: center;

    h1 {
      font-size: 32px;
    }
  }
`

const Container = styled(AppContainer)`
  padding-bottom: var(--margin-7);
`

const AvatarResponsive = styled(AuthorizedAvatar)`
  height: auto;
  margin: auto;
  max-width: 300px;
  width: 100%;
`

const Name = styled('h1')`
  @media (${BelowTablet}) {
    margin-top: -1rem;
  }
`

import * as Sentry from '@sentry/browser'

import { useAuthDataContext } from '@nuna/auth'
import { sessionCaptureService } from '@nuna/telemetry'

export function Telemetry() {
  const authData = useAuthDataContext()

  if (authData.login) {
    if (import.meta.env.PROD) {
      Sentry.setUser({
        email: authData.login ? authData.login.email : '',
        id: authData.login ? authData.login.id : '',
      })

      sessionCaptureService.identify(authData.login)
    }
  }

  return null
}

import { styled } from '@mui/material'

import { BasicSpecialtyFragment } from '@nuna/api'
import { BasicSpecialty, routeService, specialtiesService } from '@nuna/core'
import {
  BelowTablet,
  Card,
  Chip,
  ChipGroup,
  ChipLoader,
  Grid,
  IconDuplicate,
  OutlineButton,
  TextButtonExternalLink,
  toast,
} from '@nuna/tunic'

import { getFullLicenseTitle } from '../../../util/licensure'
import { WidthContainer } from '../Drawer'
import { ProviderBioPolicies } from './ProviderBioPolicies'

export interface ProviderBioProps {
  provider?: {
    id: string
    aboutBlurb?: string | null
    specialties: BasicSpecialtyFragment[]
    licenseTitle?: string | null
    slug?: string | null
    firstName: string
  }
}

const getPublicProfileLink = (slug: string) => {
  const providerUrlString = routeService.publicProviderRoute(slug, true)
  const providerUrl = new URL(providerUrlString)
  providerUrl.searchParams.set('source', 'client')

  return providerUrl.toString()
}

const providerDetailsExist = (details: BasicSpecialty[]) => details.length > 0

export function ProviderBio({ provider, ...props }: ProviderBioProps) {
  const specialties = provider?.specialties ?? []
  const providerChallenges = specialtiesService.getChallenges(specialties)
  const providerLanguages = specialtiesService.getLanguagesSpokenSpecialties(specialties)
  const providerClientFocus = specialtiesService.getProviderPopulationSpecialties(specialties)
  const providerAgeGroups = specialtiesService.getProviderAgesSpecialties(specialties)
  const providerModalities = specialtiesService.getModalitySpecialties(specialties)
  const nonEnglishLanguages = providerLanguages.filter(pl => pl.name !== 'English')

  const handleCopyPublicProfileClick = () => {
    navigator.clipboard.writeText(getPublicProfileLink(provider?.slug || ''))
    toast.info(`Link copied to clipboard`)
  }

  return (
    <Bio {...props}>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            md: 8,
          }}
        >
          {!provider && <div style={{ height: 200, width: '90%' }} className="loading" />}
          {provider && <AboutBlurb dangerouslySetInnerHTML={{ __html: provider?.aboutBlurb ?? '' }} />}
        </Grid>

        <Grid
          size={{
            xs: 12,
            md: 4,
          }}
        >
          {provider?.slug && (
            <Card depth={-1} className="p-3 mb-3" style={{ paddingBottom: 20 }}>
              <h6 className="text-black">Like this therapist?</h6>
              <div className="text-black" style={{ lineHeight: '1rem' }}>
                Share {provider.firstName}'s{' '}
                <TextButtonExternalLink href={getPublicProfileLink(provider?.slug ?? '')}>
                  practice website
                </TextButtonExternalLink>{' '}
                with someone you know.
              </div>
              <OutlineButton className="mt-3" variant="secondary" onClick={handleCopyPublicProfileClick}>
                <IconDuplicate className="mr-1" /> Copy Link
              </OutlineButton>
            </Card>
          )}

          <h3 className="h6 mb-3">Licenses</h3>

          {!provider && <p className={`h6 mb-4 text-normal loading`}></p>}

          {provider && provider.licenseTitle && (
            <p className="h6 mb-4 text-normal">
              <span className="text-medium">{provider.licenseTitle}</span>
              {`, ${getFullLicenseTitle(provider.licenseTitle)}`}
            </p>
          )}

          <h3 className="h6 mb-3">Challenges Treated</h3>

          <ChipGroup>
            {!provider && <ChipLoader quantity={4} small />}
            {provider &&
              providerChallenges.map(challenge => (
                <Chip key={challenge.id} small disabled>
                  {challenge.name}
                </Chip>
              ))}
          </ChipGroup>

          {providerDetailsExist(nonEnglishLanguages) && (
            <>
              <h3 className="h6 mt-4 mb-3">Languages Spoken</h3>

              <ChipGroup>
                {!provider && <ChipLoader quantity={4} small />}
                {provider &&
                  providerLanguages.map(language => (
                    <Chip key={language.id} small disabled>
                      {language.name}
                    </Chip>
                  ))}
              </ChipGroup>
            </>
          )}

          {providerDetailsExist(providerAgeGroups) && <h3 className="h6 mt-4 mb-3">Client Focus - Age Groups</h3>}

          <ChipGroup>
            {!provider && <ChipLoader quantity={4} small />}
            {provider &&
              providerAgeGroups.map(ageGroup => (
                <Chip key={ageGroup.id} small disabled>
                  {ageGroup.name}
                </Chip>
              ))}
          </ChipGroup>

          {providerDetailsExist(providerClientFocus) && <h3 className="h6 mt-4 mb-3">Client Focus - Population</h3>}

          <ChipGroup>
            {!provider && <ChipLoader quantity={4} small />}
            {provider &&
              providerClientFocus.map(focus => (
                <Chip key={focus.id} small disabled>
                  {focus.name}
                </Chip>
              ))}
          </ChipGroup>

          {providerDetailsExist(providerModalities) && <h3 className="h6 mt-4 mb-3">Methods</h3>}

          <ChipGroup>
            {!provider && <ChipLoader quantity={4} small />}
            {provider &&
              providerModalities.map(method => (
                <Chip key={method.id} small disabled>
                  {method.name}
                </Chip>
              ))}
          </ChipGroup>

          <ProviderBioPolicies provider={provider} />
        </Grid>
      </Grid>
    </Bio>
  )
}

const AboutBlurb = styled('div')`
  padding-right: 48px;

  @media (${BelowTablet}) {
    padding-right: 0;
  }
`
AboutBlurb.defaultProps = { className: 'large text-light paragraphs' }

const Bio = styled(WidthContainer)`
  flex: 0 0 50%;
  padding: 2rem;

  @media (${BelowTablet}) {
    height: 100%;
    overflow-y: auto;
  }
`
Bio.defaultProps = { as: 'section' }

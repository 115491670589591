import { MentalHealthLibraryContextProvider } from './MentalHealthLibraryContext'
import { FeaturedPosts } from './components/FeaturedPosts'
import { PostPlayer } from './components/PostPlayer'

export function MentalHealthLibraryWidget() {
  return (
    <MentalHealthLibraryContextProvider>
      <FeaturedPosts showMoreLink />
      <PostPlayer />
    </MentalHealthLibraryContextProvider>
  )
}

import { HTMLAttributes } from 'react'

import { GhostButton, IconClose } from '@nuna/tunic'

import { FilteredSummary } from '../FilteredSummary'
import { useSearchProviderContext } from '../SearchProviderContext'
import { FilterSearchField } from '../filter-controls/FilterSearchField'
import { AvailabilityFilterMenu } from './AvailabilityFilterMenu'
import { CoverageFilterMenu } from './CoverageFilterMenu'
import { DemographicsFilterMenu } from './DemographicsFilterMenu'
import { FavoritesFilterToggle } from './FavoritesFilterToggle'
import { SpecialtiesFilterMenu } from './SpecialtiesFilterMenu'

export function FilterBar(props: HTMLAttributes<HTMLDivElement>) {
  const { clearFilters, appliedFilters } = useSearchProviderContext()

  return (
    <div {...props}>
      <FilteredSummary style={{ marginBottom: 4 }} />
      <div className="v-align" style={{ marginTop: 6 }}>
        <AvailabilityFilterMenu />
        <CoverageFilterMenu className="ml-1" />
        <SpecialtiesFilterMenu className="ml-1" />
        <DemographicsFilterMenu className="ml-1" />
        <FavoritesFilterToggle className="ml-1" />
        <FilterSearchField className="ml-2" />
        <GhostButton
          variant="secondary"
          disabled={!appliedFilters.dirty}
          className="ml-auto"
          onClick={() => clearFilters()}
        >
          <IconClose className="mr-1" size={16} />
          Reset filters
        </GhostButton>
      </div>
    </div>
  )
}

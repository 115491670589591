import { useParams } from 'react-router-dom'

import { usePostListQuery } from '../generated/sanctuary-graphql'

export function useActivePost() {
  const { data } = usePostListQuery()
  const { postId } = useParams<{ postId: string }>()

  const posts = data?.getPosts ?? []

  return posts.find(post => post.id === postId) ?? null
}

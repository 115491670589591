import {
  AccessCodeCoverageDetailsFragment,
  CashCoverageDetailsFragment,
  EapCoverageDetailsFragment,
  InsuranceCoverageDetailsFragment,
  PaymentPreference,
} from '../internal-only/coverage.types'

export interface EAPCoverageFragment {
  type: PaymentPreference.Employer
  details: EapCoverageDetailsFragment
}

/**  Note that an invalid sponsor and an invalid EAP are not necessarily the same thing.
 * Invalid sponsor is relevant for showing errors when entering new coverage and EAP coverage can be invalid for reasons other than the sponsor being invalid.
 */
export enum InvalidSponsorReason {
  notOnTava = 'Not offering Tava',
  notAvailableToDependents = 'Not available to dependents',
  requiresReferralId = 'Sponsor requires a referralId',
}

// Allows showing errors in the CoverageStatus and EAPCard component during Intake
export interface InvalidSponsorCoverageFragment {
  type: PaymentPreference.Employer
  invalid: true
  details: {
    employerName: string
    employerLogo?: string
    invalidSponsorReason: InvalidSponsorReason
  }
}

export interface InsuranceCoverageFragment {
  type: PaymentPreference.Insurance
  details: InsuranceCoverageDetailsFragment
}

export interface CashCoverageFragment {
  type: PaymentPreference.Cash
  details: CashCoverageDetailsFragment
}

export interface AccessCodeCoverageFragment {
  type: PaymentPreference.Accesscode
  details: AccessCodeCoverageDetailsFragment
}

export type DiscriminatedPatientCoverageFragment =
  | EAPCoverageFragment
  | InsuranceCoverageFragment
  | CashCoverageFragment
  | AccessCodeCoverageFragment

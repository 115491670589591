import { HTMLAttributes } from 'react'

import { ProviderStatus, PublicProviderFragment } from '@nuna/api'
import { useAuthDataContext, useSourceReferralSearchParams } from '@nuna/auth'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { userService } from '@nuna/core'
import { Avatar, CardBody, FillButton, Skeleton, StatusLabel, makeTypographyComponent } from '@nuna/tunic'

import { getFullLicenseTitle } from '../../../util/licensure'
import { PublicProviderStates } from './PublicProviderStates'

const shouldShowScheduleButton = (provider: PublicProviderFragment, isProviderSourced: boolean) => {
  if (provider.p4Disabled) return false
  if (provider.credentialedInStates.length === 0) return false
  if (provider.status === ProviderStatus.AcceptingClients) return true
  if (provider.status === ProviderStatus.NotAcceptingClients && isProviderSourced) return true
  return false
}

interface PublicProviderCardProps extends HTMLAttributes<HTMLDivElement> {
  provider: PublicProviderFragment
  showScheduleButton?: boolean
}

export function PublicProviderCard({ provider, showScheduleButton, ...props }: PublicProviderCardProps) {
  const { login } = useAuthDataContext()
  const { isProviderSourced } = useSourceReferralSearchParams()
  const { openScheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()
  const isActive = shouldShowScheduleButton(provider, isProviderSourced)
  const handleScheduleClick = () => {
    openScheduleAppointmentDrawer(provider.id, { patientId: login?.patientId ?? '' })
  }

  return (
    <div {...props}>
      <div className="text-center">
        <Avatar url={provider.avatarUrl} size="md" />
        <Name>{userService.userFullName(provider)}</Name>
        <Title>{getFullLicenseTitle(provider.licenseTitle ?? '')}</Title>
        <StatusLabel intent={isActive ? 'information' : 'caution'}>
          {isActive ? 'Accepting new clients' : 'Not accepting new clients'}
        </StatusLabel>
        {isActive && <PublicProviderStates states={provider.credentialedInStates} />}
        {showScheduleButton && isActive && (
          <FillButton className="mt-3" onClick={handleScheduleClick}>
            Schedule appointment
          </FillButton>
        )}
      </div>
    </div>
  )
}

export function PublicProviderCardLoader() {
  return (
    <CardBody className="text-center">
      <Skeleton style={{ display: 'inline-block', borderRadius: 146 }} width={146} height={146} dimensionUnits="px" />
      <Name className="loading">Loading</Name>
      <Title className="loading">Loading</Title>
      <StatusLabel className="loading" intent="default">
        Loading
      </StatusLabel>
      <PublicProviderStates states={[]} className="loading" />
    </CardBody>
  )
}

const Name = makeTypographyComponent('h4 text-default my-1', 'h1')
const Title = makeTypographyComponent('text-medium text-dark-grey mb-4', 'p')

import { styled } from '@mui/material'
import { motion } from 'framer-motion'
import { useState } from 'react'

import { BelowTablet, IconChevronThick, borderGrey } from '@nuna/tunic'

interface FAQProps {
  faqs: {
    question: string
    answer: string
  }[]
}

export function FAQ({ faqs }: FAQProps) {
  return (
    <>
      {faqs.map(questionAnswerPair => (
        <AccordionItem key={questionAnswerPair.question} questionAnswer={questionAnswerPair} />
      ))}
    </>
  )
}

function AccordionItem({
  questionAnswer,
  ...props
}: {
  questionAnswer: {
    question: string
    answer: string
  }
}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <QuestionAnswerContainer {...props}>
      <button
        className="h5"
        onClick={() => setIsOpen(!isOpen)}
        style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center', width: '100%' }}
      >
        <IconChevronThick size={12} direction={isOpen ? 'down' : 'right'} />
        <Question>{questionAnswer.question}</Question>
      </button>

      <motion.div
        animate={{ height: isOpen ? 'auto' : 0 }}
        initial={false}
        transition={{ duration: 0.3, ease: 'easeOut' }}
        style={{ overflow: 'hidden' }}
      >
        <Answer dangerouslySetInnerHTML={{ __html: questionAnswer.answer ?? '' }}></Answer>
      </motion.div>
    </QuestionAnswerContainer>
  )
}

const QuestionAnswerContainer = styled('div')`
  border-bottom: 1px solid ${borderGrey};
  border-top: 1px solid ${borderGrey};
  padding-top: 1rem;

  & + & {
    border-top: 0;
  }

  svg {
    transition: transform 0.3s;
    flex: 0 0 auto;
  }
`

const Question = styled('div')`
  margin-left: 1rem;
  text-align: left;

  @media (${BelowTablet}) {
    font-size: 1.125rem;
  }
`

const Answer = styled('div')`
  padding-bottom: 1rem;
  padding-left: 1.75rem;

  p:last-child {
    margin-bottom: 0;
  }

  @media (${BelowTablet}) {
    && {
      font-size: 1.125rem;
    }
  }
`
Answer.defaultProps = { className: 'large paragraphs' }

import { Category } from '../generated/sanctuary-graphql'

export function getPostCategory(category: Category) {
  // Sanctuary's categories come in strings like "wellbeing_meditation_breathwork". The first one is too broad, the last one is too specific
  const desiredCategory = category.split('_')[1]

  // we don't want a category called "Anxiety Panic" so modifying this here
  if (desiredCategory === 'anxietyPanic') return 'anxiety'

  return desiredCategory
}

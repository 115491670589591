import { styled } from '@mui/material'
import moment from 'moment'

import { CapStatus } from '@nuna/api'
import { CapProgress } from '@nuna/coverage'
import { BelowTablet } from '@nuna/tunic'

import { Ticker } from '../../shared/components/Ticker'

export interface SessionLimitsModalCapHeaderProps {
  attendedCapStatus: CapStatus
  hasPaymentMethod: boolean
  capResetDate: string
}

export function SessionLimitsModalCapHeader({
  attendedCapStatus,
  capResetDate,
  hasPaymentMethod,
  ...props
}: SessionLimitsModalCapHeaderProps) {
  if (hasPaymentMethod && attendedCapStatus === CapStatus.Exhausted) {
    const capReset = moment(capResetDate)
    const today = moment()
    return (
      <Container {...props}>
        <div className="v-align">
          <Ticker style={{ marginRight: '0.5rem' }}>{capReset.diff(today, 'days').toString()}</Ticker>Days 'til reset
        </div>
      </Container>
    )
  }

  return (
    <Container {...props}>
      <div className="v-align">
        <CapProgress className="mb-2" />
      </div>
    </Container>
  )
}

const Container = styled('div')`
  margin-bottom: 1.5rem;

  @media (${BelowTablet}) {
    margin-top: 3.5rem;
  }
`
Container.defaultProps = { className: 'text-secondary' }

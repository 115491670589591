import { styled } from '@mui/material'

import { usePatientContextQuery } from '@nuna/api'
import { ClientCoveragePanel, FeePaymentMethodManagement, TransactionHistory } from '@nuna/coverage'
import { ProviderFeeList } from '@nuna/fee-policies'
import { Grid, IconDollarSymbol, body2 } from '@nuna/tunic'

import { AppLayout } from '../../../layouts/AppLayout'
import { ClientPaymentStatusAlert } from '../../../shared/components/ClientPaymentStatusAlert'
import { AppContainer } from '../../../shared/components/Container'
import { Subheader } from '../../../shared/components/Subheader'

export function CoverageAndBilling() {
  const { data, refetch } = usePatientContextQuery()

  if (!data) return null

  const patientId = data?.patientContext?.patient?.id

  return (
    <AppLayout paddingTop={0}>
      <AppContainer>
        <Subheader className="mb-5" />
        <h1 className="h4 v-align mb-5">
          <IconDollarSymbol color={body2} className="mr-1" /> Coverage and billing
        </h1>

        {!!patientId && <ClientPaymentStatusAlert scene={'billing'} />}

        <Section>
          <h2 className="h5">Coverage for sessions</h2>
          <ClientCoveragePanel isHorizontalLayout client={data.patientContext.patient} refetchClient={refetch} />
        </Section>

        <Section>
          <Grid container spacing={3}>
            <Grid
              size={{
                xs: 12,
                md: 9,
              }}
            >
              <h2 className="h5">Late cancelation and no-show fees</h2>
              <FeePaymentMethodManagement patientId={patientId} />
            </Grid>
            <Grid
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <ProviderFeeList />
            </Grid>
          </Grid>
        </Section>

        <Section>
          <h2 className="h5">Transaction history</h2>
          {!!patientId && <ClientPaymentStatusAlert scene={'transaction'} />}
          <TransactionHistory patientId={patientId} />
        </Section>
      </AppContainer>
    </AppLayout>
  )
}

const Section = styled('section')``
Section.defaultProps = { className: 'mb-6' }

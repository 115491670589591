import * as Yup from 'yup'
import { useFormikContext } from 'formik'
import { useState } from 'react'

import { PasswordValidator } from '@nuna/auth'
import { formService, signupService } from '@nuna/core'
import { Checkbox, DOBTextField, TextButtonExternalLink, TextField } from '@nuna/tunic'

const { composeHelperTextWithError } = formService
const { dobSchema, agreesToTermsSchema, passwordSchema, confirmPasswordSchema } = signupService

export interface PasswordDOBValues {
  dob: string
  password: string
  confirmPassword: string
  agreesToTerms: boolean
}

export const passwordDOBSchema = Yup.object().shape({
  dob: dobSchema,
  password: passwordSchema,
  confirmPassword: confirmPasswordSchema,
  agreesToTerms: agreesToTermsSchema,
})

export const passwordDOBInitialValues = {
  dob: '',
  password: '',
  confirmPassword: '',
  agreesToTerms: false,
}

export function PasswordDOBStep() {
  const { handleChange, errors, touched, values, handleBlur } = useFormikContext<PasswordDOBValues>()
  const [isPasswordFocused, setIsPasswordFocused] = useState(false)
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false)

  return (
    <>
      <div className="mb-2">
        <DOBTextField
          id="dob"
          label="Date of birth"
          value={values.dob}
          placeholder="MM/DD/YYYY"
          name="dob"
          fullWidth
          onBlur={handleBlur}
          onChange={handleChange}
          {...composeHelperTextWithError('', errors.dob, touched.dob)}
          slotProps={{ htmlInput: { 'data-testid': 'signup-dob', autoFocus: true } }}
        />
      </div>

      <TextField
        label="Password"
        type="password"
        name="password"
        onChange={handleChange}
        onFocus={() => setIsPasswordFocused(true)}
        onBlur={e => {
          setIsPasswordFocused(false)
          handleBlur(e)
        }}
        value={values.password}
        inputProps={{
          'data-testid': 'signup-password',
        }}
        {...composeHelperTextWithError('', errors.password, touched.password)}
        className="mb-2"
      />

      <PasswordValidator
        password={values.password}
        isExpanded={isPasswordFocused}
        error={(errors.password && touched.password) as boolean}
        wrapperPadding="0 0 16px"
      />

      <TextField
        label="Confirm password"
        type="password"
        name="confirmPassword"
        value={values.confirmPassword}
        onChange={handleChange}
        onFocus={() => setIsConfirmPasswordFocused(true)}
        onBlur={e => {
          setIsConfirmPasswordFocused(false)
          handleBlur(e)
        }}
        {...composeHelperTextWithError('', errors.confirmPassword, touched.confirmPassword)}
        inputProps={{
          'data-testid': 'signup-confirm-password',
        }}
        className="mb-2"
      />

      <PasswordValidator
        password={values.confirmPassword}
        successMessage="Oh yeah, it's a match"
        isExpanded={isConfirmPasswordFocused}
        confirm
        error={(errors.confirmPassword && touched.confirmPassword) as boolean}
        matchingPassword={values.password}
        wrapperPadding="0 0 16px"
      />

      <Checkbox
        className="mt-3"
        error={(errors.agreesToTerms && touched.agreesToTerms) as boolean}
        checked={values.agreesToTerms}
        name="agreesToTerms"
        onChange={handleChange}
        labelProps={{
          'data-testid': 'signup-tos',
        }}
      >
        <span>
          I agree to the{' '}
          <TextButtonExternalLink href="https://www.tavahealth.com/consent-to-care">
            Consent to Care
          </TextButtonExternalLink>{' '}
          &amp;{' '}
          <TextButtonExternalLink href="https://www.tavahealth.com/notice-of-privacy-practices">
            Notice of Privacy Practices
          </TextButtonExternalLink>
        </span>
      </Checkbox>
    </>
  )
}

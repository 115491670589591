import { useCallback } from 'react'

import { useClientContextForFeaturesLazyQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { LaunchDarklyContexts } from '@nuna/feature-flag'

export function useClientLaunchDarklyContext() {
  const { login } = useAuthDataContext()
  const [queryContext] = useClientContextForFeaturesLazyQuery()

  const buildLaunchDarklyContext = useCallback(async () => {
    const context: LaunchDarklyContexts = {}
    const { data } = await queryContext({ variables: { clientId: login?.patientId ?? '' } })
    if (data?.patientContext.company) {
      const { name, id } = data.patientContext.company
      context['eap-company'] = { name, key: id }
    }

    if (data?.patientCoverage && data?.patientCoverage.length > 0) {
      const coverage = data.patientCoverage[0]
      context.coverage = { key: coverage.type }
    }
    return context
  }, [queryContext, login])

  return buildLaunchDarklyContext
}

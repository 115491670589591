import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { ResetPasswordScreen, StytchProvider } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { EnvironmentContextProvider } from '@nuna/environment'
import { FeatureFlagCheck, FeatureFlagProvider } from '@nuna/feature-flag'
import { ConversationParticipantLookup, ConversationUnreadContextProvider } from '@nuna/messaging'
import { PageViewTracker, useSupportChat } from '@nuna/telemetry'
import { Toaster, globalStyles, tavaMuiTheme } from '@nuna/tunic'

import { environment } from '../environments/environment'
import { AppLayout } from '../layouts/AppLayout'
import { AssessmentBundleLayout } from '../layouts/AssessmentBundleLayout'
import { GuardianConsentRequired } from '../layouts/GuardianConsentRequiredLayout'
import { ScreenTakeoverLayout } from '../layouts/ScreenTakeoverLayout'
import { ClientAssessments } from '../scenes/assessments/ClientAssessments'
import { InviteLovedOnes } from '../scenes/family/InviteLovedOnes'
import { Home } from '../scenes/home/Home'
import { Intake as InsuranceIntake } from '../scenes/intake/InsuranceIntake'
import { LoginLanding } from '../scenes/login/LoginLanding'
import { MentalHealthLibrary } from '../scenes/mental-health-library/MentalHealthLibrary'
import { ClientMessages } from '../scenes/messages/ClientMessages'
import { CoverageAndBilling } from '../scenes/payment/CoverageAndBilling'
import { PrivacyPolicy } from '../scenes/privacy-policy/PrivacyPolicy'
import { Profile } from '../scenes/profile/Profile'
import { ProviderSuggestion } from '../scenes/provider-suggestion/ProviderSuggestion'
import { PublicProviderProfile } from '../scenes/public-providers/PublicProviderProfile'
import { Signup } from '../scenes/signup/Signup'
import { InternationalSignup } from '../scenes/signup/international/InternationalSignup'
import { SsoScreen } from '../scenes/sso/SsoScreen'
import { SwitchProvider } from '../scenes/switch-provider/SwitchProvider'
import { PatientVideo } from '../scenes/video/PatientVideo'
import { PatientVideoRedirect } from '../scenes/video/PatientVideoRedirect'
import { SelectProvider } from '../select-provider/v2/SelectProvider'
import { ArrowModalFarm } from '../shared/components/ArrowModalFarm'
import { AppContainer } from '../shared/components/Container'
import { ScrollToTop } from '../shared/components/ScrollToTop'
import { Telemetry } from '../shared/components/Telemetry'
import { useClientLaunchDarklyContext } from '../shared/hooks/useClientLaunchDarklyContext'
import {
  getInternationalRoute,
  getMentalHealthLibraryRoute,
  getProviderSuggestionRoute,
  getSignupRoute,
} from '../util/routes'
import { ClientInitializer } from './ClientInitializer'

const { conversationsBaseRoute, conversationsParticipantFullPath } = routeService

const wrapInAppLayout = (element: JSX.Element, paddingTop?: number | string | undefined) => (
  <AppLayout paddingTop={paddingTop}>
    <AppContainer>{element}</AppContainer>
  </AppLayout>
)

export function App() {
  const getLaunchDarklyContext = useClientLaunchDarklyContext()
  useSupportChat({ audience: 'client', environment })

  return (
    <BrowserRouter>
      {globalStyles}
      <Toaster />

      <EnvironmentContextProvider environmentValues={{ ...environment, app: 'arrow' }}>
        <FeatureFlagProvider getAdditionalContext={getLaunchDarklyContext}>
          <ThemeProvider theme={tavaMuiTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <StytchProvider b2bEnabled>
                <ConversationUnreadContextProvider>
                  <PageViewTracker />
                  <ScrollToTop />
                  <Telemetry />
                  <ArrowModalFarm />
                  <Routes>
                    {/* ScreenTakeoverLayout */}
                    <Route path={getSignupRoute()} element={<Signup />} />
                    <Route path={`${getSignupRoute()}/invite`} element={<Signup redirectToInvite />} />
                    <Route path={`${getSignupRoute()}/:companySlug`} element={<Signup />} />
                    <Route path={getInternationalRoute()} element={<InternationalSignup />} />

                    <Route path="/login" element={<LoginLanding />} />
                    <Route path="/login/:ssoOrgSlug" element={<SsoScreen />} />
                    <Route path="/login/:ssoOrgSlug/finish" element={<SsoScreen postFlow />} />
                    <Route path="/reset-password" element={<ResetPasswordScreen audience="client" />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path={routeService.publicProviderPattern} element={<PublicProviderProfile />} />

                    <Route path="/" element={<ClientInitializer />}>
                      <Route index element={<Home />} />

                      {/* MinorConsent */}
                      <Route path="/parent-guardian" element={<GuardianConsentRequired />} />

                      {/* AppLayout */}
                      <Route path="/limitfaq" element={<Home />} />
                      <Route path="/schedule-appointment/:providerId" element={<Home />} />
                      <Route path={routeService.noCurrentSessionRoute()} element={<Home />} />
                      <Route path="/manage-payment" element={<Navigate to={routeService.clientBilling()} replace />} />
                      <Route path={routeService.clientBilling()} element={<CoverageAndBilling />} />
                      <Route path="/select-provider" element={<SelectProvider />} />
                      <Route path="/switch-provider/*" element={<SwitchProvider />}>
                        <Route path=":providerId" element={<SwitchProvider />}>
                          <Route path="select/*" element={<SwitchProvider />} />
                        </Route>
                      </Route>
                      <Route path="/family-members" element={<Navigate to="/invite" replace />} />
                      <Route path="/invite" element={<InviteLovedOnes />} />
                      <Route path={routeService.clientProfile} element={<Profile />} />
                      <Route path={getProviderSuggestionRoute()} element={<ProviderSuggestion />} />
                      <Route path={`${conversationsBaseRoute}/*`} element={<ClientMessages />} />
                      <Route path="/assessments/:bundleId/*" element={<AssessmentBundleLayout />} />
                      <Route path="/assessments" element={wrapInAppLayout(<ClientAssessments />, 0)} />

                      <Route
                        path={`${conversationsParticipantFullPath}`}
                        element={wrapInAppLayout(<ConversationParticipantLookup />)}
                      />

                      {/* Mental Health Library */}
                      <Route
                        path={`${getMentalHealthLibraryRoute()}/*`}
                        element={
                          <FeatureFlagCheck flags={['mentalHealthLibrary']}>
                            <MentalHealthLibrary />
                          </FeatureFlagCheck>
                        }
                      />

                      {/* IntakeLayout */}
                      <Route path={`${routeService.intake()}/*`} element={<InsuranceIntake />} />

                      {/* Video */}
                      <Route path={routeService.videoSession()} element={<PatientVideoRedirect />} />
                      <Route path="/livesession" element={<Navigate to={routeService.videoSession()} />} />
                      <Route path={`${routeService.videoSession()}/:appointmentId`} element={<PatientVideo />} />
                    </Route>

                    {/* 404 TODO */}
                    <Route element={<ScreenTakeoverLayout />} />
                  </Routes>
                </ConversationUnreadContextProvider>
              </StytchProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </FeatureFlagProvider>
      </EnvironmentContextProvider>
    </BrowserRouter>
  )
}
